import { Button, Drawer, Grid, MenuItem, TextField } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { format } from "date-fns";
import moment from "moment";
import React from "react";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { CheckScreenPermission } from "../../Common/CheckScreenPermission";
import { PreserveFilters } from "../../Common/PreserveFilters";
import { ShowAlert, ShowAlertwithOptions } from "../../Common/ShowAlert";
import ShowModelAlert from "../../Common/ShowModelAlert";
import { IShowModelAlert, ShowModelAlertType } from "../../Common/interface";
import DataGrid from "../../Components/DataGrid/DataGrid";
import DataGridToolbar from "../../Components/DataGrid/DataGridToolbar";
import { DataGridFilterType, IAppliedFilter, IDataGridColumn, IDateRange, INumberRange } from "../../Components/DataGrid/Interfaces";
import { ShowBlocker } from "../../Components/LoaderComponent";
import { ShowToastr } from "../../Components/Toastr";
import ArrayHelper from "../../Helpers/ArrayHelper";
import { DateTimeHelper } from "../../Helpers/DateTimeHelper";
import ObjectHelper from "../../Helpers/ObjectHelper";
import { RightPanelState } from "../../Models/CommonInterfaces";
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";
import PropertyService from "../../Services/PropertyService";
import SpotService from "../../Services/SpotService";
import AddEditBookings from "../BookingsCalendar/AddEditBookings";
import { BookingType, IBookingDetailInfo, IPreZoneSpot, IUserSearchModel, IVehicle, MonthlyBookingType } from "../BookingsCalendar/interfaces";
import { IPropertyBasics, ISpot, ISpotBlockData } from "../Spots/Interfaces";
import EditBookingsModal from './EditBookings';
import { BookingDetailsHistory, EnumRecurringEventType, IReservation } from "./Interfaces";
import ModifyBooking from "./ModifyBooking";
import ReservationCalendar from "./ReservationCalendar";
import ReservationFilterView from "./ReservationFilterView";
import ReservationTimeGrid from "./ReservationTimeGrid";
import "./Schedule.css";
import { cloneDeep } from 'lodash';
import Bookinghistory from "../../Common/BookingHistory/Bookinghistory";

export default class ReservationList extends React.Component<IReservationListProps, IReservationListState> {
    private _spotService: SpotService;
    private _propertyService: PropertyService;
    private _spotListColumns: IDataGridColumn<IReservation>[] = [];
    private _defaultFilter: any = {
        property: {
            type: DataGridFilterType.StringList,
            filterValue: []
        },
        spotNumber: {
            type: DataGridFilterType.StringList,
            filterValue: []
        },
        parker: {
            type: DataGridFilterType.StringList,
            filterValue: []
        },
        license: {
            type: DataGridFilterType.StringList,
            filterValue: []
        },
        reservation: {
            type: DataGridFilterType.DateRange,
            filterValue: {
                start: undefined,
                end: undefined
            }
        },
        revenue: {
            type: DataGridFilterType.RevenueRange,
            filterValue: {
                start: undefined,
                end: undefined,
                optionalTag: ""
            }
        }
    };
    _isFiltered: boolean = false;
    private _default: IBookingDetailInfo = {
        userDetail: {
            parkerEncodeGuid: "",
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            isExistingUser: false
        },
        bookingType: BookingType['Hourly/Daily'],
        selectedProperty: null,
        selectedZone: null,
        selectedSpot: null,
        startTime: {
            dateTime: new Date(),
            timeString: "12:00 PM"
        },
        endTime: {
            dateTime: new Date(),
            timeString: "05:00 PM"
        },
        date: {
            endDate: new Date(),
            startDate: new Date()
        },
        monthlyBookingType: MonthlyBookingType['24/7'],
        vehicleDetail: null,
        selectedMonth: new Date(),
        selectedModifyMonth: new Date(),
        preZoneSpot: []
    }

    private _defaultShowModel: IShowModelAlert = {
        headerTitle: "",
        messageContent: "",
        showHideAlert: false,
        showModelAlertType: ShowModelAlertType.Info
    }

    constructor(props_: IReservationListProps) {
        super(props_);

        this.state = {
            viewMode: this.props.match.params.source == "spot" ? EnumReservationViewMode.Calendar : EnumReservationViewMode.List,
            selectedDate: new Date(),
            isReservationsLoaded: false,
            properties: [],
            spots: [],
            selectedProperty: null,
            selectedSpot: null,
            allReservations: [],
            filteredReservations: [],
            subFilteredReservations: [],
            calendarReservations: [],
            blocks: [],
            searchTerm: "",
            rightPanelState: RightPanelState.None,
            isFiltered: false,
            _autoApplyFilterKey: 0,
            editingReservation: null,
            isVisibleEditing: false,
            isScreenAccessible: false,
            disableProperty: true,
            disableSpot: true,
            isAddEditOpen: false,
            isOpenModifyBooking: false,
            bookingDetailInfo: { ...this._default },
            oldBookingDetailInfo: { ...this._default },
            hasUnsavedChanges: false,
            showModelAlert: { ...this._defaultShowModel },
            bookingDetails: null
        };

        this._spotListColumns = this._spotListColumns.concat([
            {
                key: "spacer1",
                name: ""
            },
            {
                key: "reservationStatusText",
                name: "STATUS",
                contentProvider: (row_: IReservation) => {
                    return (<div className={'list-status-text' + (row_.reservationStatusClassName ? ` ${row_.reservationStatusClassName}` : "")}>{row_.reservationStatusText}</div>);
                }
            },
            {
                key: "reservationTypeId",
                name: "Booking Type",
                contentProvider: (row_: IReservation) => {
                    let bookingType = BookingType[row_.reservationTypeId];
                    //bookingType = row_.isCashPayment ? BookingType[BookingType.Cash] : bookingType;
                    return (<span><span className="no-wrap-text">{bookingType}</span></span>);
                }
            },
            {
                key: "friendlyReservationId",
                name: "Booking Id"
            },
            {
                key: "start",
                name: "From",
                contentProvider: (row_: IReservation) => {
                    let date = DateTimeHelper.dateStringToDate(row_.start);
                    return (<span><span className="no-wrap-text">{format(date, "dd MMM yyyy")}</span><br /><span className="no-wrap-text">{format(date, "hh:mm aa")}</span></span>);
                }
            },
            {
                key: "end",
                name: "To",
                contentProvider: (row_: IReservation) => {
                    if (row_.friendlyReservationId === "GP23ZT703G") {
                        console.log("GP23ZT703G");
                    }
                    let date = DateTimeHelper.dateStringToDate(row_.end);
                    return (<span><span className="no-wrap-text">{format(date, "dd MMM yyyy")}</span><br /><span className="no-wrap-text">{format(date, "hh:mm aa")}</span></span>);
                }
            }
        ]);
        if (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) > -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) > -1) {
            this._spotListColumns.push({
                key: "parkerName",
                name: "Parker",
                getComparator: ArrayHelper.getNumberInStringComparator
            });
        }
        this._spotListColumns = this._spotListColumns.concat([
            {
                key: "licensePlateNumber",
                name: "License Plate",
                getComparator: ArrayHelper.getNumberInStringComparator
            },
            {
                key: "propertyName",
                name: "Property Name",
                getComparator: ArrayHelper.getNumberInStringComparator
            },
            {
                key: "friendlySpotId",
                name: "Spot Number",
                getComparator: ArrayHelper.getNumberInStringComparator
            },
            {
                key: "reservationAmount",
                name: "Booking Amount",
                contentProvider: (row_: IReservation) => {
                    return (<span>${row_.reservationAmount.toFixed(2)}</span>);
                },
                textAlignment: "right"
            },
            {
                key: "spacer2",
                name: ""
            }
        ]);

        this._spotService = new SpotService();
        this._propertyService = new PropertyService();
    }

    async componentDidMount() {
        const isAccessible = await CheckScreenPermission("user-interface-bookings");

        this.setState({
            isScreenAccessible: isAccessible
        }, () => {
            if (this.state.isScreenAccessible) {
                this._loadReservations();
            }
        });
    }
    componentWillUnmount() {
        PreserveFilters.setSearchTerm('bookingsSearch', this.state.searchTerm)
    }

    getReservationCallback = (searchTerm: string, onApiResponse: () => void,
        filteredBookingscache: any, filterSpot: any, selectedProperty: IPropertyBasics) => {
        let isBusy = false;

        ObjectHelper.GetAllResponse<IReservation>(
            (reservation: IReservation[]) => {
                return reservation.map((e: IReservation) => {
                    let reservationStatus = 'Reserved';
                    let className = "";

                    if (e.reservationTypeId == 1 || e.reservationTypeId == 3) {
                        if (e.reservationStatus == 1 && e.paymentStatus == 3) {
                            reservationStatus = 'Paid';
                            className = "paid";
                        }
                        else if (e.reservationStatus == 5) {
                            reservationStatus = 'Completed';
                            className = "completed";
                        }
                        else if (e.reservationStatus == 2) {
                            reservationStatus = 'Cancelled';
                            className = "cancelled";
                        }
                    }
                    else {
                        if ((e.reservationStatus == 1 && e.isMonthlyReservationActive) && e.paymentStatus == 3) {
                            if (e.isUpcomingMonthlyReservationActive) {
                                reservationStatus = 'Paid';
                                className = "paid";
                            } else {
                                reservationStatus = 'Subscription Cancelled';
                                className = "subscription-cancelled";
                            }
                        }
                        else if (e.reservationStatus == 2 || e.isMonthlyReservationActive) {
                            reservationStatus = "Cancelled"; // 'Unsubscribed';
                            className = "cancelled";
                        }
                    }

                    e.reservationStatusText = reservationStatus;
                    e.reservationStatusClassName = className;
                    e.start = new Date(e.start);
                    e.end = new Date(e.end);
                    if (e.isCashPayment) {
                        e.stripeTransactionsIds = "Cash Payment"
                    } else {
                        e.stripeTransactionsIds = e.stripeTransactionsIds.indexOf(",") == 0 ? e.stripeTransactionsIds.replace(',,', ',').substring(1) : e.stripeTransactionsIds.replace(',,', ',');
                    }

                    return e;
                });
            },
            (reservation: IReservation[], progress_: number) => {
                if (!isBusy) {
                    isBusy = true;
                    let updatedState: IReservationListState = {
                        allReservations: reservation,
                        filteredReservations: reservation.slice(0),
                        subFilteredReservations: searchTerm ? this._filterDataBySearch(reservation, searchTerm) : reservation.slice(0),
                        calendarReservations: reservation.slice(0),
                        searchTerm: searchTerm ? searchTerm : '',
                        isReservationsLoaded: true,
                        loaderPercentage: progress_ * 100
                    } as any;
                    this.setState(updatedState, () => { isBusy = false; });
                }
            }, this._spotService.getLatestReservations(), ",{\"reservationGuid\"").then(reservation => {
                let updatedState: IReservationListState = {
                    allReservations: reservation,
                    filteredReservations: reservation.slice(0),
                    subFilteredReservations: searchTerm ? this._filterDataBySearch(reservation, searchTerm) : reservation.slice(0),
                    calendarReservations: reservation.slice(0),
                    searchTerm: searchTerm ? searchTerm : '',
                    isReservationsLoaded: true,
                    loaderPercentage: 100
                } as any;

                if (filteredBookingscache) {
                    updatedState.appliedFilter = filteredBookingscache;
                }

                if (filterSpot) {
                    updatedState.selectedProperty = selectedProperty;
                }

                this.setState(updatedState, () => {
                    this.setState({ loaderPercentage: 0 }, () => {
                        isBusy = false;
                        onApiResponse();
                    });
                });
            });
    }

    private _loadReservations = () => {
        let apiCounts = 1;
        let properties: IPropertyBasics[] = [];
        let filterSpot = this.props.match.params.source == "spot";
        let selectedProperty: IPropertyBasics;
        let searchTerm = PreserveFilters.getSearchTerm('bookingsSearch');
        let filteredBookingscache = PreserveFilters.readPreservedFilter('bookings');
        let onApiResponse = () => {
            if (--apiCounts == 0) {

                let updatedState: IReservationListState = {
                    appliedFilter: this._defaultFilter,
                    properties: properties,
                    disableProperty: false,
                    disableSpot: false
                } as any;

                this.setState(updatedState, () => {
                    this.getReservationCallback(searchTerm, () => {
                        if (this.state.appliedFilter) {
                            let filteredProperties = this.state.appliedFilter?.property.filterValue as string[];
                            let filteredSpots = this.state.appliedFilter?.spotNumber.filterValue as string[];

                            if (filteredProperties && filteredProperties.length > 0) {
                                let propIndex_ = this.state.properties.map(x => x.propertyName).indexOf(filteredProperties[0])

                                selectedProperty = this.state.properties[propIndex_];

                                this.setState({
                                    selectedProperty: selectedProperty
                                }, () => {
                                    this._onPropertySelect(selectedProperty, (s_) => {
                                        this.setState({
                                            spots: s_
                                        }, () => {
                                            if (filteredSpots && filteredSpots.length > 0) {
                                                let spotIndex_ = s_.map(x => x.friendlySpotId).indexOf(filteredSpots[0]);
                                                this._onSpotSelect(s_[spotIndex_], () => {
                                                    this.filterItems();
                                                })
                                            } else {
                                                this.filterItems();
                                            }
                                        });
                                    });
                                });
                            } else {
                                this.filterItems();
                            }
                        }
                    }, filteredBookingscache, filterSpot, selectedProperty);
                });
            }
        }

        this._propertyService.getPropertiesBasics()
            .then(r => r.json())
            .then(r => {
                properties = r;

                if (filterSpot) {
                    selectedProperty = ArrayHelper.findObject(properties, "propertyGuid", this.props.match.params.propertyGuid) as IPropertyBasics;
                }
                onApiResponse();
            });
    }
    private _getResevationDetails(reservationGUID: string) {
        this._spotService.getReservationActivity(reservationGUID).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    if (data) {
                        this.setState({
                            bookingDetails: data,
                            rightPanelState: RightPanelState.BookingDetails,
                        });
                    }
                })
            }
        });
    }
    protected filterItems = () => {
        this._isFiltered = false;
        let filteredArray = this.state.allReservations.filter(s => {
            let isValid = true;

            if (this.state.appliedFilter && Object.keys(this.state.appliedFilter).length > 1) {
                let filteredProperties = this.state.appliedFilter?.property.filterValue as string[];
                let filteredSpots = this.state.appliedFilter?.spotNumber.filterValue as string[];
                let filteredParkers = this.state.appliedFilter?.parker.filterValue as string[];
                let filteredLicense = this.state.appliedFilter?.license.filterValue as string[];
                let filteredReservationDate = this.state.appliedFilter?.reservation.filterValue as IDateRange;
                let filteredRevenue = this.state.appliedFilter?.revenue.filterValue as INumberRange;

                if (filteredProperties && filteredProperties.length > 0 && isValid) {
                    this._isFiltered = true;
                    isValid = filteredProperties.indexOf(s.propertyName) > -1;
                }
                if (filteredSpots && filteredSpots.length > 0 && isValid) {
                    this._isFiltered = true;
                    isValid = filteredSpots.indexOf(s.friendlySpotId) > -1;
                }
                if (filteredParkers && filteredParkers.length > 0 && isValid) {
                    this._isFiltered = true;
                    isValid = filteredParkers.indexOf(s.parkerName) > -1;
                }
                if (filteredLicense && filteredLicense.length > 0 && isValid) {
                    this._isFiltered = true;
                    isValid = filteredLicense.indexOf(s.licensePlateNumber) > -1;
                }
                if (filteredReservationDate && (filteredReservationDate.start || filteredReservationDate.end) && isValid) {
                    this._isFiltered = true;
                    if (filteredReservationDate.start && filteredReservationDate.end) {
                        isValid = (s.start >= filteredReservationDate.start && s.start <= filteredReservationDate.end);
                    }
                    else if (filteredReservationDate.start) {
                        isValid = (s.start >= filteredReservationDate.start);
                    }
                    else if (filteredReservationDate.end) {
                        isValid = (s.start <= filteredReservationDate.end);
                    }
                }
                if (filteredRevenue && (filteredRevenue.start || filteredRevenue.end) && isValid) {
                    this._isFiltered = true;
                    if (filteredRevenue.start && filteredRevenue.end) {
                        isValid = (s.reservationAmount >= filteredRevenue.start && s.reservationAmount <= filteredRevenue.end);
                    }
                    else if (filteredRevenue.start) {
                        isValid = (s.reservationAmount >= filteredRevenue.start);
                    }
                    else if (filteredRevenue.end) {
                        isValid = (s.reservationAmount <= filteredRevenue.end);
                    }
                }
            }

            return isValid;
        });
        this.setState({ subFilteredReservations: this._filterDataBySearch(filteredArray, this.state.searchTerm) })
    }
    getReservations = (callback_: (reservations_: IReservation[]) => void) => {
        this._spotService.getLatestReservations()
            .then(r => r.json())
            .then(r => {
                let updatedList = r.map((e: IReservation) => {
                    let reservationStatus = 'Reserved';
                    let className = "";

                    if (e.reservationTypeId == 1 || e.reservationTypeId == 3) {
                        if (e.reservationStatus == 1 && e.paymentStatus == 3) {
                            reservationStatus = 'Paid';
                            className = "paid";
                        }
                        else if (e.reservationStatus == 5) {
                            reservationStatus = 'Completed';
                            className = "completed";
                        }
                        else if (e.reservationStatus == 2) {
                            reservationStatus = 'Cancelled';
                            className = "cancelled";
                        }
                    }
                    else {
                        if ((e.reservationStatus == 1 && e.isMonthlyReservationActive) && e.paymentStatus == 3) {
                            if (e.isUpcomingMonthlyReservationActive) {
                                reservationStatus = 'Paid';
                                className = "paid";
                            } else {
                                reservationStatus = 'Subscription Cancelled';
                                className = "subscription-cancelled";
                            }
                        }
                        else if (e.reservationStatus == 2 || e.isMonthlyReservationActive) {
                            reservationStatus = "Cancelled"; // 'Unsubscribed';
                            className = "cancelled";
                        }
                    }

                    e.reservationStatusText = reservationStatus;
                    e.reservationStatusClassName = className;
                    e.start = new Date(e.start);
                    e.end = new Date(e.end);
                    if (e.isCashPayment) {
                        e.stripeTransactionsIds = "Cash Payment"
                    } else {
                        e.stripeTransactionsIds = e.stripeTransactionsIds.indexOf(",") == 0 ? e.stripeTransactionsIds.replace(',,', ',').substring(1) : e.stripeTransactionsIds.replace(',,', ',');
                    }

                    return e;
                });

                callback_(updatedList);
            });
    }

    render() {
        if (!this.state.isReservationsLoaded || !this.state.isScreenAccessible) {
            // return <LoaderComponent loaderVisible={true} />
            return null;
        }

        return (
            <div className="mx-3 data-grid-container reservations-page">
                <ShowModelAlert {...this.state.showModelAlert} />
                {
                    (this.state.editingReservation || this.state.isVisibleEditing) &&
                    <EditBookingsModal
                        isOpen={true}
                        onClose={(s_: boolean) => {
                            this.setState({
                                editingReservation: null,
                                isVisibleEditing: false
                            }, () => {
                                if (s_) {
                                    ShowBlocker(true);
                                    this.getReservations((reservations_: IReservation[]) => {
                                        this.setState({
                                            allReservations: reservations_,
                                            appliedFilter: this.state.appliedFilter,
                                            _autoApplyFilterKey: new Date().getTime()
                                        }, () => {
                                            ShowBlocker(false);
                                        });
                                    });
                                }
                            })
                        }}
                        selectedReservation={this.state.editingReservation}
                    />
                }

                {
                    this.state.isOpenModifyBooking && <ModifyBooking
                        backToModify={() => {
                            this.setState({
                                rightPanelState: RightPanelState.Edit,
                                isOpenModifyBooking: false,
                                isAddEditOpen: true
                            });
                        }}
                        bookingDetailInfo={this.state.bookingDetailInfo}
                        isOpen={true}
                        onClose={(s_: boolean, showOverrideTF?: boolean) => {
                            this.closeDrawRightPanel(showOverrideTF);
                        }}
                        updateChange={(bookingDetailInfo: IBookingDetailInfo, updateParent: boolean = false) => {
                            if (updateParent) {
                                let isBookingDetailChanged = false;
                                if (bookingDetailInfo.selectedNewSpot && bookingDetailInfo.selectedSpot &&
                                    bookingDetailInfo.selectedNewSpot.spotGuid !== bookingDetailInfo.selectedSpot.spotGuid) {
                                    isBookingDetailChanged = true;
                                }
                                if (bookingDetailInfo.selectedNewZone && bookingDetailInfo.selectedZone &&
                                    bookingDetailInfo.selectedNewZone.spotGuid !== bookingDetailInfo.selectedZone.spotGuid) {
                                    isBookingDetailChanged = true;
                                }
                                this.setState({
                                    bookingDetailInfo,
                                    oldBookingDetailInfo: !isBookingDetailChanged ?
                                        {
                                            ...this.state.oldBookingDetailInfo,
                                            changedSpotGuid: bookingDetailInfo.changedSpotGuid,
                                            selectedSpot: bookingDetailInfo.selectedSpot,
                                            selectedZone: bookingDetailInfo.selectedZone,
                                            selectedNewSpot: bookingDetailInfo.selectedNewSpot ? { ...bookingDetailInfo.selectedNewSpot } : null,
                                            selectedNewZone: bookingDetailInfo.selectedNewZone ? { ...bookingDetailInfo.selectedNewZone } : null,
                                            nearBySpots: bookingDetailInfo.nearBySpots ? { ...bookingDetailInfo.nearBySpots } : undefined,
                                            totalPrice: bookingDetailInfo.totalPrice ?? "0.00",
                                            serviceFeePrice: bookingDetailInfo.serviceFeePrice ?? "0.00",
                                            totalPriceWithServiceFeeTax: bookingDetailInfo.totalPriceWithServiceFeeTax ?? "0.00",
                                            taxPrice: bookingDetailInfo.taxPrice ?? "0.00"
                                        } :
                                        {
                                            ...this.state.oldBookingDetailInfo,
                                            changedSpotGuid: bookingDetailInfo.changedSpotGuid,
                                            selectedSpot: bookingDetailInfo.selectedSpot,
                                            selectedZone: bookingDetailInfo.selectedZone,
                                            selectedNewSpot: null,
                                            selectedNewZone: null,
                                            nearBySpots: bookingDetailInfo.nearBySpots ? { ...bookingDetailInfo.nearBySpots } : undefined,
                                            totalPrice: bookingDetailInfo.totalPrice ?? "0.00",
                                            serviceFeePrice: bookingDetailInfo.serviceFeePrice ?? "0.00",
                                            totalPriceWithServiceFeeTax: bookingDetailInfo.totalPriceWithServiceFeeTax ?? "0.00",
                                            taxPrice: bookingDetailInfo.taxPrice ?? "0.00"
                                        },
                                    showModelAlert: { ...this._defaultShowModel }
                                }, () => {
                                    const bookingDetailInfoOrdered = this.state.bookingDetailInfo;
                                    const oldBookingDetailInfoOrdered = this.state.oldBookingDetailInfo;
                                    let hasUnsavedChanges = false;
                                    if (bookingDetailInfoOrdered && ((JSON.stringify(bookingDetailInfoOrdered.vehicleDetail) !==
                                        JSON.stringify(oldBookingDetailInfoOrdered.vehicleDetail)) ||
                                        (bookingDetailInfoOrdered.selectedSpot && bookingDetailInfoOrdered.selectedNewSpot &&
                                            bookingDetailInfoOrdered.selectedSpot.spotGuid !== bookingDetailInfoOrdered.selectedNewSpot.spotGuid) ||
                                        (bookingDetailInfoOrdered.bookingType === BookingType["Hourly/Daily"] &&
                                            (((bookingDetailInfoOrdered.date && oldBookingDetailInfoOrdered.date && ((
                                                moment(bookingDetailInfoOrdered.date.startDate).format("MM-DD-yyyy") !==
                                                moment(oldBookingDetailInfoOrdered.date.startDate).format("MM-DD-yyyy")) || (
                                                    moment(bookingDetailInfoOrdered.date.endDate).format("MM-DD-yyyy") !==
                                                    moment(oldBookingDetailInfoOrdered.date.endDate).format("MM-DD-yyyy")))) ||
                                                (bookingDetailInfo.endTime && oldBookingDetailInfoOrdered.endTime &&
                                                    bookingDetailInfo.endTime.timeString !== oldBookingDetailInfoOrdered.endTime.timeString) ||
                                                (bookingDetailInfo.startTime && oldBookingDetailInfoOrdered.startTime &&
                                                    bookingDetailInfo.startTime.timeString !== oldBookingDetailInfoOrdered.startTime.timeString)))) ||
                                        (bookingDetailInfo.bookingType === BookingType.Monthly &&
                                            ((moment(bookingDetailInfoOrdered.selectedMonth).format("MM-DD-yyyy") !==
                                                moment(oldBookingDetailInfoOrdered.selectedMonth).format("MM-DD-yyyy")))))) {
                                        hasUnsavedChanges = true;
                                    }
                                    this.setState({
                                        hasUnsavedChanges
                                    });
                                });
                            }
                            else {
                                this.setState({
                                    bookingDetailInfo,
                                    showModelAlert: { ...this._defaultShowModel }
                                });
                            }
                        }}
                        showModelAlert={(showModelAlert: IShowModelAlert, callBack?: () => void) => {
                            this.setState({
                                showModelAlert
                            }, callBack);
                        }}
                        closeModelAlert={(isSuccess?: boolean, recurringEventType?: number) => {
                            if (isSuccess) {
                                this.setState({
                                    hasUnsavedChanges: false
                                }, () => {
                                    ShowBlocker(true);
                                    let spotGuid = this.state.bookingDetailInfo.selectedNewSpot?.spotGuid ?? "";
                                    if (recurringEventType && (recurringEventType === EnumRecurringEventType.Today || recurringEventType === EnumRecurringEventType["This month"])) {
                                        spotGuid = this.state.bookingDetailInfo.reservation?.spotGuid ?? "";
                                    }
                                    this.refreshReservation(spotGuid,
                                        this.state.bookingDetailInfo.reservation?.reservationGuid ?? "", () => {
                                            this.closeDrawRightPanel();
                                            ShowBlocker(false);
                                            this._searchTermChange(this.state.searchTerm);
                                        });
                                });
                            }
                            else {
                                this.setState({
                                    showModelAlert: { ...this._defaultShowModel }
                                });
                            }
                        }}
                        selectedReservation={this.state.editingReservation}
                        getOldBookingDetailInfo={() => {
                            return this.state.oldBookingDetailInfo;
                        }}
                    />
                }

                {/* Header region of the page */}
                <Grid container className="mb-3">
                    <Grid item>
                        {/* Title of the page */}
                        <h1 className="list-header">
                            {
                                this.state.viewMode == EnumReservationViewMode.List ? "Bookings" : "Calendar"
                            }
                        </h1>
                    </Grid>
                    {
                        <React.Fragment>
                            <Grid className="px-4">
                                <Autocomplete
                                    className="mt-2 mr-2"
                                    options={this.state.properties}
                                    getOptionLabel={option_ => option_.propertyName}
                                    style={{ width: 200 }}
                                    size="small"
                                    value={this.state.selectedProperty}
                                    renderInput={(params) => <TextField {...params} label="Property" variant="outlined" />}
                                    onChange={(e_: any, property_: IPropertyBasics | null) => {
                                        this.setState({
                                            spots: [],
                                            selectedProperty: property_,
                                        }, () => {
                                            this._onPropertySelect(property_);
                                        });
                                    }}
                                    disabled={this.state.disableProperty}
                                />
                            </Grid>
                            <Grid>
                                <Autocomplete
                                    className="mt-2"
                                    options={this.state.spots}
                                    getOptionLabel={option_ => option_.friendlySpotId}
                                    style={{ width: 180 }}
                                    size="small"
                                    value={this.state.selectedSpot}
                                    renderInput={(params) => <TextField {...params} label="Spot" variant="outlined" />}
                                    onChange={(e_: any, spot_: ISpot | null) => {
                                        this._onSpotSelect(spot_);
                                    }}
                                    disabled={this.state.disableSpot}
                                />
                            </Grid>
                        </React.Fragment>
                    }
                    <Grid item xs>
                        {/* Grid toolbar */}
                        <DataGridToolbar
                            search={PreserveFilters.getSearchTerm('bookingsSearch')}
                            singularEntityName="booking"
                            pluralEntityName="bookings"
                            onSearch={this._searchTermChange}
                            isFiltered={this.state.isFiltered}
                            onFilterClick={() => {
                                this.setState({
                                    rightPanelState: RightPanelState.Filter
                                });
                            }}
                            onAddClick={() => {
                                this.setState({
                                    rightPanelState: RightPanelState.Add
                                });
                            }}
                            isNoAddButton={true}
                        >
                            <React.Fragment>
                            </React.Fragment>
                        </DataGridToolbar>
                    </Grid>
                    <div style={{ marginTop: '8px' }}>
                        <CSVLink data={this.state.subFilteredReservations} filename={"Bookings.csv"} className="suppress-link">
                            <MenuItem>Export to CSV</MenuItem>
                        </CSVLink>
                    </div>

                </Grid>

                {/* Data Grid */}
                {
                    this.state.viewMode == EnumReservationViewMode.List &&
                    <DataGrid
                        title="Reservation"
                        data={this.state.subFilteredReservations}
                        columns={this._spotListColumns}
                        appliedFilter={this.state.appliedFilter}
                        defaultSortColumnKey="start"
                        defaultSortDirection="desc"
                        loaderPercentage={this.state.loaderPercentage}
                        isNoMoreLink={!((GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1) || (GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1))}
                        isNoMoreLinkDisabled={(item_: IReservation) => {
                            return !this._validateEndTime(item_);
                        }}
                        isRowsNonSelectable={true}
                        onFilterTagRemoved={(filter_: IAppliedFilter) => {
                            let updatedState: IReservationListState = {
                                appliedFilter: filter_ as IAppliedFilter,
                                _autoApplyFilterKey: new Date().getTime()
                            } as IReservationListState as any;
                            if (filter_) {
                                if (!(filter_.property.filterValue && (filter_.property.filterValue as string[]).length)) {
                                    if (this.state.selectedProperty) {
                                        updatedState.selectedProperty = null;
                                        updatedState.selectedSpot = null;
                                        updatedState.spots = [];
                                    }
                                    if (filter_.spotNumber.filterValue && (filter_.spotNumber.filterValue as string[]).length) {
                                        // spot can't be selected without selecting property first!
                                        filter_.spotNumber.filterValue = [];
                                    }
                                }
                            }

                            this.setState(updatedState, () => {
                                PreserveFilters.preserveFilter('bookings', this.state.appliedFilter)
                            });
                        }}
                        onMoreClick={(item_: IReservation) => {
                            let optionTypes: any[] = [];

                            if (this._validateEndTime(item_)) {

                                if ((item_.reservationTypeId == 1 || item_.reservationTypeId == 2) && !item_.isCashPayment) {
                                    optionTypes.push({
                                        key: "modifybooking",
                                        label: "Modify Booking"
                                    })
                                }
                                optionTypes.push({
                                    key: "View in Calendar",
                                    label: (
                                        <Link
                                            to={`/Calendar/spot/${item_.propertyGuid}/${item_.spotGuid}`}
                                            style={{ textDecoration: "none", color: "inherit" }}
                                        >
                                            View in Calendar
                                        </Link>
                                    ),
                                });

                                optionTypes.push({
                                    key: "cancel",
                                    label: "Cancel"
                                });



                            }

                            return optionTypes;
                        }}
                        onRowClick={(key_) => {
                            this._getResevationDetails(key_.reservationGuid.toString().toUpperCase());

                        }}
                        onPopoverClick={(key_, item_) => {
                            // if (key_ == "rebook") {
                            //     this.setState({
                            //         editingReservation: item_
                            //     });
                            // }
                            // else 
                            if (key_ === "modifybooking") {
                                this._openModifyBooking(item_);
                            }
                            else if (key_ == "View in Calendar") {
                                const start = new Date(item_.start);
                                // set item(bookings) start date
                                localStorage.setItem("ReserStartDate", start.toDateString());
                            }
                            else if (key_ == "cancel") {
                                ShowAlertwithOptions(
                                    "Booking Cancellation",
                                    "Are you sure you want to cancel this booking?",
                                    "warning",
                                    "Yes, cancel immediately",
                                    item_.reservationTypeId == 2 && item_.isUpcomingMonthlyReservationActive ?
                                        "Yes, but only cancel upcoming monthly renewal"
                                        : null,
                                    "No, don't cancel")
                                    .then(response_ => {
                                        if (response_.option1) {
                                            this._cancelBooking(item_, true);
                                        }
                                        else if (response_.option2) {
                                            this._cancelBooking(item_, false);
                                        }
                                    });
                            }
                        }}
                    />
                }

                {/* Calendar View */}
                {
                    this.state.viewMode == EnumReservationViewMode.Calendar &&
                    <div className="reservation-calendar-view">
                        {
                            this.state.selectedSpot == null &&
                            <div className="block-reservation-calendar">Please select a spot to view calendar</div>
                        }
                        <div className="month-view d-flex flex-column h-100">
                            <ReservationCalendar
                                reservations={this.state.selectedSpot == null ? [] : this.state.calendarReservations}
                                selectedDay={this.state.selectedDate}
                                onDateSelected={date_ => {
                                    this.setState({
                                        selectedDate: date_,
                                        selectedTimeSlots: undefined
                                    });
                                }}
                                propertyDetails={this.state.selectedProperty}
                            />
                        </div>
                        <div className="day-view d-flex flex-column h-100">
                            <ReservationTimeGrid
                                reservations={this.state.selectedSpot == null ? [] : this.state.calendarReservations}
                                blocks={this.state.blocks}
                                selectedDay={this.state.selectedDate}
                                selectedSpot={this.state.selectedSpot}
                                selectedProperty={this.state.selectedProperty}
                                selectedTimeRange={this.state.selectedTimeSlots}
                                onSelect={(start_: Date | null, end_: Date | null) => {
                                    if (start_ && end_) {
                                        let startTime = format(start_, "HH:mm");
                                        let endTime = format(end_, "HH:mm");

                                        this.setState({
                                            selectedTimeSlots: {
                                                start: DateTimeHelper.timeStringToMinutes(startTime),
                                                end: DateTimeHelper.timeStringToMinutes(endTime)
                                            }
                                        });
                                    }
                                    else {
                                        this.setState({
                                            selectedTimeSlots: undefined
                                        });
                                    }
                                }}
                                onSelecting={() => {
                                    if (this.state.selectedTimeSlots) {
                                        this.setState({
                                            selectedTimeSlots: undefined
                                        });
                                    }
                                    return true;
                                }}
                                blockSelection={(spotGuid_: string, date_: Date, startTime_: number, endTime_: number) => {
                                    this._spotService.blockSpot(spotGuid_, {
                                        date: format(date_, "yyyy-MM-dd"),
                                        startTime: startTime_,
                                        endTime: endTime_
                                    }).then(r => {
                                        if (r.ok) {
                                            r.json().then(block_ => {
                                                block_.start = new Date(block_.startUtc);
                                                block_.end = new Date(block_.endUtc);

                                                this.state.blocks.push(block_);
                                                this.setState({
                                                    selectedTimeSlots: undefined,
                                                    blocks: this.state.blocks
                                                });
                                                ShowToastr("Successfully blocked selected duration");
                                            });
                                        }
                                        else {
                                            ShowAlert("", "Couldn't block the spot due to conflicts. Please try blocking again after reloading the page.", "error");
                                        }
                                    })
                                }}
                                onUnblock={block_ => {
                                    this._spotService.unblockSpot(block_.dailySpotScheduleGuid)
                                        .then(r => {
                                            if (r.ok) {
                                                let index = this.state.blocks.findIndex(b => b.dailySpotScheduleGuid == block_.dailySpotScheduleGuid);
                                                this.state.blocks.splice(index, 1);
                                                this.setState({
                                                    blocks: this.state.blocks
                                                });
                                                ShowToastr("Successfully unblocked selected duration");
                                            }
                                        });
                                }}
                                cancelBookings={this._cancelBooking}
                            />
                        </div>
                    </div>
                }

                {/* Right side drawer */}
                <Drawer
                    anchor="right"
                    variant={this.state.rightPanelState == RightPanelState.None ? "persistent" : "temporary"}
                    open={this.state.rightPanelState != RightPanelState.None}
                    onClose={() => {
                        this.closeDrawRightPanel();
                    }}
                >
                    {
                        this.state.rightPanelState !== RightPanelState.BookingDetails && <div className="mx-3">
                            <Grid container className={`right-panel-header p-2 ${this.state.isAddEditOpen ? "" : "border-bottom"}`}>
                                <Grid item xs>
                                    <h4 className="right-panel-title m-0">
                                        {this._getRightPanelTitle()}
                                    </h4>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={ () => 
                                        { 
                                            this.closeDrawRightPanel(); 
                                        }}>
                                        <CloseIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    }
                    {this._getRightPanelContent()}
                </Drawer>
            </div>
        );
    }

    private refreshReservation = (spotGuid: string, reservationGuid_: string, callBack?: () => void) => {
        this._spotService.getReservationDetails(reservationGuid_)
            .then(r => r.json())
            .then(r => {

                let reservationItem: IReservation = r;

                reservationItem && this.state.subFilteredReservations.forEach(reservation => {
                    if (reservation.reservationGuid === reservationGuid_) {
                        reservation.description = reservationItem.description;
                        reservation.reservationAmount = reservationItem.reservationAmount;
                        reservation.stripeTransactionsIds = reservationItem.stripeTransactionsIds.indexOf(",") === 0 ?
                            reservationItem.stripeTransactionsIds.replace(',,', ',').substring(1) :
                            reservationItem.stripeTransactionsIds.replace(',,', ',');
                        reservation.friendlySpotId = reservationItem.friendlySpotId;
                        reservation.spotGuid = reservationItem.spotGuid;
                        reservation.start = new Date(reservationItem.start);
                        reservation.end = new Date(reservationItem.end);
                        reservation.licensePlateNumber = reservationItem.licensePlateNumber;

                        return reservation;
                    }
                });

                this.setState({
                    subFilteredReservations: this.state.subFilteredReservations,
                    _autoApplyFilterKey: Math.random()
                }, callBack);
            });
    }

    private closeDrawRightPanel = (showOverrideTF?: boolean) => {
        const { isOpenModifyBooking, isAddEditOpen, hasUnsavedChanges } = this.state;
        if (isOpenModifyBooking || isAddEditOpen) {
            if (hasUnsavedChanges || showOverrideTF) {
                const showModelAlert: IShowModelAlert = {
                    headerTitle: "Close Without Booking?",
                    messageContent: <div style={{ textAlign: 'center' }}>
                        <div className="mt-3 bulk-upload-sub-content">
                            Are you sure you would like to close before
                        </div>
                        <div className="mt-1 bulk-upload-sub-content">
                            finishing the booking?
                        </div>
                    </div>,
                    showHideAlert: true,
                    showModelAlertType: ShowModelAlertType.Error,
                    confirmation: true,
                    yesButtonText: "Continue",
                    noButtonText: "Close",
                    yesCallBack: () => {
                        this.setState({
                            showModelAlert: { ...this._defaultShowModel }
                        });
                    },
                    noCallBack: () => {
                        this.setState({
                            rightPanelState: RightPanelState.None,
                            bookingDetailInfo: { ...this._default },
                            oldBookingDetailInfo: { ...this._default },
                            isOpenModifyBooking: false,
                            isAddEditOpen: false,
                            showModelAlert: { ...this._defaultShowModel },
                            hasUnsavedChanges: false
                        });
                    }
                }
                this.setState({
                    showModelAlert
                });
            }
            else {
                this.setState({
                    rightPanelState: RightPanelState.None,
                    bookingDetailInfo: { ...this._default },
                    oldBookingDetailInfo: { ...this._default },
                    isOpenModifyBooking: false,
                    isAddEditOpen: false,
                    showModelAlert: { ...this._defaultShowModel },
                    hasUnsavedChanges: false
                });
            }
        }
        else {
            this.setState({
                rightPanelState: RightPanelState.None,
                bookingDetailInfo: { ...this._default },
                oldBookingDetailInfo: { ...this._default },
                isOpenModifyBooking: false,
                isAddEditOpen: false,
                showModelAlert: { ...this._defaultShowModel },
                hasUnsavedChanges: false
            });
        }
    }

    private _openModifyBooking = (item_: IReservation) => {
        this._spotService.getReservationDetails(item_.reservationGuid)
            .then(res => {
                if (res.ok) {
                    res.json().then(response => {
                        const reservationDetail: any = response;

                        let startDate_ = reservationDetail.start;

                        if (reservationDetail.reservationTypeId === 2 && moment(startDate_).format('YYYY-MM') != moment(reservationDetail.end).format('YYYY-MM')) {
                            startDate_ = moment(reservationDetail.end).format('YYYY-MM-01');
                        }

                        const selectProperty = this.state.properties.find(x => x.propertyGuid === reservationDetail.propertyGuid) ?? null;
                        const selectedZone: IPreZoneSpot = {
                            zoneGuid: reservationDetail.zoneGuid,
                            zoneName: reservationDetail.zoneName,
                            propertyGuid: selectProperty?.propertyGuid ?? "",
                            spotGuid: reservationDetail.spotGuid,
                            friendlySpotId: reservationDetail.friendlySpotId,
                            isBooked: true,
                            otherFeatures: "",
                            weeklyScheduleString: null
                        }
                        const vehicleDetail: IVehicle | null = reservationDetail.vehicleGuid ? {
                            vehicleGuid: reservationDetail.vehicleGuid ?? "",
                            vehicleOwnerGuid: reservationDetail.parkerGuid ?? "",
                            licensePlate: reservationDetail.licensePlateNumber ?? "",
                            vehicleMake: reservationDetail.vehicleName ?? "",
                            vehicleModel: reservationDetail.vehicleModel ?? "",
                            vehicleColor: reservationDetail.vehicleColor ?? ""
                        } : null;

                        const bookingDetailInfo: IBookingDetailInfo = {
                            userDetail: {
                                parkerEncodeGuid: reservationDetail.encryptedUserGuid,
                                firstName: reservationDetail.parkerName ?? "",
                                lastName: reservationDetail.parkerName ?? "",
                                email: reservationDetail.email ?? "",
                                phoneNumber: reservationDetail.mobileNumber ?? "",
                                isExistingUser: true,
                                parkerGuid: reservationDetail.parkerGuid ?? ""
                            },
                            bookingType: reservationDetail.reservationTypeId,
                            selectedProperty: selectProperty,
                            selectedZone: selectedZone,
                            selectedSpot: { ...selectedZone },
                            startTime: {
                                dateTime: moment(reservationDetail.start).startOf('day').toDate(),
                                timeString: moment(reservationDetail.start).format("hh:mm A")
                            },
                            endTime: {
                                dateTime: moment(reservationDetail.end).startOf('day').toDate(),
                                timeString: moment(reservationDetail.end).format("hh:mm A")
                            },
                            date: {
                                endDate: moment(reservationDetail.end).startOf('day').toDate(),
                                startDate: moment(reservationDetail.start).startOf('day').toDate()
                            },
                            monthlyBookingType: reservationDetail.weeklyTimeBlock,
                            vehicleDetail: vehicleDetail,
                            selectedMonth: reservationDetail.weeklyTimeBlock === 2 ? 
                            moment(moment(reservationDetail.start).startOf('day').format("MM/DD/yyyy 06:00 A")).toDate() : 
                            moment(reservationDetail.start).startOf('day').toDate(),
                            selectedModifyMonth: reservationDetail.weeklyTimeBlock === 2 ? 
                            moment(moment(startDate_).startOf('day').format("MM/DD/yyyy 06:00 A")).toDate() : 
                            moment(startDate_).startOf('day').toDate(),
                            preZoneSpot: [],
                            reservation: reservationDetail
                        }
                        this.setState({
                            rightPanelState: RightPanelState.Edit,
                            isAddEditOpen: true,
                            bookingDetailInfo,
                            oldBookingDetailInfo: cloneDeep(bookingDetailInfo)
                        });
                    });
                }
                else {
                    res.json().then(response => {
                        ShowAlert("", response, "error");
                    });
                }
            });
    }

    private _cancelBooking = (reservation_: IReservation, initiateRefund_: boolean) => {
        let reservationGuid_ = reservation_.reservationGuid;
        this._spotService.cancelReservation(reservationGuid_, initiateRefund_)
            .then(data => {
                if (data.ok) {
                    data.json().then(resp => {
                        if (resp == "Spot Cancelled") {
                            // || resp == "Success"
                            let cancelledMessage: string = "";
                            if (initiateRefund_) {
                                cancelledMessage = "Booking(s) are cancelled and initiated refund."
                                if (reservation_.reservationTypeId == 3) {
                                    cancelledMessage = "Booking(s) are cancelled."
                                }
                            } else {
                                cancelledMessage = "Upcoming booking(s) are cancelled."
                            }

                            //, format(reservation_.start, "dd-MM-yyyy")
                            ShowBlocker(true);
                            this._spotService.getReservations(reservation_.spotGuid)
                                .then(r => r.json())
                                .then(r => {
                                    let reservationData = r.filter((r_: IReservation) => {
                                        return r_.reservationGuid == reservationGuid_;
                                    });

                                    let reservationItem: IReservation = reservationData[0];

                                    this.state.subFilteredReservations.forEach(reservation => {
                                        if (reservation.reservationGuid == reservationGuid_) {
                                            if (initiateRefund_) {
                                                reservation.reservationStatus = 2;
                                                reservation.reservationStatusText = "Cancelled";
                                                reservation.reservationStatusClassName = "cancelled";
                                                reservation.description = reservationItem.description;
                                                reservation.reservationAmount = reservationItem.reservationAmount;
                                                reservation.stripeTransactionsIds = reservationItem.stripeTransactionsIds.indexOf(",") == 0 ? reservationItem.stripeTransactionsIds.replace(',,', ',').substring(1) : reservationItem.stripeTransactionsIds.replace(',,', ',');
                                            } else {
                                                reservation.reservationStatusText = "Subscription Cancelled";
                                                reservation.reservationStatusClassName = "subscription-cancelled";
                                            }

                                            reservation.isUpcomingMonthlyReservationActive = false;

                                            return reservation;
                                        }
                                    });
                                    ShowBlocker(false);
                                    this.setState({
                                        subFilteredReservations: this.state.subFilteredReservations,
                                        _autoApplyFilterKey: Math.random()
                                    }, () => {
                                        ShowToastr(cancelledMessage);
                                    });
                                }).catch(() => ShowBlocker(false));
                        } else {
                            ShowBlocker(false);
                            ShowToastr("Booking(s) are not cancelled. Please contact admin.");
                        }

                    });
                }
            });
    }

    private _onPropertySelect(property_: IPropertyBasics | null, callback_?: (spots_: ISpot[]) => void): void {
        if (property_) {
            this._spotService.getPropertySpots(property_.propertyGuid)
                .then(r => r.json())
                .then(r => {
                    r = (r as ISpot[]).sort((a, b) => a.friendlySpotId > b.friendlySpotId ? 1 : -1);
                    this.setState({
                        spots: r
                    }, () => {
                        if (callback_) {
                            callback_(r);
                        }
                    });
                });
        }

        if (this.state.appliedFilter) {
            if (property_) {
                this.state.appliedFilter.property.filterValue = [property_.propertyName];
                this.state.appliedFilter.spotNumber.filterValue = [];
            }
            else {
                this.state.appliedFilter.property.filterValue = [];
                this.state.appliedFilter.spotNumber.filterValue = [];
            }

            this.setState({
                appliedFilter: this.state.appliedFilter,
                _autoApplyFilterKey: new Date().getTime(),
                selectedSpot: null
            });
        }
    }

    private _onSpotSelect(spot_: ISpot | null, callback_?: () => void): void {
        if (this.state.appliedFilter) {
            if (spot_) {
                this.state.appliedFilter.spotNumber.filterValue = [spot_.friendlySpotId];
            }
            else {
                this.state.appliedFilter.spotNumber.filterValue = [];
            }
            this.setState({
                selectedSpot: spot_,
                appliedFilter: this.state.appliedFilter,
                _autoApplyFilterKey: new Date().getTime()
            });
        }
    }


    private _getRightPanelTitle(): string {
        switch (this.state.rightPanelState) {
            case RightPanelState.Filter:
                return "Filter";
            case RightPanelState.Edit:
                return "Modify Booking";
        }

        return "";
    }


    private _getRightPanelContent() {
        let rightPanelViews = [<ReservationFilterView
            isVisible={this.state.rightPanelState == RightPanelState.Filter}
            data={this.state.allReservations}
            appliedFilter={this.state.appliedFilter}
            onFilterChange={(appliedFilter_, filteredData_, isFiltered_) => {
                console.log('Filter change');
                let calendarReservations = this.state.allReservations.filter(r => {
                    if (this.state.selectedSpot) {
                        return r.spotGuid == this.state.selectedSpot.spotGuid;
                    }

                    return false;
                });
                this.setState({
                    appliedFilter: appliedFilter_,
                    filteredReservations: filteredData_,
                    subFilteredReservations: this._filterDataBySearch(filteredData_, this.state.searchTerm),
                    calendarReservations: calendarReservations,
                    isFiltered: isFiltered_
                }, () => {
                    PreserveFilters.preserveFilter('bookings', this.state.appliedFilter)
                })
            }}
            onClose={() => this.setState({ rightPanelState: RightPanelState.None, isAddEditOpen: false })}
            autoApplyFilterKey={this.state._autoApplyFilterKey}
        />]
        if (this.state.isAddEditOpen) {
            rightPanelViews.push(<AddEditBookings
                onClose={() => {
                    this.closeDrawRightPanel();
                }}
                onNext={(bookingDetailInfo: IBookingDetailInfo, userSearchModel: IUserSearchModel | null) => {
                    this.setState({
                        rightPanelState: RightPanelState.Edit,
                        isOpenModifyBooking: true,
                        isAddEditOpen: false,
                        bookingDetailInfo
                    });
                }}
                isFromBookingSummary={false}
                handleChange={(bookingDetailInfo: IBookingDetailInfo) => {
                    const { oldBookingDetailInfo } = this.state;
                    if (JSON.stringify(bookingDetailInfo) !== JSON.stringify(oldBookingDetailInfo)) {
                        this.setState({
                            hasUnsavedChanges: true
                        });
                    }
                    else {
                        this.setState({
                            hasUnsavedChanges: false
                        });
                    }
                }}
                properties={[]}
                backToSearch={() => {
                }}
                bookingDetailInfo={{ ...this.state.bookingDetailInfo }}
                showModelAlert={(showModelAlert: IShowModelAlert, callback?: () => void) => {
                    this.setState({ showModelAlert });
                }}
                selectedProperty={null}
                userDetail={null}
                isModifiedBooking={true}
                isNoHeader={true}
                customClass={"calendar-block-container-update"}
            />);
        }
        else if (RightPanelState.BookingDetails === this.state.rightPanelState) {
            rightPanelViews.push(this._getBookingDetails());
        }

        return rightPanelViews;
    }
    private _getBookingDetails() {
        return (<Bookinghistory
            bookingDetails={this.state.bookingDetails}
            onClose={() => {
                this.setState({
                    rightPanelState: RightPanelState.None,
                    isAddEditOpen: false
                })
            }}
            isButtonEnabled={true}
            handleModify={(item_: BookingDetailsHistory) => {
                this._openModifyBooking(item_);
            }}
            handleCancel={(item_: BookingDetailsHistory) => {
                this.setState({
                    rightPanelState: RightPanelState.None,
                    isAddEditOpen: false
                })
                ShowAlertwithOptions(
                    "Booking Cancellation",
                    "Are you sure you want to cancel this booking?",
                    "warning",
                    "Yes, cancel immediately",
                    item_.reservationTypeId == 2 && item_.isUpcomingMonthlyReservationActive ?
                        "Yes, but only cancel upcoming monthly renewal"
                        : null,
                    "No, don't cancel")
                    .then(response_ => {
                        if (response_.option1) {
                            this._cancelBooking(item_, true);
                        }
                        else if (response_.option2) {
                            this._cancelBooking(item_, false);
                        }
                    });
            }}

        />)
    }

    private _searchTermChange = (searchTerm_: string): void => {
        this.setState({
            searchTerm: searchTerm_,

        }, () => {
            this.filterItems();
        });
    }

    private _filterDataBySearch = (data_: IReservation[], searchTerm_: string): IReservation[] => {
        const filter = data_.filter(d => {
            let bookingType = BookingType[d.reservationTypeId];
            return (
                d.friendlyReservationId?.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                d.parkerName?.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                d.licensePlateNumber?.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                d.friendlySpotId?.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                d.propertyName?.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                d.stripeTransactionsIds?.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                d.description?.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                bookingType?.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1);
        });
        return filter;
    }

    private _validateEndTime(item_: IReservation) {
        let endTime = new Date(item_.endTimeUtc);
        let dtLocal = new Date();
        let localUtc = new Date(dtLocal.toISOString().split('Z')[0]);

        return (endTime > localUtc && item_.reservationStatus != 2 && item_.reservationStatus != 5);
    }
}

interface IReservationListProps {
    match: {
        params: {
            source?: string;
            propertyGuid?: string;
            spotGuid?: string;
        }
    }
    backToSchedule: () => void;
}

interface IReservationListState {
    isReservationsLoaded: boolean;
    viewMode: EnumReservationViewMode;
    properties: IPropertyBasics[];
    spots: ISpot[];
    selectedProperty: IPropertyBasics | null;
    selectedSpot: ISpot | null;
    selectedDate: Date;
    allReservations: IReservation[];
    filteredReservations: IReservation[];
    subFilteredReservations: IReservation[];
    calendarReservations: IReservation[];
    blocks: ISpotBlockData[];
    searchTerm: string;
    appliedFilter?: IAppliedFilter;
    rightPanelState: RightPanelState;
    isFiltered: boolean;
    selectedReservation?: IReservation;
    _autoApplyFilterKey: number;

    selectedTimeSlots?: INumberRange;
    editingReservation: IReservation | null;
    isVisibleEditing: boolean;
    isScreenAccessible: boolean;
    loaderPercentage?: number;
    disableProperty: boolean;
    disableSpot: boolean;
    isAddEditOpen: boolean;
    isOpenModifyBooking: boolean,
    bookingDetailInfo: IBookingDetailInfo,
    oldBookingDetailInfo: IBookingDetailInfo,
    hasUnsavedChanges: boolean,
    showModelAlert: IShowModelAlert,
    bookingDetails: BookingDetailsHistory | null;
}

enum EnumReservationViewMode {
    List = 1,
    Calendar = 2
}