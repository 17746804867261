import React from "react";
import BaseFilterView, { IBaseFilterViewProps, IBaseFilterViewState } from "../PartialViews/BaseFilterView";
import SelectFilter from "../../Components/FilterComponents/SelectFilter";
import { DataGridFilterType, IDateRange, INumberRange, IAppliedFilter } from "../../Components/DataGrid/Interfaces";
import RadioListFilter from "../../Components/FilterComponents/RadioListFilter";
import DateRangeFilter from "../../Components/FilterComponents/DateRangeFilter";
import PriceRangeSelector from "../../Components/PriceRangeSelector";
import { IReservation } from "./Interfaces";
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";

export default class ReservationFilterView
    extends BaseFilterView<IBaseFilterViewProps<IReservation, IAppliedFilter>, IBaseFilterViewState<IAppliedFilter>> {
    private _allProperties: string[] = [];
    private _allSpotNumber: string[] = [];
    private _allLicenses: string[] = [];
    private _allParker: string[] = [];
    private _revenueFilters: string[] = [];

    constructor(props_: IBaseFilterViewProps<IReservation, IAppliedFilter>) {
        super(props_, {
            property: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            spotNumber: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            parker: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            license: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            reservation: {
                type: DataGridFilterType.DateRange,
                filterValue: {
                    start: undefined,
                    end: undefined
                }
            },
            revenue: {
                type: DataGridFilterType.RevenueRange,
                filterValue: {
                    start: undefined,
                    end: undefined,
                    optionalTag: ""
                }
            }
        } as IAppliedFilter);

        for (let reservation of props_.data) {
            if (this._allProperties.indexOf(reservation.propertyName) == -1) {
                this._allProperties.push(reservation.propertyName);
            }
            if (this._allSpotNumber.indexOf(reservation.friendlySpotId) == -1) {
                this._allSpotNumber.push(reservation.friendlySpotId);
            }
            if (this._allParker.indexOf(reservation.parkerName) == -1) {
                this._allParker.push(reservation.parkerName);
            }
            if (this._allLicenses.indexOf(reservation.licensePlateNumber) == -1) {
                this._allLicenses.push(reservation.licensePlateNumber);
            }
        }

        this._revenueFilters = [
            "Less than $50",
            "More than $50",
            "More than $100",
            "Custom"
        ]
    }

    protected renderFilterOptions = () => {
        return (
            <div className="filter-options-list">
                <SelectFilter
                    title="Spots"
                    placeholderText="Search by spot number"
                    items={this._allSpotNumber}
                    selectedItems={this.state.appliedFilter.spotNumber?.filterValue as string[]}
                    onChange={selection_ => {
                        if (this.state.appliedFilter.spotNumber) {
                            this.state.appliedFilter.spotNumber.filterValue = selection_;
                            this.setState({
                                appliedFilter: this.state.appliedFilter
                            });
                        }
                    }}
                />
                {/* <SelectFilter
                    title="Property"
                    placeholderText="Search by property name"
                    items={this._allProperties}
                    selectedItems={this.state.appliedFilter.property?.filterValue as string[]}
                    onChange={selection_ => {
                        if (this.state.appliedFilter.property) {
                            this.state.appliedFilter.property.filterValue = selection_;
                            this.setState({
                                appliedFilter: this.state.appliedFilter
                            });
                        }
                    }}
                /> */}
                {
                    (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) > -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) > -1) &&
                    <SelectFilter
                        title="Parker"
                        placeholderText="Search by parker name"
                        items={this._allParker}
                        selectedItems={this.state.appliedFilter.parker?.filterValue as string[]}
                        onChange={selection_ => {
                            if (this.state.appliedFilter.parker) {
                                this.state.appliedFilter.parker.filterValue = selection_;
                                this.setState({
                                    appliedFilter: this.state.appliedFilter
                                });
                            }
                        }}
                    />
                }
                <SelectFilter
                    title="License"
                    placeholderText="Search by license plate number"
                    items={this._allLicenses}
                    selectedItems={this.state.appliedFilter.license?.filterValue as string[]}
                    onChange={selection_ => {
                        if (this.state.appliedFilter.license) {
                            this.state.appliedFilter.license.filterValue = selection_;
                            this.setState({
                                appliedFilter: this.state.appliedFilter
                            });
                        }
                    }}
                />
                <DateRangeFilter
                    title="Bookings"
                    selectedDateRange={this.state.appliedFilter.reservation?.filterValue as IDateRange}
                    onChange={dateRange_ => {
                        if (this.state.appliedFilter.reservation) {
                            this.state.appliedFilter.reservation.filterValue = dateRange_;
                            this.setState({
                                appliedFilter: this.state.appliedFilter
                            });
                        }
                    }}
                />
                <RadioListFilter
                    title="Revenue"
                    placeholderText="Search by revenue"
                    items={this._revenueFilters}
                    selectedItem={(this.state.appliedFilter.revenue?.filterValue as INumberRange).optionalTag as string}
                    onChange={selection_ => {
                        if (this.state.appliedFilter.revenue) {
                            switch (this._revenueFilters.indexOf(selection_)) {
                                case 0:
                                    this.state.appliedFilter.revenue.filterValue = {
                                        start: undefined,
                                        end: 50,
                                        optionalTag: selection_
                                    };
                                    break;
                                case 1:
                                    this.state.appliedFilter.revenue.filterValue = {
                                        start: 50,
                                        end: undefined,
                                        optionalTag: selection_
                                    };
                                    break;
                                case 2:
                                    this.state.appliedFilter.revenue.filterValue = {
                                        start: 100,
                                        end: undefined,
                                        optionalTag: selection_
                                    };
                                    break;
                                case 3:
                                    this.state.appliedFilter.revenue.filterValue = {
                                        start: 65,
                                        end: 120,
                                        optionalTag: selection_
                                    };
                                    break;
                                default:
                                    this.state.appliedFilter.revenue.filterValue = {
                                        start: undefined,
                                        end: undefined,
                                        optionalTag: selection_
                                    };
                                    break;
                            }
                            this.setState({
                                appliedFilter: this.state.appliedFilter
                            });
                        }
                    }}
                >
                    <div className="slider-revenue-controller">
                        {
                            (this.state.appliedFilter.revenue.filterValue as INumberRange).optionalTag == "Custom" &&
                            <PriceRangeSelector
                                selectedRange={{
                                    start: ((this.state.appliedFilter.revenue && this.state.appliedFilter.revenue.filterValue as INumberRange).start ?
                                        (this.state.appliedFilter.revenue.filterValue as INumberRange).start : 0) as number,
                                    end: ((this.state.appliedFilter.revenue && this.state.appliedFilter.revenue.filterValue as INumberRange).end ?
                                        (this.state.appliedFilter.revenue.filterValue as INumberRange).end : 0) as number
                                }}
                                min={0}
                                max={500}
                                stepSize={5}
                                onChange={(start_: number, end_: number) => {
                                    this.state.appliedFilter.revenue.filterValue = {
                                        start: start_,
                                        end: end_,
                                        optionalTag: "Custom"
                                    };
                                    this.setState({
                                        appliedFilter: this.state.appliedFilter
                                    });
                                }}
                            />
                        }
                    </div>
                </RadioListFilter>
            </div>
        );
    }

    protected filterItems = () => {
        this._isFiltered = false;
        const { appliedFilter } = this.state;        
        let filteredProperties = appliedFilter.property.filterValue as string[];
        const { customFilter } = this.props;
        if (filteredProperties && filteredProperties.length === 0 && customFilter){
            if(customFilter.selectedProperties){
                appliedFilter.property.filterValue = [customFilter.selectedProperties.propertyName];
                filteredProperties = [customFilter.selectedProperties.propertyName];
            }
            else{
                filteredProperties = customFilter.properties.map((x: any) => { return x.propertyName; });
                appliedFilter.property.filterValue = filteredProperties;
            }
        }
        return this.props.data.filter(s => {
            let isValid = true;
            let filteredSpots = appliedFilter.spotNumber.filterValue as string[];
            let filteredParkers = appliedFilter.parker.filterValue as string[];
            let filteredLicense = appliedFilter.license.filterValue as string[];
            let filteredReservationDate = appliedFilter.reservation.filterValue as IDateRange;
            let filteredRevenue = appliedFilter.revenue.filterValue as INumberRange;

            if (filteredProperties && filteredProperties.length > 0 && isValid) {
                this._isFiltered = true;
                isValid = filteredProperties.indexOf(s.propertyName) > -1;
            }
            if (filteredSpots && filteredSpots.length > 0 && isValid) {
                this._isFiltered = true;
                isValid = filteredSpots.indexOf(s.friendlySpotId) > -1;
            }
            if (filteredParkers && filteredParkers.length > 0 && isValid) {
                this._isFiltered = true;
                isValid = filteredParkers.indexOf(s.parkerName) > -1;
            }
            if (filteredLicense && filteredLicense.length > 0 && isValid) {
                this._isFiltered = true;
                isValid = filteredLicense.indexOf(s.licensePlateNumber) > -1;
            }
            if (filteredReservationDate && (filteredReservationDate.start || filteredReservationDate.end) && isValid) {
                this._isFiltered = true;
                if (filteredReservationDate.start && filteredReservationDate.end) {
                    isValid = (s.start >= filteredReservationDate.start && s.start <= filteredReservationDate.end);
                }
                else if (filteredReservationDate.start) {
                    isValid = (s.start >= filteredReservationDate.start);
                }
                else if (filteredReservationDate.end) {
                    isValid = (s.start <= filteredReservationDate.end);
                }
            }
            if (filteredRevenue && (filteredRevenue.start || filteredRevenue.end) && isValid) {
                this._isFiltered = true;
                if (filteredRevenue.start && filteredRevenue.end) {
                    isValid = (s.reservationAmount >= filteredRevenue.start && s.reservationAmount <= filteredRevenue.end);
                }
                else if (filteredRevenue.start) {
                    isValid = (s.reservationAmount >= filteredRevenue.start);
                }
                else if (filteredRevenue.end) {
                    isValid = (s.reservationAmount <= filteredRevenue.end);
                }
            }

            return isValid;
        });
    }
}