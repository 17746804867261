import {Button, Grid, IconButton, TextField, Tooltip} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import {ArrowBackIos, CheckCircle} from "@material-ui/icons";
import Error from "@material-ui/icons//Error";
import CloseIcon from '@material-ui/icons/Close';
import React from "react";
import {Col, Row} from "reactstrap";
import {ShowAlertWithCustomIcon} from "../../Common/ShowAlert";
import StripeTerminals from "../../Common/StripeTerminals";
import ReceiptService from "../../Services/ReceiptService";
import SpotService from "../../Services/SpotService";
import {IAddCashBookingData, IInitiateReservationPayment} from "./Interfaces";

export default class BookingSummaryView extends React.Component<IBookingSummaryViewProps, IBookingSummaryViewState> {
  private _spotService: SpotService;
  private _receiptService: ReceiptService;

  constructor(props_: IBookingSummaryViewProps) {
    super(props_);

    this.state = {
      isBookingCompleted: false,
      receiptSuccess: false,
      receiptByPhone: false,
      email: "",
      mobileNumber: "",
      enabledSendBtnByEmail: false,
      enabledSendBtnByNumber: false,
      isPaymentIntent: false,
      IsPaymentFailed: false,
      PaymentError: "",
      isPaymentCancelled: false,
      isSuccessCancel: false,
      isHeldSpot: false
    };
    this._spotService = new SpotService();
    this._receiptService = new ReceiptService();
  }

  getReceiptMessage = (errorMessage: string) => {
    return (
      this.state.receiptSend && <Row>
          <Col>
            {this.state.receiptSuccess && <div className="receipt-success">Receipt sent</div>}
            {this.state.receiptSuccess === false &&
                <div className="receipt-error">{`Receipt failed. Please check your ${errorMessage}`}</div>}
            {this.state.receiptSuccess === undefined &&
                <div className="receipt-error">{`Please check your ${errorMessage}`}</div>}
          </Col>
      </Row>
    );
  }

  toMonthName = (date: any) => {
    return date.toLocaleString('en-US', {
      month: 'long',
    });
  }

  getDate = (date: number) => {
    return date < 10 ? `0${date}` : date;
  }

  getSelectedDates = (startDate: any, endDate: any) => {
    const startDateValue = this.getDate(startDate.getDate());
    const endDateValue = this.getDate(endDate.getDate());
    const startDateTime = `${this.toMonthName(startDate)} ${startDateValue}, ${startDate.getFullYear()}`;
    const endDateTime = `${this.toMonthName(endDate)} ${endDateValue}, ${endDate.getFullYear()}`;

    if (startDateTime === endDateTime) {
      return startDateTime;
    } else {
      return `${startDateTime} - ${endDateTime}`;
    }
  }

  render() {
    const {holdSpotResponse, addCashBookingData} = this.props;
    const {date_, startTime, endTime} = {...addCashBookingData};
    const bookedDate = this.getSelectedDates(date_.startDate, date_.endDate);
    const bookedTime = `${startTime.timeString} - ${endTime.timeString}`;
    const isCardPayment = addCashBookingData.transactionType === 3;
    let headerTitle = this.state.isBookingCompleted ? "Booking Complete" : "Booking Total";
    headerTitle = this.state.isPaymentIntent ? "" : headerTitle;
    return (
      <>
        <div className="mx-3">
          <Grid container className={`right-panel-header p-2`}>
            <Grid item xs>
              {<>
                <Grid container onClick={() => {
                  if (holdSpotResponse && holdSpotResponse.blockGuid && ((!this.state.isPaymentIntent && !this.state.isBookingCompleted) || this.state.isHeldSpot)) {
                    this._spotService.saveUnHoldSpot(holdSpotResponse.blockGuid)
                      .then(res => {
                        if (res.ok) {
                          if (this.state.isHeldSpot) {
                            const {addCashBookingData} = this.props;
                            addCashBookingData.selectedZone = null;
                            addCashBookingData.selectedSpot = null;
                            addCashBookingData.spots = [];
                            this.props.onUpdateState(addCashBookingData);
                          }
                          this.props.onCompleteClick();
                        }
                      });
                  } else {
                    this.state.isPaymentIntent && this.state.IsPaymentFailed && this._spotService.updateHoldMetadata(holdSpotResponse.blockGuid, true)
                      .then(r => {
                        if (r.ok) {
                          this.setState({
                            isPaymentCancelled: false,
                            isPaymentIntent: false,
                            isBookingCompleted: false,
                            PaymentError: ""
                          }, () => {
                            this.props.enableDisableOutsideClick(false);
                          });
                        }
                      });
                  }
                }}>
                  {((this.state.isPaymentIntent && this.state.IsPaymentFailed)
                      || (!this.state.isPaymentIntent && !this.state.isBookingCompleted))
                    && <Grid item xs={2} className="MuiGrid-grid-xs-2-custom">
                          <Tooltip title="Back">
                              <Grid container className="mt-4-px">
                                  <Grid item>
                                      <IconButton aria-label="Back" size="small">
                                          <ArrowBackIos/>
                                      </IconButton>
                                  </Grid>
                                  <Grid item className="mt-4-px back-button">
                                      Back
                                  </Grid>
                              </Grid>
                          </Tooltip>
                      </Grid>}
                  <Grid item xs={12}>
                    <h4
                      className={`right-panel-title ${!this.state.isPaymentIntent && this.state.isBookingCompleted ? "mt-35-px" : "m-0"}`}>
                      {headerTitle}
                    </h4>
                  </Grid>
                </Grid>
              </>
              }
            </Grid>
            {((this.state.isPaymentIntent && this.state.IsPaymentFailed) ||
              !this.state.isPaymentIntent) && <Grid item>
                <Button
                    onClick={() => {
                      this.props.onClose();
                    }}>
                    <CloseIcon/>
                </Button>
            </Grid>
            }
          </Grid>
        </div>
        <div className="cash-booking-edit-view edit-view p-3">
          {
            this.state.isPaymentIntent && <div className="panel-view-scrollable-area">
              {!this.state.IsPaymentFailed && <><Row className="mt-5">
                  <Col>
                      <div className="textCenter-align">
                          <CircularProgress
                              size={50}
                              thickness={5}
                              disableShrink/>
                      </div>
                  </Col>
              </Row>
                  <Row className="mt-2">
                      <Col>
                          <div
                              className="field-label-size textCenter-align">{this.state.isSuccessCancel ? "Cancelling" : "Processing"}</div>
                      </Col>
                  </Row>
                  <Row className="mt-1">
                      <Col>
                          <div className="field-label-size textCenter-align">Payment</div>
                      </Col>
                  </Row></>}
              {this.state.IsPaymentFailed && <>
                  <Row className="mt-6">
                      <Col>
                          <div className="textCenter-align">
                              <Error className="error-icon-size"/>
                          </div>
                      </Col>
                  </Row>
                  <Row className="mt-3">
                      <Col>
                          <div className="field-label-size textCenter-align">
                              Payment failed
                          </div>
                      </Col>
                  </Row>
                  <Row className="mt-2">
                      <Col>
                          <div className="textCenter-align">{this.state.PaymentError}</div>
                      </Col>
                  </Row></>}
              </div>
          }
          {
            (!this.state.isPaymentIntent && !this.state.isBookingCompleted) &&
              <div className="panel-view-scrollable-area">
                  <Row>
                      <Col>
                          <div className="panel-body-field-label field-label-size">{bookedDate}</div>
                      </Col>
                      <Col>
                          <div className="panel-body-field-label panel-right">${
                            isCardPayment ?
                              holdSpotResponse?.booking?.bestRate?.totalPrice.toFixed(2) ?? 0.00
                              : holdSpotResponse?.booking?.bestRate?.totalPriceWithServiceFee.toFixed(2) ?? 0.00
                          }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                          <div>{bookedTime}</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col className="bb-px-1"></Col>
                  </Row>
                  <Row>
                      <Col>
                          <div className="panel-body-field-label field-label-size">Subtotal</div>
                      </Col>
                      <Col>
                          <div className="panel-body-field-label panel-right">${isCardPayment ?
                            holdSpotResponse?.booking?.bestRate?.totalPrice.toFixed(2) ?? 0.00
                            : holdSpotResponse?.booking?.bestRate?.totalPriceWithServiceFee.toFixed(2) ?? 0.00}</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                          <div className="panel-body-field-label field-label-size">Taxes</div>
                      </Col>
                      <Col>
                          <div
                              className="panel-body-field-label panel-right">${holdSpotResponse?.booking?.bestRate?.taxPrice.toFixed(2) ?? 0.00}</div>
                      </Col>
                  </Row>
                {isCardPayment && <Row>
                    <Col>
                        <div className="panel-body-field-label field-label-size">Service Fee</div>
                    </Col>
                    <Col>
                        <div
                            className="panel-body-field-label panel-right">${holdSpotResponse?.booking?.bestRate?.serviceFeePrice.toFixed(2) ?? 0.00}</div>
                    </Col>
                </Row>}
                  <Row>
                      <Col className="bb-px-1"></Col>
                  </Row>
                  <Row>
                      <Col>
                          <div className="panel-body-field-label field-label-size">Total Due</div>
                      </Col>
                      <Col>
                          <div
                              className="panel-body-field-label panel-right">${holdSpotResponse?.booking?.bestRate?.totalPriceWithServiceFeeTax.toFixed(2) ?? 0.00}</div>
                      </Col>
                  </Row>
              </div>
          }
          {
            (!this.state.isPaymentIntent && this.state.isBookingCompleted) &&
              <div className="panel-view-scrollable-area py-3">
                  <Row className="mt-5">
                      <Col>
                          <div className="textCenter-align">
                              <CheckCircle className="check-icon-size"/>
                          </div>
                      </Col>
                  </Row>
                  <Row className="mt-2">
                      <Col>
                          <div className="textCenter-align">Parking has been booked successfully:</div>
                      </Col>
                  </Row>
                  <Row className="mt-4">
                      <Col>
                          <div className="field-label-size textCenter-align">{bookedDate}</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                          <div className="field-label-size textCenter-align mt-0">{bookedTime}</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col className="bb-px-1"></Col>
                  </Row>
                  <Row className="mt-2">
                      <Col>
                          <div className="textCenter-align">Send a copy of receipt by email or phone.</div>
                      </Col>
                  </Row>
                  <Row className="mt-2">
                      <Col>
                          <TextField
                              fullWidth
                              placeholder="Email Address"
                              variant="outlined"
                              required={true}
                              margin="normal"
                              className="mt-0 customHeight mb-px-0"
                              onPaste={() => {
                                return false
                              }}
                              onChange={(e_: any) => {
                                let email = e_.target.value;
                                const {mobileNumber} = this.state;
                                const enabledByEmail = this.validateEmail(email);
                                this.setState({
                                  receiptByPhone: (email === "" && !mobileNumber),
                                  email,
                                  receiptSend: false,
                                  enabledSendBtnByEmail: enabledByEmail
                                });
                              }}
                              autoComplete="off"
                          />
                      </Col>
                  </Row>
                {
                  !this.state.receiptByPhone && this.getReceiptMessage("email")
                }
                  <Row className="mt-2">
                      <Col>
                          <div className="textCenter-align">OR</div>
                      </Col>
                  </Row>
                  <Row className="mt-2">
                      <Col>
                          <TextField
                              fullWidth
                              placeholder="Phone Number"
                              variant="outlined"
                              required={true}
                              margin="normal"
                              className="mt-0 customHeight mb-px-0"
                              onPaste={() => {
                                return false
                              }}
                              inputProps={{maxLength: 10}}
                              onChange={(e_: any) => {
                                let mobileNumber = e_.target.value;
                                const {email} = this.state;
                                const enabledByNumber = this.validateMobile(mobileNumber);
                                this.setState({
                                  receiptByPhone: !email,
                                  mobileNumber,
                                  receiptSend: false,
                                  enabledSendBtnByNumber: enabledByNumber
                                });
                              }}
                              autoComplete="off"
                          />
                      </Col>
                  </Row>
                {
                  this.state.receiptByPhone && this.getReceiptMessage("phone number")
                }
              </div>
          }
          {
            <Row className="mt-3 mb-3">
              {(!this.state.isPaymentIntent && !this.state.isBookingCompleted &&
                addCashBookingData.transactionType === 5) && <Col>
                  <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this._spotService.saveConfirmCashBooking(holdSpotResponse.blockGuid)
                          .then(response => {
                            if (response.ok) {
                              response.json().then(res => {
                                this.setState({
                                  isBookingCompleted: true,
                                  isPaymentIntent: false,
                                  IsPaymentFailed: false,
                                  PaymentError: ""
                                }, () => {
                                  this.props.onCompleteClick(res);
                                });
                              });
                            }
                          });
                      }}
                  >Complete Booking</Button>
              </Col>
              }
              {(!this.state.isPaymentIntent && !this.state.isBookingCompleted &&
                isCardPayment) && <Col>

                  <StripeTerminals
                      callGlobalFunction={(globalFunction: () => void) => {
                        this.setState({
                          isPaymentIntent: true,
                          isBookingCompleted: false,
                          IsPaymentFailed: false,
                          PaymentError: ""
                        }, () => {
                          this.props.enableDisableOutsideClick(true);
                          globalFunction();
                        });
                      }}
                      paymentIntentClientSecret={() => this.paymentIntentClientSecret()}
                      buttonName="Charge"
                      callGlobalFunctionCompleted={(status: string, error?: any, paymentIntentId?: string, paymentType?: string) => {
                        if (!paymentType) {
                          if (this.state.isPaymentCancelled) {
                            ShowAlertWithCustomIcon(
                              "Cancel Payment",
                              `<div class="mt-3"> 
                                                        Payment cannot be cancelled.
                                                        </div>`,
                              "error",
                              "gryd-custom-popup",
                              '<div><svg class="MuiSvgIcon-root error-icon-size" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg></div>'
                            ).then(() => {
                              this.setState({
                                isPaymentCancelled: false
                              }, () => {
                                this.processPayment(status, paymentIntentId ?? "", error);
                              });
                            });
                          } else {
                            this.processPayment(status, paymentIntentId ?? "", error);
                          }
                        } else {
                          this.processCancellation(status, holdSpotResponse);
                        }
                      }}
                      isSimulated={process.env.REACT_APP_ENABLE_STRIPE_SIMULATER === "true"}
                      cancelButtonId="paymentCancelId"/>
              </Col>
              }
              {(!this.state.isPaymentIntent && this.state.isBookingCompleted) && <Col>
                  <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        const {
                          email,
                          mobileNumber,
                          receiptByPhone,
                          enabledSendBtnByEmail,
                          enabledSendBtnByNumber
                        } = this.state;
                        const {completeResponse} = this.props;
                        let isValidEmail = false;
                        let isValidMobileNumber = false;
                        let bodyContent: any = {
                          reservationGuid: completeResponse.reservationGuid,
                          recipientMail: email
                        };
                        let receiptByNumber = ((receiptByPhone || !enabledSendBtnByEmail) && enabledSendBtnByNumber);
                        if (!receiptByNumber) {
                          isValidEmail = this.validateEmail(email);
                        } else {
                          isValidMobileNumber = this.validateMobile(mobileNumber);
                          bodyContent = isValidMobileNumber ? {
                            reservationGuid: completeResponse.reservationGuid,
                            mobileNumber: parseInt(mobileNumber)
                          } : bodyContent;
                        }
                        if (!isValidEmail && !isValidMobileNumber) {
                          this.setState({
                            receiptSuccess: undefined, receiptSend: true, receiptByPhone: receiptByNumber,
                            isPaymentIntent: false, IsPaymentFailed: false, PaymentError: ""
                          });
                          return;
                        }

                        const sendPromiseService = receiptByNumber ?
                          this._receiptService.sendReciptBySms(bodyContent, "ignoreAlert") :
                          this._receiptService.sendReciptByEmail(bodyContent, "ignoreAlert");

                        sendPromiseService.then(response => {
                          if (response.ok) {
                            this.setState({
                              receiptSend: true, receiptSuccess: true, receiptByPhone: receiptByNumber,
                              isPaymentIntent: false, IsPaymentFailed: false, PaymentError: ""
                            });
                          } else {
                            this.setState({
                              receiptSuccess: false, receiptSend: true, receiptByPhone: receiptByNumber,
                              isPaymentIntent: false, IsPaymentFailed: false, PaymentError: ""
                            });
                          }
                        });
                      }}
                      disabled={!this.state.enabledSendBtnByEmail && !this.state.enabledSendBtnByNumber}
                  >Send Receipt</Button>
              </Col>
              }
              {this.state.isPaymentIntent && <Col>
                  <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      id={"paymentCancelId"}
                      onClick={() => {
                        if (holdSpotResponse && holdSpotResponse.blockGuid && this.state.isHeldSpot) {
                          this._spotService.saveUnHoldSpot(holdSpotResponse.blockGuid)
                            .then(res => {
                              if (res.ok) {
                                if (this.state.isHeldSpot) {
                                  const {addCashBookingData} = this.props;
                                  addCashBookingData.selectedZone = null;
                                  addCashBookingData.selectedSpot = null;
                                  addCashBookingData.spots = [];
                                  this.props.onUpdateState(addCashBookingData);
                                }
                                this.props.onCompleteClick();
                              }
                            });
                        } else if (this.state.isPaymentIntent && this.state.IsPaymentFailed) {
                          this._spotService.updateHoldMetadata(holdSpotResponse.blockGuid)
                            .then(r => {
                              if (r.ok) {
                                this.setState({
                                  isPaymentCancelled: false,
                                  isPaymentIntent: false,
                                  isBookingCompleted: false,
                                  PaymentError: ""
                                });
                              }
                            });
                        } else {
                          this.setState({PaymentError: "", isPaymentCancelled: true});
                        }
                      }}
                      disabled={this.state.isPaymentCancelled}
                  >Cancel</Button>
              </Col>
              }
            </Row>
          }
        </div>
      </>
    );
  }

  private processCancellation(status: string, holdSpotResponse: any) {
    if (status === "success") {
      this.setState({isSuccessCancel: true}, () => {
        ShowAlertWithCustomIcon(
          "Cancel Payment",
          `<div class="mt-3"> 
                    Payment has been cancelled successfully.
                    </div>`,
          "success",
          "gryd-custom-popup",
          '<svg class="MuiSvgIcon-root check-icon-size" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg>'
        ).then(() => {
          this._spotService.updateHoldMetadata(holdSpotResponse.blockGuid)
            .then(r => {
              if (r.ok) {
                this.setState({
                  isSuccessCancel: false,
                  isPaymentCancelled: false,
                  isPaymentIntent: false,
                  isBookingCompleted: false,
                  PaymentError: ""
                }, () => {
                  this.props.enableDisableOutsideClick(false);
                });
              }
            });
        }).catch(error => {
          this.setState({
            isPaymentIntent: true, IsPaymentFailed: true,
            PaymentError: error, isBookingCompleted: false,
            isSuccessCancel: false, isPaymentCancelled: false
          }, () => {
            this.props.enableDisableOutsideClick(false);
          });
        });
      });
    } else {
      ShowAlertWithCustomIcon(
        "Cancel Payment",
        `<div class="mt-3"> 
                Unable to cancel payment.
                </div>`,
        "error",
        "gryd-custom-popup",
        '<div><svg class="MuiSvgIcon-root error-icon-size" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg></div>'
      ).then(() => {
        this.setState({
          isPaymentCancelled: false
        });
      });
    }
  }

  private processPayment(status: string, paymentIntentId: string, error?: any) {
    if (status === "success") {
      let count = 10;
      this.getPaymentStatus(paymentIntentId, count);
    } else {
      let errorMsg = error?.message ?? error;
      const errorMessage = errorMsg === "SPOT_IS_NOT_HELD" ? "Your request has timed-out. Please try again." : `${errorMsg} Please try again.`;
      this.setState({
        isPaymentIntent: true, IsPaymentFailed: true,
        PaymentError: errorMessage, isBookingCompleted: false,
        isHeldSpot: errorMsg === "SPOT_IS_NOT_HELD"
      }, () => {
        this.props.enableDisableOutsideClick(false);
      });
    }
  }

  private getPaymentStatus(paymentIntentId: string, count: number) {
    this._spotService.paymentStatus(paymentIntentId)
      .then(response => {
        if (response.ok) {
          response.json().then(res => {
            this.setState({
              isBookingCompleted: true, isPaymentIntent: false,
              IsPaymentFailed: false, PaymentError: ""
            }, () => {
              if (paymentIntentId.indexOf("final") !== -1) {
                this.props.onClose(true);
              } else {
                this.props.onCompleteClick(res);
              }
            });
          });
        } else {
          if (count <= 0) {
            ShowAlertWithCustomIcon(
              "Payment error",
              `<div class="mt-3">There was an issue while processing the payment. Please try again.</div>`,
              "error",
              "gryd-custom-popup",
              '<div><svg class="MuiSvgIcon-root error-icon-size" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg></div>'
            ).then(() => {
              this.props.enableDisableOutsideClick(false);
              count < -1 && this.props.onClose(true);
              count--;
              count < 0 && !(count < -1) && this.getPaymentStatus(`${paymentIntentId}/final`, count);
            });
          } else {
            count--;
            this.getPaymentStatus(paymentIntentId, count);
          }
        }
      });
  }

  private paymentIntentClientSecret() {
    const {holdSpotResponse} = this.props;
    const InitiateReservationPayment: IInitiateReservationPayment = {
      temporaryBlockGuid: holdSpotResponse.blockGuid,
      paymentIntentType: "CashBooking"
    }
    return this._spotService.InitiateReservationPayment(InitiateReservationPayment)
      .then((response) => {
        return response.json();
      })
      .then((clientSecretId) => {
        return clientSecretId;
      });
  }

  private validateEmail(email: string) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  private validateMobile(mobile: string) {
    var phoneno = /^\d{10}$/;
    return phoneno.test(mobile);
  }
}

interface IBookingSummaryViewProps {
  onCompleteClick: (response?: any) => void;
  onClose: (ignoreChanges?: boolean) => void;
  addCashBookingData: IAddCashBookingData,
  holdSpotResponse: any,
  completeResponse: any,
  enableDisableOutsideClick: (disableOutsideClick: boolean) => void,
  onUpdateState: (addCashBookingData: IAddCashBookingData) => void
}

interface IBookingSummaryViewState {
  isBookingCompleted: boolean,
  receiptSend?: boolean,
  receiptSuccess: boolean | undefined,
  receiptByPhone: boolean,
  email: string,
  mobileNumber: string,
  enabledSendBtnByEmail: boolean,
  enabledSendBtnByNumber: boolean,
  isPaymentIntent: boolean,
  IsPaymentFailed: boolean,
  PaymentError: string,
  isPaymentCancelled: boolean,
  isSuccessCancel: boolean,
  isHeldSpot: boolean
}
