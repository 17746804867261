import { Button, IconButton, Tooltip } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";
import React from "react";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Label } from "reactstrap";
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";
import "./Bookinghistory.css";
import { AuditTrailDetails, BookingDetailsHistory } from "./Interfaces";
import StringHelper from "../../Helpers/StringHelper";


export default class BookingDetailsHistoryPage extends React.Component<BookingProps, BookingStates> {
    constructor(props_: BookingProps) {
        super(props_);
        this.state = {
            bookingDetails: this.props.bookingDetails
        }
    }

    private _parseActivityList() {
        let activityListItem: AuditTrailDetails[] = [];
        this.state.bookingDetails.auditTrailDetails.map((item: AuditTrailDetails, index: number) => {
            if (item.activityTypeId === 1) {
                let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                _auditTrailDetails.content = 'made booking'
                _auditTrailDetails.activityTypeId = item.activityTypeId;
                _auditTrailDetails.createdBy = item.createdBy;
                _auditTrailDetails.createdByName = item.createdByName;
                _auditTrailDetails.createdTime = item.createdTime;
                activityListItem.push(_auditTrailDetails as AuditTrailDetails)
            }
            if (item.activityTypeId === 2) {
                let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                _auditTrailDetails.content = 'booking has extended'
                _auditTrailDetails.activityTypeId = item.activityTypeId;
                _auditTrailDetails.createdBy = item.createdBy;
                _auditTrailDetails.createdByName = item.createdByName;
                _auditTrailDetails.createdTime = item.createdTime;
                activityListItem.push(_auditTrailDetails as AuditTrailDetails)
            }
            if (item.activityTypeId === 3) {
                //Date Time changes for monthly bookings
                if (this.state.bookingDetails.reservationTypeId === 2) {
                    let startTimeLocal = new Date(item.startTimeLocal);
                    let newStartTimeLocal = new Date(item.newStartTimeLocal);
                    if (startTimeLocal !== null && newStartTimeLocal !== null) {
                        if (startTimeLocal.getTime() !== newStartTimeLocal.getTime()) {
                            let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                            _auditTrailDetails.activityTypeId = item.activityTypeId;
                            _auditTrailDetails.content = " modified booking from " + "(" + this._modifiedTime(item.startTimeLocal.toString()) + ")" + " to " + "(" + this._modifiedTime(item.newStartTimeLocal.toString()) + ")";
                            _auditTrailDetails.createdBy = item.createdBy;
                            _auditTrailDetails.createdByName = item.createdByName;
                            _auditTrailDetails.createdTime = item.createdTime;
                            activityListItem.push(_auditTrailDetails as AuditTrailDetails)
                        }
                    }
                }
                //Date Time changes for hourly/daily bookings
                else if (this.state.bookingDetails.reservationTypeId === 1) {
                    let startTimeLocal = new Date(item.startTimeLocal);
                    let newStartTimeLocal = new Date(item.newStartTimeLocal);
                    let endTimeLocal = new Date(item.endTimeLocal);
                    let newEndTimeLocal = new Date(item.newEndTimeLocal);
                    if ((startTimeLocal !== null && newStartTimeLocal !== null) || (endTimeLocal !== null && newEndTimeLocal !== null)) {
                        if (startTimeLocal.getTime() !== endTimeLocal.getTime()) {
                            let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                            _auditTrailDetails.activityTypeId = item.activityTypeId;
                            _auditTrailDetails.content = " modified booking from " + "(" + this._modifiedTime(item.startTimeLocal.toString()) + " - " + this._modifiedTime(item.endTimeLocal.toString()) + ")" + " to " + "(" + this._modifiedTime(item.newStartTimeLocal.toString()) + " - " + this._modifiedTime(item.newEndTimeLocal.toString()) + ")";
                            _auditTrailDetails.createdBy = item.createdBy;
                            _auditTrailDetails.createdByName = item.createdByName;
                            _auditTrailDetails.createdTime = item.createdTime;
                            activityListItem.push(_auditTrailDetails as AuditTrailDetails)
                        }
                    }
                }
                //spot changes
                if (item.spotChangeDetails?.originalSpotGuid !== null && item.spotChangeDetails?.newSpotGuid !== null) {
                    if (item.spotChangeDetails?.newSpotGuid !== item.spotChangeDetails?.originalSpotGuid) {
                        if (item.spotChangeDetails?.spotChangeTypeId === 1) {
                            let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                            _auditTrailDetails.spotChangeDetails = item.spotChangeDetails;
                            _auditTrailDetails.activityTypeId = item.activityTypeId;
                            _auditTrailDetails.content = "changed spot from " + item.spotChangeDetails?.originalSpotName + " to " + item.spotChangeDetails?.newSpotName + ' for today ';
                            _auditTrailDetails.createdBy = item.createdBy;
                            _auditTrailDetails.createdByName = item.createdByName;
                            _auditTrailDetails.createdTime = item.createdTime;
                            activityListItem.push(_auditTrailDetails as AuditTrailDetails)
                        }
                        else if (item.spotChangeDetails?.spotChangeTypeId === 2) {
                            let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                            _auditTrailDetails.spotChangeDetails = item.spotChangeDetails;
                            _auditTrailDetails.activityTypeId = item.activityTypeId;
                            _auditTrailDetails.content = "changed spot from " + item.spotChangeDetails?.originalSpotName + " to " + item.spotChangeDetails?.newSpotName + ' for this month ';
                            _auditTrailDetails.createdBy = item.createdBy;
                            _auditTrailDetails.createdByName = item.createdByName;
                            _auditTrailDetails.createdTime = item.createdTime;
                            activityListItem.push(_auditTrailDetails as AuditTrailDetails)
                        }
                        else if (item.spotChangeDetails?.spotChangeTypeId === 3) {
                            let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                            _auditTrailDetails.spotChangeDetails = item.spotChangeDetails;
                            _auditTrailDetails.activityTypeId = item.activityTypeId;
                            _auditTrailDetails.content = "changed spot from " + item.spotChangeDetails?.originalSpotName + " to " + item.spotChangeDetails?.newSpotName + ' for this and future months ';
                            _auditTrailDetails.createdBy = item.createdBy;
                            _auditTrailDetails.createdByName = item.createdByName;
                            _auditTrailDetails.createdTime = item.createdTime;
                            activityListItem.push(_auditTrailDetails as AuditTrailDetails)
                        }
                        else if (item.spotChangeDetails?.spotChangeTypeId === 4) {
                            let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                            _auditTrailDetails.spotChangeDetails = item.spotChangeDetails;
                            _auditTrailDetails.activityTypeId = item.activityTypeId;
                            _auditTrailDetails.content = "changed spot from " + item.spotChangeDetails?.originalSpotName + " to " + item.spotChangeDetails?.newSpotName + ' for next month and future months ';
                            _auditTrailDetails.createdBy = item.createdBy;
                            _auditTrailDetails.createdByName = item.createdByName;
                            _auditTrailDetails.createdTime = item.createdTime;
                            activityListItem.push(_auditTrailDetails as AuditTrailDetails)
                        }
                        else {
                            let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                            _auditTrailDetails.spotChangeDetails = item.spotChangeDetails;
                            _auditTrailDetails.activityTypeId = item.activityTypeId;
                            _auditTrailDetails.content = "changed spot from " + item.spotChangeDetails?.originalSpotName + " to " + item.spotChangeDetails?.newSpotName;
                            _auditTrailDetails.createdBy = item.createdBy;
                            _auditTrailDetails.createdByName = item.createdByName;
                            _auditTrailDetails.createdTime = item.createdTime;
                            activityListItem.push(_auditTrailDetails as AuditTrailDetails)
                        }
                    }
                }
                // Price Changes
                // if (item.priceChangeDetails?.originalAmount !== 0 && item.priceChangeDetails?.newAmount !== 0) {
                if (item.priceChangeDetails?.originalAmount !== item.priceChangeDetails?.newAmount) {
                    if (item.priceChangeDetails?.priceChangeTypeId === 1) {
                        let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                        _auditTrailDetails.content = " updated price from $" + item.priceChangeDetails?.originalAmount.toFixed(2) + " to $" + item.priceChangeDetails?.newAmount.toFixed(2) + " for this month ";
                        _auditTrailDetails.activityTypeId = item.activityTypeId;
                        _auditTrailDetails.priceChangeDetails = item.priceChangeDetails;
                        _auditTrailDetails.createdBy = item.createdBy;
                        _auditTrailDetails.createdByName = item.createdByName;
                        _auditTrailDetails.createdTime = item.createdTime;
                        activityListItem.push(_auditTrailDetails as AuditTrailDetails)

                    }
                    else if (item.priceChangeDetails?.priceChangeTypeId === 2) {
                        let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                        _auditTrailDetails.content = " updated price from $" + item.priceChangeDetails.originalAmount.toFixed(2) + " to $" + item.priceChangeDetails.newAmount.toFixed(2) + " for next and future months ";
                        _auditTrailDetails.activityTypeId = item.activityTypeId;
                        _auditTrailDetails.priceChangeDetails = item.priceChangeDetails;
                        _auditTrailDetails.createdBy = item.createdBy;
                        _auditTrailDetails.createdByName = item.createdByName;
                        _auditTrailDetails.createdTime = item.createdTime;
                        activityListItem.push(_auditTrailDetails as AuditTrailDetails)
                    }
                    else if (item.priceChangeDetails?.priceChangeTypeId === 3) {
                        let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                        _auditTrailDetails.content = " updated price from $" + item.priceChangeDetails.originalAmount.toFixed(2) + " to $" + item.priceChangeDetails.newAmount.toFixed(2) + " for this and future months ";
                        _auditTrailDetails.activityTypeId = item.activityTypeId;
                        _auditTrailDetails.priceChangeDetails = item.priceChangeDetails;
                        _auditTrailDetails.createdBy = item.createdBy;
                        _auditTrailDetails.createdByName = item.createdByName;
                        _auditTrailDetails.createdTime = item.createdTime;
                        activityListItem.push(_auditTrailDetails as AuditTrailDetails)
                    }
                    else {
                        let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                        _auditTrailDetails.content = " updated price from $" + item.priceChangeDetails?.originalAmount.toFixed(2) + " to $" + item.priceChangeDetails?.newAmount.toFixed(2);
                        _auditTrailDetails.activityTypeId = item.activityTypeId;
                        _auditTrailDetails.priceChangeDetails = item.priceChangeDetails;
                        _auditTrailDetails.createdBy = item.createdBy;
                        _auditTrailDetails.createdByName = item.createdByName;
                        _auditTrailDetails.createdTime = item.createdTime;
                        activityListItem.push(_auditTrailDetails as AuditTrailDetails)

                    }
                }
                // }
                // Vechicle Changes
                if (item.vehicleDetails?.originalVehicleGuid !== null && item.vehicleDetails?.newVehicleGuid !== null) {
                    if (item.vehicleDetails?.originalVehicleGuid !== item.vehicleDetails?.newVehicleGuid) {
                        let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                        _auditTrailDetails.content = " changed vechicle from " + item.vehicleDetails?.originalLicense + " to " + item.vehicleDetails?.newLicense;
                        _auditTrailDetails.vehicleDetails = item.vehicleDetails;
                        _auditTrailDetails.activityTypeId = item.activityTypeId;
                        _auditTrailDetails.createdBy = item.createdBy;
                        _auditTrailDetails.createdByName = item.createdByName;
                        _auditTrailDetails.createdTime = item.createdTime;
                        activityListItem.push(_auditTrailDetails as AuditTrailDetails);
                    }
                }
            }

            //Renewal
            if (item.activityTypeId === 4) {
                let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                _auditTrailDetails.content = " has renewed";
                _auditTrailDetails.activityTypeId = item.activityTypeId;
                _auditTrailDetails.createdBy = item.createdBy;
                _auditTrailDetails.createdByName = item.createdByName;
                _auditTrailDetails.createdTime = item.createdTime;
                activityListItem.push(_auditTrailDetails as AuditTrailDetails);

            }
            //Refunded Amount
            if (item.activityTypeId === 5) {
                if (item.priceChangeDetails?.refundAmount !== 0) {
                    let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                    _auditTrailDetails.content = this._adjustableAmount(item.priceChangeDetails?.refundAmount);
                    _auditTrailDetails.activityTypeId = item.activityTypeId;
                    _auditTrailDetails.createdBy = item.createdBy;
                    _auditTrailDetails.createdByName = item.createdByName;
                    _auditTrailDetails.createdTime = item.createdTime;
                    activityListItem.push(_auditTrailDetails as AuditTrailDetails);
                }
            }
            //SubscriptionCancelled
            if (item.activityTypeId === 6) {
                let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                _auditTrailDetails.content = " has cancelled the subscription ";
                _auditTrailDetails.activityTypeId = item.activityTypeId;
                _auditTrailDetails.createdBy = item.createdBy;
                _auditTrailDetails.createdByName = item.createdByName;
                _auditTrailDetails.createdTime = item.createdTime;
                activityListItem.push(_auditTrailDetails as AuditTrailDetails);
            }
            //Cancelled
            if (item.activityTypeId === 7) {
                let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                _auditTrailDetails.content = " has cancelled the booking ";
                _auditTrailDetails.activityTypeId = item.activityTypeId;
                _auditTrailDetails.createdBy = item.createdBy;
                _auditTrailDetails.createdByName = item.createdByName;
                _auditTrailDetails.createdTime = item.createdTime;
                activityListItem.push(_auditTrailDetails as AuditTrailDetails);
            }
            //CheckOut
            if (item.activityTypeId === 8) {
                let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                _auditTrailDetails.content = " Booking Complete ";
                _auditTrailDetails.activityTypeId = item.activityTypeId;
                _auditTrailDetails.createdBy = item.createdBy;
                _auditTrailDetails.createdByName = item.createdByName;
                _auditTrailDetails.createdTime = item.createdTime;
                activityListItem.push(_auditTrailDetails as AuditTrailDetails);
            }
        })
        //booking complete
        if (this.state.bookingDetails.reservationTypeId !== 3 &&
            this.state.bookingDetails.reservationTypeId !== 2) {
            if (this._isPastEndDate(this.state.bookingDetails)) {
                let _auditTrailDetails: Partial<AuditTrailDetails> = {};
                _auditTrailDetails.content = " Booking Complete ";
                _auditTrailDetails.activityTypeId = 8;
                _auditTrailDetails.createdTime = this.state.bookingDetails.endTimeUtc;
                activityListItem.push(_auditTrailDetails as AuditTrailDetails);
            }
        }

        if (this.state.bookingDetails.reservationTypeId == 2 &&
            this._isPastEndDate(this.state.bookingDetails) &&
            this.state.bookingDetails.reservationStatus != 5 &&
            this.state.bookingDetails.reservationStatus != 2) {
            let _auditTrailDetails: Partial<AuditTrailDetails> = {};
            _auditTrailDetails.content =
                " This subscription may be a duplicate. Please check Stripe customer subscriptions. ";
            _auditTrailDetails.activityTypeId = 7; // This is just to display red activity circle.. not actually cancelled
            _auditTrailDetails.createdTime = this.state.bookingDetails.endTimeUtc;
            activityListItem.push(_auditTrailDetails as AuditTrailDetails);
        }

        return activityListItem;
    }
    private _adjustableAmount(amt: number | undefined) {
        let currencySign: string = '';
        if (amt !== undefined) {
            currencySign = amt < 0 ? "-$" + Math.abs(amt).toFixed(2) : "$" + amt.toFixed(2)
        }
        return "has refunded " + currencySign;
    }
    render() {
        return (<div className="booking-height">
            <div className="booking-details-bg">
                <div className="booking-details-header">
                    {(!this.props.isButtonEnabled) && (
                        <div className="header-in-cal-pg">
                            <Tooltip title="Go back" placement="right">
                                <IconButton
                                    aria-label="Back"
                                    size="small"
                                    onClick={this.props.backToSchedule}
                                >
                                    <ArrowBackIos style={{ color: '#8A959E' }} />
                                </IconButton>
                            </Tooltip>
                            <div style={{ flex: 1 }}>
                                <Button size="small" onClick={this.props.onClose}>
                                    <CloseIcon style={{ color: '#8A959E' }} />
                                </Button>
                            </div>
                        </div>
                    )}
                    {(this.props.isButtonEnabled) && (<div className="booking-details-header-label">
                        {
                            "Booking: " + this.state.bookingDetails?.friendlyReservationId
                        }
                    </div>
                    )}
                    {(this.props.isButtonEnabled) && <div style={{ flex: 1, marginTop: -5 }}>
                        <Button
                            onClick={this.props.onClose}>
                            <CloseIcon style={{ color: '#8A959E' }} />
                        </Button>
                    </div>}
                </div>
                {((!this.props.isButtonEnabled) &&
                    <div className="booking-details-header-label">
                        {
                            "Booking: " + this.state.bookingDetails?.friendlyReservationId
                        }
                    </div>
                )}
                <div style={{ height: 1, backgroundColor: '#E0E0E0', marginRight: 35 }}>

                </div>
                {
                    this._parkerBookingDate()
                }
                {
                    this._parkerVehicleDetails()
                }
                {
                    this._bookingDetailsActivity()
                }
                {
                    this._bookingDetailsNotes()
                }
            </div>
            {(this.props.isButtonEnabled) &&
                (this._validateBookingStatus(this.state.bookingDetails) && GetUserDetailsService.getUserDetails().userRoleID[0] === 1) && <div className="booking-bottom-bg">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginLeft: 20 }}>
                        <Button type="submit"
                            variant="outlined"
                            onClick={() => {
                                if (this.props.handleCancel) {
                                    this.props.handleCancel(this.state.bookingDetails);
                                }
                            }}
                            style={{ borderRadius: 8, marginRight: 14, borderWidth: 1, borderColor: '#00AF85', fontSize: 15, fontWeight: 700, color: '#00AF85' }}
                            color="primary" >
                            Cancel Booking
                        </Button>

                        {
                            ((this.state.bookingDetails.reservationTypeId == 1 || this.state.bookingDetails.reservationTypeId == 2) && !this.state.bookingDetails.isCashPayment) &&
                            <Button type="submit"
                                onClick={() => {
                                    if (this.props.handleModify) {
                                        this.props.handleModify(this.state.bookingDetails);
                                    }
                                }}
                                variant="contained"
                                style={{ borderRadius: 10, paddingTop: 10, paddingBottom: 10, paddingLeft: 40, paddingRight: 40, fontSize: 15, fontWeight: 500, color: '#F0F0F0' }}
                                color="primary" >
                                Modify
                            </Button>
                        }

                    </div>
                </div>
            }
        </div>);
    }
    private _validateBookingStatus(item_: BookingDetailsHistory) {
        const isCompletedOrCancelled = item_.reservationStatus == 5 || item_.reservationStatus == 2;
        const isMonthlyBooking = item_.reservationTypeId == 2;
        let endTime = new Date(item_.endTimeUtc);
        let dtLocal = new Date();
        let localUtc = new Date(dtLocal.toISOString().split("Z")[0]);

        if (isMonthlyBooking) {
            return isCompletedOrCancelled ? false : true
        }

        return endTime > localUtc && isCompletedOrCancelled == false;
    }
    private _isPastEndDate(item_: BookingDetailsHistory) {
        let endTime = new Date(item_.endTimeUtc);
        let dtLocal = new Date();
        let localUtc = new Date(dtLocal.toISOString().split('Z')[0]);
        return endTime < localUtc;
    }
    private _parkerBookingDate() {
        let parkingDate = <div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 14 }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Label className="booking-details-label">
                        From
                    </Label>
                    <Label className="booking-details-fields">
                        {
                            moment(this.state.bookingDetails?.start).format('DD MMM YYYY h:mmA')
                        }
                    </Label>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Label className="booking-details-label">
                        To
                    </Label>
                    <Label className="booking-details-fields">
                        {
                            moment(this.state.bookingDetails?.end).format('DD MMM YYYY h:mmA')
                        }
                    </Label>
                </div>

            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 16 }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Label className="booking-details-label">
                        Booking Amount
                    </Label>
                    {
                        '$' + this.state.bookingDetails?.reservationAmount
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    {
                        this._bookingStatus()
                    }
                </div>

            </div>


            <div style={{ display: 'block', marginTop: 16 }}>
                <Label className="booking-details-label">
                    Stripe Transaction Id(s)
                </Label>
                <Label className="booking-details-fields" style={{ wordBreak: 'break-word', marginRight: 35 }}>
                    {
                        StringHelper.trim(this.state.bookingDetails?.stripeTransactionsIds.replace(/[, ]+/g, ','), ',')
                    }
                </Label>
            </div>
            <div className="divider-bg">

            </div>
        </div>
        return (parkingDate);
    }
    private _bookingStatus = () => {
        let status: any;
        if (this.state.bookingDetails.reservationTypeId === 1) {
            if (this.state.bookingDetails.reservationStatus == 1 && this.state.bookingDetails.paymentStatus == 3) {
                status = <Label className="booking-details-paid-hourly-monthly">
                    Paid Hourly/Daily
                </Label>
            }

            else if (this.state.bookingDetails.reservationStatus == 2) {
                status = <Label className="booking-details-cancelled">
                    Cancelled
                </Label>
            }
        }
        else if (this.state.bookingDetails.reservationTypeId === 2) {
            if ((this.state.bookingDetails.reservationStatus == 1 && this.state.bookingDetails.isMonthlyReservationActive) && this.state.bookingDetails.paymentStatus == 3) {
                if (this.state.bookingDetails.isUpcomingMonthlyReservationActive) {
                    status = <Label className="booking-details-paid-hourly-monthly">
                        Paid Monthly
                    </Label>
                } else {
                    status = <Label className="booking-details-subscription-cancelled">
                        Subscription cancelled
                    </Label>
                }
            }
            else if (this.state.bookingDetails.reservationStatus == 2 || this.state.bookingDetails.isMonthlyReservationActive) {
                status = <Label className="booking-details-cancelled">
                    Cancelled
                </Label>
            }
        }
        else if (this.state.bookingDetails.reservationTypeId === 3) {
            if (this.state.bookingDetails.reservationStatus == 2) {
                status = <Label className="booking-details-cancelled">
                    Cancelled
                </Label>
            }
            else {
                status = <Label className="booking-details-reserved">
                    Reserved
                </Label>
            }
        }
        return status;
    }
    private _utcToLocal(time: string) {
        const localDate = new Date(time + 'Z');
        return moment(localDate).format('MM/DD/YY [at] h:mm A')
    }
    private _modifiedTime(time: string) {
        return moment(time).format('DD MMM YYYY h:mm A')
    }
    private _parkerVehicleDetails() {
        let vehicleDetail = <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Label className="booking-details-label">
                        Parker
                    </Label>
                    <Label className="booking-details-fields">
                        {
                            this.state.bookingDetails?.parkerName
                        }
                    </Label>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Label className="booking-details-label">
                        License Plate
                    </Label>
                    <Label className="booking-details-fields">
                        {this.state.bookingDetails?.licensePlateNumber}
                    </Label>
                </div>

            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 16 }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Label className="booking-details-label">
                        Property Name
                    </Label>
                    <Label className="booking-details-fields">
                        {
                            this.state.bookingDetails?.propertyName
                        }
                    </Label>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Label className="booking-details-label">
                        Booking Type
                    </Label>
                    {this.state.bookingDetails?.reservationTypeId === 1 && <Label className="booking-details-fields">
                        {
                            'Hourly/Daily'
                        }
                    </Label>
                    }
                    {this.state.bookingDetails?.reservationTypeId === 2 && <Label className="booking-details-fields">
                        {
                            'Monthly'
                        }
                    </Label>
                    }
                    {
                        this.state.bookingDetails?.reservationTypeId === 3 && <Label className="booking-details-fields">
                            {
                                'Lot Management'
                            }
                        </Label>
                    }
                </div>

            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 16 }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Label className="booking-details-label">
                        Spot Number
                    </Label>
                    <Label className="booking-details-fields">
                        {this.state.bookingDetails?.friendlySpotId}
                    </Label>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Label className="booking-details-label">
                        Zone
                    </Label>
                    <Label className="booking-details-fields">
                        {this.state.bookingDetails?.zoneName}
                    </Label>
                </div>

            </div>
            <div className="divider-bg">

            </div>
        </div>
        return (vehicleDetail);
    }



    private activityCircle(item: AuditTrailDetails) {
        let circle: any;
        if (item.activityTypeId === 8) {
            circle =
                <div className="booking-activity-circle-complete">
                </div>
        }
        else if (item.activityTypeId === 7) {
            circle =
                <div className="booking-activity-circle-cancel">
                </div>
        }
        else {
            circle =
                <div className="booking-activity-circle"> </div>
        }
        return circle;
    }
    private _activityAddIndividualItem(item: AuditTrailDetails, index: number, length: number) {
        return (<div className="activity-BG">
            {
                <div className="booking-activity-circle-BG">
                    {this.activityCircle(item)}
                    {index < length - 1 &&
                        <div className="booking-detail-verticle-line"></div>
                    }
                </div>
            }
            <div style={{ marginBottom: 8 }}>
                {
                    <div style={{ display: 'block', marginLeft: 6 }}>
                        <div>
                            <div className="booking-detail-activity-1" style={{ display: 'inline' }}>{item.createdByName}</div>
                            <div className="booking-detail-activity-2" style={{ display: 'inline' }}>{item.content}</div>
                        </div>
                        <div className="booking-detail-activity-3" style={{ display: 'inline' }}>
                            {
                                this._utcToLocal(item.createdTime)
                            }
                        </div>
                    </div>
                }
            </div>
        </div>)
    }

    private _bookingDetailsActivity() {
        let activity = <div>
            <div style={{}}>
                <div style={{ marginTop: 16, paddingLeft: 0, display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 20 }}>
                        <Label className="booking-details-label">
                            ACTIVITY
                        </Label>
                        {
                            this._parseActivityList().map((item: AuditTrailDetails, index: number, itemArray: AuditTrailDetails[]) => (
                                <div key={`ACTIVITY_${index.toString()}`}>
                                    {this._activityAddIndividualItem(item, index, itemArray.length)}
                                </div>
                            ))
                        }


                    </div>
                </div>
            </div>
            <div className="divider-bg">

            </div>
        </div>
        return (activity)
    }
    private _notesData() {
        return (<div className="booking-notes-parent">
            {
                this.state.bookingDetails?.auditTrailDetails?.map((item: AuditTrailDetails, index: number) => (
                    <div key={`booking-notes-parent_${index.toString()}`}>
                        {
                            item.notes !== null && item.notes !== '' && <div>
                                <div style={{ marginRight: 0, marginTop: 8 }}>
                                    {

                                        <Label data-after={this._utcToLocal(item.createdTime) + " . " + item.createdByName} className="booking-detail-notes-message">
                                            {
                                                item.notes
                                            }
                                            <br />
                                        </Label>
                                    }
                                </div>
                                <div style={{ height: 1, backgroundColor: '#E0E0E0', marginRight: 0, marginTop: 0, marginBottom: 0 }}>
                                </div>
                            </div>
                        }
                    </div>

                ))
            }

        </div>)
    }
    private _bookingDetailsNotes() {
        let notes = <div>
            <div>
                <div style={{ marginTop: 0, paddingLeft: 0, display: 'flex', flexDirection: 'row', marginRight: 0 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 0 }}>
                        <Label className="booking-details-label">
                            NOTES
                        </Label>
                        {
                            this._notesData()
                        }
                    </div>
                </div>
            </div>

        </div>
        return (notes)
    }

}
interface BookingStates {
    bookingDetails: any;
}
interface BookingProps {
    bookingDetails: any;
    onClose?: () => void;
    handleModify?: (item_: BookingDetailsHistory) => void;
    handleCancel?: (item_: BookingDetailsHistory) => void
    backToSchedule?: () => void;
    isButtonEnabled?: boolean
}
