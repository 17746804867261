import { Grid, Button } from "@material-ui/core";
import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

export default class BookingCalendarInfo extends React.Component<
  IBookingCalendarInfoProps,
  IBookingCalendarInfoState
> {
  constructor(props_: IBookingCalendarInfoProps) {
    super(props_);

    this.state = {
      selectedDate: new Date(),
      isRefreshRequired: false,
    };
  }

  componentDidUpdate() {
    if (this.state.selectedDate != this.props.viewDate) {
      this.setState(
        {
          selectedDate: this.props.viewDate,
          isRefreshRequired: true,
        },
        () => {
          this.setState({
            isRefreshRequired: false,
          });
        }
      );
    }
  }

  render() {
    return (
      <div className="c-rp-c-1">
        {/* <div className="calendar-button selected c-p-10">+ Block Spot</div> */}
        {/* <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className="bl-s-button mt-2"
          onClick={() => {
            if (this.props.onBlockSpotClick) {
              this.props.onBlockSpotClick(true);
            }
          }}>
          <span className="bl-sy-plus">+</span> Block Spot
        </Button> */}
        <div>
          <div className="c-le-c">
            <div style={{ position: "relative" }}>
              <h5>Legend</h5>
              <div className="c-legends">
                <div className="c-o">Open Spots</div>
                <div className="c-bo">Bookings</div>
                <div className="c-bl">Blockings</div>
              </div>
            </div>
          </div>
          <div className="rgt-btm-cal">
            <div className="c-le-c">
              {!this.state.isRefreshRequired && (
                <Calendar
                  formatShortWeekday={(locale, date) => {
                    let weekday = new Date(date).toLocaleString("en-us", {
                      weekday: "short",
                    });
                    return weekday.slice(0, -1);
                  }}
                  value={this.state.selectedDate}
                  calendarType={"US"}
                  onChange={(date: Date) => {
                    this.setState(
                      {
                        selectedDate: date,
                      },
                      () => {
                        if (this.props.selectedDate) {
                          this.props.selectedDate(date);
                        }
                      }
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface IBookingCalendarInfoProps {
  selectedDate: (s_: Date) => void;
  viewDate: Date;
  onBlockSpotClick: (b_: boolean) => void;
}

interface IBookingCalendarInfoState {
  selectedDate: Date;
  isRefreshRequired: Boolean;
}
