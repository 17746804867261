import { Button, TextField } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactSelect from 'react-select';
import walletImg from '../../Images/wallet.png';
import SpotService from "../../Services/SpotService";
import { ShowAlert } from "../ShowAlert";
import './Popup.css';

const PromoCodePopup = (_props: any) => {
    const [code, setCode] = useState("");
    const [selectedCode, setSelectedCode] = useState();
    const [disableApplyBT, setDisableApplyBT] = useState(false);
    const [coupon, setCoupon] = useState([]);
    const [parkerEncodeGuid, setParkerEncodeGuid] = useState('');
    const [dropDownDefaultSelect, setDropDownDefaultSelect]: any = useState({});
    const [dropDownDisplaySelect, setDropDownDisplaySelect]: any = useState({});
    const [showCodeErrorLbl, setShowCodeErrorLbl] = useState(false);
    const api = new SpotService();
    let couponArr: any[] = []
    useEffect(() => {
        setParkerEncodeGuid(_props.ParkerEncodeGuid);
    }, [])

    useEffect(() => {
        console.log('parkerEncodeGuid', parkerEncodeGuid);
        if (parkerEncodeGuid != '') {
            getCouponList();
        }
    }, [parkerEncodeGuid])

    useEffect(() => {

        if (coupon.length > 0) {
            couponArr = coupon
        }

    }, [coupon])

    useEffect(() => {
        if (dropDownDefaultSelect.code) {
            setDisableApplyBT(true);
            setSelectedCode(dropDownDefaultSelect.code);
        }
    }, [dropDownDefaultSelect])

    useEffect(() => {
        if (code.length === 8 || code.length === 9 || code.length === 14) {
            setDisableApplyBT(true);
        } else {
            setDisableApplyBT(false);
        }

    }, [code])

    const getAccessToken = () => {
        const storeUserData: any = localStorage.getItem('userdata') ? 
        localStorage.getItem('userdata') : null;
        const userDatas: any = JSON.parse(storeUserData);
        const AccessToken = userDatas['accessToken'];
        if (AccessToken) {
            return AccessToken;
        } else {
            return "";
        }
    }

    const addCreditorCoupon = () => {
        const AccessToken = getAccessToken();
        api.addCreditOrCoupon(parkerEncodeGuid, code.replaceAll("-", ""), AccessToken)
            .then(res => {
                if (res.status == 200) {
                    res.json().then(r => {
                        if (r.isAssociated) {
                            ShowAlert('Well Done', r.message, 'success').then(() => {
                                setCode('')
                                if (r.message == "Credit code added") {
                                    _props.handelApply(
                                        ''
                                    )
                                } else if (r.message == "Coupon code added") {
                                    setTimeout(() => {
                                        getCouponList();
                                    }, 500);

                                }
                                // _props.handelApply()
                            });
                        } else {
                            ShowAlert('Error', r.message, 'warning').then(() => {
                                //  setCode('')
                                setShowCodeErrorLbl(true);
                            })
                        }
                    })
                }
            });
    }
    const getCouponList = () => {
        const AccessToken = getAccessToken();
        api.getCouponsList(parkerEncodeGuid, AccessToken)
            .then(response => {
                if (response.status == 200) {
                    response.json().then(resp => {
                        console.log("getCouponList", resp);
                        if (resp.length) {
                            if (code) {
                                let getDefaultValue = resp.find((x: any) => x.code === code.replaceAll("-", ""));
                                if (getDefaultValue) {
                                    setDropDownDefaultSelect(createOption(getDefaultValue));

                                }
                            }
                        }
                        setCoupon(resp);
                    })
                }

            }
            )
    }
    const createOption = (optionData: any) => {
        const option: IPromocodeOption = {
            value: optionData,
            label: <div>
                <img
                    loading="lazy"
                    width="30"
                    src={walletImg}
                    style={{ margin: '5px' }}
                    alt=""
                />
                <label className="title" style={{ 'color': '#00AF85', 'fontSize': '15px' }}>{`${optionData.title}`}</label>
                <label className="title" style={{ 'fontSize': '15px' }}>{optionData.expirationDateUtc ? `-${optionData.code}-Expires ${convertUTCtoLocal(optionData.expirationDateUtc, `MMM DD, YYYY`)}` : `-${optionData.code}`}
                </label>
            </div>
        }
        return option;
    }
    const convertUTCtoLocal = (date: string, format: string) => {
        return moment.utc(date).local().format(format);
    }
    const handleCardNumber = (text: any) => {
        let formattedText = text.split('-').join('');

        if (formattedText.length > 0 && formattedText.match(/^[0-9]+$/)) {
            formattedText = formattedText.match(new RegExp('.{1,4}', 'g')).join('-');
        }
        setCode(formattedText);

        return formattedText;
    }
    const getOptions = () => {
        const options: IPromocodeOption[] = coupon.map((o_: any) => {
            return createOption(o_);
        });
        return options;
    }
    return (
        <>
            <h1 className="Popup_Title" style={{ marginTop: '30px' }}>
                {`Apply Coupon or Credit`}
            </h1>

            <div style={{ width: '90%' }}>
                <div style={{ marginTop: '10px', 'width': '100%' }} className="RightView">
                    <h1 className="Popup_SubTitle">Enter a new coupon or credit code</h1>
                </div>
                <div style={{ width: '100%', }}>
                    <TextField fullWidth
                        id="outlined-basic" variant="outlined"
                        color="primary" autoComplete="off" value={code}
                        label={""}

                        placeholder={'Enter Code'}
                        inputProps={{
                            style: { fontFamily: "Manrope", fontWeight: 500 },


                        }
                        }
                        onChange={(e) => {
                            if (e.target.value.length <= 14) {
                                handleCardNumber(e.target.value)
                            }
                        }}
                        onFocus={(e) => {
                            setShowCodeErrorLbl(false);
                        }}
                        onBlur={(e) => {


                        }}
                    />
                    {
                        showCodeErrorLbl && (
                            <label className="title" style={{ color: '#DC2626', marginTop: '5px' }}>Promo code invalid.</label>
                        )
                    }
                </div>
            </div>
            <div style={{ width: '90%', }}>
                <div style={{ marginTop: '10px', 'width': '100%' }} className="RightView">
                    <h1 className="Popup_SubTitle">Available Promotions</h1>
                </div>
                <div style={{ width: '100%', }}>
                    <div style={{ 'width': '100%', borderRadius: '4px', height: '48px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <ReactSelect className="basic-single"
                            classNamePrefix="Select_Payment_Method"
                            isSearchable={false}
                            isDisabled={coupon.length === 0}
                            name="Select_Payment_Method"
                            placeholder="Select Promotion"
                            value={dropDownDefaultSelect?.value?.code ?? null}
                            options={getOptions()}
                            onChange={(option: any) => {
                                const _targetValue = option.value;
                                setSelectedCode(_targetValue['code'] ?? '')
                                setDisableApplyBT(true);
                                setDropDownDisplaySelect(option);
                            }}
                            menuPlacement="auto"
                        />
                    </div>
                </div>
            </div>
            <div style={{ width: '80%', display: 'flex', flexDirection: 'row', alignContent: 'center', alignSelf: 'center', alignItems: 'center', marginTop: '20px', 'justifyContent': 'space-between', marginBottom: '20px' }}>

                <Button style={{ 'width': '140px', 'backgroundColor': disableApplyBT ? '#00AF85' : '#8A959E', 'color': 'white', 'borderRadius': '8px' }} disabled={!disableApplyBT} onClick={() => {
                    if (code) {
                        if (disableApplyBT) {
                            addCreditorCoupon();
                        }
                    } else {
                        _props.handelApply(
                            selectedCode
                        )
                    }
                }}>Apply</Button>
                <Button style={{ 'width': '140px', 'backgroundColor': '#F0F0F0', 'borderRadius': '8px' }} onClick={() => {
                    _props.handelCancel(false)


                }}>Cancel</Button>
            </div>

        </>
    )
}
export default PromoCodePopup;

interface IPromocodeOption { label: any, value: any }