import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Close from "@material-ui/icons/Close";
import Error from "@material-ui/icons/Error";
import Info from "@material-ui/icons/Info";
import Warning from "@material-ui/icons/Warning";
import React from "react";
import "./Common.css";
import { IShowModelAlert, ShowModelAlertType } from "./interface";

const ShowModelAlert = (props: IShowModelAlert) => {
    const { showModelAlertType, headerTitle, messageContent,
        confirmation, noButton, noButtonColor, noButtonText,
        yesButton, yesButtonColor, yesButtonText, yesCallBack,
        noCallBack, showHideAlert, closeCallBack, iconClass, buttonDivClass,
        messageContentClass } = props;
    return (
        <>{showHideAlert && <div className="modelPopup-blocker">
            <div className="modelPopup-popup">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        {closeCallBack && <div style={{ textAlign: "right", width: "100%", marginTop: -30, marginLeft: 50 }}>
                            <IconButton onClick={() => {
                                closeCallBack && closeCallBack()
                            }}>
                                <Close />
                            </IconButton>
                        </div>
                        }
                        {(showModelAlertType === ShowModelAlertType.Error) && <div style={{ marginTop: -10 }}>
                            <Error className={`err-icon-size ${iconClass}`} />
                        </div>
                        }
                        {(showModelAlertType === ShowModelAlertType.Info) && <div style={{ marginTop: -10 }}>
                            <Info className={`info-icon-size ${iconClass}`} />
                        </div>
                        }
                        {(showModelAlertType === ShowModelAlertType.Warning) && <div style={{ marginTop: -10 }}>
                            <Warning className={`warn-icon-size ${iconClass}`} />
                        </div>
                        }
                        {(showModelAlertType === ShowModelAlertType.Success) && <div style={{ marginTop: -10 }}>
                            <CheckCircle className={`success-icon-size ${iconClass}`} />
                        </div>
                        }
                        <div className="modelPopup-header mt-3">
                            {headerTitle}
                        </div>                        
                    </div>
                    <div className={`d-flex flex-column justify-content-center ${messageContentClass ? messageContentClass : "align-items-center"}`}>{messageContent}</div>
                    <div className={`d-flex align-items-center mt-3 ${buttonDivClass ? buttonDivClass : ""}`}>
                        {(confirmation || yesButton) && <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color={yesButtonColor ?? 'primary'}
                            className="ml-2"
                            style={{'borderRadius': '10px', margin: '5px'}}
                            onClick={() => {
                                yesCallBack && yesCallBack();
                            }}
                        >
                            {yesButtonText}
                        </Button>
                        }
                        {(confirmation || noButton) && <Button
                            fullWidth
                            variant="contained"
                            color={noButtonColor ?? 'secondary'}
                            className="ml-2"
                              style={{'borderRadius': '10px', margin: '5px'}}
                            onClick={() => {
                                noCallBack && noCallBack()
                            }}
                        >
                            {noButtonText}
                        </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
        }
        </>);
}

export default ShowModelAlert;