import {Button, Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import * as React from 'react';
import {useState} from 'react';
import StripeTerminal from "stripe-terminal";
import StripeTerminalService from "../Services/StripeTerminalService";
import StringHelper from "../Helpers/StringHelper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ShowAlert} from "./ShowAlert";

function StripeTerminals(props) {
    let _stripeTerminalService = new StripeTerminalService();

    const inValidCards = [{value: "4000000000000002", label: "Declined Card(4000000000000002)"},
        {value: "4000000000009995", label: "Insufficient Funds(4000000000009995)"},
        {value: "4000000000009987", label: "Lost Card(4000000000009987)"},
        {value: "4000000000009979", label: "Stolen Card(4000000000009979)"},
        {value: "4000000000000069", label: "Expired Card(4000000000000069)"},
        {value: "4000000000000119", label: "Processing Error(4000000000000119)"}];

    const validCards = [{value: "4242424242424242", label: "Visa Credit Card(4242424242424242)"},
        {value: "4000056655665556", label: "Visa Debit Card(4000056655665556)"},
        {value: "5555555555554444", label: "Master Credit Card(5555555555554444)"},
        {value: "5200828282828210", label: "Master Debit Card(5200828282828210)"},
        {value: "4506445006931933", label: "Interac Debit Card(4506445006931933)"}];

    const [selectValidCard, setValidCard] = useState(null);
    const [selectInValidCard, setInValidCard] = useState(null);
    const [isSimulator, setSimulator] = useState(false);
    const [isChecked, setDevice] = useState(true);

    const fetchConnectionToken = () => {
        //alert("fetchConnectionToken");
        // Do not cache or hardcode the ConnectionToken. The SDK manages the ConnectionToken's lifecycle.
        return _stripeTerminalService.fetchConnectionToken()
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                return data.secret;
            });
    }

    const unexpectedDisconnect = () => {
        // In this function, your app should notify the user that the reader disconnected.
        // You can also include a way to attempt to reconnect to a reader.
        console.log("Disconnected from reader")
    }
    StripeTerminal.setup({
        onFetchConnectionToken: fetchConnectionToken,
        onUnexpectedReaderDisconnect: unexpectedDisconnect,
    });

    const globalFunction = () => {
        //discoverReaderHandler
        let testCardNumber = "";
        if (!isChecked) {
            if (selectValidCard && selectValidCard.value) {
                testCardNumber = selectValidCard.value;
            } else {
                testCardNumber = selectInValidCard.value;
            }
        }

        var config = {simulated: props.isSimulated};
        console.log(`Simulator Status: ${props.isSimulated}`);
        StripeTerminal.discoverReaders(config).then(function (discoverResult) {
            if (discoverResult.error) {
                console.log('Failed to discover: ', discoverResult.error);
                props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("failed", discoverResult.error);
            } else if (discoverResult.discoveredReaders.length === 0) {
                console.log('No available readers.');
                discoverResult.error = "No available readers.";
                props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("failed", discoverResult.error);
            } else {
                console.log('terminal.discoverReaders', discoverResult.discoveredReaders);
                //connectReaderHandler
                var selectedReader = discoverResult.discoveredReaders[0];
                StripeTerminal.connectReader(selectedReader).then(function (connectResult) {
                    if (connectResult.error) {
                        console.log('Failed to connect: ', connectResult.error);
                        props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("failed", connectResult.error);
                    } else {
                        console.log('Connected to reader: ', connectResult.reader.label);
                        console.log('terminal.connectReader', connectResult);
                        //paymentIntent
                        props.paymentIntentClientSecret && props.paymentIntentClientSecret().then(function (clientSecret) {
                            if (!StringHelper.isValidJsonString(clientSecret)) {
                                if (clientSecret.indexOf("FREE") !== -1) {
                                    console.log('props.paymentIntentClientSecret.error', "Free charge is not supported.");
                                    props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("failed", "Free charges is not supported.");
                                    return;
                                }
                                console.log('props.paymentIntentClientSecret.error', clientSecret);
                                props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("failed", clientSecret);
                                return;
                            }
                            const {clientSecretId, paymentIntentId} = JSON.parse(clientSecret);
                            const cancelButton = props.cancelButtonId ? document.getElementById(props.cancelButtonId) : undefined;
                            // Determine if the payment is an Interac payment
                            if (cancelButton && cancelButton.disabled) {
                                cancelPayment(paymentIntentId);
                                return;
                            }
                            !isChecked && props.isSimulated && StripeTerminal.setSimulatorConfiguration({testCardNumber});
                            //collectPayment
                            StripeTerminal.collectPaymentMethod(clientSecretId).then(function (result) {
                                if (cancelButton && cancelButton.disabled) {
                                    cancelPayment(paymentIntentId);
                                    return;
                                }
                                if (result.error) {
                                    console.log('terminal.processPayment.error', result.error);
                                    props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("failed", result.error);
                                } else {
                                    console.log('terminal.collectPaymentMethod', result.paymentIntent);
                                    StripeTerminal.processPayment(result.paymentIntent).then(function (result) {
                                        if (result.error) {
                                            console.log(result.error)
                                            props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("failed", result.error);
                                        } else if (result.paymentIntent) {
                                            console.log('terminal.processPayment', result.paymentIntent);
                                            const payment_method = result.paymentIntent.charges?.data[0]?.payment_method_details;
                                            console.log('terminal.paymentMethod', payment_method);
                                            if (cancelButton && cancelButton.disabled) {
                                                cancelPayment(result.paymentIntent.id);
                                            } else {
                                                //Capture the payment if it is not an Interac payment (Interac does so automatically)
                                                console.log('terminal.paymentMethod', payment_method);
                                                //if interac not present, then capture payment
                                                //if present no action required
                                                if (!payment_method?.interac_present) {
                                                    capture(result.paymentIntent.id);
                                                } else {
                                                    props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("success", undefined, result.paymentIntent.id);
                                                }
                                            }
                                        }
                                    }).catch(error => {
                                        console.log('terminal.processPayment.error', error);
                                        props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("failed", error);
                                    });
                                }
                            }).catch(error => {
                                console.log('terminal.collectPaymentMethod.error', error);
                                props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("failed", error);
                            });
                        }).catch(error => {
                            console.log('props.paymentIntentClientSecret.error', error);
                            props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("failed", error);
                        });
                    }
                }).catch(error => {
                    console.log('Connected to reader: ', error);
                    props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("failed");
                });
            }
        }).catch(error => {
            console.log('discoverReaders.error: ', error);
            props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("failed", error);
        });
    }

    const capture = (paymentIntentId) => {
        //alert("capture");
        const cancelButton = props.cancelButtonId ? document.getElementById(props.cancelButtonId) : undefined;
        if (cancelButton && cancelButton.disabled) {
            props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("failed", "", undefined, "cancel");
        }
        const bodyContent = {"paymentintentid": paymentIntentId};
        return _stripeTerminalService.capture(bodyContent)
            .then(function (response) {
                if (StringHelper.isValidJsonString(response))
                    return response.json();
            })
            .then(function (data) {
                console.log('server.capture', data);
                props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("success", undefined, paymentIntentId);
            }).catch(error => {
                props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("failed", error);
            });
    }

    const cancelPayment = (paymentIntentId) => {
        //alert("capture");
        const bodyContent = {"paymentintentid": paymentIntentId};
        return _stripeTerminalService.cancelpayment(bodyContent)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log('server.capture', data);
                props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("success", undefined, paymentIntentId, "cancel");
            }).catch(error => {
                props.callGlobalFunctionCompleted && props.callGlobalFunctionCompleted("failed", error, undefined, "cancel");
            });
    }


    return (<>
        {isSimulator && <div className="bulk-upload-blocker">
            <div className="bulk-upload-popup">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="bulk-upload-header mt-3">
                            Card Detail
                        </div>
                        <div className="mt-3 bulk-upload-sub-content w-400-px">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{marginRight: 8}}
                                        checked={isChecked}
                                        color="primary"
                                        onChange={(event, checked) => {
                                            if (checked) {
                                                setValidCard(null);
                                                setInValidCard(null);
                                            }
                                            setDevice(checked);
                                        }}
                                    />
                                }
                                label="Disable Simulator"
                            />
                        </div>
                        <div className="mt-3 bulk-upload-sub-content w-400-px">
                            <Autocomplete
                                options={validCards}
                                value={selectValidCard}
                                onChange={(event, selectedItem_) => {
                                    setValidCard(selectedItem_);
                                    setInValidCard(null);
                                }}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} label="Valid cards"
                                                                    variant="outlined"/>}
                                disabled={isChecked}
                            />
                        </div>
                        <div className="mt-3 bulk-upload-sub-content w-400-px">
                            <Autocomplete
                                options={inValidCards}
                                value={selectInValidCard}
                                onChange={(event, selectedItem_) => {
                                    setInValidCard(selectedItem_);
                                    setValidCard(null);
                                }}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} label="Invalid cards"
                                                                    variant="outlined"/>}
                                disabled={isChecked}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="ml-2 bul-upload-primary-btn"
                            onClick={() => {
                                if (!isChecked && !selectValidCard?.value && !selectInValidCard?.value) {
                                    ShowAlert("", "Please select valid or invalid card", "error");
                                    return;
                                }

                                setSimulator(false);
                                props.callGlobalFunction && props.callGlobalFunction(globalFunction);
                            }}
                        >
                            Process
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="ml-2 bul-upload-primary-btn"
                            onClick={() => {
                                setInValidCard(null);
                                setValidCard(null);
                                setSimulator(false);
                                setDevice(true)
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>}

        <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
                /*if (props.isSimulated) {
                    setSimulator(props.isSimulated);
                }
                else {
                    props.callGlobalFunction && props.callGlobalFunction(globalFunction);
                }*/
                props.callGlobalFunction && props.callGlobalFunction(globalFunction);
            }}
        >{props.buttonName}</Button></>);
}

export default StripeTerminals;
