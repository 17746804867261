
import React from 'react';
import TimeGrid from '../../Components/TimeGrid';
import { Paper, Grid, Button, Popover } from '@material-ui/core';
import { format } from 'date-fns';
import { IReservation, extractDayStartEndFromSpotSchedule } from './Interfaces';
import { ISpot, IPropertyBasics, ISpotBlockData } from '../Spots/Interfaces';
import { GetUserDetailsService } from '../../Services/GetUserDetailsService';
import { INumberRange } from '../../Components/DataGrid/Interfaces';
import { DateTimeHelper } from '../../Helpers/DateTimeHelper';
import NumberHelper from '../../Helpers/NumberHelper';
import { ShowAlertwithConfirm, ShowAlertwithMultipleConfirm, ShowAlertwithOptions } from '../../Common/ShowAlert';
import EditBookingsModal from './EditBookings';

export default class ReservationTimeGrid extends React.Component<IReservationTimeGridProps, IReservationTimeGridState> {
    constructor(props_: IReservationTimeGridProps) {
        super(props_);
        this.state = {
            anchorEl: null,
            isModifyOpened: false
        };
    }

    render() {
        let dayValidRanges: INumberRange[] = [];
        let isSpotOwner = false;
        let isAdmin = (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) > -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) > -1);

        if (this.props.selectedSpot != null) {
            dayValidRanges = extractDayStartEndFromSpotSchedule(this.props.selectedSpot.schedules, this.props.selectedDay);

            if (GetUserDetailsService.getUserDetails().userRoleID[0] == 5 || GetUserDetailsService.getUserDetails().userRoleID[0] == 8) {
                isSpotOwner = this.props.selectedSpot.spotTypeId != 2;
            }
            else if (GetUserDetailsService.getUserDetails().userRoleID[0] == 6) {
                isSpotOwner = this.props.selectedSpot.spotTypeId == 2 && this.props.selectedSpot.claimStatusId == 3;
            }
            else if (GetUserDetailsService.getUserDetails().userRoleID[0] == 1) {
                isSpotOwner = false;
            }
        }

        let dayReservations = this.props.reservations.filter(b => {
            return b.reservationStatus == 1 &&
                NumberHelper.doNumberRangesConflict({
                    start: b.start.getTime(),
                    end: b.end.getTime()
                }, {
                    start: new Date(this.props.selectedDay.getFullYear(), this.props.selectedDay.getMonth(), this.props.selectedDay.getDate(), 0, 0, 0, 0).getTime(),
                    end: new Date(this.props.selectedDay.getFullYear(), this.props.selectedDay.getMonth(), this.props.selectedDay.getDate(), 23, 59, 0, 0).getTime()
                });
        });
        let dayBlocks = this.props.blocks.filter(b => b.start.toDateString() == this.props.selectedDay.toDateString());

        return (
            <Paper className="time-grid d-flex flex-column flex-grow-1">
                <div className="p-3">
                    <h5><b>{format(this.props.selectedDay, "EEE dd, MMM, yyyy")}</b> ({this.props.selectedProperty?.propertyTimeZone})</h5>
                </div>
                <div className="d-flex flex-row w-100" style={{ fontWeight: "bold", color: "#666" }}>
                    <Grid style={{ textAlign: "center", width: "110px" }}>
                        <span>TIME</span>
                    </Grid>
                    <Grid xs style={{ textAlign: "right" }}>
                        <span>PARKER</span>
                    </Grid>
                    <Grid>
                        <div className="pl-3" style={{ width: "240px" }}>&nbsp;</div>
                    </Grid>
                </div>
                <div className={'d-flex flex-row flex-grow-1 ' + ((isAdmin) ? 'time-grid-admin' : '')}>
                    <Grid className="flex-grow-1 w-100">
                        <TimeGrid
                            validTimeRanges={dayValidRanges}
                            reservations={dayReservations}
                            blocks={dayBlocks}
                            selectedDate={this.props.selectedDay}
                            onChange={() => { }}
                            onSelect={this.props.onSelect}
                            onSelecting={this.props.onSelecting}
                            isSelectionAllowed={(isAdmin || isSpotOwner)}
                            selectedTimeRange={this.props.selectedTimeRange}
                            onUnblock={this.props.onUnblock}
                            anchorEl={(anchorEl_: HTMLButtonElement | null, reservation: IReservation, isFuture: boolean) => {
                                this.setState({
                                    anchorEl: anchorEl_,
                                    selectedReservation: reservation,
                                    isFutureSpot: isFuture
                                })
                            }}
                            selectedProperty={this.props.selectedProperty}
                        />
                        <Popover
                            id={Boolean(this.state.anchorEl) ? 'booking-popover' : undefined}
                            open={Boolean(this.state.anchorEl)}
                            anchorEl={this.state.anchorEl}
                            onClose={() => {
                                this.setState({
                                    anchorEl: null
                                });
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div className="booking-details-popup">
                                <h6>Booking details:</h6>
                                <h3>License: {this.state.selectedReservation?.licensePlateNumber} &bull; {this.state.selectedReservation?.parkerName}&nbsp;</h3>
                                {this.renderPopupDate()}
                                {
                                    //this.state.selectedReservation?.reservationTypeId == 2 && 
                                    ((this.state.isFutureSpot && this.state.selectedReservation?.reservationStatus == 1) &&
                                        (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) > -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) > -1)) && <div className="booking-details-container">
                                        <Button variant="contained" color="primary" onClick={() => {
                                            let isActive = this.state.selectedReservation ? this.state.selectedReservation.isUpcomingMonthlyReservationActive : false;
                                            ShowAlertwithOptions(
                                                "Booking Cancellation",
                                                "Are you sure you want to cancel this booking?",
                                                "warning",
                                                "Yes, cancel immediately",
                                                this.state.selectedReservation?.reservationTypeId == 2 && isActive ?
                                                    "Yes, but only cancel upcoming monthly renewal"
                                                    : null,
                                                "No, don't cancel")
                                                .then(agreed_ => {
                                                    if (agreed_) {
                                                        let initiateRefund: boolean = false;
                                                        if (agreed_.option1) {
                                                            initiateRefund = true;
                                                            console.log('Yes, cancel booking immediately and initiate refund');
                                                        } else if (agreed_.option2) {
                                                            console.log('Yes, cancel upcoming bookings (next month renewal)');
                                                        }

                                                        this.setState({
                                                            anchorEl: null
                                                        }, () => {
                                                            if (!agreed_.cancelled) {
                                                                if (this.state.selectedReservation) {
                                                                    this.props.cancelBookings(this.state.selectedReservation, initiateRefund);
                                                                }
                                                            }
                                                        });
                                                    }
                                                });
                                        }}>Cancel</Button>
                                        <Button variant="contained" color="secondary" className="edit-booking"
                                            onClick={() => {
                                                this.setState({
                                                    isModifyOpened: true,
                                                    anchorEl: null
                                                })
                                            }}
                                        >Edit</Button>
                                    </div>
                                }
                            </div>
                        </Popover>
                        {
                            this.state.isModifyOpened &&
                            <EditBookingsModal
                                isOpen={this.state.isModifyOpened}
                                onClose={(s_: boolean) => {
                                    this.setState({ isModifyOpened: false })
                                }}
                                selectedReservation={this.state.selectedReservation}
                            />
                        }

                    </Grid>
                    <Grid>
                        <div className="pl-3" style={{ width: "220px" }}>
                            <div className="legend">
                                <span className="legend-color past-reservation"></span> Past bookings
                            </div>
                            <div className="legend">
                                <span className="legend-color current-reservation"></span> Current bookings
                            </div>
                            <div className="legend">
                                <span className="legend-color upcoming-reservation"></span> Upcoming bookings
                            </div>
                            <div className="legend last-legend">
                                <span className="legend-color blocked"></span> Blocked
                            </div>
                            {/* {(isAdmin || isSpotOwner) ? this.renderSelectionQuestion(dayReservations, dayBlocks, dayValidRanges) : null} */}
                        </div>
                    </Grid>
                </div>
            </Paper>
        );
    }

    renderPopupDate() {
        if (this.state.selectedReservation) {
            let startDate: Date = this.state.selectedReservation.start;
            let endDate: Date = this.state.selectedReservation.end;
            let startUtcDate: any = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
            let endUtcDate: any = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

            if (startUtcDate == endUtcDate) {
                return (
                    <h6>{format(startDate, "MMM dd, yyyy h:mm aa")} - {format(endDate, "h:mm aa")}</h6>
                )
            } else {
                return (
                    <h6>{format(startDate, "MMM dd, yyyy h:mm aa")} - {format(endDate, "MMM dd, yyyy h:mm aa")}</h6>
                )
            }
        }

        return null;
    }

    renderSelectionQuestion(dayReservations_: IReservation[], dayBlocks_: ISpotBlockData[], daySchedule_: INumberRange[]): JSX.Element | null {
        let today = new Date();
        let todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
        if (this.props.selectedDay >= todayStart) {
            if (this.props.selectedSpot) {
                if (this.props.selectedTimeRange) {
                    let selectedDate = format(this.props.selectedDay, "dd MMM yyyy");
                    let startTime = DateTimeHelper.minutesToTimeString(this.props.selectedTimeRange.start as number, false);
                    let endTime = DateTimeHelper.minutesToTimeString(this.props.selectedTimeRange.end as number, false);

                    return (
                        <div className="mt-4">
                            <div>Do you want to block {startTime} to {endTime} on {selectedDate}?</div>
                            <div>
                                <Button variant="contained" color="primary" onClick={() => {
                                    if (this.props.selectedSpot && this.props.selectedTimeRange) {
                                        this.props.blockSelection(this.props.selectedSpot.spotGuid, this.props.selectedDay, this.props.selectedTimeRange.start as number, this.props.selectedTimeRange.end as number);
                                    }
                                }}>Block</Button>
                                <Button variant="contained" className="ml-3" onClick={() => {
                                    this.props.onSelect(null, null);
                                }}>Cancel</Button>
                            </div>
                        </div>
                    );
                }
                else {
                    // Today or future allowed
                    let blockAllowed = daySchedule_.length > 0 && dayBlocks_.length == 0 && dayReservations_.length == 0 && (this.props.selectedDay.toDateString() == new Date().toDateString() || this.props.selectedDay > new Date());

                    return (
                        <div className="mt-4">
                            <div>
                                <Button variant="contained" color="primary" disabled={!blockAllowed}
                                    onClick={() => {
                                        if (this.props.selectedSpot) {
                                            this.props.blockSelection(this.props.selectedSpot.spotGuid, this.props.selectedDay, 0, 1439);
                                        }
                                    }}>Block Full Day</Button>
                            </div>
                            {
                                (dayBlocks_.length > 0 && DateTimeHelper.getDayMinutes(dayBlocks_[0].start) == 0 && DateTimeHelper.getDayMinutes(dayBlocks_[0].end) == 1439) &&
                                <div className="mt-4">
                                    <Button variant="contained" color="primary"
                                        onClick={() => {
                                            if (this.props.selectedSpot) {
                                                this.props.onUnblock(dayBlocks_[0]);
                                            }
                                        }}>Unblock Full Day</Button>
                                </div>
                            }
                        </div>
                    );
                }
            }
        }

        return null;
    }
}

interface IReservationTimeGridProps {
    selectedProperty: IPropertyBasics | null;
    selectedSpot: ISpot | null;
    reservations: IReservation[];
    blocks: ISpotBlockData[];
    selectedDay: Date;
    selectedTimeRange?: INumberRange;
    onSelect: (start_: Date | null, end_: Date | null) => void;
    onSelecting: () => boolean;
    blockSelection: (spotGuid_: string, date_: Date, startTime_: number, endTime_: number) => void;
    onUnblock: (block_: ISpotBlockData) => void;
    cancelBookings: (reservation_: IReservation, initiateRefund_: boolean) => void;
}

interface IReservationTimeGridState {
    anchorEl: HTMLButtonElement | null;
    selectedReservation?: IReservation;
    isFutureSpot?: boolean;
    isModifyOpened: boolean;
}