import moment from "moment";
import React from "react";
import TickSVG from '../Images/green-tick.svg';
import { BookingType, MonthlyBookingType } from "../Pages/BookingsCalendar/interfaces";

export default class BookingSuccess extends React.Component<IBookingSuccessProps, IBookingSuccessState> {

    constructor(props_: IBookingSuccessProps) {
        super(props_);
        this.state = {

        };

    }

    render() {
        return (
            <>
                <div className="root_view" style={{}}>
                    <h1 className="big_title">Booking Complete</h1>
                    <div style={{ 'display': 'flex', 'flexDirection': 'column', width: '100%', 'marginTop': '100px' }}>
                        <div style={{ alignItems: 'center', 'display': 'flex', 'flexDirection': 'column' }}>
                            <img style={{ "width": '42px', 'height': '42px', }} src={TickSVG}></img>
                            <label style={{ marginTop: '10px', color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '18px', textAlign: 'center' }}>Parking has been booked successfully:</label>
                            <label style={{ marginTop: '30px', color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '18px', textAlign: 'center', width: '70%' }}>{``}</label>
                            <label style={{ color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '18px', textAlign: "center" }}>
                                {`${moment(this.props.bookingStartDate).format('MMM DD, YYYY')} 
                             ${moment(this.props.bookingEndDate).diff(moment(this.props.bookingStartDate), 'days') >= 1 ?
                              `- ${moment(this.props.bookingEndDate).format('MMM DD, YYYY')}` : ``} `} <br /> {this.props.bookingType === BookingType["Hourly/Daily"] ? 
                              `${moment(this.props.bookingStartDate).format('hh:mm a')} - ${moment(this.props.bookingEndDate).format('hh:mm a')}` : `${MonthlyBookingType[this.props.monthlyBookingType]}`}</label>
                            {this.props.bookingId && <label style={{ color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '18px', textAlign: "center" }}>Booking Id: {this.props.bookingId}</label>}
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
interface IBookingSuccessProps {
    bookingStartDate:Date | undefined | null,
    bookingEndDate:Date | undefined,
    bookingType:number,
    monthlyBookingType:number,
    bookingId: string
}

interface IBookingSuccessState {

}
