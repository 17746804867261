import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const Notes = (_props: any) => {
    return (
        <>
            <label className="title_header" style={{ width: '60%', marginTop: '10px' }} >Notes (optional)</label>
            <TextField
                placeholder=""
                multiline
                variant="outlined"
                fullWidth
                value={_props.notes}
                onChange={(e) => {
                    _props.handelNotes(e)
                    // this.setState({
                    //     notes: e.target.value
                    // })
                }}
               
                inputProps={{
                    style: {
                        height: "86px",
                    },
                    maxlength: 1000

                }}
            >
            </TextField>
        </>
    )
}
export default Notes