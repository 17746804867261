import React, { Component } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

interface DatePickerProps {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
  viewDate: Date;
}

interface DatePickerState {
  currentDate: Date;
}

class DatePicker extends Component<DatePickerProps, DatePickerState> {
  constructor(props: DatePickerProps) {
    super(props);

    this.state = {
      currentDate: props.selectedDate,
    };

    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
  }

  componentDidMount() {
    const storedDateString: string | null =
      localStorage.getItem("ReserStartDate");

    if (storedDateString !== null) {
      const storedDate = new Date(storedDateString);
      this.setState({ currentDate: storedDate });
      this.props.onDateChange(storedDate);
    }
    // else, do nothing - the component state will not be updated
  }

  componentWillUnmount() {
    localStorage.removeItem("ReserStartDate");
  }

  handlePrevClick() {
    const prevDate = new Date(this.state.currentDate);
    prevDate.setDate(prevDate.getDate() - 12); // Go back 12 days
    this.setState({ currentDate: prevDate });
    // this.props.onDateChange(prevDate);
  }

  handleNextClick() {
    const nextDate = new Date(this.state.currentDate);
    nextDate.setDate(nextDate.getDate() + 12); // Go forward 12 days
    this.setState({ currentDate: nextDate });
    // this.props.onDateChange(nextDate);
  }

  render() {
    const dates = [];
    const startDate = new Date(this.state.currentDate);
    startDate.setDate(startDate.getDate() - 2); // Start from 2 days before the current date
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    for (let i = 0; i < 12; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      const dayOfWeek = daysOfWeek[date.getDay()];
      dates.push({ date, dayOfWeek });
    }
    return (
      <div className="cal-par-head">
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "space-around",
            paddingBottom: "3px",
          }}
        >
          <button className="cal-par-icon" onClick={this.handlePrevClick}>
            <ChevronLeftIcon />
          </button>
          {dates.map(({ date, dayOfWeek }) => (
            <div
              key={date.toDateString()}
              className={`date ${
                date.toDateString() === this.props.selectedDate.toDateString()
                  ? "selected-date"
                  : "unselected-date"
              } ${
                date.toDateString() !== this.props.selectedDate.toDateString()
                  ? "hover-effect"
                  : ""
              }`}
              onClick={() => {
                if (this.state.currentDate != date) {
                  this.props.onDateChange(date);
                }
              }}
              style={{ flex: 1, textAlign: "center" }}
            >
              <div className="date-content">
                <div className="dayOfWeek">{dayOfWeek}</div>
                <div className="datePick">{date.getDate()}</div>
              </div>
            </div>
          ))}
          <button className="cal-par-icon" onClick={this.handleNextClick}>
            <ChevronRightIcon />
          </button>
        </div>
      </div>
    );
  }
}

export default DatePicker;
