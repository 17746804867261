import React from "react";

const TaxandWaivedoff = (_props: any) => {
    return (
        <>
            <div className="flex_row"  >
                <label className="title" style={{ width: '60%' }}>Service Fee</label>
                <label className="title" style={{ width: '40%', textAlign: 'right' }}>{`$${_props.serviceFee.toFixed(2)}`}</label>
            </div>
            <div className="flex_row" >
                <label className="title" style={{ width: '60%' }} >Taxes</label>
                <label className="title" style={{ width: '40%', textAlign: 'right' }} >{`$${_props.tax.toFixed(2)}`}</label>
            </div>
            {
                Number(_props.waivedOff.toFixed(2)) !== 0 && (
                    <div className="flex_row" >
                        <label className="title" style={{ width: '60%' }} >Waived</label>
                        <label className="title" style={{ width: '40%', textAlign: 'right' }} >{`-$${_props.waivedOff.toFixed(2)}`}</label>
                    </div>
                )
            }
        </>
    )
}
export default TaxandWaivedoff