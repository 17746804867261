import React from "react";
import ReactSelect from 'react-select';
import americanSelectedImg from '../Images/american-selected.png';
import defaultCardImg from '../Images/default-card.png';
import masterSelectedImg from '../Images/master-selected.png';
import visaSelectedImg from '../Images/visa-selected.png';
import { IBookingDetailInfo } from "../Pages/BookingsCalendar/interfaces";
import SpotService from "../Services/SpotService";

export default class SelectCard extends React.Component<ISelectCardProps, ISelectCardState> {
    private spotService: SpotService;

    constructor(props_: ISelectCardProps) {
        super(props_);
        this.state = {
            cardArray: [],
            dropDownSelected: false,
            selectedCardDisplayName: null,
            selectedCardId: '',
        };
        this.spotService = new SpotService();
    }
    componentDidMount() {
        this.getCardList();
    }

    private getAccessToken() {
        const storeUserData: any = localStorage.getItem('userdata') ? localStorage.getItem('userdata') : null;
        const userDatas: any = JSON.parse(storeUserData);
        const AccessToken = userDatas['accessToken'];
        if (AccessToken) {
            return AccessToken;
        } else {
            return "";
        }
    }

    getCardImage = (name: string) => {
        if (name.toLowerCase() == 'visa') {
            return visaSelectedImg
        }
        else if (name.toLowerCase() == 'mastercard') {
            return masterSelectedImg
        }
        else if (name.toLowerCase() == 'amex') {
            return americanSelectedImg
        }
        else {
            return defaultCardImg
        }
    }

    private getCardList() {
        const bookingDetailInfo = this.props.bookingDetailInfo;
        const AccessToken = this.getAccessToken();
        this.spotService.getCardList(bookingDetailInfo.userDetail.parkerEncodeGuid, AccessToken)
            .then(r => r.json())
            .then(r => {
                this.setState({
                    cardArray: r.cards
                }, () => {
                });
            });
    }
    render() {
        const options: ICardOption[] = this.state.cardArray.map((o_: any, index) => {
            return {
                label: (
                    <div>
                        <img
                            loading="lazy"
                            width="30"
                            src={this.getCardImage(o_.cardBrand)}
                            style={{ margin: '5px' }}
                            alt=""
                        />{o_.cardDisplayName.toString().replace("****", "ending in")}
                    </div>
                ),
                value: o_.cardDisplayName
            }
        });
        options.push({
            label: (
                <div className="newPayment">
                    + New Payment Method
                </div>
            ),
            value: "addNewCard"
        });
        return (
            <>
                <div>
                    <div className="flex_colum" >
                        <label className="title_header" style={{ color: '#6B7278' }}>PAYMENT DETAILS</label>
                        <label className="title_small" style={{}}>Select Payment Method</label>
                    </div>
                </div>
                <div style={{ 'width': '100%', borderRadius: '4px', height: '48px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <ReactSelect className="basic-single"
                        classNamePrefix="Select_Payment_Method"
                        isSearchable={false}
                        isDisabled={this.props.disable}
                        name="Select_Payment_Method"
                        placeholder="Select Payment Method"
                        value={this.state.selectedCardDisplayName?.value == 'addNewCard' ? null :
                            this.state.selectedCardDisplayName}
                        options={options}
                        onChange={(option) => {
                            this.setState({
                                selectedCardDisplayName: option
                            }, () => {
                                const { selectedCardDisplayName } = this.state;
                                const value = (!selectedCardDisplayName ||
                                    (selectedCardDisplayName && selectedCardDisplayName.value)) === 'addNewCard' ? "" :
                                    selectedCardDisplayName?.value;
                                if (value) {
                                    this.setState({ dropDownSelected: true });
                                    let cardDetail: any = this.state.cardArray.find(x => x.cardDisplayName == value);

                                    this.setState({
                                        selectedCardId: cardDetail.cardId
                                    }, () => {
                                        this.props.onCardSelection(this.state.selectedCardId)
                                        console.log("selectedCardId", this.state.selectedCardId);
                                    })
                                }
                                else {
                                    this.props.onOpenNewCardScreen();
                                }
                            });
                        }}
                        menuPlacement="top"
                    />
                </div>

            </>
        )
    }
}
interface ISelectCardProps {
    bookingDetailInfo: IBookingDetailInfo,
    onCardSelection: (cardId: string) => void,
    disable: boolean,
    onOpenNewCardScreen: () => void,

}

interface ISelectCardState {
    cardArray: any[],
    dropDownSelected: boolean,
    selectedCardDisplayName: ICardOption | null,
    selectedCardId: string,

}
interface ICardOption { label: any, value: string }
