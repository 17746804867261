import {Button, Drawer, Grid, TextField} from "@material-ui/core";
import {CheckCircle} from "@material-ui/icons";
import Error from "@material-ui/icons//Error";
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from "@material-ui/lab/Autocomplete";
import {format} from "date-fns";
import React from "react";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {CheckScreenPermission} from "../../Common/CheckScreenPermission";
import {PreserveFilters} from "../../Common/PreserveFilters";
import {ShowAlert, ShowAlertwithConfirm} from "../../Common/ShowAlert";
import DataGrid from "../../Components/DataGrid/DataGrid";
import DataGridToolbar from "../../Components/DataGrid/DataGridToolbar";
import {
  DataGridFilterType,
  IAppliedFilter,
  IDataGridColumn,
  IDateRange,
  INumberRange
} from "../../Components/DataGrid/Interfaces";
import {ShowBlocker} from "../../Components/LoaderComponent";
import {ShowToastr} from "../../Components/Toastr";
import ArrayHelper from "../../Helpers/ArrayHelper";
import {DateTimeHelper} from "../../Helpers/DateTimeHelper";
import ObjectHelper from "../../Helpers/ObjectHelper";
import {RightPanelState} from "../../Models/CommonInterfaces";
import {GetUserDetailsService} from "../../Services/GetUserDetailsService";
import PropertyService from "../../Services/PropertyService";
import SpotService from "../../Services/SpotService";
import {IPropertyBasics, ISpot, ISpotBlockData} from "../Spots/Interfaces";
import AddNewBookingView from "./AddNewBookingView";
import BookingSummaryView from "./BookingSummaryView";
import "./CashBookingSchedule.css";
import EditBookingsModal from './EditBookings';
import {IAddCashBookingData, IReservation} from "./Interfaces";
import ReservationCalendar from "./ReservationCalendar";
import ReservationFilterView from "./ReservationFilterView";
import ReservationTimeGrid from "./ReservationTimeGrid";

export default class CashBookingList extends React.Component<IReservationListProps, IReservationListState> {
  _isFiltered: boolean = false;
  private _spotService: SpotService;
  private _propertyService: PropertyService;
  private _spotListColumns: IDataGridColumn<IReservation>[] = [];
  private _defaultAddCashBookingData: IAddCashBookingData = {
    zones: [],
    propertyGuid: "",
    spots: [],
    date_: {
      startDate: new Date(),
      endDate: new Date()
    },
    startTime: {
      dateTime: new Date(),
      timeString: "12:00 PM"
    },
    endTime: {
      dateTime: new Date(),
      timeString: "05:00 PM"
    },
    licensePlate: "",
    selectedZone: null,
    selectedSpot: null,
    isNewItem: true,
    transactionType: 3
  };
  private _defaultFilter: any = {
    property: {
      type: DataGridFilterType.StringList,
      filterValue: []
    },
    spotNumber: {
      type: DataGridFilterType.StringList,
      filterValue: []
    },
    parker: {
      type: DataGridFilterType.StringList,
      filterValue: []
    },
    license: {
      type: DataGridFilterType.StringList,
      filterValue: []
    },
    reservation: {
      type: DataGridFilterType.DateRange,
      filterValue: {
        start: undefined,
        end: undefined
      }
    },
    revenue: {
      type: DataGridFilterType.RevenueRange,
      filterValue: {
        start: undefined,
        end: undefined,
        optionalTag: ""
      }
    }
  };

  constructor(props_: IReservationListProps) {
    super(props_);

    this.state = {
      viewMode: this.props.match.params.source == "spot" ? EnumReservationViewMode.Calendar : EnumReservationViewMode.List,
      selectedDate: new Date(),
      isReservationsLoaded: false,
      properties: [],
      spots: [],
      selectedProperty: null,
      selectedSpot: null,
      allReservations: [],
      filteredReservations: [],
      subFilteredReservations: [],
      calendarReservations: [],
      blocks: [],
      searchTerm: "",
      rightPanelState: RightPanelState.None,
      isFiltered: false,
      _autoApplyFilterKey: 0,
      editingReservation: null,
      isVisibleEditing: false,
      isScreenAccessible: false,

      cashBookingNotifications: [],
      isCashBookingNotificationEnabled: false,
      completedTitle: "",
      isCancelWarning: false,
      isCancelSuccess: false,
      isCancelFailed: false,
      addCashBookingData: {...this._defaultAddCashBookingData},
      cancellingReservation: null,
      disableOutsideClick: false
    };

    this._spotListColumns = this._spotListColumns.concat([
      {
        key: "spacer1",
        name: ""
      },
      {
        key: "reservationStatusText",
        name: "STATUS",
        contentProvider: (row_: IReservation) => {
          return (<div
            className={'list-status-text' + (row_.reservationStatusClassName ? ` ${row_.reservationStatusClassName}` : "")}>{row_.reservationStatusText}</div>);
        }
      },
      {
        key: "friendlyReservationId",
        name: "Booking Id"
      },
      {
        key: "start",
        name: "From",
        contentProvider: (row_: IReservation) => {
          let date = DateTimeHelper.dateStringToDate(row_.start);
          return (<span><span className="no-wrap-text">{format(date, "dd MMM yyyy")}</span><br/><span
            className="no-wrap-text">{format(date, "hh:mm aa")}</span></span>);
        }
      },
      {
        key: "end",
        name: "To",
        contentProvider: (row_: IReservation) => {
          let date = DateTimeHelper.dateStringToDate(row_.end);
          return (<span><span className="no-wrap-text">{format(date, "dd MMM yyyy")}</span><br/><span
            className="no-wrap-text">{format(date, "hh:mm aa")}</span></span>);
        }
      }
    ]);
    if (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) > -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) > -1) {
      this._spotListColumns.push({
        key: "parkerName",
        name: "Parker",
        getComparator: ArrayHelper.getNumberInStringComparator
      });
    }
    this._spotListColumns = this._spotListColumns.concat([
      {
        key: "licensePlateNumber",
        name: "License Plate",
        getComparator: ArrayHelper.getNumberInStringComparator
      },
      // {
      //     key: "propertyName",
      //     name: "Property Name",
      //     getComparator: ArrayHelper.getNumberInStringComparator
      // },
      {
        key: "friendlySpotId",
        name: "Spot Number",
        getComparator: ArrayHelper.getNumberInStringComparator
      },
      {
        key: "isCashPayment",
        name: "Booking Type",
        contentProvider: (row_: IReservation) => {
          return (<span>{row_.isCashPayment ? "Cash" : "Online"}</span>);
        },
      },
      {
        key: "reservationAmount",
        name: "Booking Amount",
        contentProvider: (row_: IReservation) => {
          return (<span>${row_.reservationAmount.toFixed(2)}</span>);
        },
        textAlignment: "right"
      },
      {
        key: "spacer2",
        name: ""
      }
    ]);

    // if (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1) {
    //     this._spotListColumns = this._spotListColumns.concat([
    //         {
    //             key: "stripeTransactionsIds",
    //             name: "Stripe Transactions Id(s)",
    //             contentProvider: (row_: IReservation) => {
    //                 return (<span className="wrapcell">{row_.stripeTransactionsIds.replace(/,/g, ', \n')}</span>);
    //             }
    //         },
    //         {
    //             key: "description",
    //             name: "Description",
    //             contentProvider: (row_: IReservation) => {
    //                 return (<span>{row_.description}</span>);
    //             },
    //             getComparator: (order: "asc" | "desc", orderBy: string) => {
    //                 return (a: any, b: any) => {
    //                     let result: number;

    //                     let longTextRgx = /^([a-z]|\s)*?([0-9.]+)(.*)/i;

    //                     let aValue = parseFloat(a["description"].replace(longTextRgx, "$2"));
    //                     let bValue = parseFloat(b["description"].replace(longTextRgx, "$2"));

    //                     if (aValue == bValue) {
    //                         return 0;
    //                     } else {
    //                         result = aValue > bValue ? 1 : -1;
    //                     }

    //                     return result * (order == 'desc' ? -1 : 1);
    //                 }
    //             }
    //         },
    //     ])
    // }

    this._spotService = new SpotService();
    this._propertyService = new PropertyService();
  }

  async componentDidMount() {
    var isAccessible = await CheckScreenPermission("user-interface-cashbookings");

    this.setState({
      isScreenAccessible: isAccessible
    }, () => {
      if (this.state.isScreenAccessible) {
        this._loadReservations();
      }
    });
  }

  componentWillUnmount() {
    PreserveFilters.setSearchTerm('bookingsSearch', this.state.searchTerm)
    //PreserveFilters.clearPreservedFilter('bookings');
  }

  getReservationCallback = (searchTerm: string, filteredBookingscache: any, properties?: IPropertyBasics[],
                            propertyGuid_?: string, selectedProperty?: IPropertyBasics) => {
    let isBusy = false;

    ObjectHelper.GetAllResponse<IReservation>(
      (reservation: IReservation[]) => {
        return reservation.map((e: IReservation) => {
          let reservationStatus = 'Reserved';
          let className = "";

          if (e.reservationTypeId == 1 || e.reservationTypeId == 3) {
            if (e.reservationStatus == 1 && e.paymentStatus == 3) {
              reservationStatus = 'Paid';
              className = "paid";
            } else if (e.reservationStatus == 5) {
              reservationStatus = 'Completed';
              className = "completed";
            } else if (e.reservationStatus == 2) {
              reservationStatus = 'Cancelled';
              className = "cancelled";
            }
          } else {
            if ((e.reservationStatus == 1 && e.isMonthlyReservationActive) && e.paymentStatus == 3) {
              if (e.isUpcomingMonthlyReservationActive) {
                reservationStatus = 'Paid';
                className = "paid";
              } else {
                reservationStatus = 'Subscription Cancelled';
                className = "subscription-cancelled";
              }
            } else if (e.reservationStatus == 2 || e.isMonthlyReservationActive) {
              reservationStatus = "Cancelled"; // 'Unsubscribed';
              className = "cancelled";
            }
          }

          e.reservationStatusText = reservationStatus;
          e.reservationStatusClassName = className;
          e.start = new Date(e.start);
          e.end = new Date(e.end);
          e.stripeTransactionsIds = e.stripeTransactionsIds.indexOf(",") == 0 ? e.stripeTransactionsIds.replace(',,', ',').substring(1) : e.stripeTransactionsIds.replace(',,', ',');
          return e;
        });
      },
      (reservation: IReservation[], progress_: number) => {
        if (!isBusy) {
          isBusy = true;
          let updatedState: IReservationListState = {
            allReservations: reservation,
            filteredReservations: reservation.slice(0),
            subFilteredReservations: reservation.slice(0),
            calendarReservations: reservation.slice(0),
            isReservationsLoaded: true,
            loaderPercentage: progress_ * 100
          } as any;

          this.setState({...updatedState}, () => {
            isBusy = false;
          });
        }
      }, this._spotService.getReservation(propertyGuid_), ",{\"reservationGuid\"").then(reservation => {
      const {addCashBookingData} = this.state;
      let updatedstate: IReservationListState = !properties ? {
        allReservations: reservation,
        filteredReservations: reservation.slice(0),
        subFilteredReservations: searchTerm ? this._filterDataBySearch(reservation, searchTerm) : reservation.slice(0),
        calendarReservations: reservation.slice(0),
        appliedFilter: this._defaultFilter,
        searchTerm: searchTerm ? searchTerm : '',
        isReservationsLoaded: true,
        loaderPercentage: 100
      } as any : {
        allReservations: reservation,
        filteredReservations: reservation.slice(0),
        subFilteredReservations: reservation.slice(0),
        calendarReservations: reservation.slice(0),
        isReservationsLoaded: true,
        appliedFilter: this._defaultFilter,
        searchTerm: searchTerm ? searchTerm : '',
        loaderPercentage: 100,
        properties: properties
      } as any;

      if (filteredBookingscache) {
        updatedstate.appliedFilter = {...filteredBookingscache};
      }

      if (properties) {
        const selectedProperties = updatedstate.appliedFilter?.property?.filterValue as string[];
        updatedstate.selectedProperty = (selectedProperties.length === 1 || selectedProperties.length === 0) ?
          selectedProperty ?? null : null;
        addCashBookingData.propertyGuid = updatedstate.selectedProperty?.propertyGuid ?? "";

        this.setState({...updatedstate, addCashBookingData}, () => {
          this.setState({loaderPercentage: 0}, () => {
            isBusy = false;
            if (updatedstate.selectedProperty)
              this._onPropertySelect([updatedstate.selectedProperty]);
            else {
              this._onPropertySelect(updatedstate.properties);
            }
          });
        });
      } else {
        this.setState({...updatedstate}, () => {
          this.setState({loaderPercentage: 0}, () => {
            isBusy = false;
            if (selectedProperty)
              this._onPropertySelect([selectedProperty]);
            else {
              this._onPropertySelect(this.state.properties);
            }
          });
        });
      }
    });
  }

  getReservations = (callback_: (reservations_: IReservation[]) => void, propertyGuid_?: string) => {
    this._spotService.getReservation(propertyGuid_)
      .then(r => r.json())
      .then(r => {
        let updatedList = r.map((e: IReservation) => {
          let reservationStatus = 'Reserved';
          let className = "";

          if (e.reservationTypeId == 1 || e.reservationTypeId == 3) {
            if (e.reservationStatus == 1 && e.paymentStatus == 3) {
              reservationStatus = 'Paid';
              className = "paid";
            } else if (e.reservationStatus == 5) {
              reservationStatus = 'Completed';
              className = "completed";
            } else if (e.reservationStatus == 2) {
              reservationStatus = 'Cancelled';
              className = "cancelled";
            }
          } else {
            if ((e.reservationStatus == 1 && e.isMonthlyReservationActive) && e.paymentStatus == 3) {
              if (e.isUpcomingMonthlyReservationActive) {
                reservationStatus = 'Paid';
                className = "paid";
              } else {
                reservationStatus = 'Subscription Cancelled';
                className = "subscription-cancelled";
              }
            } else if (e.reservationStatus == 2 || e.isMonthlyReservationActive) {
              reservationStatus = "Cancelled"; // 'Unsubscribed';
              className = "cancelled";
            }
          }

          e.reservationStatusText = reservationStatus;
          e.reservationStatusClassName = className;
          e.start = new Date(e.start);
          e.end = new Date(e.end);
          e.stripeTransactionsIds = e.stripeTransactionsIds.indexOf(",") == 0 ? e.stripeTransactionsIds.replace(',,', ',').substring(1) : e.stripeTransactionsIds.replace(',,', ',');
          return e;
        });

        callback_(updatedList);
      });
  }

  render() {
    if (!this.state.isReservationsLoaded || !this.state.isScreenAccessible) {
      // return <LoaderComponent loaderVisible={true} />
      return null;
    }

    const userRoleIds = GetUserDetailsService.getUserDetails().userRoleID;

    return (
      <div className="mx-3 data-grid-container reservations-page cash-booking">
        {
          (this.state.editingReservation || this.state.isVisibleEditing) &&
            <EditBookingsModal
                isOpen={true}
                onClose={(s_: boolean) => {
                  this.setState({
                    editingReservation: null,
                    isVisibleEditing: false
                  }, () => {
                    if (s_) {
                      ShowBlocker(true);
                      const {selectedProperty} = this.state;
                      this.getReservations((reservations_: IReservation[]) => {
                        this.setState({
                          allReservations: reservations_,
                          appliedFilter: this.state.appliedFilter,
                          _autoApplyFilterKey: new Date().getTime()
                        }, () => {
                          ShowBlocker(false);
                        });
                      }, selectedProperty?.propertyGuid);
                    }
                  })
                }}
                selectedReservation={this.state.editingReservation}
            />
        }

        {/* Header region of the page */}
        <Grid container className="mb-3">
          <Grid item>
            {/* Title of the page */}
            <h1 className="list-header">
              {
                this.state.viewMode == EnumReservationViewMode.List ? "Bookings" : "Calendar"
              }
            </h1>
          </Grid>
          {
            // this.state.viewMode == EnumReservationViewMode.Calendar &&
            <React.Fragment>
              <Grid className="px-4">
                <Autocomplete
                  className="mt-2 mr-2"
                  options={this.state.properties}
                  getOptionLabel={option_ => option_.propertyName}
                  style={{width: 200}}
                  size="small"
                  value={this.state.selectedProperty}
                  renderInput={(params) => <TextField {...params} label="Property" variant="outlined"/>}
                  onChange={(e_: any, property_: IPropertyBasics | null) => {
                    const {addCashBookingData} = this.state;
                    addCashBookingData.propertyGuid = property_?.propertyGuid ?? "";
                    this.setState({
                      spots: [],
                      selectedProperty: property_,
                      addCashBookingData
                    }, () => {
                      this.state.selectedProperty && this._ReloadReservations();
                    });
                  }}
                />
              </Grid>
              <Grid>
                <Button
                  type="submit"
                  size={"small"}
                  variant="contained"
                  color="primary"
                  className='mt-2 c-go-button'
                  onClick={() => {
                    this._ReloadReservations();
                  }}
                  disabled={!!!this.state.selectedProperty}
                >
                  Go
                </Button>
              </Grid>
              {/* <Grid>
                                <Autocomplete
                                    className="mt-2"
                                    options={this.state.spots}
                                    getOptionLabel={option_ => option_.friendlySpotId}
                                    style={{ width: 180 }}
                                    size="small"
                                    value={this.state.selectedSpot}
                                    renderInput={(params) => <TextField {...params} label="Spot" variant="outlined" />}
                                    onChange={(e_: any, spot_: ISpot | null) => {
                                        this._onSpotSelect(spot_);
                                    }}
                                />
                            </Grid> */}
            </React.Fragment>
          }
          <Grid item xs>
            {/* Grid toolbar */}
            <DataGridToolbar
              search={PreserveFilters.getSearchTerm('cashBookingsSearch')}
              singularEntityName="booking"
              pluralEntityName="bookings"
              onSearch={this._searchTermChange}
              isFiltered={this.state.isFiltered}
              onFilterClick={() => {
                this.setState({
                  rightPanelState: RightPanelState.Filter
                });
              }}
              onAddClick={() => {
                if (this.state.selectedProperty) {
                  this.setState({
                    rightPanelState: RightPanelState.Add
                  });
                } else {
                  ShowAlert("", "Please select property", "warning");
                }
              }}
              isNoAddButton={false}
              className="cash-booking-datagridtoolbar"
            >
              <React.Fragment>
                {/* <Grid item className="d-flex align-items-center pr-3"> */}
                {/* this.setState({ isNotificationEnabled: !this.state.isNotificationEnabled }) */}
                {/* (this.state.isNotificationEnabled ? "notify-active" : "") + " btn-notification" */}
                {/* <div className={"notify-active btn-notification"} onClick={() => {}}>
                                    { */}
                {/* invisible={!(this.state.privateLotNotifications && this.state.privateLotNotifications.length > 0)} */}


                {/* Enable the Below line for Notification */}
                {/* <Badge className={(this.state.isCashBookingNotificationEnabled ? "notify-active" : "") + " btn-notification"} color="primary"
                                    overlap="circle" variant="dot" onClick={() => this.setState({ isCashBookingNotificationEnabled: !this.state.isCashBookingNotificationEnabled, rightPanelState: RightPanelState.Notification })}
                                    invisible={!(this.state.cashBookingNotifications?.length > 0)}>
                                    <NotificationsOutlined />
                                </Badge> */}
                {/* Enable the above line for Notification */}


                {/* }
                                </div> */}
                {/* </Grid> */}
                {/* <Tooltip title="Calendar/List View">
                                    <IconButton aria-label="Calendar/List View" onClick={() => {
                                        if (this.state.viewMode == EnumReservationViewMode.List) {
                                            this.setState({
                                                viewMode: EnumReservationViewMode.Calendar
                                            });
                                        }
                                        else {
                                            this.setState({
                                                viewMode: EnumReservationViewMode.List
                                            });
                                        }
                                    }}>
                                        <CalendarTodayIcon color={(this.state.viewMode == EnumReservationViewMode.Calendar) ? "primary" : undefined} />
                                    </IconButton>
                                </Tooltip> */}
                {/* {
                                    ((GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1) || (GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1)) &&
                                    <Tooltip title="Update booking">
                                        <IconButton aria-label="Update booking" onClick={() => {
                                            this.setState({
                                                isVisibleEditing: true
                                            })
                                        }}>
                                            <UpdateIcon color={(this.state.editingReservation) ? "primary" : undefined} />
                                        </IconButton>
                                    </Tooltip>


                                } */}
              </React.Fragment>
            </DataGridToolbar>
          </Grid>
          {/* <div style={{ marginTop: '8px' }}>
                        <CSVLink data={this.state.subFilteredReservations} filename={"Bookings.csv"} className="suppress-link">
                            <MenuItem>Export to CSV</MenuItem>
                        </CSVLink>
                    </div> */}

        </Grid>

        {/* Data Grid */}
        {
          this.state.viewMode == EnumReservationViewMode.List &&
            <DataGrid
                title="Reservation"
                data={this.state.subFilteredReservations}
                columns={this._spotListColumns}
                appliedFilter={this.state.appliedFilter}
                defaultSortColumnKey="start"
                defaultSortDirection="desc"
                loaderPercentage={this.state.loaderPercentage}
              //isNoMoreLink={!((userRoleIds.indexOf(1) !== -1) || (userRoleIds.indexOf(2) !== -1))}
                isNoMoreLinkDisabled={(item_: IReservation) => {
                  return !this._validateEndTime(item_);
                }}
                isRowsNonSelectable={true}
                onFilterTagRemoved={(filter_: IAppliedFilter) => {
                  let updatedState: IReservationListState = {
                    appliedFilter: filter_ as IAppliedFilter,
                    _autoApplyFilterKey: new Date().getTime()
                  } as IReservationListState as any;
                  if (filter_) {
                    if (!(filter_.property.filterValue && (filter_.property.filterValue as string[]).length)) {
                      if (this.state.selectedProperty) {
                        updatedState.selectedProperty = null;
                        updatedState.selectedSpot = null;
                        updatedState.spots = [];
                      }
                      if (filter_.spotNumber.filterValue && (filter_.spotNumber.filterValue as string[]).length) {
                        // spot can't be selected without selecting property first!
                        filter_.spotNumber.filterValue = [];
                      }
                    }
                  }

                  this.setState(updatedState, () => {
                    PreserveFilters.preserveFilter('cashbookings', this.state.appliedFilter)
                  });
                }}
                onMoreClick={(item_: IReservation) => {
                  let optionTypes: any[] = [];

                  if (this._validateEndTime(item_)) {
                    // if (item_.reservationTypeId == 1 || item_.reservationTypeId == 2) {
                    //     optionTypes.push({
                    //         key: "rebook",
                    //         label: "Rebook"
                    //     })
                    // }

                    optionTypes.push({
                      key: "cancel",
                      label: "Cancel"
                    });
                  }

                  return optionTypes;
                }}
                onPopoverClick={(key_, item_) => {
                  // if (key_ == "rebook") {
                  //     this.setState({
                  //         editingReservation: item_
                  //     });
                  // }
                  // else
                  if (key_ == "cancel") {
                    this.setState({isCancelWarning: true, cancellingReservation: item_});
                    // ShowAlertwithOptions(
                    //     "Booking Cancellation",
                    //     "Are you sure you want to cancel this booking?",
                    //     "warning",
                    //     "Yes, cancel immediately",
                    //     item_.reservationTypeId == 2 && item_.isUpcomingMonthlyReservationActive ?
                    //         "Yes, but only cancel upcoming monthly renewal"
                    //         : null,
                    //     "No, don't cancel")
                    //     .then(response_ => {
                    //         if (response_.option1) {
                    //             this._cancelBooking(item_, true);
                    //         }
                    //         else if (response_.option2) {
                    //             this._cancelBooking(item_, false);
                    //         }
                    //     });
                  }
                }}
            />
        }

        {/* Calendar View */}
        {
          this.state.viewMode == EnumReservationViewMode.Calendar &&
            <div className="reservation-calendar-view">
              {
                this.state.selectedSpot == null &&
                  <div className="block-reservation-calendar">Please select a spot to view calendar</div>
              }
                <div className="month-view d-flex flex-column h-100">
                    <ReservationCalendar
                        reservations={this.state.selectedSpot == null ? [] : this.state.calendarReservations}
                        selectedDay={this.state.selectedDate}
                        onDateSelected={date_ => {
                          this.setState({
                            selectedDate: date_,
                            selectedTimeSlots: undefined
                          });
                        }}
                        propertyDetails={this.state.selectedProperty}
                    />
                </div>
                <div className="day-view d-flex flex-column h-100">
                    <ReservationTimeGrid
                        reservations={this.state.selectedSpot == null ? [] : this.state.calendarReservations}
                        blocks={this.state.blocks}
                        selectedDay={this.state.selectedDate}
                        selectedSpot={this.state.selectedSpot}
                        selectedProperty={this.state.selectedProperty}
                        selectedTimeRange={this.state.selectedTimeSlots}
                        onSelect={(start_: Date | null, end_: Date | null) => {
                          if (start_ && end_) {
                            let startTime = format(start_, "HH:mm");
                            let endTime = format(end_, "HH:mm");

                            this.setState({
                              selectedTimeSlots: {
                                start: DateTimeHelper.timeStringToMinutes(startTime),
                                end: DateTimeHelper.timeStringToMinutes(endTime)
                              }
                            });
                          } else {
                            this.setState({
                              selectedTimeSlots: undefined
                            });
                          }
                        }}
                        onSelecting={() => {
                          if (this.state.selectedTimeSlots) {
                            this.setState({
                              selectedTimeSlots: undefined
                            });
                          }
                          return true;
                        }}
                        blockSelection={(spotGuid_: string, date_: Date, startTime_: number, endTime_: number) => {
                          this._spotService.blockSpot(spotGuid_, {
                            date: format(date_, "yyyy-MM-dd"),
                            startTime: startTime_,
                            endTime: endTime_
                          }).then(r => {
                            if (r.ok) {
                              r.json().then(block_ => {
                                block_.start = new Date(block_.startUtc);
                                block_.end = new Date(block_.endUtc);

                                this.state.blocks.push(block_);
                                this.setState({
                                  selectedTimeSlots: undefined,
                                  blocks: this.state.blocks
                                });
                                ShowToastr("Successfully blocked selected duration");
                              });
                            } else {
                              ShowAlert("", "Couldn't block the spot due to conflicts. Please try blocking again after reloading the page.", "error");
                            }
                          })
                        }}
                        onUnblock={block_ => {
                          this._spotService.unblockSpot(block_.dailySpotScheduleGuid)
                            .then(r => {
                              if (r.ok) {
                                let index = this.state.blocks.findIndex(b => b.dailySpotScheduleGuid == block_.dailySpotScheduleGuid);
                                this.state.blocks.splice(index, 1);
                                this.setState({
                                  blocks: this.state.blocks
                                });
                                ShowToastr("Successfully unblocked selected duration");
                              }
                            });
                        }}
                        cancelBookings={this._cancelBooking}
                    />
                </div>
            </div>
        }
        {
          this.state.isCancelWarning && this._getCancelWarningPopup()
        }

        {
          this.state.isCancelSuccess && <div className="bulk-upload-blocker">
                <div className="bulk-upload-popup">
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <div>
                                <CheckCircle className="check-icon-size"/>
                            </div>
                            <div className="bulk-upload-header mt-3">
                                Booking Cancelled
                            </div>
                            <div className="mt-3 bulk-upload-sub-content">
                                Booking has been cancelled successfully. please
                            </div>
                            <div className="bulk-upload-sub-content">
                                refund the
                                customer <b>${this.state.cancellingReservation?.reservationAmount.toFixed(2) ?? 0.00}</b>.
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center mt-4">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="ml-2 bul-upload-primary-btn"
                                onClick={() => {
                                  this._closeCancelBookingPopup('success');
                                }}
                            >
                                Ok
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        }

        {
          this.state.isCancelFailed && <div className="bulk-upload-blocker">
                <div className="bulk-upload-popup">
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <div>
                                <Error className="error-icon-size"/>
                            </div>
                            <div className="bulk-upload-header mt-3">
                                Booking are not cancelled. Please contact admin.
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center mt-4">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="ml-2 bul-upload-primary-btn"
                                onClick={() => {
                                  this._closeCancelBookingPopup();
                                }}
                            >
                                Ok
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        }
        {/* Right side drawer */}
        <Drawer
          anchor="right"
          variant={this.state.rightPanelState == RightPanelState.None ? "persistent" : "temporary"}
          open={this.state.rightPanelState != RightPanelState.None}
          onClose={() => {
            this.onCloseDrawerPanel();
          }}
          className="data-grid-right-panel cash-booking-panel"
        >
          {(this.state.rightPanelState !== RightPanelState.Back) && <div className="mx-3">
              <Grid container
                    className={`right-panel-header p-2 ${this.state.rightPanelState === RightPanelState.None || this.state.rightPanelState === RightPanelState.Notification ? "border-bottom" : ""}`}>
                  <Grid item xs>
                      <h4 className={`right-panel-title ${this.state.rightPanelState !== RightPanelState.Notification && this.state.rightPanelState !== RightPanelState.None ? "mt-35-px" : "m-0"}`}>
                        {this._getRightPanelTitle()}
                      </h4>
                  </Grid>
                  <Grid item>
                      <Button
                          onClick={() => {
                            this.onCloseDrawerPanel();
                          }}>
                          <CloseIcon/>
                      </Button>
                  </Grid>
              </Grid>
          </div>
          }
          {this._getRightPanelContent()}
        </Drawer>
      </div>
    );
  }

  protected filterItems = () => {
    this._isFiltered = false;
    let filteredArray = this.state.allReservations.filter(s => {
      let isValid = true;

      if (this.state.appliedFilter && Object.keys(this.state.appliedFilter).length > 1) {
        let filteredProperties = this.state.appliedFilter?.property.filterValue as string[];
        let filteredSpots = this.state.appliedFilter?.spotNumber.filterValue as string[];
        let filteredParkers = this.state.appliedFilter?.parker.filterValue as string[];
        let filteredLicense = this.state.appliedFilter?.license.filterValue as string[];
        let filteredReservationDate = this.state.appliedFilter?.reservation.filterValue as IDateRange;
        let filteredRevenue = this.state.appliedFilter?.revenue.filterValue as INumberRange;

        if (filteredProperties && filteredProperties.length > 0 && isValid) {
          this._isFiltered = true;
          isValid = filteredProperties.indexOf(s.propertyName) > -1;
        }
        if (filteredSpots && filteredSpots.length > 0 && isValid) {
          this._isFiltered = true;
          isValid = filteredSpots.indexOf(s.friendlySpotId) > -1;
        }
        if (filteredParkers && filteredParkers.length > 0 && isValid) {
          this._isFiltered = true;
          isValid = filteredParkers.indexOf(s.parkerName) > -1;
        }
        if (filteredLicense && filteredLicense.length > 0 && isValid) {
          this._isFiltered = true;
          isValid = filteredLicense.indexOf(s.licensePlateNumber) > -1;
        }
        if (filteredReservationDate && (filteredReservationDate.start || filteredReservationDate.end) && isValid) {
          this._isFiltered = true;
          if (filteredReservationDate.start && filteredReservationDate.end) {
            isValid = (s.start >= filteredReservationDate.start && s.start <= filteredReservationDate.end);
          } else if (filteredReservationDate.start) {
            isValid = (s.start >= filteredReservationDate.start);
          } else if (filteredReservationDate.end) {
            isValid = (s.start <= filteredReservationDate.end);
          }
        }
        if (filteredRevenue && (filteredRevenue.start || filteredRevenue.end) && isValid) {
          this._isFiltered = true;
          if (filteredRevenue.start && filteredRevenue.end) {
            isValid = (s.reservationAmount >= filteredRevenue.start && s.reservationAmount <= filteredRevenue.end);
          } else if (filteredRevenue.start) {
            isValid = (s.reservationAmount >= filteredRevenue.start);
          } else if (filteredRevenue.end) {
            isValid = (s.reservationAmount <= filteredRevenue.end);
          }
        }
      }

      return isValid;
    });
    this.setState({subFilteredReservations: this._filterDataBySearch(filteredArray, this.state.searchTerm)})
  }

  private _loadReservations = () => {
    let properties: IPropertyBasics[] = [];
    let selectedProperty: IPropertyBasics | undefined;
    let filteredBookingscache = PreserveFilters.readPreservedFilter('cashbookings');
    let searchTerm = PreserveFilters.getSearchTerm('cashBookingsSearch');

    this._propertyService.GetAllCashProperty()
      .then(r => {
        if (r.ok) {
          r.json().then(response => {
            properties = response;
            selectedProperty = properties && properties.length > 0 ? properties[0] : undefined;
            this.getReservationCallback(searchTerm, filteredBookingscache, properties, selectedProperty?.propertyGuid, selectedProperty);
          });
        }
      });
  }

  private _closeCancelBookingPopup = (isSuccess?: any) => {
    this.setState({
      isCancelWarning: false,
      isCancelFailed: false,
      isCancelSuccess: false,
      cancellingReservation: null
    }, () => {
      isSuccess && this._ReloadReservations();
    });
  }

  private _getCancelWarningPopup = () => {
    const {cancellingReservation} = this.state;
    return <div className="bulk-upload-blocker">
      <div className="bulk-upload-popup">
        <div className="d-flex flex-column">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div>
              <Error className="error-icon-size"/>
            </div>
            <div className="bulk-upload-header mt-3">
              Cancel Booking?
            </div>
            <div className="mt-3 bulk-upload-sub-content">
              Are you sure you want to cancel the booking for
            </div>
            <div className="mt-1 bulk-upload-sub-content">
              license plate <b>{cancellingReservation?.licensePlateNumber ?? ""}</b>?
            </div>
            <div className="mt-1 bulk-upload-sub-content">
              This cannot be undone.
            </div>
          </div>
          <div className="d-flex align-items-center mt-4">
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              className="ml-2"
              onClick={() => {
                this.setState({isCancelWarning: false, cancellingReservation: null});
              }}
            >
              No
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="ml-2"
              onClick={() => {
                if (cancellingReservation) {
                  this._cancelBooking(cancellingReservation, false);
                } else {
                  ShowAlert("", "Invalid Cash booking detail", "error");
                }
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </div>
    </div>
  }

  private _closeDrawPanel = () => {
    const addCashBookingData = {...this._defaultAddCashBookingData};
    if (!this.state.completedTitle)
      this.setState({
        isCashBookingNotificationEnabled: false,
        rightPanelState: RightPanelState.None,
        completedTitle: "",
        addCashBookingData,
        oldAddCashBookingData: undefined
      });
    else {
      this.setState(
        {
          isCashBookingNotificationEnabled: false,
          rightPanelState: RightPanelState.None,
          completedTitle: "",
          addCashBookingData,
          oldAddCashBookingData: undefined,
          disableOutsideClick: false
        }, () => {
          this._ReloadReservations();
        });
    }
  }

  private onCloseDrawerPanel = (ignoreHasChanges?: boolean) => {
    const {addCashBookingData, oldAddCashBookingData, rightPanelState, completedTitle} = this.state;
    let hasUnsavedChanges = false;
    if (rightPanelState !== RightPanelState.Notification && !completedTitle && !ignoreHasChanges)
      hasUnsavedChanges = !oldAddCashBookingData ? false : JSON.stringify(addCashBookingData) !== JSON.stringify(oldAddCashBookingData);

    if (!this.state.disableOutsideClick) {
      if (hasUnsavedChanges) {
        ShowAlertwithConfirm("You have unsaved changes!", "Are you sure you want to close this window?", "warning")
          .then(r_ => {
            if (r_) {
              this._closeDrawPanel();
            }
          })
      } else {
        if (ignoreHasChanges) {
          this.setState({completedTitle: "completed"}, () => {
            this._closeDrawPanel();
          });
        } else {
          this._closeDrawPanel();
        }
      }
    }
  }

  private _ReloadReservations = () => {
    let {selectedProperty} = this.state;
    let filteredBookingscache = PreserveFilters.readPreservedFilter('cashbookings');
    let searchTerm = PreserveFilters.getSearchTerm('cashBookingsSearch');

    this.getReservationCallback(searchTerm, filteredBookingscache, undefined,
      selectedProperty?.propertyGuid, selectedProperty ?? undefined);
  }

  private _cancelBooking = (reservation_: IReservation, initiateRefund_: boolean) => {
    let reservationGuid_ = reservation_.reservationGuid;
    this._spotService.cancelReservation(reservationGuid_, initiateRefund_)
      .then(data => {
        if (data.ok) {
          data.json().then(resp => {
            if (resp === "Spot Cancelled") {
              this.setState({isCancelWarning: false, isCancelFailed: false, isCancelSuccess: true});
              // this._spotService.getReservations(reservation_.spotGuid)
              //     .then(r => r.json())
              //     .then(r => {
              //         let reservationData = r.filter((r_: IReservation) => {
              //             return r_.reservationGuid == reservationGuid_;
              //         });

              //         let reservationItem: IReservation = reservationData[0];

              //         this.state.subFilteredReservations.forEach(reservation => {
              //             if (reservation.reservationGuid == reservationGuid_) {
              //                 if (initiateRefund_) {
              //                     reservation.reservationStatus = 2;
              //                     reservation.reservationStatusText = "Cancelled";
              //                     reservation.reservationStatusClassName = "cancelled";
              //                     reservation.description = reservationItem.description;
              //                     reservation.reservationAmount = reservationItem.reservationAmount;
              //                     reservation.stripeTransactionsIds = reservationItem.stripeTransactionsIds.indexOf(",") == 0 ? reservationItem.stripeTransactionsIds.replace(',,', ',').substring(1) : reservationItem.stripeTransactionsIds.replace(',,', ',');
              //                 } else {
              //                     reservation.reservationStatusText = "Subscription Cancelled";
              //                     reservation.reservationStatusClassName = "subscription-cancelled";
              //                 }

              //                 reservation.isUpcomingMonthlyReservationActive = false;

              //                 return reservation;
              //             }
              //         });

              //         this.setState({
              //             subFilteredReservations: this.state.subFilteredReservations,
              //             _autoApplyFilterKey: Math.random()
              //         }, () => {
              //             //ShowToastr(cancelledMessage);
              //         this.setState({ isCancelWarning: false, isCancelFailed: false, isCancelSuccess: true });
              //         });
              //     });
            } else {
              //ShowToastr("Booking(s) are not cancelled. Please contact admin.");
              this.setState({isCancelWarning: false, isCancelFailed: true, isCancelSuccess: false});
            }

          });
        }
      });
  }

  private _onPropertySelect(property_: IPropertyBasics[] | null, callback_?: (spots_: ISpot[]) => void): void {
    if (this.state.appliedFilter) {
      if (property_ && property_.length) {
        const propertyNames = property_.map(x => {
          return x.propertyName
        });
        this.state.appliedFilter.property.filterValue = propertyNames;
        this.state.appliedFilter.spotNumber.filterValue = [];
      } else {
        this.state.appliedFilter.property.filterValue = [];
        this.state.appliedFilter.spotNumber.filterValue = [];
      }

      this.setState({
        appliedFilter: this.state.appliedFilter,
        _autoApplyFilterKey: new Date().getTime(),
        selectedSpot: null
      });
    }
  }

  private _onSpotSelect(spot_: ISpot | null, callback_?: () => void): void {
    if (this.state.appliedFilter) {
      if (spot_) {
        this.state.appliedFilter.spotNumber.filterValue = [spot_.friendlySpotId];
      } else {
        this.state.appliedFilter.spotNumber.filterValue = [];
      }
      this.setState({
        selectedSpot: spot_,
        appliedFilter: this.state.appliedFilter,
        _autoApplyFilterKey: new Date().getTime()
      });
    }
  }


  private _getRightPanelTitle(): string {
    switch (this.state.rightPanelState) {
      case RightPanelState.Filter:
        return "Filter";
      case RightPanelState.Notification:
        return "Notification";
      case RightPanelState.Add:
        return "New Booking";
      case RightPanelState.Back:
        return this.state.completedTitle ? this.state.completedTitle : "Booking Total";
    }

    return "";
  }

  private _getRightPanelContent() {
    switch (this.state.rightPanelState) {
      case RightPanelState.Notification:
        return (this.state.isCashBookingNotificationEnabled && <div className="cash-booking-parent-right">
            <div className="notification-container">
              {/* <div className='d-flex flex-column pt-4 px-4'>
                    <div className='d-flex justify-content-between'>
                        <div className='notify-header'>
                            Notifications
                        </div>
                        <div className='text-right'>
                            <CloseIcon className='close-btn' onClick={() => this.setState({ isCashBookingNotificationEnabled: !this.state.isCashBookingNotificationEnabled, rightPanelState: RightPanelState.None })} />
                        </div>
                    </div>
                </div> */}
                <div className='d-flex flex-column pt-3 common-notification-body'>
                    <div className='common-notification-sub-body'>
                      {this.state.cashBookingNotifications.map((n_, i_) => {
                        return <div className={"notification-border mx-4"} key={i_}>
                          <div className='pt-2'>
                            {/* {format(n_.endTime, "MMM/dd/yyyy h:mm aa")} */}
                            {format(n_.endTime, "MM/dd/yyyy")} at {format(n_.endTime, "h:mm aa")}
                          </div>
                          <div className='' dangerouslySetInnerHTML={{__html: n_.message}}/>
                          <div className='pt-1 pb-2'>
                            {/* <a
                                                className="sp-more-details n-setup-bg-color"
                                                onClick={() => {
                                                    // let selectedProperties_: IPrivateLotProperty[] = this.state.properties.filter(x => x.propertyGuid == n_.propertyGuid);
                                                    // let pLotList: IPrivateLotsItem[] = this.state.allTenantDetails.filter(x => x.userGuid == n_.userGuid);
                                                    // let pLots_ = null;
                                                    // if (pLotList.length > 0) {
                                                    //     pLots_ = pLotList[0];
                                                    // }
                                                    // this.setState({
                                                    //     selectedProperties: selectedProperties_,
                                                    //     selectedPrivateLot: pLots_,
                                                    //     selectedTenant: pLots_,
                                                    //     isTenantViewDetails: true,
                                                    //     isNotificationEnabled: false
                                                    // }, () => {
                                                    //     this.getTenantLeaseInDetail();
                                                    // });
                                                }}
                                            >View Tenant</a> */}
                          </div>
                        </div>
                      })}
                    </div>
                </div>
            </div>
        </div>);
      case RightPanelState.Add:
        //const addCashBookingData = { ...this.state.addCashBookingData };
        return <AddNewBookingView addCashBookingData={this.state.addCashBookingData}
                                  selectedProperty={this.state.selectedProperty}
                                  onNextClick={(response: any) => {
                                    this.setState({rightPanelState: RightPanelState.Back, holdSpotResponse: response});
                                  }} onClose={() => {
          this.onCloseDrawerPanel();
        }} onUpdateState={(addCashBookingData: IAddCashBookingData, oldAddCashBooking?: boolean) => {
          if (oldAddCashBooking) {
            const oldAddCashBookingData = JSON.parse(JSON.stringify(addCashBookingData));
            this.setState({addCashBookingData, oldAddCashBookingData});
          } else
            this.setState({addCashBookingData});
        }}/>;
      case RightPanelState.Back:
        return <BookingSummaryView holdSpotResponse={this.state.holdSpotResponse}
                                   addCashBookingData={this.state.addCashBookingData}
                                   completeResponse={this.state.completeResponse}
                                   onCompleteClick={(response?: any) => {
                                     response ? this.setState({
                                         completedTitle: "Booking Complete",
                                         completeResponse: response,
                                         disableOutsideClick: false
                                       }) :
                                       this.setState({
                                         rightPanelState: RightPanelState.Add,
                                         disableOutsideClick: false
                                       });
                                   }} onClose={(ignoreChanges?: boolean) => {
          this.onCloseDrawerPanel(ignoreChanges);
        }}
                                   enableDisableOutsideClick={(disableOutsideClick: boolean) => {
                                     this.setState({disableOutsideClick});
                                   }}
                                   onUpdateState={(addCashBookingData: IAddCashBookingData) => {
                                     this.setState({addCashBookingData});
                                   }}/>
      default: {

        return [
          <ReservationFilterView
            isVisible={this.state.rightPanelState == RightPanelState.Filter}
            data={this.state.allReservations}
            appliedFilter={this.state.appliedFilter}
            customFilter={
              {
                properties: this.state.properties,
                selectedProperties: this.state.selectedProperty
              }
            }
            onFilterChange={(appliedFilter_, filteredData_, isFiltered_) => {
              let calendarReservations = this.state.allReservations.filter(r => {
                if (this.state.selectedSpot) {
                  return r.spotGuid == this.state.selectedSpot.spotGuid;
                }

                return false;
              });
              this.setState({
                appliedFilter: appliedFilter_,
                filteredReservations: filteredData_,
                subFilteredReservations: this._filterDataBySearch(filteredData_, this.state.searchTerm),
                calendarReservations: calendarReservations,
                isFiltered: isFiltered_
              }, () => {
                PreserveFilters.preserveFilter('cashbookings', this.state.appliedFilter)
              })
            }}
            onClose={() => this.setState({rightPanelState: RightPanelState.None})}
            autoApplyFilterKey={this.state._autoApplyFilterKey}
          />
        ];
      }
    }
  }

  // private _handleAddEditPanelClose = (requiresUpdate_: boolean, newReservation_?: IReservation) => {
  //     if (requiresUpdate_ && newReservation_) {
  //         let reservations = this.state.allReservations;
  //         reservations.push(newReservation_);
  //         let filteredReservations = this.state.filteredReservations;
  //         let subFilteredReservations = this.state.subFilteredReservations.slice(0);

  //         if ((this.state.selectedProperty == null || this.state.selectedProperty.propertyGuid == newReservation_.propertyGuid) &&
  //             (this.state.selectedSpot == null || this.state.selectedSpot.spotGuid == newReservation_.spotGuid)) {
  //             console.log("IN");
  //             filteredReservations.push(newReservation_);
  //             subFilteredReservations.push(newReservation_);
  //         }

  //         this.setState({
  //             rightPanelState: RightPanelState.None,
  //             allReservations: reservations,
  //             filteredReservations: filteredReservations,
  //             subFilteredReservations: subFilteredReservations,
  //             _autoApplyFilterKey: new Date().getTime()
  //         });
  //     }
  //     else {
  //         this.setState({
  //             rightPanelState: RightPanelState.None
  //         });
  //     }
  // }

  private _searchTermChange = (searchTerm_: string): void => {
    this.setState({
      searchTerm: searchTerm_,

    }, () => {
      this.filterItems();
    });
  }

  private _filterDataBySearch = (data_: IReservation[], searchTerm_: string): IReservation[] => {
    return data_.filter(d => {
      return (
        d.friendlyReservationId?.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
        d.parkerName?.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
        d.licensePlateNumber?.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
        d.friendlySpotId?.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
        d.propertyName?.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
        d.stripeTransactionsIds?.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
        d.description?.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1);
    });
  }

  private _validateEndTime(item_: IReservation) {
    let endTime = new Date(item_.endTimeUtc);
    let dtLocal = new Date();
    let localUtc = new Date(dtLocal.toISOString().split('Z')[0]);

    return (endTime > localUtc && item_.reservationStatus != 2 && item_.reservationStatus != 5 && item_.isCashPayment);
  }

  private convertUTCDateToLocalDate(date: Date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  }
}

interface IReservationListProps {
  match: {
    params: {
      source?: string;
      propertyGuid?: string;
      spotGuid?: string;
    }
  }
}

interface IReservationListState {
  isReservationsLoaded: boolean;
  viewMode: EnumReservationViewMode;
  properties: IPropertyBasics[];
  spots: ISpot[];
  selectedProperty: IPropertyBasics | null;
  selectedSpot: ISpot | null;
  selectedDate: Date;
  allReservations: IReservation[];
  filteredReservations: IReservation[];
  subFilteredReservations: IReservation[];
  calendarReservations: IReservation[];
  blocks: ISpotBlockData[];
  searchTerm: string;
  appliedFilter?: IAppliedFilter;
  rightPanelState: RightPanelState;
  isFiltered: boolean;
  selectedReservation?: IReservation;
  _autoApplyFilterKey: number;

  selectedTimeSlots?: INumberRange;
  editingReservation: IReservation | null;
  isVisibleEditing: boolean;
  isScreenAccessible: boolean;

  cashBookingNotifications: any[];
  isCashBookingNotificationEnabled: boolean;
  completedTitle: string,
  isCancelWarning: boolean,
  isCancelSuccess: boolean,
  isCancelFailed: boolean
  addCashBookingData: IAddCashBookingData,
  holdSpotResponse?: any,
  completeResponse?: any,
  cancellingReservation: IReservation | null,
  oldAddCashBookingData?: IAddCashBookingData,
  disableOutsideClick: boolean;
  loaderPercentage?: number;
}

enum EnumReservationViewMode {
  List = 1,
  Calendar = 2
}
