import React from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { DateTimeHelper } from "../../Helpers/DateTimeHelper";
import { format } from "date-fns";

export default class BookingCalendarNav extends React.Component<
  IBookingCalendarNavProps,
  IBookingCalendarNavState
> {
  constructor(props_: IBookingCalendarNavProps) {
    super(props_);
  }

  render() {
    return (
      <div className="b-calendar-nav">
        <div className="text-center">
          {/* <div
            className="btn-prev-month"
            onClick={() => {
              // let changedDate: Date = new Date(this.props.viewDate.getFullYear(), this.props.viewDate.getMonth() - 1, this.props.viewDate.getDate());
              let changedDate: Date = this.navigateDate(-1);

              if (this.props.onDateChange) {
                this.props.onDateChange(changedDate);
              }
            }}>
            <ChevronLeftIcon />
          </div> */}
          <div className="c-month-name d-inline-block">
            {this.displayDate()}
          </div>
          {/* <div
            className="btn-next-month"
            onClick={() => {
              // let changedDate: Date = new Date(this.props.viewDate.getFullYear(), this.props.viewDate.getMonth() + 1, this.props.viewDate.getDate());
              let changedDate: Date = this.navigateDate(1);
              if (this.props.onDateChange) {
                this.props.onDateChange(changedDate);
              }
            }}>
            <ChevronRightIcon />
          </div> */}
        </div>
      </div>
    );
  }

  private displayDate() {
    if (this.props.calendarType == 1) {
      return (
        <div>
          <span className="m-view-name">
            {DateTimeHelper.monthLabels[
              this.props.viewDate.getMonth()
            ].toLocaleLowerCase()}
          </span>{" "}
          <span className="m-view-name m-view-year">
            {this.props.viewDate.getFullYear()}
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="m-view-name">
            {format(this.props.viewDate, "EEEE dd MMM")}
          </span>{" "}
          <span className="m-view-name m-view-year">
            {this.props.viewDate.getFullYear()}
          </span>
        </div>
      );
    }
  }

  private navigateDate(navType: number) {
    if (this.props.calendarType == 1) {
      return new Date(
        this.props.viewDate.getFullYear(),
        this.props.viewDate.getMonth() + navType,
        this.props.viewDate.getDate()
      );
    } else {
      return new Date(
        this.props.viewDate.getFullYear(),
        this.props.viewDate.getMonth(),
        this.props.viewDate.getDate() + navType
      );
    }
  }
}

interface IBookingCalendarNavProps {
  viewDate: Date;
  onDateChange: (s_: Date) => void;
  calendarType: number;
}

interface IBookingCalendarNavState {}
