// export interface IReservationCount {
//     date: Date;
//     count: number;
// }

import { INumberRange } from "../../Components/DataGrid/Interfaces";
import { ISingleScheduleData } from "../../Components/ScheduleComponent";

export interface IReservation {
    reservationGuid: string;
    friendlyReservationId: string;
    spotGuid: string;
    friendlySpotId: string;
    parkerGuid: string;
    parkerName: string;
    vehicleGuid: string;
    licensePlateNumber: string;
    propertyGuid: string;
    propertyName: string;
    start: Date;
    end: Date;
    startTimeUtc: Date;
    endTimeUtc: Date;
    reservationAmount: number;
    reservationStatus: number;
    paymentStatus: number;
    stripeTransactionsIds: string;
    description: string;
    reservationStatusText?: string;
    reservationTypeId: number;
    spotAllocations?: ISpotAllocation[];
    isMonthlyReservationActive: boolean;
    isUpcomingMonthlyReservationActive: boolean;
    reservationStatusClassName?: string;
    propertyCity: string;
    isCashPayment?: boolean;
}

export interface ISpotAllocation {
    spotAllocationGuid: string;
    start: Date;
    end: Date;
}

export interface IReservationRequest {
    parkerGuid: string;
    vehicleGuid?: string;
    start: Date;
    end: Date;
}

export interface IVehicle {
    vehicleGuid: string;
    vehiclePlateNumber: string;
}

export function extractDayStartEndFromSpotSchedule(schedules_: ISingleScheduleData[], selectedDate_: Date): INumberRange[] {
    let selectedDateDay = selectedDate_.getDay() + 1;
    let ranges: INumberRange[] = [];

    if (schedules_) {
        for (let sc of schedules_) {
            if (sc.selectedDays && sc.selectedTimeRange) {
                if (sc.selectedDays.indexOf(selectedDateDay) > -1) {
                    ranges.push({
                        start: sc.selectedTimeRange.start,
                        end: sc.selectedTimeRange.end
                    });
                }
            }
        }
    }

    return ranges.sort((s1, s2) => (s1.start as number) - (s2.start as number));
}

export interface INearBySpots {
    refundPrice: number;
    originalBookingPrice: number;
    originalFriendlySpotId: string;
    availableSpots: ISpotAvailable[];
}

export interface ISpotAvailable {
    spotGuid: string;
    friendlySpotId: string;
    schedule: ISingleScheduleData[] | null;
    price: number;
    matchingAmenities: string[];
    additionalAmenities: string[];
    missingAmenities: string[];
    spotSchedule: string[];
    spotAmenities: ISpotAmenities[];
    zoneGuid: string;
    zoneName: string;
    isBooked: boolean;
    priceWithoutTax: number,
    fullPrice: number
}

export interface ISpotAmenities {
    type: string;
    amenity: string;
}

export enum EnumRecurringEventType {
    "None" = 0,
    "Today",
    "This month",
    "This and future months",
    "Next month and future months"
}
export interface BookingDetailsHistory extends IReservation {
    monthlyBookingAmount: number;
    zoneGuid: string;
    zoneName: string;
    auditTrailDetails?: AuditTrailDetails[];
    encryptedUserGuid: string;
    weeklyTimeBlock: number;
    spotGuid: string;
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: number;
    paidForToday: number;
    paidForCurrentMonth: number;
    reservationAmountWithoutTax: number;
    vehicleModel: string;
    vehicleName: string;
    vehicleColor: string;
    timeZone: string;
}
export interface AuditTrailDetails {
    spotAllocationGuid: string,
    activityTypeId: number,
    vehicleDetails?: VehicleChangeDetails,
    spotChangeDetails?: SpotChangeDetails,
    priceChangeDetails?: PriceChangeDetails,
    createdBy: string,
    createdByName: string,
    createdTime: string,
    notes: string,
    startTimeLocal: string,
    endTimeLocal: string,
    newStartTimeLocal: string,
    newEndTimeLocal: string
}
export interface NotesList {
    message: string;
    dateandtime: string;
    name: string;
}
export interface SpotChangeDetails {
    originalSpotGuid: string;
    originalSpotName: string;
    newSpotGuid: string;
    newSpotName: string;
    spotChangeTypeId: number

}
export interface VehicleChangeDetails {
    originalVehicleGuid: null,
    originalLicense: null,
    newVehicleGuid: null,
    newLicense: null

}
export interface PriceChangeDetails {
    originalAmount: number;
    newAmount: number;
    refundAmount: number;
    priceChangeTypeId: number;
    spotChangeTypeId: number

}

export enum EnumSpotRecurrenceType {
    Today = 1,
    CurrentMonth,
    Future
}