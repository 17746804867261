import { Button, Checkbox, Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import { ArrowBackIos } from "@material-ui/icons";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import { format } from 'date-fns';
import { DateRange } from 'materialui-daterange-picker';
import moment from 'moment';
import React from "react";
import { Col, Row } from "reactstrap";
import { ShowAlert } from '../../Common/ShowAlert';
import { IShowModelAlert, ShowModelAlertType } from '../../Common/interface';
import ArrayHelper from '../../Helpers/ArrayHelper';
import { DateTimeHelper } from '../../Helpers/DateTimeHelper';
import StringHelper from '../../Helpers/StringHelper';
import SpotService from '../../Services/SpotService';
import UserService from '../../Services/UserService';
import { IBlockTime } from '../CashBookingSchedule/Interfaces';
import { IPropertyBasics } from '../Spots/Interfaces';
import GrydCalendarPicker from './GrydCalendarPicker';
import GrydDateRangePicker from './GrydDateRangePicker';
import './ScheduledSpots.css';
import { BookingType, EnumCalendarPosition, IBookingDetailInfo, IMonthlyPreZoneSpotRequest, IPreZoneSpot, IPreZoneSpotRequest, IUserSearchModel, IVehicle, MonthlyBookingType } from './interfaces';

export default class AddEditBookings extends React.Component<IAddEditBookingProps, IAddEditBookingState> {

    private _spotService: SpotService;
    private _userService: UserService;
    constructor(props: IAddEditBookingProps) {
        super(props)
        this.state = {
            showFilter: false,
            times: this.getTimes(),
            bookingDetailInfo: this.props.bookingDetailInfo,
            zones: [],
            spots: [],
            isMonthlyBooking: false,
            isAnotherVehicle: false,
            isNewUser: false,
            userDetail: null,
            vehicleDetails: [],
            selectedVehicleDetail: null,
            preZoneSpot: []
        }
        this._spotService = new SpotService();
        this._userService = new UserService();
    }

    componentDidMount() {
        let { bookingDetailInfo } = this.state;
        let { userDetail, isFromBookingSummary, isModifiedBooking } = this.props;
        const { selectedStartTime, selectedEndTime } = this.defaultTime();
        //Setting user detail while coming back to booking detail screen
        if (isFromBookingSummary || isModifiedBooking) {
            userDetail = bookingDetailInfo.userDetail;
        }

        if (userDetail) {
            //Get existing Vehicle details
            this._spotService.getVehicle(userDetail.parkerEncodeGuid)
                .then(vehicleJson => {
                    if (vehicleJson.ok) {
                        vehicleJson.json()
                            .then(vehicleResponse => {
                                const vehicleDetails: IVehicle[] = vehicleResponse;
                                if (isModifiedBooking) {
                                    vehicleDetails.forEach(x => {
                                        x.vehicleOwnerGuid = bookingDetailInfo.userDetail.parkerGuid ?? "00000000-0000-0000-0000-000000000000";
                                    });
                                }
                                if (isFromBookingSummary || isModifiedBooking) {
                                    this.setState({
                                        bookingDetailInfo,
                                        userDetail: this.props.userDetail ? userDetail : null,
                                        vehicleDetails,
                                        selectedVehicleDetail: bookingDetailInfo.vehicleDetail,
                                        isNewUser: !bookingDetailInfo.userDetail.isExistingUser,
                                        preZoneSpot: bookingDetailInfo.preZoneSpot,
                                        zones: bookingDetailInfo.preZoneSpot.filter((value, index, self) => self.findIndex(x => x.zoneGuid === value.zoneGuid) === index),
                                        spots: bookingDetailInfo.preZoneSpot.filter(x => x.zoneGuid === bookingDetailInfo.selectedZone?.zoneGuid),
                                        isMonthlyBooking: bookingDetailInfo.bookingType === BookingType.Monthly
                                    });
                                }
                                else {
                                    this.setState({
                                        bookingDetailInfo: {
                                            ...bookingDetailInfo,
                                            startTime: selectedStartTime,
                                            endTime: selectedEndTime,
                                            userDetail: userDetail ?? {
                                                email: "",
                                                firstName: "",
                                                isExistingUser: false,
                                                lastName: "",
                                                parkerEncodeGuid: "",
                                                phoneNumber: ""
                                            }
                                        },
                                        userDetail,
                                        vehicleDetails
                                    });
                                }
                            });
                    }
                    else {
                        if (isFromBookingSummary || isModifiedBooking) {
                            let userDetails = this.props.userDetail ? userDetail : null;
                            userDetails = isModifiedBooking ? userDetail : userDetails;
                            this.setState({
                                bookingDetailInfo,
                                vehicleDetails: [],
                                userDetail: userDetails,
                                selectedVehicleDetail: bookingDetailInfo.vehicleDetail,
                                isNewUser: !bookingDetailInfo.userDetail.isExistingUser,
                                preZoneSpot: bookingDetailInfo.preZoneSpot,
                                zones: bookingDetailInfo.preZoneSpot.filter((value, index, self) => self.findIndex(x => x.zoneGuid === value.zoneGuid) === index),
                                spots: bookingDetailInfo.preZoneSpot.filter(x => x.zoneGuid === bookingDetailInfo.selectedZone?.zoneGuid),
                                isMonthlyBooking: bookingDetailInfo.bookingType === BookingType.Monthly
                            });
                        }
                        else {
                            this.setState({
                                bookingDetailInfo: {
                                    ...bookingDetailInfo,
                                    startTime: selectedStartTime,
                                    endTime: selectedEndTime
                                },
                                userDetail,
                                isNewUser: true
                            });
                        }
                    }
                }).catch(error => {
                    console.log("Vehicle Error: ", error);
                });
        }
        else {
            this.setState({
                bookingDetailInfo: {
                    ...bookingDetailInfo,
                    startTime: selectedStartTime,
                    endTime: selectedEndTime
                },
                userDetail
            });
        }
    }

    defaultTime = () => {
        const startTime = DateTimeHelper.getCurrentArrangeTime(new Date());
        const endTime = DateTimeHelper.getEndTime(startTime, 60);
        const selectedStartTime = this.state.times.filter(x => x.timeString === startTime)[0];
        const selectedEndTime = this.state.times.filter(x => x.timeString === endTime)[0];
        return {
            selectedStartTime,
            selectedEndTime
        }
    }

    private GetVehicle = (parkerEncodeGuid: string) => {
        const { bookingDetailInfo } = this.state;
        this._spotService.getVehicle(parkerEncodeGuid)
            .then(vehicleJson => {
                if (vehicleJson.ok) {
                    vehicleJson.json()
                        .then(vehicleResponse => {
                            const vehicleDetails: IVehicle[] = vehicleResponse;
                            this.setState({
                                vehicleDetails,
                                isNewUser: this.props.userDetail === null,
                                bookingDetailInfo: {
                                    ...bookingDetailInfo,
                                    userDetail: {
                                        ...bookingDetailInfo.userDetail,
                                        isExistingUser: this.props.userDetail !== null
                                    }
                                },
                                userDetail: this.props.userDetail === null ? null : {
                                    ...bookingDetailInfo.userDetail,
                                    isExistingUser: this.props.userDetail !== null
                                }
                            });
                        });
                }
                else {
                    this.setState({
                        isNewUser: true
                    });
                }
            });
    }

    private addVehicle = (bookingDetailInfo: IBookingDetailInfo) => {
        let { userDetail, vehicleDetail } = bookingDetailInfo;
        const { isModifiedBooking } = this.props;
        let { vehicleDetails } = this.state;
        const { onNext } = this.props;
        this._userService.addUserVehicle({
            parkerEncodeGuid: userDetail.parkerEncodeGuid,
            plateNumber: vehicleDetail?.licensePlate,
            vehicleName: vehicleDetail?.vehicleMake,
            vehicleModel: vehicleDetail?.vehicleModel,
            vehicleColor: vehicleDetail?.vehicleColor,
            vehicleTypeId: 1,
            vehicleStatusId: 1
        })
            .then(vehicleJson => {
                if (vehicleJson.ok) {
                    vehicleJson.json()
                        .then(vehicleResponse => {
                            const vehicleGuid: string = vehicleResponse;
                            const vehicalDetail: IVehicle = {
                                vehicleGuid: vehicleGuid,
                                vehicleOwnerGuid: isModifiedBooking ? bookingDetailInfo.userDetail.parkerGuid ?? "00000000-0000-0000-0000-000000000000"
                                    : vehicleDetail?.vehicleOwnerGuid ?? "00000000-0000-0000-0000-000000000000",
                                licensePlate: vehicleDetail?.licensePlate ?? "",
                                vehicleMake: vehicleDetail?.vehicleMake ?? "",
                                vehicleModel: vehicleDetail?.vehicleModel ?? "",
                                vehicleColor: vehicleDetail?.vehicleColor ?? ""
                            }
                            if (vehicleDetails.length > 0) {
                                vehicleDetails.push(vehicalDetail);
                            }
                            this.setState({
                                bookingDetailInfo: {
                                    ...bookingDetailInfo,
                                    vehicleDetail: vehicalDetail
                                },
                                selectedVehicleDetail: vehicalDetail,
                                vehicleDetails,
                                isNewUser: true
                            }, () => {
                                onNext(this.state.bookingDetailInfo, this.state.userDetail);
                            });
                        });
                }
                else {
                    vehicleJson.json().then(response => {
                        ShowAlert("", response, "error");
                        this.setState({
                            isNewUser: true
                        });
                    });
                }
            });
    }

    private loadPreZoneSpot = () => {
        const { bookingDetailInfo } = this.state;
        const { bookingType, selectedProperty, selectedMonth,
            startTime, endTime, date, monthlyBookingType } = bookingDetailInfo;

        let zoneSpotRequest: IPreZoneSpotRequest | null = null;
        let monthlyZoneSpotRequest: IMonthlyPreZoneSpotRequest | null = null;

        if (bookingType === BookingType['Hourly/Daily'] && selectedProperty && startTime && endTime && date && date.startDate && date.endDate) {
            const copyDate = { startDate: new Date(date.startDate), endDate: new Date(date.endDate) };
            DateTimeHelper.ChangeTime(copyDate.startDate, startTime.timeString);
            DateTimeHelper.ChangeTime(copyDate.endDate, endTime.timeString);

            const bookingStartUTC = DateTimeHelper.LocalToUtcString(copyDate.startDate);
            const bookingEndUTC = DateTimeHelper.LocalToUtcString(copyDate.endDate);

            zoneSpotRequest = {
                propertyGuid: selectedProperty.propertyGuid,
                startDate: bookingStartUTC,
                endDate: bookingEndUTC
            }
        }

        if (bookingType === BookingType.Monthly && selectedProperty && selectedMonth && monthlyBookingType !== 0) {
            monthlyZoneSpotRequest = {
                propertyGuid: selectedProperty.propertyGuid,
                searchStartDate: DateTimeHelper.GetDateOnly(selectedMonth),
                monthlyRateTypeId: monthlyBookingType
            }
        }

        let zoneSpotService: Promise<Response> | null = null;

        if (zoneSpotRequest) {
            zoneSpotService = this._spotService.getPreBookingZoneSpot(zoneSpotRequest);
        }

        if (monthlyZoneSpotRequest) {
            zoneSpotService = this._spotService.getMonthlyPreBookingZoneSpot(monthlyZoneSpotRequest);
        }

        if (zoneSpotService) {
            zoneSpotService
                .then(res => {
                    if (res.ok) {
                        res.json().then(response => {
                            const preZoneSpot: IPreZoneSpot[] = response;
                            const zones: IPreZoneSpot[] = ArrayHelper.sortArrayAlphaNumericObj(preZoneSpot.filter((value, index, self) =>
                                self.findIndex(x => x.zoneGuid === value.zoneGuid) === index), "zoneName", "asc");
                            this.setState({
                                zones,
                                preZoneSpot,
                                bookingDetailInfo: {
                                    ...bookingDetailInfo,
                                    preZoneSpot
                                }
                            });
                        });
                    }
                })
        }
    }

    render() {
        const { bookingDetailInfo, zones, spots, isMonthlyBooking,
            vehicleDetails, selectedVehicleDetail, preZoneSpot, isNewUser } = this.state;
        const { handleChange, isModifiedBooking } = this.props;
        let { userDetail, vehicleDetail, bookingType,
            selectedProperty, selectedZone, selectedSpot,
            startTime, endTime, date, monthlyBookingType, selectedMonth } = bookingDetailInfo;

        const { email, firstName, lastName, phoneNumber, isExistingUser } = userDetail ?? {
            email: "", firstName: "", lastName: "", phoneNumber: "", parkerEncodeGuid: ""
        };

        if (vehicleDetail === null) {
            vehicleDetail = {
                vehicleMake: "", vehicleModel: "", vehicleColor: "", licensePlate: "", vehicleOwnerGuid: "", vehicleGuid: ""
            };
        }

        const { vehicleMake, vehicleModel, vehicleColor, licensePlate, vehicleOwnerGuid, vehicleGuid } = vehicleDetail;

        let disableMonthly = false;

        let disableStartTime = false;

        if (isModifiedBooking && bookingDetailInfo.reservation) {
            const currentDate = new Date(new Date().toISOString().split('Z')[0]);
            const startDate = moment(bookingDetailInfo.reservation.startTimeUtc).toDate();
            if (bookingType === BookingType.Monthly && currentDate >= startDate) {
                disableMonthly = true;
            }

            const start = moment(bookingDetailInfo.reservation.startTimeUtc).toDate();
            if (bookingType === BookingType['Hourly/Daily'] && currentDate >= start) {
                disableStartTime = true;
            }
        }

        return (
            <>
                {!this.props.isNoHeader && <Grid container className="right-panel-header p-2">
                    <Grid item xs>
                        <Grid item xs={2} className="MuiGrid-grid-custom">
                            <Tooltip title="Back">
                                <Grid container className="mt-4-px">
                                    <Grid item>
                                        <IconButton aria-label="Back" size="small" onClick={() => {
                                            if (this.state.isNewUser) {
                                                this.setState({ isNewUser: false });
                                            }
                                            else {
                                                this.props.backToSearch();
                                            }
                                        }}>
                                            <ArrowBackIos />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={() => {
                                this.props.onClose();
                            }}
                        >
                            <CloseIcon />
                        </Button>
                    </Grid>
                </Grid>
                }
                <div className={`calendar-block-container ${this.props.customClass ? this.props.customClass : ""}`}>
                    {!this.props.isNoHeader && <h4 className="booking-heading">New Booking</h4>}
                    <div className="booking-right-panel-body">
                        {!this.props.isModifiedBooking && <><div className="cl-block-sc mt-4">
                            <div className="booking-header">PARKER DETAILS</div>
                        </div>
                            {(this.state.userDetail && isExistingUser) && <>
                                <div className="cl-block-sc">
                                    <div className="bk-sp-lable" style={{ fontWeight: 400, marginBottom: 0 }}>Email Address</div>
                                    <div className="bk-sp-lable" style={{ margin: 0 }}>{email}</div>
                                </div>
                                {(firstName || lastName) && <div className="cl-block-sc">
                                    <div className="bk-sp-lable" style={{ fontWeight: 400, marginBottom: 0 }}>Full Name</div>
                                    <div className="bk-sp-lable" style={{ margin: 0 }}>{`${firstName} ${lastName}`.trim()}</div>
                                </div>
                                }
                                {phoneNumber !== "" && phoneNumber !== "0" && <div className="cl-block-sc">
                                    <div className="bk-sp-lable" style={{ fontWeight: 400, marginBottom: 0 }}>Phone Number</div>
                                    <div className="bk-sp-lable" style={{ margin: 0 }}>{StringHelper.ConvertPhoneNumberHyphen(phoneNumber.toString())}</div>
                                </div>
                                }
                                <div className='fulldivider'></div>
                            </>
                            }
                            {((!this.state.userDetail && isNewUser) || (userDetail && !isExistingUser)) && <>
                                <div className="cl-block-sc">
                                    <div className="bk-sp-lable">Email Address</div>
                                    <TextField
                                        placeholder="Email Address"
                                        fullWidth
                                        variant="outlined"
                                        required={true}
                                        margin="normal"
                                        className="mt-1 customHeight"
                                        onPaste={() => { return false }}
                                        inputProps={{ maxLength: 100 }}
                                        value={email}
                                        onChange={(e_: any) => {
                                            this.setState({
                                                bookingDetailInfo: { ...bookingDetailInfo, userDetail: { ...userDetail, email: e_.target.value } }
                                            }, () => {
                                                handleChange(this.state.bookingDetailInfo);
                                            });
                                        }}
                                        error={!StringHelper.validateEmail(email)}
                                        autoComplete="off"
                                        disabled={this.state.isNewUser}
                                    />
                                </div>
                                <div className="cl-block-sc">
                                    <div className="bk-sp-lable">First Name</div>
                                    <TextField
                                        placeholder="First Name"
                                        fullWidth
                                        variant="outlined"
                                        required={true}
                                        margin="normal"
                                        className="mt-1 customHeight"
                                        onPaste={() => { return false }}
                                        inputProps={{ maxLength: 120 }}
                                        value={firstName}
                                        onChange={(e_: any) => {
                                            this.setState({
                                                bookingDetailInfo: { ...bookingDetailInfo, userDetail: { ...userDetail, firstName: e_.target.value } }
                                            }, () => {
                                                handleChange(this.state.bookingDetailInfo);
                                            });
                                        }}
                                        error={!firstName}
                                        autoComplete="off"
                                        disabled={this.state.isNewUser}
                                    />
                                </div>
                                <div className="cl-block-sc">
                                    <div className="bk-sp-lable">Last Name</div>
                                    <TextField
                                        placeholder="Last Name"
                                        fullWidth
                                        variant="outlined"
                                        required={true}
                                        margin="normal"
                                        className="mt-1 customHeight"
                                        onPaste={() => { return false }}
                                        inputProps={{ maxLength: 120 }}
                                        value={lastName}
                                        onChange={(e_: any) => {
                                            this.setState({
                                                bookingDetailInfo: { ...bookingDetailInfo, userDetail: { ...userDetail, lastName: e_.target.value } }
                                            }, () => {
                                                handleChange(this.state.bookingDetailInfo);
                                            });
                                        }}
                                        error={!lastName}
                                        autoComplete="off"
                                        disabled={this.state.isNewUser}
                                    />
                                </div>
                                <div className="cl-block-sc">
                                    <div className="bk-sp-lable">Phone Number</div>
                                    <TextField
                                        placeholder="123-456-7890"
                                        fullWidth
                                        variant="outlined"
                                        required={true}
                                        margin="normal"
                                        className="mt-1 customHeight"
                                        onPaste={() => { return false }}
                                        inputProps={{ maxLength: 10 }}
                                        value={StringHelper.ConvertPhoneNumberHyphen(phoneNumber)}
                                        onChange={(e_: any) => {
                                            let mobileNumber = isNaN(e_.target.value.replaceAll('-', '')) ? phoneNumber : e_.target.value.replaceAll('-', '');
                                            this.setState({
                                                bookingDetailInfo: { ...bookingDetailInfo, userDetail: { ...userDetail, phoneNumber: mobileNumber } }
                                            }, () => {
                                                handleChange(this.state.bookingDetailInfo);
                                            });
                                        }}
                                        error={!StringHelper.validateMobile(phoneNumber)}
                                        autoComplete="off"
                                        disabled={this.state.isNewUser}
                                    />
                                </div>
                            </>}
                        </>}
                        {((this.state.userDetail || isNewUser || this.props.isModifiedBooking)) && <>
                            <div className="cl-block-sc  mt-3">
                                <div className="booking-header">VEHICLE DETAILS</div>
                            </div>
                            {((!this.state.userDetail && (vehicleDetails && vehicleDetails.length > 0)) ||
                                (this.state.userDetail && (vehicleDetails && vehicleDetails.length > 0)) ||
                                isModifiedBooking) && <>
                                    <div className="cl-block-sc">
                                        <div className="bk-sp-lable">Vehicle</div>
                                        <Autocomplete
                                            size='small'
                                            id="cl-start-time"
                                            className="mt-1"
                                            options={vehicleDetails}
                                            value={selectedVehicleDetail}
                                            onChange={(event: any, value: IVehicle | null) => {
                                                this.setState({
                                                    selectedVehicleDetail: value,
                                                    bookingDetailInfo: {
                                                        ...bookingDetailInfo,
                                                        vehicleDetail: value
                                                    },
                                                    isAnotherVehicle: false
                                                }, () => {
                                                    handleChange(this.state.bookingDetailInfo);
                                                });
                                            }}
                                            openOnFocus={true}
                                            selectOnFocus={true}
                                            getOptionLabel={(option) => { return `${option.licensePlate}, ${option.vehicleColor} ${option.vehicleMake} ${option.vehicleModel}`.trim() }}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    {`${option.licensePlate}, ${option.vehicleColor} ${option.vehicleMake} ${option.vehicleModel}`.trim()}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => <TextField {...params}
                                                placeholder="Select Vehicle"
                                                variant="outlined"
                                                error={vehicleDetails.length > 0 && !selectedVehicleDetail}
                                            />}
                                            disabled={this.state.isAnotherVehicle || vehicleDetails.length === 0}
                                        />
                                    </div>
                                    <div className="cl-block-sc mt-3 add-another">
                                        <Checkbox
                                            icon={<div className="repeat-b-check" />}
                                            checkedIcon={<div className="repeat-b-check selected" />}
                                            style={{ marginRight: 8 }}
                                            checked={this.state.isAnotherVehicle}
                                            color="primary"
                                            onChange={() => {
                                                this.setState({
                                                    isAnotherVehicle: !this.state.isAnotherVehicle,
                                                    selectedVehicleDetail: null,
                                                    bookingDetailInfo: {
                                                        ...bookingDetailInfo,
                                                        vehicleDetail: {
                                                            vehicleMake: "",
                                                            vehicleModel: "",
                                                            vehicleColor: "",
                                                            licensePlate: "",
                                                            vehicleGuid: "",
                                                            vehicleOwnerGuid: ""
                                                        }
                                                    }
                                                });
                                            }}
                                        /> Add another vehicle
                                    </div>
                                </>
                            }
                            {((!this.state.userDetail && vehicleDetails.length === 0 && !isModifiedBooking) || (this.state.isAnotherVehicle) ||
                                (this.state.userDetail && vehicleDetails.length === 0 && !isModifiedBooking)
                                //  || (this.state.userDetail && selectedVehicleDetail)
                                //  || (!this.state.userDetail && selectedVehicleDetail)
                            ) && <>
                                    <div className="cl-block-sc">
                                        <div className="bk-sp-lable">License Plate</div>
                                        <TextField
                                            placeholder="License Plate"
                                            fullWidth
                                            variant="outlined"
                                            required={true}
                                            margin="normal"
                                            className="mt-1 customHeight"
                                            onPaste={() => { return false }}
                                            inputProps={{ maxLength: 9 }}
                                            value={licensePlate}
                                            onChange={(e_: any) => {
                                                this.setState({
                                                    bookingDetailInfo: {
                                                        ...bookingDetailInfo,
                                                        vehicleDetail:
                                                        {
                                                            vehicleMake,
                                                            licensePlate: e_.target.value,
                                                            vehicleColor,
                                                            vehicleGuid,
                                                            vehicleModel,
                                                            vehicleOwnerGuid
                                                        }
                                                    }
                                                }, () => {
                                                    handleChange(this.state.bookingDetailInfo);
                                                });
                                            }}
                                            error={!licensePlate}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="cl-block-sc">
                                        <div className="bk-sp-lable">Make</div>
                                        <TextField
                                            placeholder="Make"
                                            fullWidth
                                            variant="outlined"
                                            required={true}
                                            margin="normal"
                                            className="mt-1 customHeight"
                                            onPaste={() => { return false }}
                                            inputProps={{ maxLength: 30 }}
                                            value={vehicleMake}
                                            onChange={(e_: any) => {
                                                this.setState({
                                                    bookingDetailInfo: {
                                                        ...bookingDetailInfo,
                                                        vehicleDetail:
                                                        {
                                                            vehicleMake: e_.target.value,
                                                            licensePlate,
                                                            vehicleColor,
                                                            vehicleGuid,
                                                            vehicleModel,
                                                            vehicleOwnerGuid
                                                        }
                                                    }
                                                }, () => {
                                                    handleChange(this.state.bookingDetailInfo);
                                                });
                                            }}
                                            error={!vehicleMake}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="cl-block-sc">
                                        <div className="bk-sp-lable">Model</div>
                                        <TextField
                                            placeholder="Model"
                                            fullWidth
                                            variant="outlined"
                                            required={true}
                                            margin="normal"
                                            className="mt-1 customHeight"
                                            onPaste={() => { return false }}
                                            inputProps={{ maxLength: 30 }}
                                            value={vehicleModel}
                                            onChange={(e_: any) => {
                                                this.setState({
                                                    bookingDetailInfo: {
                                                        ...bookingDetailInfo,
                                                        vehicleDetail:
                                                        {
                                                            vehicleMake,
                                                            licensePlate,
                                                            vehicleColor,
                                                            vehicleGuid,
                                                            vehicleModel: e_.target.value,
                                                            vehicleOwnerGuid
                                                        }
                                                    }
                                                }, () => {
                                                    handleChange(this.state.bookingDetailInfo);
                                                });
                                            }}
                                            error={!vehicleModel}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="cl-block-sc">
                                        <div className="bk-sp-lable">Colour</div>
                                        <TextField
                                            placeholder="Colour"
                                            fullWidth
                                            variant="outlined"
                                            required={true}
                                            margin="normal"
                                            className="mt-1 customHeight"
                                            onPaste={() => { return false }}
                                            inputProps={{ maxLength: 30 }}
                                            value={vehicleColor}
                                            onChange={(e_: any) => {
                                                this.setState({
                                                    bookingDetailInfo: {
                                                        ...bookingDetailInfo,
                                                        vehicleDetail:
                                                        {
                                                            vehicleMake,
                                                            licensePlate,
                                                            vehicleColor: e_.target.value,
                                                            vehicleGuid,
                                                            vehicleModel,
                                                            vehicleOwnerGuid
                                                        }
                                                    }
                                                }, () => {
                                                    handleChange(this.state.bookingDetailInfo);
                                                });
                                            }}
                                            error={!vehicleColor}
                                            autoComplete="off"
                                        />
                                    </div>
                                </>
                            }
                            <div className="cl-block-sc  mt-3">
                                <div className="booking-header">BOOKING DETAILS</div>
                            </div>
                            <div className="cl-block-sc">
                                <div className="bk-sp-lable">Booking Type</div>
                                <Autocomplete
                                    size='small'
                                    id="cl-start-time"
                                    className="mt-1"
                                    options={[BookingType[BookingType['Hourly/Daily']], BookingType[BookingType.Monthly]]}
                                    value={BookingType[bookingType]}
                                    disableClearable
                                    onChange={(event: any, value: any) => {
                                        const { selectedStartTime, selectedEndTime } = this.defaultTime();
                                        const bookType: any = BookingType[value];
                                        this.setState({
                                            bookingDetailInfo: {
                                                ...bookingDetailInfo,
                                                bookingType: bookType,
                                                selectedProperty: null,
                                                selectedZone: null,
                                                selectedSpot: null,
                                                monthlyBookingType: MonthlyBookingType['24/7'],
                                                startTime: selectedStartTime,
                                                endTime: selectedEndTime,
                                                selectedMonth: new Date(),
                                                preZoneSpot: []
                                            },
                                            isMonthlyBooking: bookType === BookingType.Monthly,
                                            zones: [],
                                            spots: [],
                                            preZoneSpot: []
                                        }, () => {
                                            handleChange(this.state.bookingDetailInfo);
                                        });
                                    }}
                                    openOnFocus={true}
                                    selectOnFocus={true}
                                    getOptionLabel={(option) => option}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            {option}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => <TextField {...params}
                                        placeholder="Booking Type"
                                        variant="outlined"
                                        error={!bookingType}
                                    />}
                                    disabled={isModifiedBooking}
                                />
                            </div>
                            <div className="cl-block-sc">
                                <div className="bk-sp-lable">Property</div>
                                <Autocomplete
                                    size='small'
                                    id="cl-start-time"
                                    className="mt-1"
                                    options={this.props.properties}
                                    value={selectedProperty}
                                    onChange={(event: any, value: IPropertyBasics | null) => {
                                        this.setState({
                                            bookingDetailInfo: {
                                                ...bookingDetailInfo,
                                                selectedProperty: value,
                                                selectedSpot: null,
                                                selectedZone: null,
                                                preZoneSpot: []
                                            },
                                            zones: [],
                                            spots: [],
                                            preZoneSpot: []
                                        }, () => {
                                            this.loadPreZoneSpot();
                                            handleChange(this.state.bookingDetailInfo);
                                        });
                                    }}
                                    openOnFocus={true}
                                    selectOnFocus={true}
                                    getOptionLabel={(option) => option.propertyName}
                                    renderInput={(params) => <TextField {...params}
                                        placeholder="Select Property"
                                        variant="outlined"
                                        error={!selectedProperty}
                                    />}
                                    disabled={isModifiedBooking}
                                />
                            </div>
                            {!isMonthlyBooking &&
                                <>{!isModifiedBooking && <div className="cl-block-sc">
                                    <div className="bk-sp-lable">Date</div>
                                    <GrydDateRangePicker
                                        placeHolder="Date"
                                        initialDateRange={date}
                                        onDateChange={(dates_: any) => {
                                            this.setState({
                                                bookingDetailInfo: !isModifiedBooking ? {
                                                    ...bookingDetailInfo,
                                                    date: dates_,
                                                    selectedSpot: null,
                                                    selectedZone: null
                                                } : {
                                                    ...bookingDetailInfo,
                                                    date: dates_
                                                },
                                                zones: [],
                                                spots: []
                                            }, () => {
                                                !isModifiedBooking && this.loadPreZoneSpot();
                                                handleChange(this.state.bookingDetailInfo);
                                            });
                                        }}
                                        minDate={moment(new Date()).startOf('day').toDate()}
                                    />
                                </div>
                                }
                                    {
                                        isModifiedBooking && <Grid container>
                                            <Grid item xs>
                                                <div className="bk-sp-lable">Start Date</div>
                                                <GrydCalendarPicker
                                                    placeHolder="Start Date"
                                                    defaultDate={this.getStartDate()}

                                                    onDateChange={(date_: Date | null) => {
                                                        
                                                        if (date_ && date) {
                                                            date.startDate = date_;
                                                        }
                                                        this.setState({
                                                            bookingDetailInfo: {
                                                                ...bookingDetailInfo,
                                                                date
                                                            },
                                                            zones: [],
                                                            spots: []
                                                        }, () => {
                                                            !isModifiedBooking && this.loadPreZoneSpot();
                                                            handleChange(this.state.bookingDetailInfo);
                                                        });
                                                    }}
                                                    calendarPosition={EnumCalendarPosition.Top}
                                                    minDate={moment(new Date()).startOf('day').toDate()}
                                                    disabled={disableStartTime}
                                                />
                                            </Grid>
                                            <Grid item><div style={{ marginRight: 10 }}></div></Grid>
                                            <Grid item xs>
                                                <div className="bk-sp-lable">End Date</div>
                                                <GrydCalendarPicker
                                                    placeHolder="End Date"
                                                    defaultDate={this.getEndDate()}
                                                    onDateChange={(date_: Date | null) => {
                                                        if (date_ && date) {
                                                            date.endDate = date_;
                                                        }
                                                        this.setState({
                                                            bookingDetailInfo: {
                                                                ...bookingDetailInfo,
                                                                date
                                                            },
                                                            zones: [],
                                                            spots: []
                                                        }, () => {
                                                            !isModifiedBooking && this.loadPreZoneSpot();
                                                            handleChange(this.state.bookingDetailInfo);
                                                        });
                                                    }}
                                                    minDate={moment(new Date()).startOf('day').toDate()}
                                                    calendarPosition={EnumCalendarPosition.Top}
                                                    calendarClass={'cl-block-right'}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                </>
                            }
                            {isMonthlyBooking && <>
                                {!isModifiedBooking && <div className="cl-block-sc">
                                    <div className="bk-sp-lable">Monthly Booking Type</div>
                                    <Autocomplete
                                        size='small'
                                        id="cl-start-time"
                                        className="mt-1"
                                        options={[MonthlyBookingType[MonthlyBookingType['24/7']],
                                        MonthlyBookingType[MonthlyBookingType.Commuter],
                                        MonthlyBookingType[MonthlyBookingType['Evenings and Weekends']]]}
                                        value={MonthlyBookingType[monthlyBookingType]}
                                        disableClearable
                                        onChange={(event: any, value: any) => {
                                            const { selectedStartTime, selectedEndTime } = this.defaultTime();
                                            const monthlyBookingType: any = MonthlyBookingType[value];
                                            this.setState({
                                                bookingDetailInfo: {
                                                    ...bookingDetailInfo,
                                                    monthlyBookingType,
                                                    startTime: selectedStartTime,
                                                    endTime: selectedEndTime,
                                                    selectedSpot: null,
                                                    selectedZone: null
                                                },
                                                zones: [],
                                                spots: []
                                            }, () => {
                                                this.loadPreZoneSpot();
                                                handleChange(this.state.bookingDetailInfo);
                                            });
                                        }}
                                        openOnFocus={true}
                                        selectOnFocus={true}
                                        getOptionLabel={(option) => option}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                {option}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => <TextField {...params} placeholder="Booking Type" variant="outlined" error={!monthlyBookingType} />}
                                    />
                                </div>
                                }
                                <div className="cl-block-sc">
                                    <div className="bk-sp-lable">Month</div>
                                    <GrydCalendarPicker
                                        placeHolder="Month"
                                        defaultDate={selectedMonth} //format(new Date(), "MMM yyyy")
                                        onDateChange={(date: Date | null) => {
                                            const { selectedStartTime, selectedEndTime } = this.defaultTime();
                                            this.setState({
                                                bookingDetailInfo: !isModifiedBooking ? {
                                                    ...bookingDetailInfo,
                                                    selectedMonth: date,
                                                    startTime: selectedStartTime,
                                                    endTime: selectedEndTime,
                                                    selectedSpot: null,
                                                    selectedZone: null
                                                } : {
                                                    ...bookingDetailInfo,
                                                    selectedMonth: date,
                                                    selectedModifyMonth: date,
                                                    startTime: selectedStartTime,
                                                    endTime: selectedEndTime
                                                },
                                                zones: [],
                                                spots: []
                                            }, () => {
                                                !isModifiedBooking && this.loadPreZoneSpot();
                                                handleChange(this.state.bookingDetailInfo);
                                            });
                                        }}
                                        calendarPosition={EnumCalendarPosition.Top}
                                        minDate={moment(new Date()).startOf('day').toDate()}
                                        disabled={disableMonthly}
                                    />
                                </div>
                            </>
                            }
                            {
                                (!isMonthlyBooking
                                ) && <div className="cl-block-sc">
                                    <div className="bk-sp-lable">Time</div>
                                    <div className="cl-flex">
                                        <div className="cl-flex-cl cl-r-padding">
                                            <div className="cl-block-input-c">
                                                <AccessTimeIcon className="cl-icon" />
                                                <Autocomplete
                                                    id="cl-start-time"
                                                    className="cl-time-dropdown"
                                                    options={this.state.times}
                                                    value={startTime}
                                                    onChange={(event: any, value: IBlockTime | null) => {
                                                        this.setState({
                                                            bookingDetailInfo: !isModifiedBooking ? {
                                                                ...bookingDetailInfo,
                                                                startTime: value,
                                                                selectedSpot: null,
                                                                selectedZone: null
                                                            } : {
                                                                ...bookingDetailInfo,
                                                                startTime: value
                                                            },
                                                            zones: [],
                                                            spots: []
                                                        }, () => {
                                                            !isModifiedBooking && this.loadPreZoneSpot();
                                                            handleChange(this.state.bookingDetailInfo);
                                                        });
                                                    }}
                                                    openOnFocus={true}
                                                    selectOnFocus={true}
                                                    getOptionLabel={(option) => option.timeString}
                                                    renderOption={(option, { selected }) => (
                                                        <React.Fragment>
                                                            {option.timeString}
                                                        </React.Fragment>
                                                    )}
                                                    renderInput={(params) => <TextField {...params} placeholder="Start Time"
                                                        variant="outlined"
                                                        error={!startTime}
                                                    />}
                                                    disabled={disableStartTime}
                                                />
                                            </div>
                                        </div>
                                        <div className="cl-flex-pd">&mdash;</div>
                                        <div className="cl-flex-cl cl-r-padding">
                                            <div className="cl-block-input-c">
                                                <AccessTimeIcon className="cl-icon" />
                                                <Autocomplete
                                                    id="cl-end-time"
                                                    className="cl-time-dropdown"
                                                    options={this.getTimes(true)}
                                                    value={endTime}
                                                    onChange={(event: any, value: IBlockTime | null) => {
                                                        this.setState({
                                                            bookingDetailInfo: !isModifiedBooking ? {
                                                                ...bookingDetailInfo,
                                                                endTime: value,
                                                                selectedSpot: null,
                                                                selectedZone: null
                                                            } : {
                                                                ...bookingDetailInfo,
                                                                endTime: value
                                                            },
                                                            zones: [],
                                                            spots: []
                                                        }, () => {
                                                            !isModifiedBooking && this.loadPreZoneSpot();
                                                            handleChange(this.state.bookingDetailInfo);
                                                        });
                                                    }}
                                                    openOnFocus={true}
                                                    selectOnFocus={true}
                                                    getOptionLabel={(option) => option.timeString}
                                                    renderOption={(option, { selected }) => (
                                                        <React.Fragment>
                                                            {option.timeString}
                                                        </React.Fragment>
                                                    )}
                                                    renderInput={(params) => <TextField {...params} placeholder="End Time"
                                                        variant="outlined"
                                                        error={!endTime}
                                                    />}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {!isModifiedBooking && <><div className="cl-block-sc  mt-3">
                                <div className="booking-header">SPOT DETAILS</div>
                            </div>
                                <div className="cl-block-sc">
                                    <div className="bk-sp-lable">Select Zone</div>
                                    <Autocomplete
                                        size='small'
                                        id="cl-zone"
                                        className="mt-1"
                                        options={zones}
                                        value={selectedZone}
                                        onChange={(event: any, value: IPreZoneSpot | null) => {
                                            this.setState({
                                                bookingDetailInfo: {
                                                    ...bookingDetailInfo,
                                                    selectedZone: value,
                                                    selectedSpot: null
                                                },
                                                spots: ArrayHelper.sortArrayAlphaNumericObj(preZoneSpot.filter(x => x.zoneGuid === value?.zoneGuid), "friendlySpotId", "asc")
                                            }, () => {
                                                handleChange(this.state.bookingDetailInfo);
                                            });
                                        }}
                                        openOnFocus={true}
                                        selectOnFocus={true}
                                        getOptionLabel={(option) => option.zoneName}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                {option.zoneName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            placeholder="Select Zone"
                                            variant="outlined"
                                            error={!selectedZone} />}
                                    />
                                </div>
                                <div className="cl-block-sc">
                                    <div className="bk-sp-lable">Select Spot (if applicable)</div>
                                    <Autocomplete
                                        size='small'
                                        id="cl-spot"
                                        className="mt-1"
                                        value={selectedSpot}
                                        options={[...spots].sort((a, b) => -b.isBooked.toString().localeCompare(a.isBooked.toString()))}
                                        groupBy={(option) => option.isBooked ? "Booked spots" : ""}
                                        onChange={(event: any, value: IPreZoneSpot | null) => {
                                            this.setState({
                                                bookingDetailInfo: {
                                                    ...bookingDetailInfo,
                                                    selectedSpot: value
                                                }
                                            }, () => {
                                                handleChange(this.state.bookingDetailInfo);
                                            });
                                        }}
                                        openOnFocus={true}
                                        selectOnFocus={true}
                                        getOptionLabel={(option) => option.friendlySpotId}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <div className='row'>
                                                    <Checkbox
                                                        icon={<div className="repeat-b-check" />}
                                                        checkedIcon={<div className="repeat-b-check selected" />}
                                                        checked={selected}
                                                        color="primary"
                                                    />
                                                    <span className='cl-spot-option-text'>{option.friendlySpotId}</span>
                                                </div>
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            placeholder="Select Spot"
                                            variant="outlined" />}
                                    />
                                </div>
                            </>
                            }
                        </>
                        }
                    </div>
                </div>
                <Row className={`save-button-container ${isModifiedBooking ? "width-100" : "margin-r-5-l-45"}`}>
                    <Col xs={4}>
                        &nbsp;
                    </Col>
                    <Col xs={4} className='right-align p-r-0-px'>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                this.props.onClose();
                            }}
                        >Cancel</Button>
                    </Col>
                    <Col xs={4} className='right-align p-r-0-px'>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (!isModifiedBooking) {
                                    this.addBookings();
                                }
                                else {
                                    const { bookingDetailInfo } = this.state;
                                    let { vehicleDetail, bookingType,
                                        selectedProperty, selectedZone,
                                        startTime, endTime, date, monthlyBookingType, selectedMonth } = bookingDetailInfo;

                                    const { parkerEncodeGuid } = userDetail ?? {
                                        email: "", firstName: "", lastName: "", phoneNumber: "", parkerEncodeGuid: ""
                                    };

                                    if (vehicleDetail === null) {
                                        vehicleDetail = {
                                            vehicleMake: "", vehicleModel: "", vehicleColor: "", licensePlate: "", vehicleOwnerGuid: "", vehicleGuid: ""
                                        };
                                    }

                                    const { vehicleMake, vehicleModel, vehicleColor, licensePlate, vehicleGuid } = vehicleDetail;

                                    if ((!vehicleGuid && (!vehicleMake || !vehicleModel || !vehicleColor || !licensePlate)) ||
                                        !parkerEncodeGuid || !selectedProperty || !selectedZone ||
                                        (bookingType === BookingType['Hourly/Daily'] && (!date.startDate || !date.endDate ||
                                            !startTime || !endTime)) || (bookingType === BookingType.Monthly &&
                                                (!monthlyBookingType || !selectedMonth))) {
                                        ShowAlert("", "Please fill all mandatory fields", "error");
                                        return;
                                    }

                                    const dupDate: DateRange = { ...date }
                                    if (bookingType === BookingType['Hourly/Daily'] && startTime && endTime && dupDate && dupDate.startDate && dupDate.endDate) {
                                        const copyDate = { startDate: new Date(dupDate.startDate), endDate: new Date(dupDate.endDate) };
                                        const startDate = DateTimeHelper.ChangeTime(copyDate.startDate, startTime.timeString);
                                        const endDate = DateTimeHelper.ChangeTime(copyDate.endDate, endTime.timeString);
                                        const currentDate = DateTimeHelper.convertToTimezone(new Date(), selectedProperty?.propertyTimeZone);
                                        if ((!disableStartTime && startDate <= currentDate) || endDate <= currentDate) {
                                            ShowAlert("", disableStartTime ? 'end time should be greater than current time' : 'start time and end time should be greater than current time', "error");
                                            return;
                                        }
                                        if (endDate <= startDate) {
                                            ShowAlert("", 'End time should be greater than start time', "error");
                                            return;
                                        }
                                    }
                                    this.addVehicleAndMoveNext(this.state.bookingDetailInfo, vehicleGuid, isModifiedBooking);
                                }
                            }}
                        >Next</Button>
                    </Col>
                </Row>
            </>)
    }

    private addBookings = () => {
        const { bookingDetailInfo, spots, isNewUser } = this.state;
        const { showModelAlert } = this.props;
        let { userDetail, vehicleDetail, bookingType,
            selectedProperty, selectedZone, selectedSpot,
            startTime, endTime, date, monthlyBookingType, selectedMonth } = bookingDetailInfo;

        const { email, firstName, lastName, phoneNumber, parkerEncodeGuid } = userDetail ?? {
            email: "", firstName: "", lastName: "", phoneNumber: "", parkerEncodeGuid: ""
        };

        if (vehicleDetail === null) {
            vehicleDetail = {
                vehicleMake: "", vehicleModel: "", vehicleColor: "", licensePlate: "", vehicleOwnerGuid: "", vehicleGuid: ""
            };
        }

        const { vehicleMake, vehicleModel, vehicleColor, licensePlate, vehicleGuid } = vehicleDetail;
        if (!this.state.userDetail && !isNewUser) {
            if (!firstName || !lastName || !email || !phoneNumber) {
                ShowAlert("", "Please fill all mandatory fields.", "error");
                return;
            }
            if (!StringHelper.validateMobile(phoneNumber)) {
                ShowAlert("", "Please enter valid phone number.", "error");
                return;
            }
            if (!StringHelper.validateEmail(email)) {
                ShowAlert("", "Please enter valid email.", "error");
                return;
            }
            if (parkerEncodeGuid) {
                this.GetVehicle(parkerEncodeGuid);
            }
            else {
                this._userService.partialOnBoarding({
                    firstName,
                    lastName,
                    email,
                    mobileNumber: phoneNumber
                })
                    .then(res => {
                        if (res.ok) {
                            res.json().then(response => {
                                const publicUserId: string = response;
                                this.setState({
                                    bookingDetailInfo: {
                                        ...bookingDetailInfo,
                                        userDetail: {
                                            ...userDetail,
                                            parkerEncodeGuid: publicUserId
                                        }
                                    },
                                    isNewUser: true
                                }, () => {
                                    this.state.bookingDetailInfo?.userDetail?.parkerEncodeGuid && this.GetVehicle(this.state.bookingDetailInfo.userDetail.parkerEncodeGuid);
                                });
                            });
                        }
                        else {
                            res.json().then(response => {
                                if (response === "Email id or Phone number already exists!") {
                                    ShowAlert("", response, "error");
                                }
                                else {
                                    ShowAlert("", "There was an error processing your request.", "error");
                                }
                            });
                        }
                    })
            }
        }
        else {
            if ((!vehicleGuid && (!vehicleMake || !vehicleModel || !vehicleColor || !licensePlate)) ||
                !parkerEncodeGuid || !selectedProperty || !selectedZone ||
                (bookingType === BookingType['Hourly/Daily'] && (!date.startDate || !date.endDate ||
                    !startTime || !endTime)) || (bookingType === BookingType.Monthly &&
                        (!monthlyBookingType || !selectedMonth))) {
                ShowAlert("", "Please fill all mandatory fields", "error");
                return;
            }

            if ((spots.length === 0 || (spots.filter(x => !x.isBooked)).length === 0) && !selectedSpot) {
                ShowAlert("", "Spot not available", "error");
                return;
            }

            const dupDate: DateRange = { ...date }
            if (bookingType === BookingType['Hourly/Daily'] && startTime && endTime && dupDate && dupDate.startDate && dupDate.endDate) {
                const currentMomentTime = moment(DateTimeHelper.convertToTimezone(new Date(), selectedProperty?.propertyTimeZone));
                const minutes = currentMomentTime.format('mm');
                const rest = Number(minutes) % 15;
                const roundedTime = moment(currentMomentTime.subtract(rest, 'minutes')).format("hh:mm A");
                const perFrmRoundedTime = moment(roundedTime, "hh:mm A").subtract(15, 'minutes').format("hh:mm A");
                const currentDate = moment(DateTimeHelper.convertToTimezone(new Date(), selectedProperty?.propertyTimeZone)).startOf('day').toDate();
                const copyDate = { startDate: new Date(dupDate.startDate), endDate: new Date(dupDate.endDate) };
                const startDate = DateTimeHelper.ChangeTime(copyDate.startDate, startTime.timeString);
                const endDate = DateTimeHelper.ChangeTime(copyDate.endDate, endTime.timeString);
                const currentDatePerFrmRoundedTime = DateTimeHelper.ChangeTime(new Date(currentDate), perFrmRoundedTime);
                if(startDate < currentDatePerFrmRoundedTime){
                    ShowAlert("", 'start time and end time should be greater than current time', "error");
                     return;
                }
                if (endDate <= startDate) {
                    ShowAlert("", 'End time should be greater than start time', "error");
                    return;
                }
            }

            if (selectedSpot?.isBooked && bookingType !== BookingType.Monthly) {
                this.BookedSpot(vehicleGuid, bookingDetailInfo, () => {
                    this.addVehicleAndMoveNext(bookingDetailInfo, vehicleGuid);
                });
            }
            else if (bookingType === BookingType.Monthly) {
                const showAlertModel: IShowModelAlert = {
                    headerTitle: "Monthly Parking Terms",
                    messageContent: <>
                        <ul className='mt-3' style={{ listStyleType: 'disc', marginRight: 25 }}>
                            <li>
                                <div className="popup-content">
                                    Your subscription automatically renews on the
                                </div>
                                <div className="mt-1 popup-content">
                                    last day of the month.
                                </div>
                            </li>
                            <li>
                                <div className="mt-2 popup-content">
                                    You can cancel at anytime, your spot will remain
                                </div>
                                <div className="mt-1 popup-content">
                                    active until the end of the month.
                                </div>
                            </li>
                            <li>
                                <div className="mt-2 popup-content">
                                    You are automatically renewed for the same
                                </div>
                                <div className="mt-1 popup-content">
                                    spot each month. If anything changes we will let
                                </div>
                                <div className="mt-1 popup-content">
                                    you know.
                                </div>
                            </li>
                        </ul>
                    </>,
                    showHideAlert: true,
                    showModelAlertType: ShowModelAlertType.Error,
                    confirmation: true,
                    yesButtonText: "Agree",
                    noButtonText: "Cancel",
                    buttonDivClass: "m-40-r-40-l"
                }
                showModelAlert(showAlertModel, () => {
                    if (selectedSpot?.isBooked) {
                        this.BookedSpot(vehicleGuid, bookingDetailInfo, () => {
                            this.addVehicleAndMoveNext(bookingDetailInfo, vehicleGuid);
                        });
                    }
                    else {
                        this.addVehicleAndMoveNext(bookingDetailInfo, vehicleGuid);
                    }
                });
            }
            else {
                this.addVehicleAndMoveNext(bookingDetailInfo, vehicleGuid);
            }
        }
    }

    private addVehicleAndMoveNext = (bookingDetailInfo: IBookingDetailInfo, vehicleGuid: string, isModifiedBooking?: boolean) => {
        const { onNext } = this.props;
        if (!isModifiedBooking) {
            if (!vehicleGuid) {
                this.moveNext(bookingDetailInfo, () => {
                    this.addVehicle(this.state.bookingDetailInfo);
                });
            }
            else {
                this.moveNext(bookingDetailInfo, () => {
                    this.setState({
                        isNewUser: true
                    }, () => {
                        onNext(this.state.bookingDetailInfo, this.state.userDetail);
                    });
                });
            }
        }
        else {
            if (!vehicleGuid) {
                this.addVehicle(this.state.bookingDetailInfo);
            }
            else {
                onNext(this.state.bookingDetailInfo, this.state.userDetail);
            }
        }
    }

    private moveNext = (bookingDetailInfo: IBookingDetailInfo, moveToSummary: () => void) => {
        let serviceCall = null;
        if (bookingDetailInfo.bookingType === BookingType.Monthly) {
            const availabilityRequest = {
                propertyGuid: bookingDetailInfo.selectedProperty?.propertyGuid,
                filterAmenities: "",
                monthlyRateTypeId: bookingDetailInfo.monthlyBookingType,
                zoneGuid: bookingDetailInfo.selectedZone?.zoneGuid,
                spotGuid: bookingDetailInfo.selectedSpot?.spotGuid,
                bookingStartUTC: DateTimeHelper.LocalToUtcString(bookingDetailInfo.selectedMonth ?? new Date()),
                bookingEndUTC: DateTimeHelper.LocalToUtcString(bookingDetailInfo.selectedMonth ?? new Date()),
                isAllowOverlap: bookingDetailInfo.selectedSpot ? bookingDetailInfo.selectedSpot.isBooked : false
            }
            serviceCall = this._spotService.getBookingPropertyAvailability(availabilityRequest);
        }
        else {
            const { date, startTime, endTime } = { ...bookingDetailInfo };
            const startDate = DateTimeHelper.ChangeTime(date.startDate ?? new Date(), startTime?.timeString ?? "00:00");
            const endDate = DateTimeHelper.ChangeTime(date.endDate ?? new Date(), endTime?.timeString ?? "11:59");
            const availabilityRequest = {
                propertyGuid: bookingDetailInfo.selectedProperty?.propertyGuid,
                filterAmenities: "",
                monthlyRateTypeId: bookingDetailInfo.monthlyBookingType,
                zoneGuid: bookingDetailInfo.selectedZone?.zoneGuid,
                spotGuid: bookingDetailInfo.selectedSpot?.spotGuid,
                bookingStartUTC: DateTimeHelper.LocalToUtcString(startDate),
                bookingEndUTC: DateTimeHelper.LocalToUtcString(endDate),
                isAllowOverlap: bookingDetailInfo.selectedSpot ? bookingDetailInfo.selectedSpot.isBooked : false
            }
            serviceCall = this._spotService.getBookingZoneAvailability(availabilityRequest);
        }

        if (serviceCall !== null) {
            serviceCall.then(res => {
                if (res.ok) {
                    res.json().then(response => {
                        const bookingPriceDetail = response;
                        this.setState({
                            bookingDetailInfo: {
                                ...bookingDetailInfo,
                                totalPrice: bookingPriceDetail?.booking?.bestRate?.totalPrice.toFixed(2) ?? 0.00,
                                serviceFeePrice: bookingPriceDetail?.booking?.bestRate?.serviceFeePrice.toFixed(2) ?? 0.00,
                                totalPriceWithServiceFeeTax: bookingPriceDetail?.booking?.bestRate?.totalPriceWithServiceFeeTax.toFixed(2) ?? 0.00,
                                taxPrice: bookingPriceDetail?.booking?.bestRate?.taxPrice.toFixed(2) ?? 0.00,
                                fullPrice: bookingPriceDetail?.booking?.bestRate?.fullPrice.toFixed(2) ?? 0.00
                            }
                        }, () => {
                            moveToSummary();
                        });
                    });
                }
                else {
                    res.json().then(response => {
                        ShowAlert("", response, "error");
                    });
                }
            });
        }
    }

    private BookedSpot = (vehicleGuid: string, bookingDetailInfo: IBookingDetailInfo, callBack: () => void) => {
        this._spotService.getBookedSpotDetails({
            propertyGuid: bookingDetailInfo.selectedProperty?.propertyGuid,
            spotGuid: bookingDetailInfo.selectedSpot?.spotGuid
        }).then(bookedDetailResp => {
            if (bookedDetailResp.ok && bookedDetailResp.status !== 204) {
                bookedDetailResp.json().then(bookedDetailResponse => {
                    const bookedDetail = bookedDetailResponse;
                    const showAlertModel: IShowModelAlert = {
                        headerTitle: "Spot Booked",
                        messageContent: <div style={{ textAlign: 'center' }}>
                            <div className="mt-3 bulk-upload-sub-content">
                                The spot you have selected is currently booked
                            </div>
                            {bookedDetail.reservationTypeId !== BookingType.Monthly && <div className={`mt-1 bulk-upload-sub-content`}>
                                by <b>{(bookedDetail.parkerFirstName + " " + bookedDetail.parkerLastName).trim()}</b> until <b>
                                    {moment(bookedDetail.bookedUntil).format('DD-MMM-YYYY hh:mm A')}</b>. Are you sure
                            </div>
                            }
                            {bookedDetail.reservationTypeId === BookingType.Monthly && <><div className={`mt-1 bulk-upload-sub-content`}>
                                by <b>{(bookedDetail.parkerFirstName + " " + bookedDetail.parkerLastName).trim()}</b> until <b>
                                    {moment(bookedDetail.bookedUntil).format('DD-MMM-YYYY hh:mm A')}</b>
                            </div>
                                <div className={`mt-1 bulk-upload-sub-content`}>
                                    <b>{bookedDetail.reservationTypeId === BookingType.Monthly ? " (" + bookedDetail.monthlyRateType + ")" : ""}</b>. Are you sure
                                </div></>
                            }
                            <div className="mt-1 bulk-upload-sub-content">
                                you want to assign the parker to <b>{bookedDetail.friendlySpotName}</b>?
                            </div>
                        </div>,
                        showHideAlert: true,
                        showModelAlertType: ShowModelAlertType.Error,
                        confirmation: true,
                        yesButtonText: "Assign",
                        noButtonText: "Cancel"
                    }
                    this.props.showModelAlert(showAlertModel, callBack);
                });
            }
            else {
                if (bookedDetailResp.status === 204) { //Spot was hold by someone 15 min
                    ShowAlert("", "Spot was not available. Please try again later.", "error");
                }
                else {
                    bookedDetailResp.json().then(error => {
                        ShowAlert("", error, "error");
                    });
                }
            }
        });
    }

    private getTimes(isEndTime: boolean = false) {
        let hours: IBlockTime[] = [];

        let currentDayDate: Date = new Date(new Date().setHours(0, 0, 0, 0));
        let minMinutes = DateTimeHelper.timeStringToMinutes("12:00 AM");
        let maxMinutes = DateTimeHelper.timeStringToMinutes("11:59 PM");
        let minTime: Date = new Date(new Date().setHours(0, 0, 0, 0));
        let maxTime: Date = new Date(new Date().setHours(0, 0, 0, 0));

        minTime.setMinutes(minMinutes);
        maxTime.setMinutes(maxMinutes);

        let totalHours: number = 97;
        let startIndex: number = 0;

        for (let i = startIndex; i < totalHours; i++) {
            let currentDate = new Date(currentDayDate);
            currentDate.setMinutes((i / 4) * 60);
            let outputDate = new Date(currentDate);

            if (outputDate.getTime() >= maxTime.getTime()) {
                outputDate = maxTime;
            }

            let time: IBlockTime = {
                dateTime: outputDate,
                timeString: format(outputDate, "hh:mm aa")
            }

            if (outputDate.getTime() >= minTime.getTime() && outputDate.getTime() <= maxTime.getTime()) {
                hours.push(time);
            }
        }

        if (isEndTime) {
            hours.splice(0, 1);
        }
        else {
            hours.splice(hours.length - 1, 1);
        }

        return hours;
    }

    private getStartDate() {
        let startDate_ = this.state.bookingDetailInfo.date.startDate;

        if (!startDate_) {
            startDate_ = new Date();
        }

        return startDate_;
    }

    private getEndDate() {
        let endDate_ = this.state.bookingDetailInfo.date.endDate;

        if (!endDate_) {
            endDate_ = new Date();
        }

        return endDate_;
    }
}

interface IAddEditBookingProps {
    onClose: () => void,
    properties: IPropertyBasics[],
    selectedProperty: IPropertyBasics | null,
    userDetail: IUserSearchModel | null,
    backToSearch: () => void,
    handleChange: (bookingDetailInfo: IBookingDetailInfo) => void,
    isFromBookingSummary: boolean,
    bookingDetailInfo: IBookingDetailInfo,
    showModelAlert: (showModelAlert: IShowModelAlert, callback?: () => void) => void,
    onNext: (bookingDetailInfo: IBookingDetailInfo, userSearchModel: IUserSearchModel | null) => void,
    isModifiedBooking?: boolean,
    isNoHeader?: boolean,
    customClass?: string
}

interface IAddEditBookingState {
    showFilter: boolean,
    times: IBlockTime[],
    bookingDetailInfo: IBookingDetailInfo,
    zones: IPreZoneSpot[],
    spots: IPreZoneSpot[],
    preZoneSpot: IPreZoneSpot[]
    isMonthlyBooking: boolean,
    isAnotherVehicle: boolean,
    isNewUser: boolean,
    userDetail: IUserSearchModel | null,
    vehicleDetails: IVehicle[],
    selectedVehicleDetail: IVehicle | null
}