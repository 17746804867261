import { Button, Grid, IconButton, TextField, Tooltip } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import { CardElement } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { cloneDeep } from "lodash";
import moment from 'moment';
import React from "react";
import { ShowCustomBlocker } from "../Components/LoaderComponent";
import { DateTimeHelper } from "../Helpers/DateTimeHelper";
import americanSelectedImg from '../Images/american-selected.png';
import defaultCardImg from '../Images/default-card.png';
import masterSelectedImg from '../Images/master-selected.png';
import visaSelectedImg from '../Images/visa-selected.png';
import { BookingType, IBookingDetailInfo, MonthlyBookingType } from "../Pages/BookingsCalendar/interfaces";
import SpotService from "../Services/SpotService";
import BookingSuccess from "./BookingSuccess";
import "./BookingSummary.css";
import OverrideTF from "./OverrideTF";
import DrawPopup from "./Popup/DrawPopup";
import OverridePopup from "./Popup/OverridePopup";
import PromoCodePopup from "./Popup/PromoCodePopup";
import SelectCard from './SelectCard';
import { ShowAlert } from "./ShowAlert";
import ShowWarningMessage from "./ShowWarningMessage";
import TaxandWaivedoff from "./TaxandWaivedoff";
import { getMonthDifferenceFromCurrent } from './getMonthDifferenceFromCurrent';
import { IShowModelAlert, ShowModelAlertType } from "./interface";

export default class BookingSummary extends React.Component<IBookingSummaryProps, IBookingSummaryState> {
    private spotService: SpotService;
    CheckoutOptionBookingAmount = 0
    CheckIntialRun = 0

    constructor(props_: IBookingSummaryProps) {
        super(props_);

        this.state = {
            defaultSelectedCard: {
                image_url: "https://seeklogo.com/images/V/visa-logo-4E989827A8-seeklogo.com.png", card_name: "Visa", card_number: "0033"
            },
            showPromoCode: false,
            showOverrideTF: false,
            hourlyPrice: '',
            modifiedPrice: '',
            propertyGuid: this.props.propertyGuid,
            serviceFee: 0,
            tax: 0,
            price: 0,
            orderTotal: 0,
            intialPrice: 0,
            maxserviceFree: 0,
            bookingType: "",
            showNewCardScreen: false,
            showAddNewCard: false,
            marginOfShowCard: 10,
            showDropDown: false,
            cardArray: [],
            selectedCardDisplayName: '',
            selectedCardId: '',
            creditButtonText: 'Remove',
            couponButtonText: 'Remove',
            credit: 0,
            coupon: 0,
            isCreditBT: false,
            isCouponBT: false,
            showCredit: false,
            showCoupon: false,
            chooseCredit: true,
            chooseCoupon: true,
            bookingDetailInfo: this.props.bookingDetailInfo,
            manualSelectedCoupon: '',
            showOnPopUp: false,
            dropDownSelected: false,
            showBookingSuccess: false,
            orderBookingAmount: 0,
            waivedOff: 0,
            couponIds: [],
            monthlyOverridOptions: 0,
            creditIds: [],
            discountObj: {},
            overridePrice: 0,
            intialProrate: 0,
            isOverrided: false,
            friendlyReservationId: ""
        }
        this.spotService = new SpotService();
    }

    private getCheckoutOption = (modifiedPrice?: string) => {
        const bookingDetailInfo = this.props.bookingDetailInfo;
        const propertyGuid = bookingDetailInfo.selectedProperty?.propertyGuid;
        const startTime = moment(bookingDetailInfo.date.startDate).format('YYYY-MM-DD');
        const endTime = moment(bookingDetailInfo.date.endDate).format('YYYY-MM-DD');

        let bookingAmount = modifiedPrice ? Number(modifiedPrice) : Number(bookingDetailInfo.totalPrice);
        const Coupon = this.state.manualSelectedCoupon
        const parkerEncodeGuid = bookingDetailInfo.userDetail.parkerEncodeGuid
        console.log('bookingDetailInfo', bookingDetailInfo);
        const AccessToken = this.getAccessToken();
        let monthlyPrice = this.state.monthlyOverridOptions === 2 || this.state.monthlyOverridOptions === 0 ?
            this.state.intialProrate : Number(this.props.bookingDetailInfo.totalPrice);
        if (getMonthDifferenceFromCurrent(this.props.bookingDetailInfo.selectedMonth) > 0) {
            monthlyPrice = Number(this.props.bookingDetailInfo.totalPrice);
        }
        this.CheckoutOptionBookingAmount =  bookingDetailInfo.bookingType === BookingType['Monthly'] ?
        monthlyPrice : bookingAmount
        this.spotService.checkoutOption({
            "PropertyGuid": propertyGuid, "StartTime": startTime, "EndTime": endTime, "BookingAmount":
            this.CheckoutOptionBookingAmount, "Coupon": Coupon, "IsNoCoupon": false,
            "ParkerEncodeGuid": parkerEncodeGuid
        }, AccessToken)
            .then(r => r.json())
            .then(r => {
                let data = [];
                // if promo code fails show erro 1st time only
                if(r.hasErrorLoadingVouchers && this.CheckIntialRun == 0){
                    this.CheckIntialRun = 1
                    ShowAlert("GrydPark", `Sorry! We are unable to access your credits and coupons. But, you can still complete your purchase. If you wish to apply credits or coupons, please try again later.`, "warning");

                }
                this.setState({
                    isCreditBT: r.areCreditsAvailable,
                    isCouponBT: r.areCouponsAvailable
                }, () => {

                })
                if (this.state.chooseCredit && this.state.chooseCoupon) {
                    if (r.areCouponsAvailable && r.areCreditsAvailable) {
                        data = r['creditCoupon']
                        this.setState({
                            credit: data['credit'],
                            coupon: data['coupon'],
                            showCredit: data['credit'] > 0 ? true : false,
                            showCoupon: data['coupon'] > 0 ? true : false
                        })
                    } else if (r.areCouponsAvailable) {
                        data = r['coupon']
                        if (!r.areCreditsAvailable) {
                            this.setState({
                                chooseCredit: false,
                            });
                        }
                        this.setState({
                            coupon: data['coupon'],
                            showCoupon: true
                        });
                    }
                    else if (r.areCreditsAvailable) {
                        data = r['credit']
                        if (!r.areCouponsAvailable) {
                            this.setState({
                                chooseCoupon: false,
                            });
                        }
                        this.setState({
                            credit: data['credit'],
                            showCredit: true,
                        });
                    }
                    else {
                        data = r['noDiscount'];                        
                    }
                    this.setState({
                        serviceFee: data['serviceFee'],
                        tax: data['tax'],
                        orderTotal: data['orderTotal'],
                        orderBookingAmount: data['bookingAmount'],
                        price: data['bookingAmount'],
                        waivedOff: data['waivedOff'],
                        couponIds: data['couponIds'],
                        creditIds: data['creditIds'],
                        discountObj: data
                    });
                }
                else if (this.state.chooseCoupon && r.areCouponsAvailable) {
                    if (r.areCouponsAvailable) {
                        data = r['coupon'];
                        console.log('choose is,coupon', data);
                        this.setState({
                            coupon: data['coupon'],
                            credit: data['credit'],
                            showCoupon: true,
                            showCredit: r.areCreditsAvailable,
                            serviceFee: data['serviceFee'],
                            tax: data['tax'],
                            orderTotal: data['orderTotal'],
                            orderBookingAmount: data['bookingAmount'],
                            price: data['bookingAmount'],
                            waivedOff: data['waivedOff'],
                            couponIds: data['couponIds'],
                            creditIds: data['creditIds'],
                            discountObj: data
                        });
                    }
                }
                else if (this.state.chooseCredit && r.areCreditsAvailable) {
                    data = r['credit'];
                    this.setState({
                        coupon: data['coupon'],
                        credit: data['credit'],
                        showCoupon: r.areCouponsAvailable,
                        showCredit: true,
                        serviceFee: data['serviceFee'],
                        tax: data['tax'],
                        orderTotal: data['orderTotal'],
                        orderBookingAmount: data['bookingAmount'],
                        price: data['bookingAmount'],
                        waivedOff: data['waivedOff'],
                        couponIds: data['couponIds'],
                        creditIds: data['creditIds'],
                        discountObj: data
                    });
                }
                else if (!this.state.chooseCoupon && !this.state.chooseCredit) {
                    data = r['noDiscount'];
                    this.setState({
                        serviceFee: data['serviceFee'],
                        tax: data['tax'],
                        orderTotal: data['orderTotal'],
                        orderBookingAmount: data['bookingAmount'],
                        price: data['bookingAmount'],
                        coupon: data['coupon'],
                        credit: data['credit'],
                        waivedOff: data['waivedOff'],
                        couponIds: data['couponIds'],
                        creditIds: data['creditIds'],
                        discountObj: data
                    });
                }

                if (!modifiedPrice) {
                    if (bookingDetailInfo.bookingType === BookingType['Hourly/Daily']) {
                        this.setState({
                            intialPrice: data['bookingAmount']
                        });
                    } else {
                        this.setState({
                            intialPrice: Number(bookingDetailInfo.fullPrice)
                        });
                    }
                }
                if(this.CheckoutOptionBookingAmount  <=  1){
                    this.setState({
                        showCoupon:false,
                        showCredit:false
                    });
                }
            });
    }

    private getCardList() {
        const bookingDetailInfo = this.props.bookingDetailInfo;
        const AccessToken = this.getAccessToken()
        this.spotService.getCardList(bookingDetailInfo.userDetail.parkerEncodeGuid, AccessToken)
            .then(r => r.json())
            .then(r => {
                this.setState({
                    cardArray: r.cards
                });
            });
    }

    private getMaxServiceFee() {
        const AccessToken = this.getAccessToken();
        this.spotService.getMaxServerFee(AccessToken)
            .then(r => r.json())
            .then(r => {
                this.setState({
                    maxserviceFree: r
                })
            })
    }

    componentDidMount() {
        this.getMaxServiceFee()
        this.getCardList();
        this.setState({
            intialProrate: Number(this.props.bookingDetailInfo.totalPrice),
        }, () => {
            this.getCheckoutOption();
        });
    }

    showOverrideMonthlyOption() {
        const showAlertModel: IShowModelAlert = {
            headerTitle: `Please choose override options`,
            messageContent:
                <OverridePopup
                    showWaringMessage={false}
                    disabledOptions={[]}
                    startDate={this.props.bookingDetailInfo.selectedMonth}
                    handelCancel={() => {
                        this.props.handelAlert(false);
                        this.resetModificationPrice();
                        this.props.showModelAlert({
                            headerTitle: "",
                            messageContent: "",
                            showHideAlert: false,
                            showModelAlertType: ShowModelAlertType.Info
                        });
                    }}
                    handelSubmit={(option: number) => {
                        this.props.handelAlert(false);
                        if (!isNaN(option) && option !== 0) {
                            this.setState({
                                monthlyOverridOptions: option,
                                isOverrided: true
                            }, () => {
                                this.updatePrice(cloneDeep(this.props.bookingDetailInfo), () => {
                                    this.getCheckoutOption(this.state.modifiedPrice);
                                    this.props.showModelAlert({
                                        headerTitle: "",
                                        messageContent: "",
                                        showHideAlert: false,
                                        showModelAlertType: ShowModelAlertType.Info
                                    });
                                });
                            })



                        }

                    }
                    }
                ></OverridePopup>
            ,
            showHideAlert: true,
            showModelAlertType: ShowModelAlertType.None,
            buttonDivClass: "removeDevHeight"
        }
        this.props.showModelAlert(showAlertModel);
        return
    }
    resetModificationPrice() {
        if (this.state.showOverrideTF) {
            this.setState({
                showOverrideTF: false,
                modifiedPrice: "",
                monthlyOverridOptions: 0,
                isOverrided: false
            }, () => {
                if (this.props.bookingDetailInfo.bookingType === BookingType.Monthly) {
                    this.updatePrice(cloneDeep(this.props.bookingDetailInfo), this.getCheckoutOption);
                }
                else {
                    this.getCheckoutOption()
                }
            })
        } else {
            this.setState({
                showOverrideTF: true,
                modifiedPrice: "",
                isOverrided: false
            })
        }
    }
    render() {
        const bookingDetailInfo = this.props.bookingDetailInfo;
        return (
            <>
                {
                    this.state.showOnPopUp && (
                        <DrawPopup child={<PromoCodePopup
                            ParkerEncodeGuid={bookingDetailInfo.userDetail.parkerEncodeGuid}
                            handelCancel={() => {
                                this.props.handelAlert(false);
                                this.setState({ showOnPopUp: false })
                            }}
                            handelApply={(selectedCoupon: string) => {
                                this.setState({ manualSelectedCoupon: selectedCoupon }, () => {
                                    if (this.state.showOverrideTF) {
                                        this.getCheckoutOption(this.state.modifiedPrice);
                                    } else {
                                        this.getCheckoutOption();
                                    }
                                });

                                this.props.handelAlert(false)
                                this.setState({
                                    showOnPopUp: false,
                                    chooseCredit: true,
                                    chooseCoupon: true
                                });
                            }}
                        ></PromoCodePopup>}>
                        </DrawPopup>
                    )
                }
                {
                    this.state.showNewCardScreen ? (
                        <>
                            <Grid container className="right-panel-header p-2">
                                <Grid item xs>
                                    <Grid item xs={2} className="MuiGrid-grid-custom">
                                        <Tooltip title="Back">
                                            <Grid container className="mt-4-px">
                                                <Grid item>
                                                    <IconButton aria-label="Back" size="small" onClick={() => {
                                                        this.setState({
                                                            showNewCardScreen: false
                                                        })
                                                    }}>
                                                        <ArrowBackIos />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Tooltip>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Button
                                        onClick={() => {
                                            this.props.onClose(this.state.showBookingSuccess);
                                        }}
                                    >
                                        <CloseIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                            <div className="root_view" style={{}}>
                                <h1 className="big_title">Payment information</h1>
                                <div className="flex_row" style={{ 'marginTop': '30px' }}>
                                    <div style={{ 'width': '100%' }}>
                                        <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                                            <form id="payment-form">
                                                <TextField fullWidth
                                                    placeholder="Cardholder Name"
                                                    variant="outlined"
                                                >
                                                </TextField>
                                                <div style={{ marginTop: '20px' }}>
                                                    <CardElement id="card-element" />
                                                </div>
                                                {
                                                    this.props.bookingDetailInfo.bookingType === BookingType.Monthly && (
                                                        <div style={{ marginTop: '20px' }}>
                                                            <ShowWarningMessage message={'The new card that is being added will not be linked to the monthly subscription'}></ShowWarningMessage>
                                                        </div>
                                                    )
                                                }
                                            </form>
                                        </div>
                                        <div className="flex_colum" style={{ width: '85%', alignContent: 'center', position: 'absolute', bottom: 15 }}>
                                            {/* <div className="save-button-container"> */}
                                            <Button id="submit" className="title" style={{ 'backgroundColor': '#00AF85', alignSelf: 'center', width: '100%', 'color': 'white', }}
                                                onClick={() => {
                                                    this.bookTheSpot(true);
                                                }}
                                            >Complete Booking</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (

                        <>
                            {
                                this.state.showBookingSuccess ? (
                                    <>
                                        {!this.props.isNoHeader && <Grid container className="right-panel-header p-2">
                                            <Grid item xs>
                                                <Grid item xs={2} className="MuiGrid-grid-custom">
                                                    <Tooltip title="Back">
                                                        <Grid container className="mt-4-px">
                                                            <Grid item>
                                                                {!this.state.showBookingSuccess &&
                                                                    (<IconButton aria-label="Back" size="small" onClick={() => {
                                                                        this.props.backToNewBookings(this.state.bookingDetailInfo);
                                                                    }}>
                                                                        <ArrowBackIos />
                                                                    </IconButton>)
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>

                                            <Grid item>
                                                <Button
                                                    onClick={() => {
                                                        this.props.onClose(this.state.showBookingSuccess);
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        }
                                        <BookingSuccess
                                            bookingStartDate={bookingDetailInfo.bookingType === BookingType.Monthly ? bookingDetailInfo.selectedMonth : bookingDetailInfo.date.startDate}
                                            bookingEndDate={bookingDetailInfo.date.endDate}
                                            bookingType={bookingDetailInfo.bookingType}
                                            monthlyBookingType={bookingDetailInfo.monthlyBookingType}
                                            bookingId={this.state.friendlyReservationId}
                                        ></BookingSuccess>
                                    </>
                                ) : (
                                    <>
                                        {!this.props.isNoHeader && <Grid container className="right-panel-header p-2">
                                            {!this.state.showNewCardScreen && <Grid item xs>
                                                <Grid item xs={2} className="MuiGrid-grid-custom">
                                                    <Tooltip title="Back">
                                                        <Grid container className="mt-4-px">
                                                            <Grid item>
                                                                <IconButton aria-label="Back" size="small" onClick={() => {
                                                                    this.props.backToNewBookings(this.state.bookingDetailInfo);
                                                                }}>
                                                                    <ArrowBackIos />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            }
                                            <Grid item>
                                                <Button
                                                    onClick={() => {
                                                        this.props.onClose(this.state.showBookingSuccess);
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        }
                                        <div className="root_view" style={{ display: 'flex', 'flexDirection': 'column', 'overflowY': 'scroll', height: '80vh' }}>
                                            <h1 className="big_title">{this.props.headerText ? this.props.headerText : "Booking Summary"}</h1>
                                            <div className="flex_row" style={{ 'marginTop': '30px' }}>
                                                <div style={{ 'width': '70%' }}>
                                                    <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>

                                                        <label className="title_header">{bookingDetailInfo.bookingType === BookingType["Hourly/Daily"] ? 'Hourly/Daily Booking' : 'Monthly Booking'}</label>
                                                        {
                                                            bookingDetailInfo.bookingType === BookingType["Hourly/Daily"] && (
                                                                <label className="title">{`${moment(bookingDetailInfo.date.startDate).format('MMM DD, YYYY')} 
                                                                 ${moment(bookingDetailInfo.date.endDate).diff(moment(bookingDetailInfo.date.startDate).format('MMM DD, YYYY'), 'days') > 0 ?
                                                                        `- ${moment(bookingDetailInfo.date.endDate).format('MMM DD, YYYY')}` : ``} `}
                                                                    <br /> {bookingDetailInfo.bookingType === BookingType["Hourly/Daily"] ?
                                                                        `${moment(bookingDetailInfo.date.startDate).format('hh:mm a')} - ${moment(bookingDetailInfo.date.endDate).format('hh:mm a')}` :
                                                                        `${MonthlyBookingType[bookingDetailInfo.monthlyBookingType]}`}</label>

                                                            )
                                                        }
                                                        {
                                                            bookingDetailInfo.bookingType === BookingType['Monthly'] && (
                                                                <label className="title">{`${moment(bookingDetailInfo.selectedMonth).format('MMM DD, YYYY')}`} <br />
                                                                    {`${MonthlyBookingType[bookingDetailInfo.monthlyBookingType]}`}</label>
                                                            )
                                                        }                                                        
                                                    </div>
                                                            <label className="green_underline" style={{}} onMouseDown={() => {
                                                                if (this.state.showOverrideTF) {
                                                                    this.setState({
                                                                        showOverrideTF: false,
                                                                        modifiedPrice: String(this.state.intialPrice.toFixed(2)),
                                                                        monthlyOverridOptions: 0,
                                                                        isOverrided: false
                                                                    }, () => {
                                                                        if (this.props.bookingDetailInfo.bookingType === BookingType.Monthly) {
                                                                            this.updatePrice(cloneDeep(this.props.bookingDetailInfo), this.getCheckoutOption);
                                                                        }
                                                                        else {
                                                                            this.getCheckoutOption();
                                                                        }
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        showOverrideTF: true,
                                                                        modifiedPrice: this.props.bookingDetailInfo.bookingType === BookingType.Monthly ?
                                                                            String(this.props.bookingDetailInfo.fullPrice) : String(this.state.price.toFixed(2)),
                                                                        isOverrided: false
                                                                    });
                                                                }
                                                            }}>{this.state.showOverrideTF ? `Reset Price` : `Override Price`} </label>
                                                </div>
                                                <div style={{ 'width': '35%' }}>
                                                    {
                                                        this.state.showOverrideTF && !this.state.isOverrided && (
                                                            <OverrideTF
                                                                modifiedPrice={this.state.modifiedPrice}
                                                                handelOnFocus={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {}}
                                                                handelOnChange={(e_: React.ChangeEvent<HTMLInputElement>) => {
                                                                    let modifiedPrice = this.state.modifiedPrice;
                                                                    if (Number(e_.target.value) <= this.state.maxserviceFree) {
                                                                        modifiedPrice = e_.target.value;
                                                                    }
                                                                    this.setState({
                                                                        modifiedPrice
                                                                    });
                                                                }}
                                                                handelOnBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                                                    this.setState({
                                                                        modifiedPrice: Number(this.state.modifiedPrice).toFixed(2)
                                                                    }, () => {
                                                                        if (this.state.modifiedPrice == '') {
                                                                            this.setState({
                                                                                showOverrideTF: false,
                                                                                modifiedPrice: String(this.state.price)
                                                                            });
                                                                        } else if (this.state.intialPrice.toFixed(2) == (Number(this.state.modifiedPrice).toFixed(2))) {
                                                                            this.setState({
                                                                                showOverrideTF: false,
                                                                                modifiedPrice: '',
                                                                            }, () => {
                                                                                console.log("modifiedPrice", this.state.modifiedPrice)
                                                                                this.setState({
                                                                                    chooseCredit: true,
                                                                                    chooseCoupon: true
                                                                                }, () => {
                                                                                    if (this.props.bookingDetailInfo.bookingType === BookingType.Monthly) {
                                                                                        this.updatePrice(cloneDeep(this.props.bookingDetailInfo), this.getCheckoutOption);
                                                                                    }
                                                                                    else {
                                                                                        this.getCheckoutOption();
                                                                                    }
                                                                                });
                                                                            });
                                                                        }
                                                                        else {

                                                                            if (this.state.intialPrice.toFixed(2) != (Number(this.state.modifiedPrice).toFixed(2))) {
                                                                                const showAlertModel: IShowModelAlert = {
                                                                                    headerTitle: "Override Price?",
                                                                                    messageContent: <div style={{ textAlign: 'center' }}>
                                                                                        <div className="mt-3 bulk-upload-sub-content">
                                                                                            Are you sure you want to override the booking <br /> price from <b> ${this.state.intialPrice.toFixed(2)} to ${Number(this.state.modifiedPrice).toFixed(2)}</b>?
                                                                                        </div>
                                                                                    </div>,
                                                                                    showHideAlert: true,
                                                                                    showModelAlertType: ShowModelAlertType.Error,
                                                                                    confirmation: true,
                                                                                    yesButtonText: "Yes",
                                                                                    noButtonText: "No",
                                                                                    yesCallBack: () => {
                                                                                        if (bookingDetailInfo.bookingType === BookingType['Monthly']) {
                                                                                            this.showOverrideMonthlyOption();
                                                                                        } else {
                                                                                            this.setState({
                                                                                                isOverrided: true
                                                                                            })
                                                                                            this.getCheckoutOption(this.state.modifiedPrice);
                                                                                            this.props.showModelAlert({
                                                                                                headerTitle: "",
                                                                                                messageContent: "",
                                                                                                showHideAlert: false,
                                                                                                showModelAlertType: ShowModelAlertType.Info
                                                                                            });
                                                                                        }
                                                                                    },
                                                                                    noCallBack: () => {
                                                                                        this.resetModificationPrice();
                                                                                        this.props.showModelAlert({
                                                                                            headerTitle: "",
                                                                                            messageContent: "",
                                                                                            showHideAlert: false,
                                                                                            showModelAlertType: ShowModelAlertType.Info
                                                                                        });
                                                                                    }
                                                                                }
                                                                                this.props.showModelAlert(showAlertModel);
                                                                                return;
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            ></OverrideTF>
                                                        )
                                                    }
                                                    {
                                                        !this.state.showOverrideTF && !this.state.isOverrided &&
                                                        <label className="title_header" style={{ textAlign: 'right', 'width': '100%' }}>{`$${this.state.intialPrice.toFixed(2)}`}</label>
                                                    }
                                                    {
                                                        this.state.isOverrided && this.state.showOverrideTF &&
                                                        <label className="title_header" style={{ textAlign: 'right', 'width': '100%' }}>{`$${Number(this.state.modifiedPrice).toFixed(2)}`}</label>
                                                    }
                                                </div>
                                            </div>
                                            <div className="underline">&nbsp;</div>
                                            {getMonthDifferenceFromCurrent(bookingDetailInfo.selectedMonth) === 0 && this.state.monthlyOverridOptions === 2 && <div style={{ maxWidth: '390px', marginBottom: '5px' }}>
                                                        <ShowWarningMessage message={`The parker isn't charged the difference right away, in the next monthly renewal the parker will be charged $${this.state.modifiedPrice}`}></ShowWarningMessage>
                                                    </div>}
                                        <div>
                                            {
                                                bookingDetailInfo.bookingType === BookingType['Monthly'] && (
                                                    <div className="flex_row"  >
                                                        <label className="title" style={{ width: '60%' }}>Prorated for first month</label>
                                                        <label className="title" style={{ width: '40%', textAlign: 'right' }}>{`$ ${(this.state.monthlyOverridOptions === 2
                                                                || this.state.monthlyOverridOptions === 0)
                                                                && getMonthDifferenceFromCurrent(this.props.bookingDetailInfo.selectedMonth) === 0 ?
                                                                Number(this.state.intialProrate).toFixed(2) : Number(this.props.bookingDetailInfo.totalPrice).toFixed(2)}`}</label>
                                                    </div>
                                                )
                                            }
                                            {
                                                this.state.showCredit && (
                                                    <div className="flex_row"  >
                                                        <label className="title" style={{}}>Credit</label>
                                                        <label className="green_underline" style={{ 'color': '#00AF85', width: '60%', marginLeft: '5px', 'cursor': 'pointer', fontSize: '12px', marginTop: '3px' }} onClick={() => {
                                                            this.setState({
                                                                creditButtonText: this.state.creditButtonText == "Remove" ? 'Reapply' : 'Remove'
                                                            }, () => {
                                                                console.log("credit button is clicked");
                                                                if (this.state.creditButtonText == 'Reapply') {

                                                                        this.setState({
                                                                            chooseCredit: false
                                                                        }, () => {
                                                                            if (this.state.showOverrideTF) {
                                                                                this.getCheckoutOption(this.state.modifiedPrice);
                                                                            } else {
                                                                                this.getCheckoutOption();
                                                                            }
                                                                        });
                                                                    } else {
                                                                        console.log("credit button is clicked 123");
                                                                        this.setState({
                                                                            chooseCredit: true
                                                                        }, () => {
                                                                            if (this.state.showOverrideTF) {
                                                                                this.getCheckoutOption(this.state.modifiedPrice);
                                                                            } else {
                                                                                this.getCheckoutOption();
                                                                            }
                                                                        });
                                                                    }
                                                                });
                                                            }}> {this.state.creditButtonText}</label>
                                                            <label className="title" style={{ width: '40%', textAlign: 'right' }}>{` ${this.state.credit > 0 ? `-` : ``} $${this.state.credit.toFixed(2)}`}</label>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    this.state.showCoupon && (
                                                        <div className="flex_row">
                                                            <label className="title" style={{}}>Coupon</label>
                                                            <label className="green_underline" style={{ 'color': '#00AF85', width: '60%', marginLeft: '5px', 'cursor': 'pointer', fontSize: '12px', marginTop: '3px' }} onClick={() => {
                                                                this.setState({
                                                                    couponButtonText: this.state.couponButtonText == "Remove" ? 'Reapply' : 'Remove'
                                                                }, () => {
                                                                    if (this.state.couponButtonText == 'Reapply') {
                                                                        this.setState({
                                                                            chooseCoupon: false
                                                                        }, () => {
                                                                            if (this.state.showOverrideTF) {
                                                                                this.getCheckoutOption(this.state.modifiedPrice);
                                                                            } else {
                                                                                this.getCheckoutOption();
                                                                            }
                                                                        });
                                                                    } else {
                                                                        this.setState({
                                                                            chooseCoupon: true
                                                                        }, () => {
                                                                            if (this.state.showOverrideTF) {
                                                                                this.getCheckoutOption(this.state.modifiedPrice);
                                                                            } else {
                                                                                this.getCheckoutOption();
                                                                            }
                                                                        });
                                                                    }
                                                                });
                                                            }}> {this.state.couponButtonText}</label>
                                                            <label className="title" style={{ width: '40%', textAlign: 'right' }}>{`${this.state.coupon > 0 ? "-" : ""} $${this.state.coupon.toFixed(2)}`}</label>
                                                        </div>
                                                    )
                                                }
                                                <TaxandWaivedoff
                                                    serviceFee={this.state.serviceFee}
                                                    tax={this.state.tax}
                                                    waivedOff={this.state.waivedOff}
                                                ></TaxandWaivedoff>

                                                <label className="title" style={{ 'color': '#00AF85', 'cursor': 'pointer' }} onClick={() => {
                                                    this.setState({ showOnPopUp: true })
                                                    this.props.handelAlert(true)
                                                }}>{`+Add a Promo Code`}</label>
                                            </div>
                                            <div className="underline">&nbsp;</div>
                                            <div>
                                                <div className="flex_row"  >
                                                    <label className="title_header" style={{ width: '80%' }}>Order Total</label>
                                                    <label className="title_header" style={{ width: '20%', textAlign: 'right' }}>{`$${this.state.orderTotal.toFixed(2)}`}</label>
                                                </div>
                                            </div>
                                            <div className="underline">&nbsp;</div>
                                            <SelectCard
                                                disable={Number(this.state.orderTotal.toFixed(2)) <= 0 ? true : false}
                                                bookingDetailInfo={bookingDetailInfo}
                                                onCardSelection={
                                                    (cardId) => {
                                                        this.setState({
                                                            selectedCardId: cardId
                                                        }, () => {
                                                            console.log("selectedCardId", this.state.selectedCardId);
                                                        });
                                                    }
                                                }
                                                onOpenNewCardScreen={
                                                    () => {
                                                        this.setState({
                                                            showNewCardScreen: true
                                                        });
                                                    }
                                                }
                                            ></SelectCard>
                                            {
                                                this.state.showAddNewCard && (
                                                    <Button className='title' style={{ 'color': '#00AF85', 'backgroundColor': "#F5F5F5", width: '100%', 'marginTop': `${this.state.marginOfShowCard}px`, justifyContent: "flex-start" }} onClick={
                                                        () => {
                                                            this.setState({
                                                                showNewCardScreen: true
                                                            });
                                                        }
                                                    } > + New Payment Method</Button>
                                                )
                                            }
                                        </div>
                                        <div className="flex_colum mt-4" style={{ alignContent: 'center', width: '100%' }}>
                                            <Button className="title" style={{ 'backgroundColor': this.state.selectedCardId === '' && this.state.orderTotal > 0 ? '#F0F0F0' : '#00AF85', alignSelf: 'center', width: '100%', 'color': this.state.selectedCardId === '' && this.state.orderTotal > 0 ? '#323232' : 'white', }}
                                                disabled={this.state.selectedCardId === '' && this.state.orderTotal > 0 ? true : false}
                                                onClick={async () => {
                                                    this.bookTheSpot();
                                                }}

                                            >Complete Booking</Button>
                                        </div>
                                    </>
                                )
                            }

                        </>
                    )
                }

            </>
        )
    }
    
    private async checkPaymentStatusRepeat(accesstoken: string, paymentIntent: string, blockGuid: string) {
        const paymentIntentId = paymentIntent;
        for (let i = 0; i < 30; i++) {
            const no_payment_status: Response = await this.spotService.check_payment_no_final(accesstoken, paymentIntentId);
            if (no_payment_status.ok) {
                let check_payment_no_final: any = await no_payment_status.json();
                if (check_payment_no_final.reservationGuid) {
                    const payment_status: any = await this.spotService.check_payment_with_final(accesstoken, paymentIntentId);
                    let ConfirmPayment: any = await payment_status.json();
                    if (ConfirmPayment.friendlyReservationId && ConfirmPayment.friendlySpotId) {
                        setTimeout(() => {
                            this.setState({
                                showNewCardScreen: false,
                                showBookingSuccess: true,
                                friendlyReservationId: ConfirmPayment.friendlyReservationId
                            }, () => {
                                this.props.resetHasChange();
                                ShowCustomBlocker(false);
                            });
                        }, 1000);
                    }
                    return;
                }
            } else {
                if (i == 29 && !no_payment_status.ok) {
                    const payment_status: any = await this.spotService.check_payment_with_final(accesstoken, paymentIntentId);
                    if (payment_status.ok) {
                        let ConfirmPayment: any = await payment_status.json();
                        if (ConfirmPayment.friendlyReservationId && ConfirmPayment.friendlySpotId) {
                            setTimeout(() => {
                                this.setState({
                                    showNewCardScreen: false,
                                    showBookingSuccess: true,
                                    friendlyReservationId: ConfirmPayment.friendlyReservationId
                                }, () => {
                                    ShowCustomBlocker(false);
                                    this.props.resetHasChange();
                                    ShowCustomBlocker(false);
                                })
                            }, 1000);
                        }
                    } else {
                        ShowCustomBlocker(false);
                        this.unHoldSpot(blockGuid);
                        if (no_payment_status.status == 404) {
                            ShowAlert("GrydPark", "Your payment isn't confirmed yet. Please check again later", "warning");
                        } else {
                            ShowAlert("GrydPark", "Sorry, we are facing issue in booking your spot. Please try again later", "warning");
                        }
                    }
                }
            }
            await new Promise<void>((resolve, reject) => {
                setTimeout(() => {
                    resolve()
                }, 1000);
            })

        }

    }
    
    private getAccessToken() {
        const storeUserData: any = localStorage.getItem('userdata') ? localStorage.getItem('userdata') : null;
        const userDatas: any = JSON.parse(storeUserData);
        const AccessToken = userDatas['accessToken'];
        if (AccessToken) {
            return AccessToken;
        } else {
            return "";
        }
    }

    private async unHoldSpot(blockGuid: string) {
        if (blockGuid) {
            await this.spotService.unholdSpot(this.getAccessToken(), blockGuid);
        }
    }

    private async bookTheSpot(newCardBooking ?: boolean) {
    const bookingDetailInfo = this.props.bookingDetailInfo;
    if (bookingDetailInfo.bookingType === BookingType['Monthly'] && this.state.monthlyOverridOptions === 0 && 
    this.state.modifiedPrice !== '' && this.state.showOverrideTF) {
        this.showOverrideMonthlyOption();
    } else {
        ShowCustomBlocker(true);
        const AccessToken = this.getAccessToken();
        let blockGuid: string = "";
        const StartDateTime = DateTimeHelper.ChangeTime(bookingDetailInfo.date.startDate ?? new Date(), bookingDetailInfo.startTime?.timeString ?? "");
        const EndDateTime = DateTimeHelper.ChangeTime(bookingDetailInfo.date.endDate ?? new Date(), bookingDetailInfo.endTime?.timeString ?? "")
        let holdSpotPostData: any;
        let discountData: any = this.state.discountObj;
        let isPassDiscount = false;if (this.state.credit >= 1 || this.state.coupon >= 1){
            isPassDiscount = true;
        }
        if (bookingDetailInfo.bookingType === BookingType["Hourly/Daily"]) {
            holdSpotPostData = {
                PropertyGuid: bookingDetailInfo.selectedProperty?.propertyGuid,
                BookingStartUTC: moment(StartDateTime).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                BookingEndUTC: moment(EndDateTime).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                BookingTotalPrice: this.state.modifiedPrice ? Number(this.state.modifiedPrice) : this.state.orderBookingAmount,
                FilterAmenities: "",
                VehicleGuid: bookingDetailInfo.vehicleDetail?.vehicleGuid,
                Discount: isPassDiscount ? discountData : null,
                ZoneGuid: bookingDetailInfo.selectedZone?.zoneGuid,
                ParkerEncodeGuid: bookingDetailInfo.userDetail.parkerEncodeGuid,
                isAllowOverlap: bookingDetailInfo.selectedSpot ? bookingDetailInfo.selectedSpot.isBooked : false,
                existingBookingTotalPrice: this.state.intialPrice
            }
            if (this.state.modifiedPrice) {
                holdSpotPostData["newBookingTotalPrice"] = Number(this.state.modifiedPrice);
            }
        } else {
            // If price is overridden for future months and the selectedMonth is current month, then initial prorate should be charged, otherwise use totalprice
            let monthlyPrice =
                    ((this.state.monthlyOverridOptions === 2 && getMonthDifferenceFromCurrent(bookingDetailInfo.selectedMonth) === 0)
                    || this.state.monthlyOverridOptions === 0)
                    ?
                    this.state.intialProrate
                    : Number(this.props.bookingDetailInfo.totalPrice);
            holdSpotPostData = {
                PropertyGuid: bookingDetailInfo.selectedProperty?.propertyGuid,
                BookingStartUTC: moment(bookingDetailInfo.selectedMonth).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                BookingTotalPrice: monthlyPrice,
                FilterAmenities: "",
                VehicleGuid: bookingDetailInfo.vehicleDetail?.vehicleGuid,
                Discount: isPassDiscount ? discountData : null,
                MonthlyRateTypeId: bookingDetailInfo.monthlyBookingType,
                ZoneGuid: bookingDetailInfo.selectedZone?.zoneGuid,
                ParkerEncodeGuid: bookingDetailInfo.userDetail.parkerEncodeGuid,
                isAllowOverlap: bookingDetailInfo.selectedSpot ? bookingDetailInfo.selectedSpot.isBooked : false,
                ModifiedBookingRate: this.state.modifiedPrice !== "" ? Number(this.state.modifiedPrice) : this.state.intialPrice,
                existingMonthlyPrice: this.state.intialPrice,
                monthlyOverridePrice: this.state.modifiedPrice !== "" ? Number(this.state.modifiedPrice) : this.state.intialPrice
            }
        }
        holdSpotPostData["SpotGuid"] = bookingDetailInfo.selectedSpot?.spotGuid;
        if (this.state.modifiedPrice && this.state.showOverrideTF) {
            holdSpotPostData["IsOverridePrice"] = true;
        }
        if (this.state.monthlyOverridOptions !== 0 && bookingDetailInfo.bookingType === BookingType['Monthly'] && this.state.showOverrideTF && this.state.modifiedPrice != '') {
            holdSpotPostData["PriceChangeType"] = this.state.monthlyOverridOptions;
        }
        const holdSpot = await this.spotService.holdSpot(AccessToken, holdSpotPostData, bookingDetailInfo.bookingType === BookingType['Monthly'] ? 'Monthly' : '')
        if (holdSpot.status == 200) {
            const holdSpotResponse: any = await holdSpot.json();
            if (holdSpotResponse.hasAvailableSpots) {
                blockGuid = holdSpotResponse.blockGuid;
                let initiatePayment: any;
                if (newCardBooking && this.state.orderTotal > 0) {
                    const { stripe, elements } = this.props;
                    if (elements == null) {
                        ShowCustomBlocker(false);
                        return;
                    }
                    const payment = await stripe?.createPaymentMethod({
                        type: 'card',
                        card: elements.getElement(CardElement),
                    });
                    if (payment?.error) {
                        ShowCustomBlocker(false);
                        this.unHoldSpot(blockGuid);
                        ShowAlert('GrydPark', payment?.error.message ?? '', 'warning');
                        return;
                    } else if (payment?.paymentMethod) {
                        initiatePayment = await this.spotService.guestInitiate_payment(AccessToken, blockGuid, payment?.paymentMethod?.id, bookingDetailInfo.userDetail.parkerEncodeGuid);
                    }
                    } else {
                        initiatePayment = await this.spotService.initiate_payment(AccessToken, blockGuid, this.state.selectedCardId, bookingDetailInfo.userDetail.parkerEncodeGuid);
                    }
                    if (initiatePayment.status == 200) {
                        const clientSecret: string = await initiatePayment.json();
                        if (this.state.orderTotal == 0) {
                            const checkPaymentStatusFinal: any = await this.spotService.check_payment_with_final(AccessToken, clientSecret);
                            const ConfirmPayment: any = await checkPaymentStatusFinal.json();
                            if (ConfirmPayment.friendlyReservationId && ConfirmPayment.friendlySpotId) {
                                setTimeout(() => {
                                    this.setState({
                                        showNewCardScreen: false,
                                        showBookingSuccess: true,
                                        friendlyReservationId: ConfirmPayment.friendlyReservationId
                                    }, () => {
                                        this.props.resetHasChange();
                                        ShowCustomBlocker(false);
                                    });
                                }, 500);
                            }
                        } else {
                            const { stripe } = this.props;
                            const payload = await stripe?.confirmCardPayment(clientSecret);
                            console.log("payload", payload?.paymentIntent?.id);
                            if (payload?.error) {
                                ShowCustomBlocker(false);
                                this.unHoldSpot(blockGuid);
                                ShowAlert('GrydPark', payload.error.message ?? '', 'warning');
                            } else {
                                let paymentIntent = payload?.paymentIntent?.id;
                                if (paymentIntent) {
                                    this.checkPaymentStatusRepeat(AccessToken, paymentIntent, blockGuid);
                                }
                            }
                        }
                    } else {
                        ShowCustomBlocker(false);
                        this.unHoldSpot(blockGuid);
                        if (initiatePayment.status == 400) {
                            const initiatePaymentRes: string = await initiatePayment.json();
                            if (typeof (initiatePaymentRes) === "string") {
                                ShowAlert("GrydPark", initiatePaymentRes == 'PAYMENT_ALREADY_INITIATED' ? "Your payment has already been initiated." : initiatePaymentRes, "warning");
                            }
                        }
                    }
                } else {
                    ShowCustomBlocker(false);
                    if (holdSpotResponse.booking && holdSpotResponse.booking.bestRate?.totalPrice !== 0) {
                        this.unHoldSpot(blockGuid);
                        ShowAlert("GrydPark", "Spot price are updated. Please verify", "warning");
                    } else {
                        ShowAlert("GrydPark", "All spots are booked. Please check another one.", "warning");
                    }
                    // fallback of holdspots if no availible spote
                }
            } else {
                ShowCustomBlocker(false);
                // fallback of holdspots non 200
                ShowAlert("GrydPark", "Oops! We’re having trouble connecting with the server, could you try again later?", "warning");
            }
        }
    }

    private updatePrice = (bookingDetailInfo: IBookingDetailInfo, callBack: () => void) => {
        let serviceCall = null;
        if (bookingDetailInfo.bookingType === BookingType.Monthly) {
            let availabilityRequest: any = {
                propertyGuid: bookingDetailInfo.selectedProperty?.propertyGuid,
                filterAmenities: "",
                monthlyRateTypeId: bookingDetailInfo.monthlyBookingType,
                zoneGuid: bookingDetailInfo.selectedZone?.zoneGuid,
                spotGuid: bookingDetailInfo.selectedSpot?.spotGuid,
                bookingStartUTC: DateTimeHelper.LocalToUtcString(bookingDetailInfo.selectedMonth ?? new Date()),
                bookingEndUTC: DateTimeHelper.LocalToUtcString(bookingDetailInfo.selectedMonth ?? new Date()),
                isAllowOverlap: bookingDetailInfo.selectedSpot ? bookingDetailInfo.selectedSpot.isBooked : false
            }
            if (this.state.showOverrideTF) {
                availabilityRequest["MonthlyOverridePrice"] = Number(this.state.modifiedPrice)
            }
            serviceCall = this.spotService.getBookingPropertyAvailability(availabilityRequest);
        }

        if (serviceCall !== null) {
            serviceCall.then(res => {
                if (res.ok) {
                    res.json().then(response => {
                        const bookingPriceDetail = response;
                        this.setState({
                            bookingDetailInfo: {
                                ...bookingDetailInfo,
                                totalPrice: bookingPriceDetail?.booking?.bestRate?.totalPrice.toFixed(2) ?? 0.00,
                                serviceFeePrice: bookingPriceDetail?.booking?.bestRate?.serviceFeePrice.toFixed(2) ?? 0.00,
                                totalPriceWithServiceFeeTax: bookingPriceDetail?.booking?.bestRate?.totalPriceWithServiceFeeTax.toFixed(2) ?? 0.00,
                                taxPrice: bookingPriceDetail?.booking?.bestRate?.taxPrice.toFixed(2) ?? 0.00,
                                fullPrice: bookingPriceDetail?.booking?.bestRate?.fullPrice.toFixed(2) ?? 0.00
                            }
                        }, () => {
                            this.props.updateBookingDetailInfo(this.state.bookingDetailInfo, callBack);
                        });
                    });
                }
                else {
                    res.json().then(response => {
                        ShowAlert("", response, "error");
                    });
                }
            });
        }
    }
}


interface IBookingSummaryProps {
    handelAlert: (b_: boolean) => void;
    propertyGuid: string;
    stripe: Stripe | null,
    elements: any,
    onClose: (isSuccess?: boolean) => void,
    backToNewBookings: (bookingDetailInfo: IBookingDetailInfo) => void,
    bookingDetailInfo: IBookingDetailInfo,
    appliedCoupon: string,
    isOnlyRefersh: boolean,
    isNoHeader?: boolean,
    headerText?: string,
    showModelAlert: (showModelAlert: IShowModelAlert, callback?: () => void) => void,
    resetHasChange: () => void,
    updateBookingDetailInfo: (bookingDetailInfo: IBookingDetailInfo, callBack: () => void) => void
}

interface CountryType {
    image_url: string;
    card_name: string;
    card_number: string;
    suggested?: boolean;
}

interface IBookingSummaryState {
    defaultSelectedCard: CountryType,
    showPromoCode: boolean,
    showOverrideTF: boolean,
    hourlyPrice: string,
    modifiedPrice: string,
    propertyGuid: string,
    serviceFee: number,
    tax: number,
    price: number,
    intialPrice: number,
    credit: number,
    coupon: number,
    maxserviceFree: number,
    orderTotal: number,
    orderBookingAmount: number,
    bookingType: string,
    showNewCardScreen: boolean,
    showAddNewCard: boolean,
    marginOfShowCard: number,
    showDropDown: boolean,
    cardArray: any[],
    selectedCardDisplayName: string,
    selectedCardId: string,
    creditButtonText: string,
    couponButtonText: string,
    isCreditBT: boolean,
    isCouponBT: boolean,
    showCredit: boolean,
    showCoupon: boolean,
    chooseCredit: boolean,
    chooseCoupon: boolean,
    bookingDetailInfo: IBookingDetailInfo,
    manualSelectedCoupon: string,
    showOnPopUp: boolean,
    dropDownSelected: boolean,
    showBookingSuccess: boolean,
    waivedOff: number,
    couponIds: any[],
    monthlyOverridOptions: number,
    creditIds: any[],
    discountObj: any,
    overridePrice: 0,
    intialProrate: number,
    isOverrided: boolean,
    friendlyReservationId: string
}