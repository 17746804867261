import {Button, TextField} from "@material-ui/core";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Autocomplete from "@material-ui/lab/Autocomplete";
import {format} from "date-fns";
import {DateRange} from "materialui-daterange-picker";
import React from "react";
import {Col, Row} from "reactstrap";
import {ShowAlert} from "../../Common/ShowAlert";
import {INumberRange} from "../../Components/DataGrid/Interfaces";
import {DateTimeHelper} from "../../Helpers/DateTimeHelper";
import PropertyService from "../../Services/PropertyService";
import SpotService from "../../Services/SpotService";
import UserService from "../../Services/UserService";
import ZoneService from "../../Services/ZoneService";
import GrydDateRangePicker from "../BookingsCalendar/GrydDateRangePicker";
import {IBlockSpot} from "../BookingsCalendar/interfaces";
import {IBasicUser, IPropertyBasics, ISpot} from "../Spots/Interfaces";
import {
  IActiveZone,
  IAddCashBookingData,
  IBlockTime,
  INewCashBookingRequest,
  IPaymentType,
  IReservation,
  IVehicle
} from "./Interfaces";

export default class AddNewBookingView extends React.Component<IAddNewBookingViewProps, IAddNewBookingViewState> {
  state: IAddNewBookingViewState = {
    isReady: false,
    properties: [],
    spots: [],
    parkers: [],
    vehicles: [],
    selectedProperty: null,
    selectedSpot: null,
    selectedParker: null,
    // spotSchedules: null,
    selectedVehicle: null,
    selectedDate: new Date(),
    selectedTime: {
      start: 0,
      end: 1439
    },
    times: this.getTimes(),
    zones: [],
    selectedZone: null,
    date_: {
      endDate: new Date(),
      startDate: new Date()
    },
    selectedStartTime: {
      dateTime: new Date(),
      timeString: "12:00 PM"
    },
    selectedEndTime: {
      dateTime: new Date(),
      timeString: "05:00 PM"
    },
    licensePlate: "",
    selectTransactionType: {label: "Cash", value: 5} as IPaymentType,
    transactionType: [{label: "Card", value: 3}, {label: "Cash", value: 5}] as IPaymentType[]
  };
  private _propertyService: PropertyService;
  private _spotService: SpotService;
  private _userService: UserService;
  private _zoneService: ZoneService;

  constructor(props_: IAddNewBookingViewProps) {
    super(props_);

    this._propertyService = new PropertyService();
    this._spotService = new SpotService();
    this._userService = new UserService();
    this._zoneService = new ZoneService();
  }

  componentDidMount() {
    const {selectedProperty, addCashBookingData} = this.props;
    if (selectedProperty && addCashBookingData.isNewItem) {
      this._zoneService.getActiveZones(selectedProperty.propertyGuid)
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              const zones: IActiveZone[] = res;
              const startTime = DateTimeHelper.getCurrentArrangeTime(new Date());
              const endTime = DateTimeHelper.getEndTime(startTime, 60);

              const selectedStartTime = this.state.times.filter(x => x.timeString === startTime)[0];
              const selectedEndTime = this.state.times.filter(x => x.timeString === endTime)[0];
              let updatedState: IAddNewBookingViewState = {
                isReady: true,
                properties: this.props.properties,
                selectedProperty: selectedProperty,
                zones,
                selectedStartTime,
                selectedEndTime
              } as any;
              this.setState({...updatedState});
              const {addCashBookingData} = this.props;
              addCashBookingData.zones = zones;
              addCashBookingData.isNewItem = false;
              addCashBookingData.propertyGuid = selectedProperty.propertyGuid;
              addCashBookingData.startTime = selectedStartTime;
              addCashBookingData.endTime = selectedEndTime;
              addCashBookingData.transactionType = this.state.selectTransactionType?.value ?? 5;

              this.props.onUpdateState(addCashBookingData, true)
            });
          }
        });
    } else {
      const selectTransactionType = this.state.transactionType.find(x => x.value === addCashBookingData.transactionType) ?? {
        label: "Cash",
        value: 5
      } as IPaymentType;
      let updatedState: IAddNewBookingViewState = {
        isReady: true,
        properties: this.props.properties,
        selectedProperty: selectedProperty,
        zones: addCashBookingData.zones,
        licensePlate: addCashBookingData.licensePlate,
        date_: addCashBookingData.date_,
        spots: addCashBookingData.spots,
        selectedStartTime: addCashBookingData.startTime,
        selectedEndTime: addCashBookingData.endTime,
        selectedZone: addCashBookingData.selectedZone,
        selectedSpot: addCashBookingData.selectedSpot,
        selectTransactionType
      } as any;

      this.setState({...updatedState});
    }
    // if (this.props.properties && this.props.spots && this.props.selectedProperty) {
    //     let selectedProperty = (this.props.selectedProperty != null && this.props.selectedProperty.isActive == true) ? this.props.selectedProperty : null;
    //     let selectedSpot = (selectedProperty != null && this.props.selectedSpot != null && this.props.selectedSpot.spotStatusId == 1) ? this.props.selectedSpot : null;
    //     let updatedState: IAddNewBookingViewState = {
    //         isReady: true,
    //         properties: this.props.properties,
    //         selectedProperty: selectedProperty
    //     } as any;

    //     if (selectedProperty?.isActive) {
    //         updatedState.spots = this.props.spots;

    //         if (this.props.selectedSpot) {
    //             updatedState.selectedSpot = this.props.selectedSpot;
    //         }
    //     }

    //     if (this.props.selectedDate && this.props.selectedTime) {
    //         updatedState.selectedDate = this.props.selectedDate;
    //         updatedState.selectedTime = this.props.selectedTime;
    //     }

    //     this.setState(updatedState, () => {
    //         this._userService.getAllUsers()
    //             .then(r => r.json())
    //             .then(r => {
    //                 this.setState({
    //                     parkers: r
    //                 }, this._setDateLimitation);
    //             });
    //     });
    // }
    // else {
    //     let apiCounts = 2;
    //     let properties: IPropertyBasics[] = [];
    //     let parkers: IBasicUser[] = [];

    //     let onApiResponse = () => {
    //         if (--apiCounts == 0) {
    //             this.setState({
    //                 properties: properties,
    //                 parkers: parkers,
    //                 isReady: true
    //             });
    //         }
    //     }

    //     this._propertyService.getActivePropertiesBasics()
    //         .then(r => r.json())
    //         .then(r => {
    //             properties = r;
    //             onApiResponse();
    //         });

    //     this._userService.getAllUsers()
    //         .then(r => r.json())
    //         .then(r => {
    //             parkers = r;
    //             onApiResponse();
    //         });
    // }
  }

  render() {
    const {date_, licensePlate} = this.state;
    return (
      <div
        className={"cash-booking-edit-view edit-view p-3" + (this.props.className ? (" " + this.props.className) : "")}>
        {
          this.state.isReady ?
            <div className="panel-view-scrollable-area py-3">
              {/* <Row className="mt-3">
                                <Col>
                                    <Autocomplete
                                        options={this.state.vehicles}
                                        value={this.state.selectedVehicle}
                                        onChange={(event: any, selectedItem_: IVehicle | null) => {
                                            this.setState({
                                                selectedVehicle: selectedItem_,
                                            });
                                        }}
                                        getOptionLabel={(option) => option.vehiclePlateNumber}
                                        renderInput={(params) => <TextField {...params} label="Vehicle" variant="outlined" />}
                                    />
                                </Col>
                            </Row> */}
              <Row>
                <Col>
                  <h6>PARKER DETAILS</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="panel-body-field-label">License Plate</div>
                  <TextField
                    fullWidth
                    placeholder="License Plate"
                    variant="outlined"
                    required={true}
                    margin="normal"
                    className="mt-0 customHeight"
                    onPaste={() => {
                      return false
                    }}
                    inputProps={{maxLength: 9}}
                    value={licensePlate}
                    onChange={(e_: any) => {
                      this.setState({licensePlate: e_.target.value});
                      const {addCashBookingData} = this.props;
                      addCashBookingData.licensePlate = e_.target.value;
                      addCashBookingData.isNewItem = false;
                      this.props.onUpdateState(addCashBookingData);
                    }}
                    error={!licensePlate}
                    autoComplete="off"
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <h6>BOOKING DETAILS</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="panel-body-field-label">Date</div>
                  <GrydDateRangePicker
                    placeHolder="Date"
                    initialDateRange={date_}
                    onDateChange={(dates_: any) => {
                      this.setState({
                        date_: dates_,
                        selectedZone: null,
                        selectedSpot: null,
                        spots: []
                      });
                      const {addCashBookingData} = this.props;
                      addCashBookingData.date_ = dates_;
                      addCashBookingData.isNewItem = false;
                      this.props.onUpdateState(addCashBookingData);
                    }}
                    required={true}
                    // minDate={this.getMinDateRange()}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="panel-body-field-label">Time</div>
                  <div className="cl-flex">
                    <div className="cl-flex-cl cl-r-padding">
                      <div className="cl-block-input-c">
                        <AccessTimeIcon className="cl-icon"/>
                        <Autocomplete
                          id="cl-start-time"
                          className="cl-time-dropdown"
                          options={this.state.times}
                          value={this.state.selectedStartTime}
                          onChange={(event: any, value: IBlockTime | null) => {
                            this.setState({
                              selectedStartTime: value,
                              selectedZone: null,
                              selectedSpot: null,
                              spots: []
                            }, () => {
                              this.checkStartAndEndTime();
                              const {addCashBookingData} = this.props;
                              addCashBookingData.startTime = this.state.selectedStartTime ?? {
                                dateTime: new Date(),
                                timeString: "12:00 PM"
                              };
                              addCashBookingData.isNewItem = false;
                              this.props.onUpdateState(addCashBookingData);
                            });
                            // let oldValue = this.state.selectedStartTime;
                            // this.setState({
                            //     selectedStartTime: value
                            // }, () => {
                            //     this.checkStartAndEndTime();
                            // })
                          }}
                          openOnFocus={true}
                          selectOnFocus={true}
                          getOptionLabel={(option) => option.timeString}
                          renderOption={(option, {selected}) => (
                            <React.Fragment>
                              {option.timeString}
                            </React.Fragment>
                          )}
                          renderInput={(params) => <TextField {...params} placeholder="Start Time"
                                                              variant="outlined"
                                                              error={!this.state.selectedStartTime}
                          />}
                        />
                      </div>
                    </div>
                    <div className="cl-flex-pd">&mdash;</div>
                    <div className="cl-flex-cl cl-r-padding">
                      <div className="cl-block-input-c">
                        <AccessTimeIcon className="cl-icon"/>
                        <Autocomplete
                          id="cl-end-time"
                          className="cl-time-dropdown"
                          options={this.getTimes(true)}
                          value={this.state.selectedEndTime}
                          onChange={(event: any, value: IBlockTime | null) => {
                            this.setState({
                              selectedEndTime: value,
                              selectedZone: null,
                              selectedSpot: null,
                              spots: []
                            }, () => {
                              //this.checkStartAndEndTime();
                              const {addCashBookingData} = this.props;
                              addCashBookingData.endTime = this.state.selectedEndTime ?? {
                                dateTime: new Date(),
                                timeString: "05:00 PM"
                              };
                              addCashBookingData.isNewItem = false;
                              this.props.onUpdateState(addCashBookingData);
                            });
                            // this.setState({
                            //     selectedEndTime: value
                            // }, () => {
                            //     this.checkStartAndEndTime();
                            // })
                          }}
                          openOnFocus={true}
                          selectOnFocus={true}
                          getOptionLabel={(option) => option.timeString}
                          renderOption={(option, {selected}) => (
                            <React.Fragment>
                              {option.timeString}
                            </React.Fragment>
                          )}
                          renderInput={(params) => <TextField {...params} placeholder="End Time"
                                                              variant="outlined"
                                                              error={!this.state.selectedEndTime}
                          />}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <h6>SPOT DETAILS</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="panel-body-field-label">Select Zone</div>
                  <Autocomplete
                    options={this.state.zones}
                    size={"small"}
                    value={this.state.selectedZone}
                    onChange={(event: any, selectedItem_: IActiveZone | null) => {
                      this.setState({
                        selectedZone: selectedItem_,
                        selectedSpot: null
                      }, () => {
                        if (selectedItem_) {
                          const {addCashBookingData} = this.props;
                          const {date_, selectedStartTime, selectedEndTime} = this.state;
                          const date = {...date_};

                          let copyDate = {startDate: new Date(), endDate: new Date()};

                          if (date.startDate && date.endDate) {
                            copyDate = {startDate: new Date(date.startDate), endDate: new Date(date.endDate)};
                            DateTimeHelper.ChangeTime(copyDate.startDate, selectedStartTime?.timeString ?? "");
                            DateTimeHelper.ChangeTime(copyDate.endDate, selectedEndTime?.timeString ?? "");
                          }
                          let zoneData = {
                            "propertyGuid": addCashBookingData.propertyGuid,
                            "zoneGuid": selectedItem_?.zoneGuid,
                            "startDate": DateTimeHelper.LocalToUtcString(copyDate.startDate),
                            "endDate": DateTimeHelper.LocalToUtcString(copyDate.endDate),
                            "isFromCashbooking": true
                          } as any;
                          this._spotService.getActiveSpots(zoneData)
                            .then(response => {
                              if (response.ok) {
                                response.json().then(res => {
                                  const spots: IBlockSpot[] = res;
                                  this.setState({spots});
                                  const {addCashBookingData} = this.props;
                                  addCashBookingData.spots = spots;
                                  addCashBookingData.isNewItem = false;
                                  addCashBookingData.selectedZone = selectedItem_;
                                  this.props.onUpdateState(addCashBookingData)
                                });
                              }
                            });
                        }
                      });

                    }}
                    getOptionLabel={(option) => option.zoneName}
                    renderInput={(params) => <TextField
                      {...params} placeholder="Select Zone" variant="outlined"
                      error={!this.state.selectedZone}/>}
                  />
                </Col>
                <Col>
                  <div className="panel-body-field-label">Select Spot (if applicable)</div>
                  <Autocomplete
                    options={this.state.spots}
                    size={"small"}
                    value={this.state.selectedSpot}
                    onChange={(event: any, selectedItem_: IBlockSpot | null) => {
                      this.setState({
                        selectedSpot: selectedItem_,
                      }, () => {
                        if (selectedItem_) {
                          const {addCashBookingData} = this.props;
                          addCashBookingData.selectedSpot = this.state.selectedSpot;
                          addCashBookingData.isNewItem = false;
                          this.props.onUpdateState(addCashBookingData);
                        }
                      });
                    }}
                    getOptionLabel={(option) => option.friendlySpotId}
                    renderInput={(params) => <TextField {...params} placeholder="Select Spot" variant="outlined"/>}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="panel-body-field-label">Payment Type</div>
                  <Autocomplete
                    options={this.state.transactionType}
                    size={"small"}
                    value={this.state.selectTransactionType}
                    onChange={(event: any, selectedItem_: IPaymentType | null) => {
                      this.setState({
                        selectTransactionType: selectedItem_
                      }, () => {
                        if (selectedItem_) {
                          const {addCashBookingData} = this.props;
                          addCashBookingData.transactionType = this.state.selectTransactionType?.value ?? 5;
                          addCashBookingData.isNewItem = false;
                          this.props.onUpdateState(addCashBookingData);
                        }
                      });
                    }}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField
                      {...params} placeholder="Select Payment type" variant="outlined"
                      error={!this.state.selectTransactionType}/>}
                  />
                </Col>
              </Row>
            </div>
            : null
        }
        {
          this.state.isReady ?
            <Row className="save-button-container">
              <Col>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    this.props.onClose(false);
                  }}
                >Cancel</Button>
              </Col>
              <Col>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const {
                      date_, licensePlate, selectedProperty, selectedZone,
                      selectedStartTime, selectedEndTime, selectedSpot, selectTransactionType
                    } = {...this.state};
                    if (!date_ || !licensePlate || !selectedProperty || !selectedZone
                      || !selectedStartTime || !selectedEndTime || !selectTransactionType) {
                      ShowAlert("", 'Please fill the mandatory fields', "error");
                      return;
                    }
                    const date = {...date_};

                    if (date.startDate && date.endDate) {
                      const copyDate = {startDate: new Date(date.startDate), endDate: new Date(date.endDate)};
                      DateTimeHelper.ChangeTime(copyDate.startDate, selectedStartTime.timeString);
                      DateTimeHelper.ChangeTime(copyDate.endDate, selectedEndTime.timeString);

                      if (copyDate.startDate.toDateString() === copyDate.endDate.toDateString() && copyDate.endDate <= copyDate.startDate) {
                        ShowAlert("", 'End time should be greater than start time', "error");
                        return;
                      }
                      // const bookingStartUTC = date.startDate;//DateTimeHelper.convertIntoUtc(date.startDate ?? new Date());
                      // const bookingEndUTC = date.endDate;//DateTimeHelper.convertIntoUtc(date.endDate ?? new Date());
                      const bookingStartUTC = DateTimeHelper.LocalToUtcString(copyDate.startDate);
                      const bookingEndUTC = DateTimeHelper.LocalToUtcString(copyDate.endDate);
                      const newCashBookingRequest: INewCashBookingRequest = {
                        zoneGuid: selectedZone.zoneGuid,
                        propertyGuid: selectedProperty.propertyGuid,
                        bookingStartUTC: bookingStartUTC,
                        bookingEndUTC: bookingEndUTC,
                        spotGuid: selectedSpot?.spotGuid,
                        vehicleLicense: licensePlate,
                        discount: null,
                        paymentType: selectTransactionType.value === 3 ? "Credit" : "Cash"
                      }
                      this._spotService.saveHoldSpot(newCashBookingRequest)
                        .then(response => {
                          if (response.ok) {
                            response.json().then(res => {
                              if (res && (!res.blockGuid || !res.blockedSpotGuid || res.availableSpotCount === 0)) {
                                ShowAlert("", 'Spot not available', "error");
                                return;
                              }
                              this.props.onNextClick(res);
                            });
                          } else {
                            response.json().then(res => {
                              if (res === "Invalid start time") {
                                ShowAlert("", 'Invalid start time', "error");
                                return;
                              } else if (res === "No spot(s) are available") {
                                ShowAlert("", 'Spot not available', "error");
                                return;
                              } else if (res === "Selected spot is not available") {
                                ShowAlert("", 'Selected spot is not available.', "error");
                                return;
                              } else {
                                ShowAlert("", 'There was an error processing your request.', "error");
                                return;
                              }
                            }).catch(err => {
                              ShowAlert("", err, "error");
                            });
                          }
                        });
                    }


                  }}
                  disabled={this.state.isProcessing}
                >Next</Button>
              </Col>
            </Row>
            : null
        }
      </div>
    );
  }

  private checkStartAndEndTime() {
    let s_ = this.state.selectedStartTime;
    let e_ = this.state.selectedEndTime;

    if (s_ != null &&
      ((e_ != null && s_.dateTime.getTime() >= e_.dateTime.getTime()))) {
      let n_ = new Date(s_.dateTime);
      n_.setMinutes(n_.getMinutes() + 60);
      let o_ = new Date(n_);

      let time: IBlockTime = {
        dateTime: o_,
        timeString: format(o_, "hh:mm aa")
      }

      this.setState({
        selectedEndTime: time
      }, () => {
        const {addCashBookingData} = this.props;
        addCashBookingData.endTime = this.state.selectedEndTime ?? {
          dateTime: new Date(),
          timeString: "05:00 PM"
        };
        addCashBookingData.isNewItem = false;
        this.props.onUpdateState(addCashBookingData);
      });
    } else if (e_ != null && ((s_ != null && e_.dateTime.getTime() <= s_.dateTime.getTime()))) {
      let n_ = new Date(e_.dateTime);
      n_.setMinutes(n_.getMinutes() - 60);
      let o_ = new Date(n_);

      let time: IBlockTime = {
        dateTime: o_,
        timeString: format(o_, "hh:mm aa")
      }

      this.setState({
        selectedStartTime: time
      }, () => {
        const {addCashBookingData} = this.props;
        addCashBookingData.startTime = this.state.selectedStartTime ?? {
          dateTime: new Date(),
          timeString: "12:00 PM"
        };
        addCashBookingData.isNewItem = false;
        this.props.onUpdateState(addCashBookingData);
      });
    } else if (s_ == null) {
      this.setState({
        selectedStartTime: null
      });
    }
  }

  private getTimes(isEndTime: boolean = false) {
    let hours: IBlockTime[] = [];

    //if (this.props.selectedProperty && this.props.selectedProperty.openTimeString && this.props.selectedProperty.closeTimeString) {
    let currentDayDate: Date = new Date(new Date().setHours(0, 0, 0, 0));
    let minMinutes = DateTimeHelper.timeStringToMinutes("12:00 AM");
    let maxMinutes = DateTimeHelper.timeStringToMinutes("11:59 PM");
    let minTime: Date = new Date(new Date().setHours(0, 0, 0, 0));
    let maxTime: Date = new Date(new Date().setHours(0, 0, 0, 0));

    // if ((maxMinutes % 10) != 0) {
    //     maxMinutes += 1;
    // }

    // // If not 24 hrs
    // if (maxMinutes < 1439 && !isEndTime) {
    //     maxMinutes -= 30;
    // }

    minTime.setMinutes(minMinutes);
    maxTime.setMinutes(maxMinutes);

    let totalHours: number = 97;
    let startIndex: number = 0;

    for (let i = startIndex; i < totalHours; i++) {
      let currentDate = new Date(currentDayDate);
      currentDate.setMinutes((i / 4) * 60);
      let outputDate = new Date(currentDate);

      if (outputDate.getTime() >= maxTime.getTime()) {
        outputDate = maxTime;
      }

      let time: IBlockTime = {
        dateTime: outputDate,
        timeString: format(outputDate, "hh:mm aa")
      }

      if (outputDate.getTime() >= minTime.getTime() && outputDate.getTime() <= maxTime.getTime()) {
        hours.push(time);
      }
    }

    if (isEndTime) {
      hours.splice(0, 1);
    } else {
      hours.splice(hours.length - 1, 1);
    }

    //}

    return hours;
  }

  private getMinDateRange() {
    let currentDate = new Date();
    // return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  }
}

interface IAddNewBookingViewProps {
  reservation?: IReservation;
  className?: string;
  customTitle?: string;
  onClose: (requiresUpdate_: boolean, data_?: IReservation) => void;
  onSave?: (data_: any) => void;
  onNextClick: (response: any) => void;

  properties?: IPropertyBasics[];
  spots?: ISpot[];
  selectedProperty?: IPropertyBasics | null;
  selectedSpot?: ISpot | null;
  selectedDate?: any;
  selectedTime?: INumberRange;
  addCashBookingData: IAddCashBookingData,
  onUpdateState: (addCashBookingData: any, oldAddCashBooking?: boolean) => void;
}

interface IAddNewBookingViewState {
  isReady: boolean;
  properties: IPropertyBasics[];
  spots: IBlockSpot[];
  parkers: IBasicUser[];
  vehicles: IVehicle[];

  // spotSchedules: ISingleScheduleData[] | null;
  selectedProperty: IPropertyBasics | null;
  selectedSpot: IBlockSpot | null;
  selectedParker: IBasicUser | null;
  selectedVehicle: IVehicle | null;
  selectedDate: any;
  selectedTime: INumberRange;
  isProcessing?: boolean;

  dayStart?: number;
  dayEnd?: number;
  times: IBlockTime[];

  zones: IActiveZone[];
  selectedZone: IActiveZone | null;

  date_: DateRange;
  selectedStartTime: IBlockTime | null;
  selectedEndTime: IBlockTime | null;
  licensePlate: string,

  selectTransactionType: IPaymentType | null;
  transactionType: IPaymentType[];
}
