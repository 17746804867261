import * as React from 'react';
import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { IPropertyBasics } from '../Pages/Spots/Interfaces';
const locales = {
    'en-US': require('date-fns/locale/en-US'),
}
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

export default class ScheduleCalendar extends React.Component<IScheduleCalendarProps, IScheduleCalendarState> {

    constructor(props_: IScheduleCalendarProps) {
        super(props_);

        this.state = {
            viewDate: props_.viewDate,
            selectedDates: props_.selectedDates,
            aggregatedSchedules: props_.aggregatedCount
        };
    }

    componentDidUpdate(oldProps_: IScheduleCalendarProps) {
        if (this.props.selectedDates != oldProps_.selectedDates) {
            this.setState({
                selectedDates: this.props.selectedDates
            });
        }
        else if (this.props.viewDate.toDateString() != oldProps_.viewDate.toDateString()) {
            this.setState({
                viewDate: this.props.viewDate,
                aggregatedSchedules: this.props.aggregatedCount
            });
        }
        else if (JSON.stringify(this.props.aggregatedCount) != JSON.stringify(oldProps_.aggregatedCount)) {
            this.setState({
                aggregatedSchedules: this.props.aggregatedCount
            });
        }
    }

    onSlotRender = (date_: Date) => {
        let dateString = date_.toDateString();
        for (let date of this.state.selectedDates) {
            if (dateString == date.toDateString()) {
                return {
                    className: "d-selected",
                    style: null
                };
            }
        }

        return null;
    }

    render() {
        return (
            <Calendar
                date={this.state.viewDate}
                events={this.state.aggregatedSchedules}
                views={[Views.MONTH]}
                selectable={true}
                step={60}
                localizer={localizer}
                toolbar={false}
                dayPropGetter={this.onSlotRender}
                onSelectSlot={(slotInfo_: any) => {
                    this.props.onDatesSelect([slotInfo_.slots[0]]);
                }}
                components={{
                    event: MonthlyEventView,
                    header: MonthlyHeader,
                    month: {
                        dateHeader: MonthDateSlotView
                    }
                }}
            />
        );
    }
}

class MonthDateSlotView extends React.Component<IMonthDateSlotView> {
    render() {
        return (
            <div className="day-number">
                {parseInt(this.props.label)}
            </div>
        );
    }
}

class MonthlyEventView extends React.Component<IMonthlyEventView> {
    render() {
        return (
            <div className="reservation-count">{this.props.event.reservationCount} {this.props.event.reservationCount > 1 ? "Bookings" : "Booking"}</div>
        );
    }
}

class MonthlyHeader extends React.Component<IMonthlyHeaderView> {
    render() {
        return this.props.label[0];
    }
}

interface IMonthDateSlotView {
    date: Date;
    label: string;
    isOffRange: boolean;
}

interface IMonthlyHeaderView {
    label: string;
}

interface IMonthlyEventView {
    event: ISlotAggregation;
}

export interface ISlotAggregation {
    start: Date;
    end: Date;
    reservationCount: number;
}

interface IScheduleCalendarProps {
    aggregatedCount: ISlotAggregation[];
    selectedDates: Date[];
    viewDate: Date;
    onDatesSelect: (dates_: Date[]) => void;
    propertyDetails: IPropertyBasics | null;
}

interface IScheduleCalendarState {
    viewDate: Date;
    aggregatedSchedules: ISlotAggregation[];
    selectedDates: Date[];
}