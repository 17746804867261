import * as React from "react";
import { Calendar, Views, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const formats = {
  weekdayFormat: "ddd",
};

export default class MonthlyCalendar extends React.Component<
  IMonthlyCalendarProps,
  IMonthlyCalendarState
> {
  constructor(props_: IMonthlyCalendarProps) {
    super(props_);

    this.state = {
      aggregatedSpots: this.props.aggregatedSpots,
      selectedDate: new Date(),
    };
  }

  componentDidUpdate() {
    if (this.props.viewDate != this.state.selectedDate) {
      this.setState({
        aggregatedSpots: this.props.aggregatedSpots,
        selectedDate: this.props.viewDate,
      });
    } else if (
      JSON.stringify(this.props.aggregatedSpots) !=
      JSON.stringify(this.state.aggregatedSpots)
    ) {
      this.setState({
        aggregatedSpots: this.props.aggregatedSpots,
        selectedDate: this.props.viewDate,
      });
    }
  }

  render() {
    return (
      <Calendar
        date={this.props.viewDate}
        events={this.state.aggregatedSpots}
        views={[Views.MONTH]}
        defaultView={Views.MONTH}
        selectable={true}
        step={60}
        localizer={localizer}
        toolbar={false}
        dayPropGetter={this.onSlotRender}
        onSelectSlot={(slotInfo_: any) => {
          this.props.onDatesSelect([slotInfo_.slots[0]]);
        }}
        components={{
          event: MonthlyEventView,
          header: MonthlyHeader,
          month: {
            dateHeader: MonthDateSlotView,
          },
        }}
        on
      />
    );
  }

  onSlotRender = (date_: Date) => {
    let dateString = date_.toDateString();
    for (let date of this.props.selectedDates) {
      if (dateString == date.toDateString()) {
        return {
          className: "d-selected",
          style: null,
        };
      }
    }

    return null;
  };
}

class MonthlyEventView extends React.Component<IMonthlyEventView> {
  render() {
    return (
      // <div className="reservation-count">{this.props.event.blocking} {this.props.event.blocking > 1 ? "Bookings" : "Booking"}</div>
      <div
        className={
          "cl-list " +
          (this.props.event.start.getTime() ==
          this.props.event.selectedDate.getTime()
            ? "cl-now"
            : "")
        }>
        <div className="o-s">{this.props.event.availableSpotCount}</div>
        <div className="bo-s">{this.props.event.bookingCount}</div>
        <div className="bl-s">{this.props.event.blockCount}</div>
      </div>
      // <div className="cl-list" style={{ color: "#000" }}>
      //     {
      //         this.props.event.start + "," + this.props.event.selectedDate
      //     }
      // </div>
    );
  }
}

class MonthDateSlotView extends React.Component<IMonthDateSlotView> {
  render() {
    return <div className="day-number">{parseInt(this.props.label)}</div>;
  }
}

class MonthlyHeader extends React.Component<IMonthlyHeaderView> {
  render() {
    return this.props.label.substring(0, 3);
  }
}

interface IMonthlyHeaderView {
  label: string;
}

interface IMonthDateSlotView {
  date: Date;
  label: string;
  isOffRange: boolean;
}

interface IMonthlyEventView {
  event: ISpotAggregation;
}

export interface ISpotAggregation {
  start: Date;
  end: Date;
  startDate: Date;
  endDate: Date;
  availableSpotCount: number;
  bookingCount: number;
  blockCount: number;
  selectedDate: Date;
}

interface IMonthlyCalendarProps {
  viewDate: Date;
  selectedDates: Date[];
  aggregatedSpots: ISpotAggregation[];
  onDatesSelect: (dates_: Date[]) => void;
}

interface IMonthlyCalendarState {
  aggregatedSpots: ISpotAggregation[];
  selectedDate: Date;
}
