import BaseService from "./BaseService";
import { ConfigService } from './ConfigService';

export default class ReceiptService extends BaseService {
    public sendReciptByEmail(emailBody: any, requestName?: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "spot/booking/guest/send-receipt-email", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(emailBody)
                }, requestName)
            });
    }

    public sendReciptBySms(smsDetail: any, requestName?: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "spot/booking/guest/send-receipt-sms", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(smsDetail)
                }, requestName)
            });
    }
}