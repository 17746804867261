import { AppBar, Button, Dialog, FormControlLabel, Grid, IconButton, Link, Radio, RadioGroup, Slide, TextField, Toolbar, Typography } from "@material-ui/core";
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { ArrowBackIos } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from "@material-ui/lab";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { format } from "date-fns";
import { cloneDeep } from "lodash";
import { DateRange } from "materialui-daterange-picker";
import moment from "moment";
import React from "react";
import BookingModification from "../../Common/BookingModification";
import { ShowAlert } from '../../Common/ShowAlert';
import { IShowModelAlert, ShowModelAlertType } from "../../Common/interface";
import DataGrid from "../../Components/DataGrid/DataGrid";
import { IDataGridColumn } from "../../Components/DataGrid/Interfaces";
import ArrayHelper from "../../Helpers/ArrayHelper";
import { DateTimeHelper } from "../../Helpers/DateTimeHelper";
import SpotService from "../../Services/SpotService";
import { BookingType, IBookingDetailInfo, IPreZoneSpot } from "../BookingsCalendar/interfaces";
import { IReservation, ISpotDetails } from "../CashBookingSchedule/Interfaces";
import { getScheduleSummaryText } from "../Spots/Interfaces";
import { EnumRecurringEventType, EnumSpotRecurrenceType, INearBySpots, ISpotAmenities, ISpotAvailable } from "./Interfaces";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export default class ModifyBooking extends React.Component<IModifyBookingProps, IModifyBookingState> {
    private _spotService: SpotService;

    private _reservationListColumns: IDataGridColumn<IReservation>[] = [
        {
            key: "spacer1",
            name: ""
        },
        {
            key: "friendlyReservationId",
            name: "Booking Id"
        },
        {
            key: "start",
            name: "From",
            contentProvider: (row_: IReservation) => {
                let date = DateTimeHelper.dateStringToDate(row_.start);
                return (<span><span className="no-wrap-text">{format(date, "dd MMM yyyy")}</span><br /><span className="no-wrap-text">{format(date, "hh:mm aa")}</span></span>);
            }
        },
        {
            key: "end",
            name: "To",
            contentProvider: (row_: IReservation) => {
                let date = DateTimeHelper.dateStringToDate(row_.end);
                return (<span><span className="no-wrap-text">{format(date, "dd MMM yyyy")}</span><br /><span className="no-wrap-text">{format(date, "hh:mm aa")}</span></span>);
            }
        },
        {
            key: "parkerName",
            name: "Parker"
        },
        {
            key: "propertyName",
            name: "Property Name"
        },
        {
            key: "friendlySpotId",
            name: "Spot Number",
            contentProvider: (row_: IReservation) => {
                let recurringSpot = <span className="wordWrap-100">{row_.friendlySpotId}</span>;
                if (this.props.bookingDetailInfo.bookingType === BookingType.Monthly) {
                    switch (this.state.recurringEventType) {
                        case EnumRecurringEventType.Today: {
                            const spot = row_.spotDetails?.find(x => x.spotRecurrenceType === EnumSpotRecurrenceType.Today) ?? null;
                            if (spot && spot.spotGuid !== row_.spotGuid) {
                                recurringSpot = <div className="wordWrap-100">
                                    <div>Today: {spot.friendlySpotId}</div>
                                    <div>Permanent: {row_.friendlySpotId}</div>
                                </div>;
                            }
                            else {
                                recurringSpot = <span className="wordWrap-100">Permanent: {row_.friendlySpotId}</span>;
                            }
                        }
                            break;
                        case EnumRecurringEventType["This month"]:
                        case EnumRecurringEventType["This and future months"]: {
                            const spot = row_.spotDetails?.find(x => x.spotRecurrenceType === EnumSpotRecurrenceType.CurrentMonth) ?? null;
                            if (spot && spot.spotGuid !== row_.spotGuid) {
                                recurringSpot = <div className="wordWrap-100">
                                    <div>Current: {spot.friendlySpotId}</div>
                                    <div>Permanent: {row_.friendlySpotId}</div>
                                </div>;
                            }
                            else {
                                recurringSpot = <span className="wordWrap-100">
                                    Permanent: {row_.friendlySpotId}
                                </span>;
                            }
                        }
                            break;
                        default: {
                            const spot = row_.spotDetails?.find(x => x.spotRecurrenceType === EnumSpotRecurrenceType.Future) ?? null;
                            recurringSpot = spot ?
                                <span className="wordWrap-100">Permanent: {spot.friendlySpotId}</span> : <span className="wordWrap-100">Permanent: {row_.friendlySpotId}</span>;
                        }
                            break;
                    }
                }
                return recurringSpot;
            }
        },
        {
            key: "reservationAmountWithoutTax",
            name: "Booking Amount",
            textAlignment: "right",
            contentProvider: (row_: IReservation) => {
                let amount = (this.props.bookingDetailInfo.bookingType === BookingType.Monthly ?
                    this.state.recurringEventType === EnumRecurringEventType.Today ? row_.paidForToday :
                        this.state.recurringEventType === EnumRecurringEventType["This month"] ? row_.paidForCurrentMonth :
                            row_.reservationAmountWithoutTax : row_.reservationAmountWithoutTax) ?? 0;
                if (!this.state.hideNextFuture && this.state.recurringEventType === EnumRecurringEventType["Next month and future months"]) {
                    const currentDate = new Date(new Date().toISOString().split('Z')[0]);
                    const startDate = moment(row_.startTimeUtc).toDate();
                    const selectedMonth = moment.utc(this.props.bookingDetailInfo.selectedModifyMonth).toDate();
                    if (currentDate >= startDate || currentDate >= selectedMonth) {
                        amount = 0;
                    }
                }
                return (
                    <span>
                        ${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                );
            }
        },
        {
            key: "spacer2",
            name: ""
        }
    ]

    private _spotListColumns: IDataGridColumn<ISpotAvailable>[] = [
        {
            key: "spacer1",
            name: ""
        },
        {
            key: "friendlySpotId",
            name: "Spot Number",
            getComparator: ArrayHelper.getNumberInStringComparator
        },
        {
            key: "spotAmenities",
            name: "Amenities",
            contentProvider: (row_: ISpotAvailable) => {
                return (<div>
                    {
                        row_.spotAmenities.map((s, i) => {
                            return (<span key={i}>
                                <span className={s.type}>{s.amenity}</span>{((row_.spotAmenities.length - 1) != i) ? ", " : ""}
                            </span>)
                        })
                    }
                </div>);
            }
        },
        {
            key: "spotSchedule",
            name: "Schedule(s)",
            contentProvider: (row_: ISpotAvailable) => {
                return (<div>
                    {
                        row_.spotSchedule?.map(s => {
                            return (<div>
                                {s}
                            </div>)
                        })
                    }
                </div>);
            }
        },
        {
            key: "priceWithoutTax",
            name: "Booking Amount",
            textAlignment: "right",
            contentProvider: (row_: ISpotAvailable) => {
                return (
                    <span>
                        ${row_.priceWithoutTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                );
            }
        },
        {
            key: "spacer2",
            name: ""
        }
    ]

    constructor(props_: IModifyBookingProps) {
        super(props_);
        this.state = {
            selectedNewSpot: null,
            nearBySpots: {
                availableSpots: [],
                refundPrice: 0,
                originalBookingPrice: 0,
                originalFriendlySpotId: ""
            },
            bookingDetailInfo: this.props.bookingDetailInfo,
            zones: [],
            selectedZone: null,
            copyNearBySpots: {
                availableSpots: [],
                refundPrice: 0,
                originalBookingPrice: 0,
                originalFriendlySpotId: ""
            },
            recurringEventType: 1,
            selectedRecurringEventType: 1,
            refreshSpotList: true,
            onLoadRecurringEventType: 1,
            showOverrideTF: false,
            modifiedPrice: "0.00",
            resetPrice: false,
            isResetPrice: false,
            hideNextFuture: false
        }
        this._spotService = new SpotService();
    }

    componentDidMount() {
        const { bookingDetailInfo, getOldBookingDetailInfo } = this.props;
        const oldBookingDetailInfo = getOldBookingDetailInfo ? getOldBookingDetailInfo() : bookingDetailInfo;
        const { reservation, bookingType, startTime, endTime, date, selectedModifyMonth } = bookingDetailInfo;
        let { onLoadRecurringEventType } = this.state;

        if (reservation) {
            const isMonthly = bookingType === BookingType.Monthly;
            let postData: any = {
                "IsForSingleDay": false,
                "CurrentMonth": false,
                "CurrentAndFutureMonths": false,
                "FutureMonths": false
            }
            let hasChanges = false;
            if (bookingDetailInfo && (
                (bookingDetailInfo.bookingType === BookingType["Hourly/Daily"] &&
                    (((bookingDetailInfo.date && oldBookingDetailInfo.date && ((
                        moment(bookingDetailInfo.date.startDate).format("MM-DD-yyyy") !==
                        moment(oldBookingDetailInfo.date.startDate).format("MM-DD-yyyy")) || (
                            moment(bookingDetailInfo.date.endDate).format("MM-DD-yyyy") !==
                            moment(oldBookingDetailInfo.date.endDate).format("MM-DD-yyyy")))) ||
                        (bookingDetailInfo.endTime && oldBookingDetailInfo.endTime &&
                            bookingDetailInfo.endTime.timeString !== oldBookingDetailInfo.endTime.timeString) ||
                        (bookingDetailInfo.startTime && oldBookingDetailInfo.startTime &&
                            bookingDetailInfo.startTime.timeString !== oldBookingDetailInfo.startTime.timeString)))) ||
                (bookingDetailInfo.bookingType === BookingType.Monthly &&
                    ((moment(bookingDetailInfo.selectedMonth).format("MM-DD-yyyy") !==
                        moment(oldBookingDetailInfo.selectedMonth).format("MM-DD-yyyy")))))) {
                hasChanges = true;
            }
            const dupDate: DateRange = { ...date };
            if (hasChanges && bookingDetailInfo.bookingType === BookingType["Hourly/Daily"] && startTime && endTime &&
                dupDate && dupDate.startDate && dupDate.endDate) {
                const copyDate = { startDate: new Date(dupDate.startDate), endDate: new Date(dupDate.endDate) };
                const bookingStartUTC = DateTimeHelper.ChangeTime(copyDate.startDate, startTime.timeString);
                const bookingEndUTC = DateTimeHelper.ChangeTime(copyDate.endDate, endTime.timeString);
                postData["bookingStartUTC"] = DateTimeHelper.LocalToUtcString(bookingStartUTC);
                postData["bookingEndUTC"] = DateTimeHelper.LocalToUtcString(bookingEndUTC);
            }
            if (isMonthly) {
                let hideNextFuture = false;
                const currentDate = new Date();
                const todayDate = moment(currentDate).toDate();
                const startDate = moment(reservation.startTimeUtc).toDate();
                const startLocalDate = moment(reservation.start).toDate();
                if (todayDate <= startDate) {//booking started
                    onLoadRecurringEventType = EnumRecurringEventType["This and future months"];
                    //checking RecurringEventType lies in current month
                    // if(selectedMonth && moment(selectedMonth).format("DD/MM/yyyy") === moment(currentDate).format("DD/MM/yyyy")){
                    //     recurringEventType = EnumRecurringEventType.Today;
                    // }
                    // else 
                    if (selectedModifyMonth && ((selectedModifyMonth > currentDate && moment(selectedModifyMonth).format("MM/yyyy") ===
                        moment(currentDate).format("MM/yyyy")) || moment(selectedModifyMonth).format("MM/yyyy") ===
                        moment(currentDate).format("MM/yyyy"))) {
                        onLoadRecurringEventType = EnumRecurringEventType["This month"];
                    }
                    else if (selectedModifyMonth && selectedModifyMonth > currentDate && moment(selectedModifyMonth).format("MM/yyyy") !==
                        moment(currentDate).format("MM/yyyy")) {
                        onLoadRecurringEventType = EnumRecurringEventType["Next month and future months"];
                    }
                }
                const recurringEventSpotType = onLoadRecurringEventType;
                postData = {
                    "IsForSingleDay": recurringEventSpotType === EnumRecurringEventType.Today,
                    "CurrentMonth": recurringEventSpotType === EnumRecurringEventType["This month"],
                    "CurrentAndFutureMonths": recurringEventSpotType === EnumRecurringEventType["This and future months"],
                    "FutureMonths": recurringEventSpotType === EnumRecurringEventType["Next month and future months"]
                }

                if (selectedModifyMonth && (hasChanges || (todayDate < startDate &&
                    ((recurringEventSpotType === EnumRecurringEventType["This month"] ||
                        recurringEventSpotType === EnumRecurringEventType["Next month and future months"] ||
                        recurringEventSpotType === EnumRecurringEventType["This and future months"]))))) {
                    postData["BookingStartUTC"] = moment(selectedModifyMonth).format('YYYY-MM-DDTHH:mm:ss.SSS');
                }
                else {
                    postData["BookingStartUTC"] = moment(currentDate).format('YYYY-MM-DDTHH:mm:ss.SSS');
                }
                //hide "Next month and future months" recurring event option for Prepone date from future month to current month
                if (hasChanges && selectedModifyMonth && selectedModifyMonth < startLocalDate && 
                    moment(selectedModifyMonth).format("MM/yyyy") !== moment(startLocalDate).format("MM/yyyy") &&
                    recurringEventSpotType !== EnumRecurringEventType["Next month and future months"]) {
                    hideNextFuture = true;
                }

                this.setState({
                    onLoadRecurringEventType,
                    recurringEventType: recurringEventSpotType,
                    selectedRecurringEventType: recurringEventSpotType,
                    hideNextFuture
                });
            }
            this.getReBookingSpot(postData);
        }
    }

    render() {
        return (
            <Dialog className="modifyBookingDialog" fullScreen open={this.props.isOpen} onClose={() => this.props.onClose(false)} TransitionComponent={Transition}>
                <Grid container style={{ height: "100%" }}>
                    <Grid item xs className="flex-display mt-2">
                        <AppBar className='add-user-appbar modifyBookingHeader' style={{ width: "99%" }}>
                            <Grid container>
                                <Grid item xs>
                                    <Toolbar>
                                        <IconButton
                                            edge="start"
                                            color="default"
                                            onClick={() => this.props.backToModify()} aria-label="close">
                                            <ArrowBackIos />
                                        </IconButton>
                                        <Typography variant="h6" className='edit-booking-appbar-title'>Modify Booking</Typography>
                                        <div className="edit-booking-modal-header">
                                            {this.renderHeaderPage()}
                                        </div>
                                    </Toolbar>
                                </Grid>
                                <Grid item className="mt-2">
                                    {this.props.bookingDetailInfo.bookingType === BookingType.Monthly && <Grid container className="mt-3 container">
                                        <Grid item style={{ color: "#323232" }}>
                                            <div className="fw-600">Changes to recurring event : </div>
                                        </Grid>
                                        <Grid item>
                                            <Link href="javascript:void(0)" className="ml-10-px fw-600" onClick={() => {
                                                this._commonRecurringEventType();
                                            }}>{EnumRecurringEventType[this.state.recurringEventType]}</Link>
                                        </Grid>
                                    </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </AppBar>
                        <div className="edit-booking-modal-box">
                            {
                                this.bindReservation()
                            }
                            {
                                this.spotSelection()
                            }
                        </div>
                    </Grid>
                    <Grid item className="b-l-1px-solid mt-2">
                        <div className="spot-payment-diff">
                            <div className="mx-0 mt-2">
                                <Grid container className="right-panel-header p-2">
                                    <Grid item xs>
                                        <h4 className="right-panel-title m-0">
                                        </h4>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            onClick={() => { 
                                                this.props.onClose(false, this.state.isResetPrice); 
                                            }}>
                                            <CloseIcon />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                            {this._getRightPanelContent()}
                        </div>
                    </Grid>
                </Grid>
            </Dialog>
        )
    }

    _getRightPanelContent = () => {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);
        let drawOpen = <div style={{ padding: 4 }}>
            <Elements stripe={stripePromise}>
                <ElementsConsumer>
                    {({ stripe, elements }) => (
                        <BookingModification
                            handelAlert={(flag: boolean) => {
                                this.setState({
                                    resetPrice: false
                                });
                            }}
                            resetPrice={this.state.resetPrice}
                            recurringEventType={this.state.recurringEventType}
                            appliedCoupon={""}
                            isOnlyRefersh={false}
                            onClose={() => {
                                this.props.onClose(false);
                            }}
                            bookingDetailInfo={{ ...this.state.bookingDetailInfo }}
                            stripe={stripe}
                            elements={elements}
                            propertyGuid={''}
                            headerText={"Booking Modifications"}
                            isNoHeader={true}
                            checkModification={(checkAllChanges?: boolean, areDateAndSpotChanges?: boolean, checkOnlyDate?: boolean) => {
                                if (this.props.getOldBookingDetailInfo) {
                                    if(checkOnlyDate){
                                        const { bookingDetailInfo } = this.state;
                                        const oldBookingDetailInfo = this.props.getOldBookingDetailInfo();
                                        if (bookingDetailInfo && ((bookingDetailInfo.bookingType === BookingType["Hourly/Daily"] &&
                                                (((bookingDetailInfo.date && oldBookingDetailInfo.date && ((
                                                    moment(bookingDetailInfo.date.startDate).format("MM-DD-yyyy") !==
                                                    moment(oldBookingDetailInfo.date.startDate).format("MM-DD-yyyy")) || (
                                                        moment(bookingDetailInfo.date.endDate).format("MM-DD-yyyy") !==
                                                        moment(oldBookingDetailInfo.date.endDate).format("MM-DD-yyyy")))) ||
                                                    (bookingDetailInfo.endTime && oldBookingDetailInfo.endTime &&
                                                        bookingDetailInfo.endTime.timeString !== oldBookingDetailInfo.endTime.timeString) ||
                                                    (bookingDetailInfo.startTime && oldBookingDetailInfo.startTime &&
                                                        bookingDetailInfo.startTime.timeString !== oldBookingDetailInfo.startTime.timeString)))) ||
                                            (bookingDetailInfo.bookingType === BookingType.Monthly &&
                                                ((moment(bookingDetailInfo.selectedMonth).format("MM-DD-yyyy") !==
                                                    moment(oldBookingDetailInfo.selectedMonth).format("MM-DD-yyyy")))))) {
                                            return true;
                                        }
                                    }
                                    else if (!areDateAndSpotChanges) {
                                        const { bookingDetailInfo } = this.state;
                                        const oldBookingDetailInfo = this.props.getOldBookingDetailInfo();
                                        let isOnlyVehicleChanged = false;
                                        let isOtherChanged = false;
                                        if (JSON.stringify(bookingDetailInfo.vehicleDetail) !== JSON.stringify(oldBookingDetailInfo.vehicleDetail)) {
                                            isOnlyVehicleChanged = true;
                                        }
                                        if (bookingDetailInfo && ((bookingDetailInfo.selectedSpot && bookingDetailInfo.selectedNewSpot &&
                                            bookingDetailInfo.selectedSpot.spotGuid !== bookingDetailInfo.selectedNewSpot.spotGuid) ||
                                            (bookingDetailInfo.bookingType === BookingType["Hourly/Daily"] &&
                                                (((bookingDetailInfo.date && oldBookingDetailInfo.date && ((
                                                    moment(bookingDetailInfo.date.startDate).format("MM-DD-yyyy") !==
                                                    moment(oldBookingDetailInfo.date.startDate).format("MM-DD-yyyy")) || (
                                                        moment(bookingDetailInfo.date.endDate).format("MM-DD-yyyy") !==
                                                        moment(oldBookingDetailInfo.date.endDate).format("MM-DD-yyyy")))) ||
                                                    (bookingDetailInfo.endTime && oldBookingDetailInfo.endTime &&
                                                        bookingDetailInfo.endTime.timeString !== oldBookingDetailInfo.endTime.timeString) ||
                                                    (bookingDetailInfo.startTime && oldBookingDetailInfo.startTime &&
                                                        bookingDetailInfo.startTime.timeString !== oldBookingDetailInfo.startTime.timeString)))) ||
                                            (bookingDetailInfo.bookingType === BookingType.Monthly &&
                                                ((moment(bookingDetailInfo.selectedMonth).format("MM-DD-yyyy") !==
                                                    moment(oldBookingDetailInfo.selectedMonth).format("MM-DD-yyyy")))))) {
                                            isOtherChanged = true;
                                        }
                                        return !checkAllChanges ? isOnlyVehicleChanged && !isOtherChanged : (!isOnlyVehicleChanged && !isOtherChanged);
                                    } else {
                                        const { bookingDetailInfo } = this.state;
                                        const oldBookingDetailInfo = this.props.getOldBookingDetailInfo();
                                        let isOtherChanged = false;
                                        if (bookingDetailInfo && ((bookingDetailInfo.selectedSpot && bookingDetailInfo.selectedNewSpot &&
                                            bookingDetailInfo.selectedSpot.spotGuid !== bookingDetailInfo.selectedNewSpot.spotGuid) ||
                                            (bookingDetailInfo.bookingType === BookingType["Hourly/Daily"] &&
                                                (((bookingDetailInfo.date && oldBookingDetailInfo.date && ((
                                                    moment(bookingDetailInfo.date.startDate).format("MM-DD-yyyy") !==
                                                    moment(oldBookingDetailInfo.date.startDate).format("MM-DD-yyyy")) || (
                                                        moment(bookingDetailInfo.date.endDate).format("MM-DD-yyyy") !==
                                                        moment(oldBookingDetailInfo.date.endDate).format("MM-DD-yyyy")))) ||
                                                    (bookingDetailInfo.endTime && oldBookingDetailInfo.endTime &&
                                                        bookingDetailInfo.endTime.timeString !== oldBookingDetailInfo.endTime.timeString) ||
                                                    (bookingDetailInfo.startTime && oldBookingDetailInfo.startTime &&
                                                        bookingDetailInfo.startTime.timeString !== oldBookingDetailInfo.startTime.timeString)))) ||
                                            (bookingDetailInfo.bookingType === BookingType.Monthly &&
                                                ((moment(bookingDetailInfo.selectedMonth).format("MM-DD-yyyy") !==
                                                    moment(oldBookingDetailInfo.selectedMonth).format("MM-DD-yyyy")))))) {
                                            isOtherChanged = true;
                                        }

                                        return isOtherChanged;
                                    }
                                }
                                return false;
                            }}
                            showModelAlert={(showModelAlert: IShowModelAlert, callback?: () => void, isSuccess?: boolean) => {
                                if (showModelAlert.yesButton && !showModelAlert.yesCallBack) {
                                    showModelAlert.yesCallBack = () => {
                                        this.props.closeModelAlert(isSuccess, this.state.recurringEventType)
                                    };
                                }
                                if (!showModelAlert.messageContent && isSuccess) {
                                    callback = () => {
                                        this.props.closeModelAlert(isSuccess, this.state.recurringEventType);
                                    }
                                }
                                this.props.showModelAlert(showModelAlert, callback);
                            }}
                            isOverridePrice={(showOverrideTF: boolean, modifiedPrice: string, callback?: () => void, isResetPrice?: boolean) => {
                                this.setState({
                                    showOverrideTF,
                                    modifiedPrice,
                                    isResetPrice
                                }, () => {
                                    this._changeRecurringEventType(callback);
                                });
                            }}
                        />
                    )}
                </ElementsConsumer>
            </Elements>
        </div>
        return drawOpen;
    }

    bindReservation() {
        return (
            <div className="eb-modal-body remove-flex-1">
                <div className="edit-booking-modal-body spot-selection">
                    <div className="spot-list clickable-rows modifyBooking">
                        {
                            this.renderReservationList()
                        }
                    </div>
                </div>
            </div>
        )
    }

    spotSelection() {
        return (
            <div className="eb-modal-body mt-4">
                <Grid container>
                    <Grid item xs className="mt-2">
                        <h5 className="h5-font">Select New Spot</h5>
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            className="mt-2 mr-2"
                            options={this.state.zones}
                            getOptionLabel={option_ => option_.zoneName}
                            style={{ width: 200 }}
                            size="small"
                            value={this.state.selectedZone}
                            renderInput={(params) => <TextField {...params} label="Zone" variant="outlined" />}
                            onChange={(e_: any, selectedZone: IPreZoneSpot | null) => {
                                let availableSpotsForZone = this.state.nearBySpots.availableSpots.filter(x => x.zoneGuid === selectedZone?.zoneGuid);
                                let selectedNewSpot = this.state.selectedNewSpot;
                                const { reservation } = this.props.bookingDetailInfo;
                                const verifyExisting = availableSpotsForZone.find((x: any) => x.spotGuid === this.props.bookingDetailInfo.reservation?.spotGuid) ?? null;
                                if (!selectedNewSpot && availableSpotsForZone && !this.props.bookingDetailInfo.selectedNewSpot) {
                                    selectedNewSpot = verifyExisting;
                                }
                                else if ((!selectedNewSpot && this.props.bookingDetailInfo.selectedNewSpot && verifyExisting &&
                                    JSON.stringify(this.props.bookingDetailInfo.selectedNewSpot) !== JSON.stringify(verifyExisting)) ||
                                    (selectedNewSpot && verifyExisting && JSON.stringify(selectedNewSpot) !== JSON.stringify(verifyExisting))) {
                                    selectedNewSpot = verifyExisting;
                                }
                                else if (!selectedNewSpot && this.props.bookingDetailInfo.selectedNewSpot) {
                                    selectedNewSpot = this.props.bookingDetailInfo.selectedNewSpot ?? null;
                                }

                                if (selectedNewSpot !== null) {
                                    let zoneSpot = availableSpotsForZone.find((x: any) => x.spotGuid === selectedNewSpot?.spotGuid);
                                    if (zoneSpot && selectedZone && selectedZone.spotGuid !== zoneSpot.spotGuid) {
                                        selectedZone = {
                                            propertyGuid: this.props.bookingDetailInfo.selectedProperty?.propertyGuid ?? "",
                                            spotGuid: zoneSpot.spotGuid,
                                            friendlySpotId: zoneSpot.friendlySpotId,
                                            otherFeatures: "",
                                            weeklyScheduleString: null,
                                            isBooked: zoneSpot.isBooked,
                                            zoneGuid: zoneSpot.zoneGuid,
                                            zoneName: zoneSpot.zoneName
                                        }
                                    }
                                    const availableSpots = ArrayHelper.sortArrayAlphaNumericObj(availableSpotsForZone.filter((x: any) => !x.isBooked && x.spotGuid !== reservation?.spotGuid), "friendlySpotId", "asc");
                                    const occupiedSpots = ArrayHelper.sortArrayAlphaNumericObj(availableSpotsForZone.filter((x: any) => x.isBooked && x.spotGuid !== reservation?.spotGuid), "friendlySpotId", "asc");
                                    availableSpotsForZone = [];
                                    availableSpotsForZone.push(...availableSpots);
                                    availableSpotsForZone.push(...occupiedSpots);
                                    verifyExisting && availableSpotsForZone.unshift(verifyExisting);
                                }
                                this.setState({
                                    selectedZone,
                                    copyNearBySpots: {
                                        ...this.state.nearBySpots,
                                        availableSpots: availableSpotsForZone
                                    },
                                    bookingDetailInfo: {
                                        ...this.props.bookingDetailInfo,
                                        selectedNewZone: selectedZone ? { ...selectedZone } : null
                                    }
                                }, () => {
                                    this.props.updateChange({
                                        ...this.state.bookingDetailInfo,
                                        selectedNewZone: selectedZone ? { ...selectedZone } : null
                                    }, true);
                                });
                            }}
                        />
                    </Grid>
                </Grid>
                <div className="edit-booking-modal-body spot-selection">
                    <div className="spot-list clickable-rows">
                        {
                            this.state.refreshSpotList ? this.renderAvailableSpotsList() : this.renderEmptySpotList()
                        }
                    </div>
                </div>
            </div>
        )
    }

    renderReservationList() {
        const { reservation } = this.props.bookingDetailInfo;
        const reservationList: IReservation[] = reservation ? [reservation] : [];
        return (
            <DataGrid
                title="reservationDetail"
                data={reservationList}
                columns={this._reservationListColumns}
                isRowsNonSelectable={true}
                isNoRowsPerPage={true}
                isNoMoreLink={true}
                isNoPagination={true}
            />
        )
    }

    renderEmptySpotList = () => {
        return (
            <DataGrid
                title="SpotDetail"
                data={[]}
                selectedItems={[]}
                columns={this._spotListColumns}
                isRowsNonSelectable={true}
                isRowsSelectableWithoutCheckBox={true}
                isNoRowsPerPage={true}
                isNoMoreLink={true}
                customClass={"modifyBookingClass"}
                compareWithStringify={true}
                groupBy={[]}
            />
        )
    }

    renderAvailableSpotsList() {
        const { changedSpotGuid } = this.props.bookingDetailInfo;
        let spotAvailable = this.state.copyNearBySpots.availableSpots;
        let selectedNewSpot = this.state.selectedNewSpot;
        let changedSpotId = selectedNewSpot ? selectedNewSpot.spotGuid : "";

        return (
            <DataGrid
                title="SpotDetail"
                data={spotAvailable}
                selectedItems={selectedNewSpot ? [selectedNewSpot] : []}
                columns={this._spotListColumns}
                isRowsNonSelectable={true}
                isRowsSelectableWithoutCheckBox={true}
                isNoRowsPerPage={true}
                isNoMoreLink={true}
                customClass={"modifyBookingClass"}
                compareWithStringify={true}
                groupBy={[
                    { label: "", key: "spotGuid", filterKey: changedSpotGuid, ignoreSorting: true, 
                    ignoreRowClickClass: changedSpotGuid === changedSpotId ? "removeRowClick" : "" },
                    { label: "AVAILABLE SPOTS", key: "isBooked", filterKey: false, 
                    ignoreKey: "spotGuid", ignoreFilterKey: changedSpotGuid },
                    {
                        label: "OCCUPIED SPOTS", key: "isBooked",
                        filterKey: true, ignoreKey: "spotGuid", ignoreFilterKey: changedSpotGuid
                    }]}
                onRowClick={(selectedItem: ISpotAvailable) => {
                    if (this.state.bookingDetailInfo.selectedSpot?.spotGuid !== selectedItem.spotGuid && selectedItem.isBooked) {
                        this.BookedSpot(selectedItem, () => {
                            this.setState({
                                selectedNewSpot: selectedItem
                            }, this.getSpotPrice);
                        }, this.props.closeModelAlert);
                    }
                    else {
                        this.setState({
                            selectedNewSpot: selectedItem
                        }, this.getSpotPrice);
                    }
                }}
            />
        )
    }

    private _commonRecurringEventType = () => {
        const { bookingDetailInfo, onLoadRecurringEventType } = this.state;
        const { reservation, bookingType } = bookingDetailInfo;

        let recurringType = [
            EnumRecurringEventType[EnumRecurringEventType.Today],
            EnumRecurringEventType[EnumRecurringEventType["This month"]],
            EnumRecurringEventType[EnumRecurringEventType["This and future months"]],
            EnumRecurringEventType[EnumRecurringEventType["Next month and future months"]]
        ];

        if (reservation) {
            const isMonthly = bookingType === BookingType.Monthly;
            if (isMonthly) {
                const currentDate = new Date();
                const todayDate = moment.utc(currentDate);
                const startDate = moment(reservation.startTimeUtc);
                if (todayDate < startDate) {
                    recurringType = onLoadRecurringEventType === EnumRecurringEventType["This month"] ?
                        [
                            EnumRecurringEventType[EnumRecurringEventType["This month"]],
                            EnumRecurringEventType[EnumRecurringEventType["This and future months"]],
                            EnumRecurringEventType[EnumRecurringEventType["Next month and future months"]]
                        ] : [
                            EnumRecurringEventType[EnumRecurringEventType["Next month and future months"]]
                        ];
                }
                //hide "This and future months" and "Next month and future months" for subscription cancelled booking
                const currentUtcDate = new Date(new Date().toISOString().split('Z')[0]);
                const startUtcDate = moment(bookingDetailInfo.selectedModifyMonth).toDate();
                if ((reservation.reservationStatus === 1 && reservation.isMonthlyReservationActive) &&
                    reservation.paymentStatus === 3 && 
                    moment(startUtcDate).format("MM/yyyy") === moment(currentUtcDate).format("MM/yyyy")) {
                    if (!reservation.isUpcomingMonthlyReservationActive) {
                        recurringType = onLoadRecurringEventType === EnumRecurringEventType["This month"] ? [
                            EnumRecurringEventType[EnumRecurringEventType["This month"]]] : [
                            EnumRecurringEventType[EnumRecurringEventType.Today],
                            EnumRecurringEventType[EnumRecurringEventType["This month"]]];
                    }
                }

                if (this.state.hideNextFuture) {
                    recurringType = recurringType.filter((x: string) => x !==
                        EnumRecurringEventType[EnumRecurringEventType["Next month and future months"]]);
                }
            }
        }
        const showAlertModel: IShowModelAlert = {
            headerTitle: "Changes to recurring event",
            messageContent: <>
                <div className="mt-2 bulk-upload-sub-content">
                    <RadioGroup>
                        {
                            recurringType.map((value_: any, index_) => {
                                const selectedRecurringEventType: any = EnumRecurringEventType[value_];
                                return (
                                    <div className="property-label" key={`property-label_${index_.toString()}`}>
                                        <FormControlLabel
                                            control={<Radio
                                                size="small"
                                                checked={this.state.selectedRecurringEventType === selectedRecurringEventType}
                                                onClick={() => {
                                                    this.setState({
                                                        selectedRecurringEventType
                                                    }, () => {
                                                        this._commonRecurringEventType();
                                                    });
                                                }}
                                                color="primary"
                                            />}
                                            label={value_}
                                        />
                                    </div>
                                )
                            }
                            )}
                    </RadioGroup>
                </div>
            </>,
            messageContentClass: "align-items-left",
            showHideAlert: true,
            showModelAlertType: ShowModelAlertType.None,
            confirmation: true,
            yesButtonText: "Apply",
            noButtonText: "Cancel",
            yesCallBack: () => {
                if (this.state.recurringEventType !== this.state.selectedRecurringEventType) {
                    if (this.state.isResetPrice && 
                        this.state.bookingDetailInfo.bookingType === BookingType.Monthly) {
                        this._changeRecurringEventType(() => {
                            this.setState({
                                resetPrice: true
                            });
                        });
                    } else {
                        const diff = DateTimeHelper.getMonthDifferenceFromCurrent(this.props.bookingDetailInfo.selectedModifyMonth);
                        if (bookingDetailInfo.bookingType === BookingType.Monthly && (!this.skipShowOverride() ||
                            !(this.state.selectedRecurringEventType !== 1 && ((diff !== 0 &&
                                this.state.selectedRecurringEventType === EnumRecurringEventType["Next month and future months"]) ||
                                this.state.selectedRecurringEventType !== EnumRecurringEventType["Next month and future months"])))) {
                            this._changeRecurringEventType(() => {
                                this.setState({
                                    resetPrice: true
                                });
                            });
                        }
                        else {
                            this._changeRecurringEventType();
                        }
                    }
                    this.setState({
                        recurringEventType: this.state.selectedRecurringEventType
                    });
                }
                else {
                    this.props.closeModelAlert();
                }
            },
            noCallBack: () => {
                this.setState({
                    selectedRecurringEventType: this.state.recurringEventType
                }, this.props.closeModelAlert);
            }
        }
        this.props.showModelAlert(showAlertModel);
    }

    private skipShowOverride = () => {
        const diff = DateTimeHelper.getMonthDifferenceFromCurrent(this.props.bookingDetailInfo.selectedModifyMonth);
        return ((diff !== 0 && this.state.selectedRecurringEventType === EnumRecurringEventType["Next month and future months"]) ||
            this.state.selectedRecurringEventType !== EnumRecurringEventType["Next month and future months"]);
    }

    private _changeRecurringEventType = (callBack?: () => void) => {
        let postData: any = {
            "IsForSingleDay": this.state.selectedRecurringEventType === EnumRecurringEventType.Today,
            "CurrentMonth": this.state.selectedRecurringEventType === EnumRecurringEventType["This month"],
            "CurrentAndFutureMonths": this.state.selectedRecurringEventType === EnumRecurringEventType["This and future months"],
            "FutureMonths": this.state.selectedRecurringEventType === EnumRecurringEventType["Next month and future months"]
        }
        const { bookingDetailInfo, showOverrideTF, modifiedPrice } = this.state;
        const { getOldBookingDetailInfo } = this.props;
        const oldBookingDetailInfo = getOldBookingDetailInfo ? getOldBookingDetailInfo() : bookingDetailInfo;
        const { bookingType, date, startTime, endTime, selectedModifyMonth } = bookingDetailInfo;
        const currentDate = new Date();
        let hasChanges = false;
        if (bookingDetailInfo && (
            (bookingDetailInfo.bookingType === BookingType["Hourly/Daily"] &&
                (((bookingDetailInfo.date && oldBookingDetailInfo.date && ((
                    moment(bookingDetailInfo.date.startDate).format("MM-DD-yyyy") !==
                    moment(oldBookingDetailInfo.date.startDate).format("MM-DD-yyyy")) || (
                        moment(bookingDetailInfo.date.endDate).format("MM-DD-yyyy") !==
                        moment(oldBookingDetailInfo.date.endDate).format("MM-DD-yyyy")))) ||
                    (bookingDetailInfo.endTime && oldBookingDetailInfo.endTime &&
                        bookingDetailInfo.endTime.timeString !== oldBookingDetailInfo.endTime.timeString) ||
                    (bookingDetailInfo.startTime && oldBookingDetailInfo.startTime &&
                        bookingDetailInfo.startTime.timeString !== oldBookingDetailInfo.startTime.timeString)))) ||
            (bookingDetailInfo.bookingType === BookingType.Monthly &&
                ((moment(bookingDetailInfo.selectedMonth).format("MM-DD-yyyy") !==
                    moment(oldBookingDetailInfo.selectedMonth).format("MM-DD-yyyy")))))) {
            hasChanges = true;
        }
        if (bookingType === BookingType["Hourly/Daily"]) {
            postData = {
                "IsForSingleDay": false,
                "CurrentMonth": false,
                "CurrentAndFutureMonths": false,
                "FutureMonths": false
            }
            const dupDate: DateRange = { ...date };
            if (hasChanges && bookingDetailInfo.bookingType === BookingType["Hourly/Daily"] && startTime && endTime &&
                dupDate && dupDate.startDate && dupDate.endDate) {
                const copyDate = { startDate: new Date(dupDate.startDate), endDate: new Date(dupDate.endDate) };
                const bookingStartUTC = DateTimeHelper.ChangeTime(copyDate.startDate, startTime.timeString);
                const bookingEndUTC = DateTimeHelper.ChangeTime(copyDate.endDate, endTime.timeString);
                postData["bookingStartUTC"] = DateTimeHelper.LocalToUtcString(bookingStartUTC);
                postData["bookingEndUTC"] = DateTimeHelper.LocalToUtcString(bookingEndUTC);
            }
        }
        else {
            if (selectedModifyMonth && (hasChanges || ((this.state.selectedRecurringEventType === EnumRecurringEventType["This month"] ||
                this.state.selectedRecurringEventType === EnumRecurringEventType["This and future months"]) &&
                moment(currentDate).format("MM/yyyy") === moment(selectedModifyMonth).format("MM/yyyy")))) {
                postData["BookingStartUTC"] = moment(selectedModifyMonth).format('YYYY-MM-DDTHH:mm:ss.SSS');
            }
            else if (selectedModifyMonth && (((this.state.selectedRecurringEventType === EnumRecurringEventType["This month"] ||
                this.state.selectedRecurringEventType === EnumRecurringEventType["This and future months"]) &&
                moment(currentDate).format("MM/yyyy") !== moment(selectedModifyMonth).format("MM/yyyy")))) {
                postData["BookingStartUTC"] = moment(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)).format('YYYY-MM-DDTHH:mm:ss.SSS');
            }
            else if (this.state.selectedRecurringEventType === EnumRecurringEventType["Next month and future months"]) {
                postData["BookingStartUTC"] = moment(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)).format('YYYY-MM-DDTHH:mm:ss.SSS');
            }
            else {
                postData["BookingStartUTC"] = moment(currentDate).format('YYYY-MM-DDTHH:mm:ss.SSS');
            }
            if (showOverrideTF) {
                postData["MonthlyOverridePrice"] = Number(modifiedPrice);
            }
        }
        this.getReBookingSpot(postData, callBack);
    }

    renderHeaderPage() {
        return (
            <div></div>
        )
    }

    private BookedSpot = (selectedItem: ISpotAvailable, yesCallBack: () => void, noCallBack: () => void) => {
        const { bookingDetailInfo } = this.state;
        this._spotService.getBookedSpotDetails({
            propertyGuid: bookingDetailInfo.selectedProperty?.propertyGuid,
            spotGuid: selectedItem?.spotGuid
        }).then(bookedDetailResp => {
            if (bookedDetailResp.ok && bookedDetailResp.status !== 204) {
                bookedDetailResp.json().then(bookedDetailResponse => {
                    const bookedDetail = bookedDetailResponse;
                    const showAlertModel: IShowModelAlert = {
                        headerTitle: "Spot Booked",
                        messageContent: <div style={{ textAlign: 'center' }}>
                            <div className="mt-3 bulk-upload-sub-content">
                                The spot you have selected is currently booked
                            </div>
                            {bookedDetail.reservationTypeId !== BookingType.Monthly && <div className={`mt-1 bulk-upload-sub-content`}>
                                by <b>{(bookedDetail.parkerFirstName + " " + bookedDetail.parkerLastName).trim()}</b> until <b>
                                    {moment(bookedDetail.bookedUntil).format('DD-MMM-YYYY hh:mm A')}</b>. Are you sure
                            </div>
                            }
                            {bookedDetail.reservationTypeId === BookingType.Monthly && <><div className={`mt-1 bulk-upload-sub-content`}>
                                by <b>{(bookedDetail.parkerFirstName + " " + bookedDetail.parkerLastName).trim()}</b> until <b>
                                    {moment(bookedDetail.bookedUntil).format('DD-MMM-YYYY hh:mm A')}</b>
                            </div>
                                <div className={`mt-1 bulk-upload-sub-content`}>
                                    <b>{bookedDetail.reservationTypeId === BookingType.Monthly ? " (" + bookedDetail.monthlyRateType + ")" : ""}</b>. Are you sure
                                </div></>
                            }
                            <div className="mt-1 bulk-upload-sub-content">
                                you want to assign the parker to <b>{bookedDetail.friendlySpotName}</b>?
                            </div>
                        </div>,
                        showHideAlert: true,
                        showModelAlertType: ShowModelAlertType.Error,
                        confirmation: true,
                        yesButtonText: "Assign",
                        noButtonText: "Cancel",
                        noCallBack: noCallBack,
                        yesCallBack: yesCallBack
                    }
                    this.props.showModelAlert(showAlertModel);
                });
            }
            else {
                if (bookedDetailResp.status === 204) { //Spot was hold by someone 15 min
                    ShowAlert("", "Spot was not available. Please try again later.", "error").then(() => {
                        this.setState({
                            selectedNewSpot: this.state.selectedNewSpot
                        });
                    });
                }
                else {
                    bookedDetailResp.json().then(error => {
                        ShowAlert("", error, "error");
                    });
                }
            }
        });
    }

    private getSpotPrice = (callBack?: () => void) => {
        const bookingDetailInfo = cloneDeep(this.state.bookingDetailInfo);
        const selectedNewSpot = cloneDeep(this.state.selectedNewSpot);
        let serviceCall = null;
        if (bookingDetailInfo.bookingType === BookingType.Monthly) {
            const availabilityRequest = {
                propertyGuid: bookingDetailInfo.selectedProperty?.propertyGuid,
                filterAmenities: "",
                monthlyRateTypeId: bookingDetailInfo.monthlyBookingType,
                zoneGuid: bookingDetailInfo.selectedNewZone?.zoneGuid,
                spotGuid: bookingDetailInfo.selectedNewSpot?.spotGuid,
                bookingStartUTC: DateTimeHelper.LocalToUtcString(bookingDetailInfo.selectedModifyMonth ?? new Date()),
                bookingEndUTC: DateTimeHelper.LocalToUtcString(bookingDetailInfo.selectedModifyMonth ?? new Date()),
                isAllowOverlap: selectedNewSpot ? selectedNewSpot.isBooked : false
            }
            serviceCall = this._spotService.getBookingPropertyAvailability(availabilityRequest);
        }
        else {
            const { date, startTime, endTime } = { ...bookingDetailInfo };
            const startDate = DateTimeHelper.ChangeTime(date.startDate ?? new Date(), startTime?.timeString ?? "00:00");
            const endDate = DateTimeHelper.ChangeTime(date.endDate ?? new Date(), endTime?.timeString ?? "11:59");
            const availabilityRequest = {
                propertyGuid: bookingDetailInfo.selectedProperty?.propertyGuid,
                filterAmenities: "",
                monthlyRateTypeId: bookingDetailInfo.monthlyBookingType,
                zoneGuid: bookingDetailInfo.selectedNewZone?.zoneGuid,
                spotGuid: bookingDetailInfo.selectedNewSpot?.spotGuid,
                bookingStartUTC: DateTimeHelper.LocalToUtcString(startDate),
                bookingEndUTC: DateTimeHelper.LocalToUtcString(endDate),
                isAllowOverlap: selectedNewSpot ? selectedNewSpot.isBooked : false
            }
            serviceCall = this._spotService.getBookingZoneAvailability(availabilityRequest);
        }

        if (serviceCall !== null) {
            serviceCall.then(res => {
                if (res.ok) {
                    res.json().then(response => {
                        const bookingPriceDetail = response;                        
                        this.setState({
                            refreshSpotList: true,
                            bookingDetailInfo: {
                                ...this.state.bookingDetailInfo,
                                selectedNewSpot,
                                totalPrice: bookingPriceDetail?.booking?.bestRate?.totalPrice.toFixed(2) ?? 0.00,
                                serviceFeePrice: bookingPriceDetail?.booking?.bestRate?.serviceFeePrice.toFixed(2) ?? 0.00,
                                totalPriceWithServiceFeeTax: bookingPriceDetail?.booking?.bestRate?.totalPriceWithServiceFeeTax.toFixed(2) ?? 0.00,
                                taxPrice: bookingPriceDetail?.booking?.bestRate?.taxPrice.toFixed(2) ?? 0.00
                            }
                        }, () => {
                            this.props.updateChange(this.state.bookingDetailInfo, true);
                            this.props.closeModelAlert();
                            callBack && callBack();
                        });
                    });
                }
                else {
                    res.json().then(response => {
                        ShowAlert("", response, "error");
                    });
                    this.setState({
                        selectedNewSpot: bookingDetailInfo.selectedNewSpot ?? null
                    });
                }
            });
        }
    }

    private getReBookingSpot = (postData: any, callBack?: () => void) => {
        const { reservation } = this.props.bookingDetailInfo;
        let reservationGuid = reservation ? reservation.reservationGuid : "";

        this._spotService.getSpotsForRebookingV2(reservationGuid, postData)
            .then(resp => {
                if (resp.ok) {
                    resp.json().then(r => {
                        if (r.availableSpots.length > 0) {
                            const zones: IPreZoneSpot[] = [];
                            let updated = r.availableSpots.map((a: ISpotAvailable, i: number) => {
                                let schedule: string[] = [];
                                if (a.schedule) {
                                    a.schedule.forEach((s, m) => {
                                        let scheduleSummary = getScheduleSummaryText(s);
                                        schedule.push(scheduleSummary[0] + "•" + scheduleSummary[1]);
                                    });
                                }
                                a.spotSchedule = schedule;

                                let spotAmenities: ISpotAmenities[] = [];

                                a.matchingAmenities.forEach(am => {
                                    let spotAmenity: ISpotAmenities = {
                                        type: "additionalAmenities",
                                        amenity: am
                                    };

                                    spotAmenities.push(spotAmenity);
                                });

                                a.additionalAmenities.forEach(am => {
                                    let spotAmenity: ISpotAmenities = {
                                        type: "matchingAmenities",
                                        amenity: am
                                    };

                                    spotAmenities.push(spotAmenity);
                                });

                                a.missingAmenities.forEach(am => {
                                    let spotAmenity: ISpotAmenities = {
                                        type: "missingAmenities",
                                        amenity: am
                                    };

                                    spotAmenities.push(spotAmenity);
                                });

                                a.spotAmenities = spotAmenities;

                                const zone: IPreZoneSpot = {
                                    friendlySpotId: a.friendlySpotId,
                                    spotGuid: a.spotGuid,
                                    zoneGuid: a.zoneGuid,
                                    zoneName: a.zoneName,
                                    isBooked: false,
                                    otherFeatures: "",
                                    propertyGuid: reservation?.propertyGuid ?? "",
                                    weeklyScheduleString: ""
                                }

                                zones.push(zone);

                                return a;
                            });
                            let spot: ISpotDetails | null = null;
                            let spotGuid = reservation?.spotGuid;                            
                            let { selectedSpot } = this.props.bookingDetailInfo;
                            let selectedZone = this.state.selectedZone ? cloneDeep(this.state.selectedZone) : cloneDeep(this.props.bookingDetailInfo.selectedZone);
                            let verifyExisting = null; 
                            if (this.props.bookingDetailInfo.bookingType === BookingType.Monthly) {
                                switch (this.state.recurringEventType) {
                                    case EnumRecurringEventType.Today:
                                        spot = reservation?.spotDetails?.find(x => x.spotRecurrenceType === EnumSpotRecurrenceType.Today) ?? null;
                                        break;
                                    case EnumRecurringEventType["This month"]:
                                    case EnumRecurringEventType["This and future months"]:
                                        spot = reservation?.spotDetails?.find(x => x.spotRecurrenceType === EnumSpotRecurrenceType.CurrentMonth) ?? null;
                                        break;
                                    default:
                                        spot = reservation?.spotDetails?.find(x => x.spotRecurrenceType === EnumSpotRecurrenceType.Future) ?? null;
                                        break;
                                }
                                spotGuid = spot ? spot.spotGuid : spotGuid;
                                verifyExisting = updated.find((x: any) => x.spotGuid === spotGuid) ?? verifyExisting;

                                if (verifyExisting && selectedSpot && selectedSpot.spotGuid !== spotGuid) {
                                    selectedSpot.friendlySpotId = verifyExisting.friendlySpotId;
                                    selectedSpot.isBooked = verifyExisting.isBooked;
                                    selectedSpot.spotGuid = verifyExisting.spotGuid;
                                    selectedSpot.zoneGuid = verifyExisting.zoneGuid;
                                    selectedSpot.zoneName = verifyExisting.zoneName;
                                }
                                if (verifyExisting && selectedZone && selectedZone.spotGuid !== spotGuid) {
                                    selectedZone.friendlySpotId = verifyExisting.friendlySpotId;
                                    selectedZone.isBooked = verifyExisting.isBooked;
                                    selectedZone.spotGuid = verifyExisting.spotGuid;
                                    selectedZone.zoneGuid = verifyExisting.zoneGuid;
                                    selectedZone.zoneName = verifyExisting.zoneName;
                                }
                            }
                            else {
                                verifyExisting = updated.find((x: any) => x.spotGuid === spotGuid) ?? verifyExisting;
                            }
                            let availableSpotsForZone = [];
                            let selectedNewSpot = this.state.selectedNewSpot; 
                            if(this.props.bookingDetailInfo.bookingType === BookingType.Monthly &&
                                ((selectedNewSpot && selectedNewSpot.spotGuid !== spotGuid) 
                            || 
                            (this.props.bookingDetailInfo.bookingType === BookingType.Monthly && this.props.bookingDetailInfo.selectedNewSpot && 
                                this.props.bookingDetailInfo.selectedNewSpot.spotGuid !== spotGuid)
                                )){
                                selectedNewSpot = verifyExisting;
                            }
                            if (!selectedNewSpot && updated && !this.props.bookingDetailInfo.selectedNewSpot) {
                                selectedNewSpot = verifyExisting;
                            }
                            else if (!selectedNewSpot && this.props.bookingDetailInfo.selectedNewSpot) {
                                selectedNewSpot = this.props.bookingDetailInfo.selectedNewSpot ?? null;
                                selectedNewSpot = updated.find((x: any) => x.spotGuid === selectedNewSpot?.spotGuid) ?? null;
                            }
                            else if (selectedNewSpot && this.props.bookingDetailInfo.selectedNewSpot) {
                                const verifySelectSpot = updated.find((x: any) => x.spotGuid === selectedNewSpot?.spotGuid);
                                selectedNewSpot = verifySelectSpot && verifySelectSpot.spotGuid === selectedNewSpot.spotGuid &&
                                    JSON.stringify(verifySelectSpot) !== JSON.stringify(selectedNewSpot) ? verifySelectSpot : selectedNewSpot;
                            }
                            if (selectedNewSpot !== null) {
                                let zoneSpot = updated.find((x: any) => x.spotGuid === spotGuid);
                                if (zoneSpot && selectedZone && selectedZone.zoneGuid !== zoneSpot.zoneGuid) {
                                    selectedZone = {
                                        propertyGuid: this.props.bookingDetailInfo.selectedProperty?.propertyGuid ?? "",
                                        spotGuid: zoneSpot.spotGuid,
                                        friendlySpotId: zoneSpot.friendlySpotId,
                                        otherFeatures: "",
                                        weeklyScheduleString: null,
                                        isBooked: zoneSpot.isBooked,
                                        zoneGuid: zoneSpot.zoneGuid,
                                        zoneName: zoneSpot.zoneName
                                    }
                                }
                                const availableSpots = ArrayHelper.sortArrayAlphaNumericObj(updated.filter((x: any) => !x.isBooked && x.spotGuid !== spotGuid && x.zoneGuid === selectedZone?.zoneGuid), "friendlySpotId", "asc");
                                const occupiedSpots = ArrayHelper.sortArrayAlphaNumericObj(updated.filter((x: any) => x.isBooked && x.spotGuid !== spotGuid && x.zoneGuid === selectedZone?.zoneGuid), "friendlySpotId", "asc");
                                availableSpotsForZone = [];
                                availableSpotsForZone.push(...availableSpots);
                                availableSpotsForZone.push(...occupiedSpots);
                                verifyExisting && availableSpotsForZone.unshift(verifyExisting);
                            }                            

                            this.setState({
                                refreshSpotList: false,
                                zones: ArrayHelper.sortArrayAlphaNumericObj(zones.filter((value, index, self) => self.findIndex(x => x.zoneGuid === value.zoneGuid) === index), "zoneName", "asc"),
                                selectedZone,
                                selectedNewSpot,
                                nearBySpots: {
                                    availableSpots: updated,
                                    refundPrice: r.refundPrice,
                                    originalBookingPrice: r.originalBookingPrice,
                                    originalFriendlySpotId: r.originalFriendlySpotId
                                },
                                copyNearBySpots: {
                                    availableSpots: availableSpotsForZone,
                                    refundPrice: r.refundPrice,
                                    originalBookingPrice: r.originalBookingPrice,
                                    originalFriendlySpotId: r.originalFriendlySpotId
                                },
                                bookingDetailInfo: this.props.bookingDetailInfo.bookingType === BookingType.Monthly ? {
                                    ...this.props.bookingDetailInfo,
                                    selectedNewSpot: selectedNewSpot ? { ...selectedNewSpot } : null,
                                    selectedNewZone: selectedZone ? { ...selectedZone } : null,
                                    changedSpotGuid: spotGuid,
                                    selectedSpot,
                                    selectedZone,
                                    nearBySpots: {
                                        availableSpots: availableSpotsForZone,
                                        refundPrice: r.refundPrice,
                                        originalBookingPrice: r.originalBookingPrice,
                                        originalFriendlySpotId: r.originalFriendlySpotId
                                    }
                                } : {
                                    ...this.props.bookingDetailInfo,
                                    selectedNewSpot: selectedNewSpot ? { ...selectedNewSpot } : null,
                                    selectedNewZone: selectedZone ? { ...selectedZone } : null,
                                    changedSpotGuid: spotGuid,
                                    nearBySpots: {
                                        availableSpots: availableSpotsForZone,
                                        refundPrice: r.refundPrice,
                                        originalBookingPrice: r.originalBookingPrice,
                                        originalFriendlySpotId: r.originalFriendlySpotId
                                    }
                                }
                            }, () => {
                                this.getSpotPrice(callBack);
                            });
                        } else {
                            ShowAlert('', 'No spots are available to change.', 'error');
                        }
                    })
                } else {
                    resp.text().then(r => {
                        let errorMsg = r != '' ? JSON.parse(r) : "Your session has expired. You will be logged out.";
                        ShowAlert("", errorMsg, "error")
                            .then(() => {
                                if (!r && this.props.onClose) {
                                    this.props.onClose(false);
                                }
                            });
                    });
                }
            });
    }
}

interface IModifyBookingProps {
    isOpen: boolean;
    onClose: (s_: boolean, showOverrideTF?: boolean) => void;
    selectedReservation?: IReservation | null;
    backToModify: () => void,
    bookingDetailInfo: IBookingDetailInfo,
    updateChange: (bookingDetailInfo: IBookingDetailInfo, updateParent: boolean) => void;
    showModelAlert: (showModelAlert: IShowModelAlert, callback?: () => void) => void;
    closeModelAlert: (isSuccess?: boolean, recurringEventType?: number) => void;
    getOldBookingDetailInfo?: () => IBookingDetailInfo
}

interface IModifyBookingState {
    nearBySpots: INearBySpots;
    copyNearBySpots: INearBySpots;
    selectedNewSpot: ISpotAvailable | null;
    bookingDetailInfo: IBookingDetailInfo;
    zones: IPreZoneSpot[];
    selectedZone: IPreZoneSpot | null;
    recurringEventType: number;
    selectedRecurringEventType: number;
    refreshSpotList: boolean;
    onLoadRecurringEventType: number;
    showOverrideTF: boolean,
    modifiedPrice: string,
    resetPrice: boolean,
    isResetPrice?: boolean,
    hideNextFuture: boolean
}