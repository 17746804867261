import * as React from "react";
import format from "date-fns/format";
import ReactTooltip from "react-tooltip";
import {
  EnumCalendarType,
  IDailySpotAggregation,
  IDailySpotAggregationMap,
  PropertyBookings,
} from "./interfaces";
import { IPropertyBasics } from "../Spots/Interfaces";
import SpotService from "../../Services/SpotService";
import { RightPanelState } from "../../Models/CommonInterfaces";
import { ShowAlert } from "../../Common/ShowAlert";

export default class BookingsDailyCalendar extends React.Component<
  IBookingsDailyCalendarProps,
  IBookingsDailyCalendarState
> {
  private reasonList: string[] = [
    "Event",
    "Maintenance",
    "Visitor use",
    "Other",
  ];
  private repeatList: string[] = [
    "Don't repeat",
    "Repeat daily",
    "Repeat weekly",
    "Repeat monthly",
  ];
  private _spotService: SpotService;
  private startTime: number = 0;

  constructor(props_: IBookingsDailyCalendarProps) {
    super(props_);

    this.state = {
      timeLineHours: this.getHeaderHours(),
      selectedDate: null,
      selectedDailySpotAggregation: null,
      bookingsForDay: {
        bookings: [],
        spots: [],
        allSpots: [],
      },
      selectedProperty: this.props.selectedProperty,
    };

    this._spotService = new SpotService();
  }

  componentDidMount() {
    this.loadBookingDetails();
  }

  componentDidUpdate() {
    if (
      this.props.viewDate != this.state.selectedDate ||
      (this.props.selectedProperty != null &&
        this.state.selectedProperty?.propertyGuid !=
          this.props.selectedProperty.propertyGuid)
    ) {
      this.setState(
        {
          selectedDate: this.props.viewDate,
          timeLineHours: this.getHeaderHours(),
          selectedDailySpotAggregation: null,
          selectedProperty: this.props.selectedProperty,
        },
        () => {
          if (this.props.onSpotEventSelected) {
            this.props.onSpotEventSelected(null, this.state.bookingsForDay);
          }
          ReactTooltip.rebuild();
          this.loadBookingDetails();
        }
      );
    } else if (
      JSON.stringify(this.props.selectedSpotAggregation) !=
      JSON.stringify(this.state.selectedDailySpotAggregation)
    ) {
      this.setState(
        {
          selectedDailySpotAggregation: this.props.selectedSpotAggregation,
        },
        () => {
          this.scrollToSpot();
        }
      );
    } else if (this.props.refresh) {
      if (this.props.refreshState) {
        this.props.refreshState(false);
      }
      ReactTooltip.rebuild();
      this.loadBookingDetails(true);
    }
  }

  private loadBookingDetails(hideLoader: boolean = false) {
    if (this.props.selectedProperty != null) {
      this._spotService
        .getBookingForDay(
          this.props.selectedProperty?.propertyGuid,
          format(this.props.viewDate, "MM-dd-yyyy"),
          hideLoader
        )
        .then((r) => r.json())
        .then((r) => {
          let bookingsForDay_: PropertyBookings = r;

          bookingsForDay_.bookings.map((e: IDailySpotAggregation) => {
            e.startTimeLocal = new Date(
              e.startTimeLocal.toString().replace("00Z", "00")
            );
            e.endTimeLocal = new Date(
              e.endTimeLocal.toString().replace("00Z", "00")
            );
            e.spotStartTimeLocal = new Date(
              e.spotStartTimeLocal.toString().replace("00Z", "00")
            );
            e.spotEndTimeLocal = new Date(
              e.spotEndTimeLocal.toString().replace("00Z", "00")
            );

            return this.getCurrentDaySpotDuration(e);
            // return e;
          });

          bookingsForDay_.bookings = this.mergeContinuousBooking(
            bookingsForDay_.bookings
          );
          bookingsForDay_.spots = this.sortSpots(bookingsForDay_.spots);

          if (this.props.match.params.source == "spot") {
            bookingsForDay_.allSpots = this.sortSpots(bookingsForDay_.allSpots);
          } else {
            bookingsForDay_.allSpots = this.sortSpots(bookingsForDay_.spots);
          }

          // For testing purpose
          // if (bookingsForDay_.bookings.length > 0) {
          //     bookingsForDay_.bookings[bookingsForDay_.bookings.length - 1].bookedType = 2;
          // }

          this.setState(
            {
              bookingsForDay: bookingsForDay_,
            },
            () => {
              if (
                this.props.selectedSpotAggregation != null &&
                this.props.rightPanelType == RightPanelState.Edit
              ) {
                var booking: IDailySpotAggregation[] = [];
                if (
                  this.props.selectedSpotAggregation?.bookedType ==
                  EnumCalendarType.Booked
                ) {
                  booking = bookingsForDay_.bookings.filter(
                    (x) =>
                      x.reservationGuid ==
                      this.props.selectedSpotAggregation?.reservationGuid
                  );
                } else {
                  let filterVal =
                    this.props.selectedSpotAggregation?.reservationGuid +
                    this.props.selectedSpotAggregation?.spotBlockGuid;
                  booking = bookingsForDay_.bookings.filter(
                    (x) => x.reservationGuid + x.spotBlockGuid == filterVal
                  );
                }

                // var booking = bookingsForDay_.bookings.filter(x => x.reservationGuid == this.props.selectedSpotAggregation?.reservationGuid);
                if (booking.length == 0) {
                  ShowAlert(
                    "",
                    "The selected block was deleted. Please reblock a new spot",
                    "error"
                  ).then(() => {
                    this.setState(
                      {
                        selectedDailySpotAggregation: null,
                      },
                      () => {
                        if (this.props.onSpotEventSelected) {
                          this.props.onSpotEventSelected(
                            null,
                            this.state.bookingsForDay
                          );
                        }
                        ReactTooltip.rebuild();
                      }
                    );
                  });
                } else {
                  ReactTooltip.rebuild();
                }
              } else if (this.props.selectedSpotAggregation != null) {
                var booking: IDailySpotAggregation[] = [];
                if (
                  this.props.selectedSpotAggregation?.bookedType ==
                  EnumCalendarType.Booked
                ) {
                  booking = bookingsForDay_.bookings.filter(
                    (x) =>
                      x.reservationGuid ==
                      this.props.selectedSpotAggregation?.reservationGuid
                  );
                } else {
                  let filterVal =
                    this.props.selectedSpotAggregation?.reservationGuid +
                    this.props.selectedSpotAggregation?.spotBlockGuid;
                  booking = bookingsForDay_.bookings.filter(
                    (x) => x.reservationGuid + x.spotBlockGuid == filterVal
                  );
                }
                // var booking = bookingsForDay_.bookings.filter(x => x.reservationGuid == this.props.selectedSpotAggregation?.reservationGuid);
                if (booking.length > 0) {
                  this.setState(
                    {
                      selectedDailySpotAggregation: booking[0],
                    },
                    () => {
                      if (this.props.onSpotEventSelected) {
                        this.props.onSpotEventSelected(
                          booking[0],
                          this.state.bookingsForDay
                        );
                      }
                      ReactTooltip.rebuild();
                    }
                  );
                } else {
                  this.setState(
                    {
                      selectedDailySpotAggregation: null,
                    },
                    () => {
                      if (this.props.onSpotEventSelected) {
                        this.props.onSpotEventSelected(
                          null,
                          this.state.bookingsForDay
                        );
                      }
                      ReactTooltip.rebuild();
                    }
                  );
                }
              } else if (this.props.match.params.source == "spot") {
                this.scrollToSpot();
                ReactTooltip.rebuild();
              } else {
                ReactTooltip.rebuild();
              }
            }
          );
        });
    }
  }

  private sortSpots(spots: IDailySpotAggregationMap[]) {
    return spots.sort(
      (a: IDailySpotAggregationMap, b: IDailySpotAggregationMap) => {
        let result: number;
        let reA = /[^a-zA-Z]/g;
        let reN = /[^0-9]/g;

        let aValue = String(a.friendlySpotId);
        let bValue = String(b.friendlySpotId);

        let aA = aValue.toLocaleLowerCase().replace(reA, "");
        let bA = bValue.toLocaleLowerCase().replace(reA, "");

        if (aA === bA) {
          var aN = parseInt(aValue.toLocaleLowerCase().replace(reN, ""), 10);
          var bN = parseInt(bValue.toLocaleLowerCase().replace(reN, ""), 10);

          result = aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
          result = aA > bA ? 1 : -1;
        }

        return result * 1;
      }
    );
  }

  private mergeContinuousBooking(bookings: IDailySpotAggregation[]) {
    let updatedBookings: IDailySpotAggregation[] = [];

    bookings.forEach((b) => {
      // var index = updatedBookings.map(item => item.reservationGuid).indexOf(b.reservationGuid);
      var index = -1;

      if (b.bookedType == EnumCalendarType.Booked) {
        index = updatedBookings
          .map((item) => item.reservationGuid)
          .indexOf(b.reservationGuid);
      } else {
        index = updatedBookings
          .map((item) => item.reservationGuid + item.spotBlockGuid)
          .indexOf(b.reservationGuid + b.spotBlockGuid);
      }

      if (index == -1) {
        updatedBookings.push(b);
      } else {
        let minute: number = 60000;
        let hour: number = 60;
        var startTime = updatedBookings[index].spotEndTimeLocal.getTime();
        var endTime = b.spotStartTimeLocal.getTime();
        var diff = endTime - startTime;
        var diffInMinutes: number = (diff / minute / hour) * 60;

        if (diffInMinutes <= 1) {
          updatedBookings[index].spotEndTimeLocal = b.spotEndTimeLocal;
        } else {
          updatedBookings.push(b);
        }
      }
    });

    return updatedBookings;
  }

  private scrollToSpot() {
    let parentElement = document.getElementById(
      "daily-view-calendar"
    ) as HTMLElement;

    if (parentElement != null) {
      if (
        this.state.selectedDailySpotAggregation != null ||
        this.props.match.params.source == "spot"
      ) {
        let cellWidth: number = 120;
        let cellHeight: number = 212;
        let elementId: string = "";

        if (this.state.selectedDailySpotAggregation != null) {
          elementId = `${
            this.state.selectedDailySpotAggregation.reservationGuid +
            this.state.selectedDailySpotAggregation.spotBlockGuid
          }-big-calendar`;
        } else {
          var spot = this.state.bookingsForDay.allSpots.findIndex(
            (x) => x.spotGuid == this.props.match.params.spotGuid
          );

          if (spot != -1) {
            elementId = `spot-cell-${spot}-0`;
          }
        }

        let slotElem = document.getElementById(elementId) as HTMLElement;

        if (slotElem != null) {
          let parentCell = slotElem.closest(".spots-cell") as HTMLElement;
          if (parentCell != null) {
            parentElement.scrollTo({
              left: parentCell.offsetLeft - cellWidth,
              top: parentCell.offsetTop - cellHeight,
            });
            // slotElem.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
          }
        }
      } else {
        parentElement.scrollTo({ left: 0, top: 0, behavior: "smooth" });
      }
    }
  }

  render() {
    return (
      <div className="d-vc">
        <div className="d-spot-header">
          <div className="dc-hours spot-cell-fixed"></div>
          {this.getHoursHeader()}
        </div>
        <div className="d-spot-body-1">{this.renderSpots()}</div>
        <ReactTooltip className="daily-tooltip" place="top" />
      </div>
    );
  }

  private getTopRanges = (setTopRanges: ISpotTopRange[], bookingItem: IDailySpotAggregation, index: number) => {
    const isAlreadExist = setTopRanges.find(topItem => topItem.reservationGuid === bookingItem.reservationGuid);
    if (!isAlreadExist) {
      const setTopRange: ISpotTopRange = {
        index,
        reservationGuid: bookingItem.reservationGuid
      }
      setTopRanges.push(setTopRange);
    }
    return setTopRanges;
  }

  private renderSpots() {
    if (this.state.bookingsForDay.allSpots.length) {
      return this.state.bookingsForDay.allSpots.map(
        (item: IDailySpotAggregationMap, index: number) => {

          let setTopRanges: ISpotTopRange[] = [];

          const bookingDetails = this.state.bookingsForDay.bookings.filter(x => x.spotGuid === item.spotGuid);
          //Check and Pushing overlap booking details
          if(bookingDetails?.length > 0){
            bookingDetails.forEach((booking) => {
              const bookings = bookingDetails.filter(x => (x.spotStartTimeLocal.getTime() >= 
              booking.spotStartTimeLocal.getTime() && x.spotEndTimeLocal.getTime() <= 
              booking.spotEndTimeLocal.getTime()) && x.reservationGuid !== booking.reservationGuid);
              if(bookings?.length > 0){
                bookings.forEach((bookingItem, index) => {
                  setTopRanges = this.getTopRanges(setTopRanges, bookingItem, index);
                });
                setTopRanges = this.getTopRanges(setTopRanges, booking, setTopRanges.length);
              }
            });
          }

          const styles: React.CSSProperties = setTopRanges.length > 0 ? {
            height: (((setTopRanges.length - 1) * 51.8) + 65) + "px",
            alignItems: "center",
            paddingTop: "0px"
          } : {
          }
          return (
          <div
            className={`d-timeline ${
              this.props.match.params.spotGuid == item.spotGuid
                ? "selected-bk"
                : ""
            }`}
          >
            <div className="spots-cell spot-cell-fixed" key={`spot${index}`} style={{...styles}}>
              <span>{item.friendlySpotId}</span>
            </div>
            {this.renderBlankElements(item, index, setTopRanges)}
          </div>
        )}
      );
    } else {
      return (
        <div className="no-spot-booking">No Spot(s) for this property</div>
      );
    }
  }

  private renderBlankElements(
    spotDetails: IDailySpotAggregationMap,
    rowIndex: number,
    setTopRanges: ISpotTopRange[]
  ) {
    let topSpotsCell = 0;
    return this.state.timeLineHours.map((item: Date, index: number) => { 
      const bindBooking = this.renderBookings(item, spotDetails, setTopRanges, topSpotsCell);
      topSpotsCell = bindBooking.topSpotsCell;
      return (      
      <div
        className="spots-cell"
        key={`spot${index}`}
        id={`spot-cell-${rowIndex}-${index}`}
      >
        {bindBooking.renderBooking}
      </div>
    )});
  }

  private getFilteredBookings = (inputDate: Date,
    spotDetails: IDailySpotAggregationMap) => {
    return this.state.bookingsForDay.bookings.filter(
      (item: IDailySpotAggregation) => {
        let hourEndTime = new Date(inputDate);
        hourEndTime.setHours(hourEndTime.getHours() + 1);

        return (
          ((item.spotStartTimeLocal != null &&
            item.spotEndTimeLocal != null &&
            item.spotStartTimeLocal.getTime() >= inputDate.getTime() &&
            item.spotStartTimeLocal.getTime() < hourEndTime.getTime()) ||
            (item.spotStartTimeLocal.getTime() < inputDate.getTime() &&
              item.spotEndTimeLocal.getTime() > inputDate.getTime() &&
              inputDate.getHours() == this.startTime)) &&
          item.spotEndTimeLocal.getTime() > inputDate.getTime() &&
          item.spotGuid == spotDetails.spotGuid
        );
      }
    );
  }

  private renderBookings(
    inputDate: Date,
    spotDetails: IDailySpotAggregationMap,
    setTopRanges: ISpotTopRange[],
    topSpotsCell: number
  ) {
    if (
      this.state.bookingsForDay.bookings &&
      this.state.bookingsForDay.bookings.length > 0
    ) {
      const filteredBookings = this.getFilteredBookings(inputDate, spotDetails);

      return {renderBooking: (
        <>
          {filteredBookings.map((x, index) => {
            const bindCell = this.renderBookingElements(x, index, inputDate, setTopRanges, topSpotsCell);
            topSpotsCell = bindCell.topSpotsCell;
            return bindCell.renderCell;
          })}
        </>
      ), topSpotsCell};

      /*if (filteredBookings.length > 0) {
                console.log(filteredBookings);
                
            }*/
    }
    return { renderBooking: null, topSpotsCell };
  }

  private renderBookingElements(
    filteredBookings: IDailySpotAggregation,
    index: number,
    inputDate: Date,
    setTopRanges: ISpotTopRange[],
    topSpotsCell: number
  ) {
    let booking: IDailySpotAggregation = filteredBookings;
    let bookingTime: string = `${format(
      booking.startTimeLocal,
      "h:mm aa"
    )} - ${format(booking.endTimeLocal, "h:mm aa")}`;

    if (!this.datesAreOnSameDay(booking)) {
      bookingTime = `${format(
        booking.startTimeLocal,
        "dd MMM yyyy h:mm aa"
      )} - ${format(booking.endTimeLocal, "dd MMM yyyy h:mm aa")}`;
    }

    var name = booking.parkerName;

    if (booking.bookedType == 2) {
      name =
        "Blocked - " +
        this.reasonList[
          booking.reasonType != null ? booking.reasonType - 1 : 0
        ];
    }

    const isHasTopRange = setTopRanges.find(x=> x.reservationGuid === filteredBookings.reservationGuid);

    const styles: React.CSSProperties = isHasTopRange ? {
      width: this.getEventWidth(booking, inputDate) + "px",
      marginLeft: this.getPosition(booking, inputDate) + "px",
      height: "50px",
      top: (topSpotsCell * 52) + "px"
    } : {
      width: this.getEventWidth(booking, inputDate) + "px",
      marginLeft: this.getPosition(booking, inputDate) + "px"
    }    

    if(isHasTopRange){
      topSpotsCell++;
    }

    return { renderCell: (
      <div
        className={
          "calendar-booked-item " +
          (booking.bookedType == 1 ? "bo-s" : "bl-s") +
          (this.isObjectSelected(booking) &&
          this.props.selectedSpotAggregation != null
            ? " selected"
            : "")
        }
        id={`${booking.reservationGuid + booking.spotBlockGuid}-big-calendar`}
        style={{
          ...styles
        }}
        // data-background-color={(booking.bookedType == 1) ? 'rgba(255, 158, 27, 1)' : 'rgba(138, 149, 158, 1)'}
        data-html={true}
        data-tip={`<div><b>${name}</b><br />${bookingTime}</div>`}
        onClick={() => {
          this.setState(
            {
              selectedDailySpotAggregation: booking,
            },
            () => {
              if (this.props.onSpotEventSelected) {
                this.props.onSpotEventSelected(
                  booking,
                  this.state.bookingsForDay
                );
              }
            }
          );
        }}
      >
        <h4>{name}</h4>
        <div>
          <span>{bookingTime}</span>
        </div>
      </div>
    ), topSpotsCell };
  }

  private isObjectSelected(booking: IDailySpotAggregation) {
    if (this.state.selectedDailySpotAggregation) {
      if (booking.bookedType == EnumCalendarType.Booked) {
        // return this.state.selectedDailySpotAggregation.reservationGuid == booking.reservationGuid;
        return (
          this.state.selectedDailySpotAggregation.reservationGuid +
            this.state.selectedDailySpotAggregation.spotBlockGuid ==
          booking.reservationGuid + booking.spotBlockGuid
        );
      } else {
        return (
          this.state.selectedDailySpotAggregation.reservationGuid +
            this.state.selectedDailySpotAggregation.spotBlockGuid ==
          booking.reservationGuid + booking.spotBlockGuid
        );
      }
    }

    return false;
  }

  private datesAreOnSameDay(booking: IDailySpotAggregation) {
    return (
      booking.startTimeLocal.getFullYear() ===
        booking.endTimeLocal.getFullYear() &&
      booking.startTimeLocal.getMonth() === booking.endTimeLocal.getMonth() &&
      booking.startTimeLocal.getDate() === booking.endTimeLocal.getDate()
    );
  }

  private getCurrentDaySpotDuration(booking: IDailySpotAggregation) {
    let dayStart: Date = new Date(
      this.props.viewDate.getFullYear(),
      this.props.viewDate.getMonth(),
      this.props.viewDate.getDate(),
      0,
      0,
      0,
      0
    );
    let dayEnd: Date = new Date(
      this.props.viewDate.getFullYear(),
      this.props.viewDate.getMonth(),
      this.props.viewDate.getDate(),
      23,
      59,
      0,
      0
    );

    var startTime = booking.spotStartTimeLocal.getTime();
    var endTime = booking.spotEndTimeLocal.getTime();

    if (startTime < dayStart.getTime()) {
      booking.spotStartTimeLocal = dayStart;
    }

    if (endTime > dayEnd.getTime()) {
      booking.spotEndTimeLocal = dayEnd;
    }

    return booking;
  }

  private getEventWidth(booking: IDailySpotAggregation, inputDate: Date) {
    let cellSize: number = 80;
    let fixedWidth: number = cellSize;
    let maxWidth: number = this.state.timeLineHours.length * fixedWidth;
    let borderWidth: number = 1;
    let minute: number = 60000;
    let hour: number = 60;
    // var startTime = booking.startTimeLocal.getTime();
    // var endTime = booking.endTimeLocal.getTime();
    var startTime = booking.spotStartTimeLocal.getTime();
    var endTime = booking.spotEndTimeLocal.getTime();
    var diff = endTime - startTime;
    var diffInHours: number = diff / minute / hour;

    var gapDiff: number = startTime - inputDate.getTime();
    var gapDiffInMinutes: number = gapDiff / minute / hour;
    let startPosition: number = this.state.timeLineHours[0].getTime();
    let blankWidthDiff: number = startTime - startPosition;
    let diffInBlankHours = blankWidthDiff / minute / hour;

    if (gapDiffInMinutes < 0) {
      diffInHours = diffInHours + gapDiffInMinutes;
    }

    fixedWidth = diffInHours * fixedWidth;

    let outputWidth = fixedWidth - borderWidth;

    let totalWidth = diffInBlankHours * cellSize + outputWidth;

    if (totalWidth > maxWidth) {
      let removeExtraWidth: number = totalWidth - maxWidth + borderWidth * 2;
      outputWidth = outputWidth - removeExtraWidth;
    }

    if (outputWidth > maxWidth) {
      outputWidth = maxWidth - borderWidth * 2;
    }

    return outputWidth;
  }

  private getPosition(booking: IDailySpotAggregation, inputDate: Date) {
    let fixedWidth: number = 76;
    //|| booking.spotStartTimeLocal.getTime() != inputDate.getTime()
    // let marginLeft: number = (booking.spotStartTimeLocal.getMinutes() == 0 || booking.spotStartTimeLocal.getDay() != inputDate.getDay()) ? 0 : (fixedWidth / 2);
    let marginLeft: number = 0;

    if (booking.spotStartTimeLocal.getMinutes() == 15) {
      marginLeft = (fixedWidth / 4) * 1;
    } else if (booking.spotStartTimeLocal.getMinutes() == 30) {
      marginLeft = (fixedWidth / 4) * 2;
    } else if (booking.spotStartTimeLocal.getMinutes() == 45) {
      marginLeft = (fixedWidth / 4) * 3;
    }

    return marginLeft;
  }

  private getHoursHeader() {
    /*let currentDate = new Date(this.props.viewDate);
        let totalHours: number = 24;
        let hours: Date[] = [];

        for (let i = 0; i < totalHours; i++) {
            currentDate.setHours(this.state.startTime + i);
            hours.push(new Date(currentDate));
        }

        return hours.map((item: Date, index: number) => (
            <div className="dc-hours"><span>{format(item, "h aa")}</span></div>
        ));*/

    return (
      <>
        {this.state.timeLineHours.map((item: Date, index: number) => (
          <div className="dc-hours" key={index}>
            <span>{format(item, "h aa")}</span>
          </div>
        ))}
        {this.props.scrollExtent && (
          <div style={{ display: "inline-block", paddingRight: "320px" }}></div>
        )}
      </>
    );
  }

  private getHeaderHours() {
    let totalHours: number = 24;
    let hours: Date[] = [];

    for (let i = 0; i < totalHours; i++) {
      let currentDate = new Date(this.props.viewDate);
      currentDate.setHours(this.startTime + i);
      hours.push(new Date(currentDate));
    }

    return hours;
  }
}

interface IBookingsDailyCalendarProps {
  viewDate: Date;
  onSpotEventSelected: (
    spotAggregation: IDailySpotAggregation | null,
    bookingsForDay: PropertyBookings
  ) => void;
  selectedSpotAggregation: IDailySpotAggregation | null;
  rightPanelStatus: boolean;
  selectedProperty: IPropertyBasics | null;
  refreshState: (rState: boolean) => void;
  refresh: boolean;
  rightPanelType: RightPanelState;
  match: {
    params: {
      source?: string;
      propertyGuid?: string;
      spotGuid?: string;
    };
  };
  scrollExtent: boolean;
}

interface IBookingsDailyCalendarState {
  bookingsForDay: PropertyBookings;
  timeLineHours: Date[];
  selectedDate: Date | null;
  selectedDailySpotAggregation: IDailySpotAggregation | null;
  selectedProperty: IPropertyBasics | null;
}

interface ISpotTopRange {
  index: number, 
  reservationGuid: string
}