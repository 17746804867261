import * as React from 'react';
import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import { IReservation } from '../Pages/Schedule/Interfaces';
import { IDateRange, INumberRange } from './DataGrid/Interfaces';
import NumberHelper from '../Helpers/NumberHelper';
import { IPropertyBasics, ISpotBlockData } from '../Pages/Spots/Interfaces';
import { DateTimeHelper } from '../Helpers/DateTimeHelper';
import { GetUserDetailsService } from '../Services/GetUserDetailsService';

const DragAndDropCalendar = withDragAndDrop(Calendar);
const locales = {
    'en-US': require('date-fns/locale/en-US'),
}
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

export default class TimeGrid extends React.Component<ISpotScheduleListProps, ISpotScheduleListState> {
    private _isComponentMount: boolean = false;
    private _lastTick: Date = new Date();
    private _slotSize: number = 30;
    private _lastSlotParkerGuid: string = "";
    private _lastSlotReservationGuid: string = "";

    constructor(props_: ISpotScheduleListProps) {
        super(props_);
        this.state = {
            reservations: this.props.reservations,
            selectedDate: this.props.selectedDate,
            blocks: this.props.blocks
        };
    }

    private _timer = () => {
        let now: Date = this._getCurrentTime();

        if (this._isComponentMount) {
            if (Math.floor(this._lastTick.getMinutes() / this._slotSize) != Math.floor(now.getMinutes() / this._slotSize)) {
                this.setState({
                    currentTimeChanged: true,
                    currentTime: now
                }, () => {
                    this.setState({
                        currentTimeChanged: false
                    });
                });
            }

            setTimeout(this._timer, 1000);
        }

        this._lastTick = now;
    }

    componentDidMount() {
        this._isComponentMount = true;
        this._timer();
    }

    componentWillUnmount() {
        this._isComponentMount = false;
    }

    componentDidUpdate(oldProps_: ISpotScheduleListProps) {
        if (this.props.selectedDate != oldProps_.selectedDate) {
            this.setState({
                selectedDate: this.props.selectedDate
            });
        }
        if (this.props.reservations != oldProps_.reservations || this.props.blocks != oldProps_.blocks) {
            this.setState({
                reservations: this.props.reservations,
                blocks: this.props.blocks
            });
        }
    }

    render() {
        if (this.state.currentTimeChanged) {
            return null;
        }

        let blockedSlots = this.props.blocks;
        let date = this.state.selectedDate;
        console.log(date);
        // let startHours = Math.floor(this.props.dayStartMinutes / 60);
        // let startMinutes = this.props.dayStartMinutes % 60;

        this._lastSlotParkerGuid = "";
        this._lastSlotReservationGuid = "";

        return (
            <DragAndDropCalendar
                selectable={false}
                date={date}
                events={blockedSlots}
                defaultView={Views.DAY}
                timeslots={1}
                step={this._slotSize}
                localizer={localizer}
                min={new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)}
                max={new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)}
                // scrollToTime={new Date(date.getFullYear(), date.getMonth(), date.getDate(), startHours, startMinutes, 0)}
                toolbar={false}
                components={{
                    timeSlotWrapper: this.TimeView,
                    timeGutterHeader: HeaderView,
                    header: HeaderView,
                    event: this.EventView
                }}
                resize={false}
                onSelectSlot={(p: any) => {
                    if (this.onSelectStart(p)) {
                        this.onSelection(p);
                    }
                }}
                onSelecting={(param_: IDateRange) => {
                    let isAllowed = this.onSelectStart(param_);
                    return isAllowed;
                }}
                getNow={() => {
                    return this._getCurrentTime();
                }}
            />
        );
    }

    onSelectStart = (param_: IDateRange): boolean => {
        if (!this.props.isSelectionAllowed) {
            return false;
        }

        if (!this.props.onSelecting()) {
            return false;
        }

        // Make sure not out of day limit
        if (this._getTimeBlockState(param_.start as Date, param_.end as Date).isBlocked) {
            return false;
        }

        if (param_.start && param_.end) {
            let now = this._getCurrentTime();
            let timeOffsetMinutes = Math.floor((now.getTime() - param_.start.getTime()) / 1000) / 60;

            // Make sure the slot isn't expired
            if (timeOffsetMinutes >= 0) {
                return false;
            }

            // Make sure the registration isn't conflicting with other registrations
            for (let reservation of this.state.reservations) {
                let startTime = Math.floor(param_.start.getTime() / 1000 / 60);
                let endTime = Math.floor(param_.end.getTime() / 1000 / 60);
                if (
                    NumberHelper.doNumberRangesConflict({
                        start: startTime,
                        end: endTime
                    }, {
                        start: Math.floor(reservation.start.getTime() / 1000 / 60),
                        end: Math.floor(reservation.end.getTime() / 1000 / 60)
                    })) {
                    return false;
                }
            }
        }

        return true;
    }

    onSelection(event_: any) {
        let start: Date = event_.start;
        let end: Date = event_.end;

        this.props.onSelect(start, end);
    }

    private EventView = (props_: { event: ISpotBlockData, title: string }) => {
        let timeRemaining = (props_.event.end.getTime() - this._getCurrentTime().getTime()) / 1000 / 60;
        if (this.props.isSelectionAllowed && timeRemaining > 0) {
            return <div className="block-entry" onClick={() => this.props.onUnblock(props_.event)} onMouseDown={ev => ev.stopPropagation()}>Unblock {format(props_.event.start, "hh:mm aa")} - {format(props_.event.end, "hh:mm aa")}</div>
        }
        else {
            return <div className="block-entry non-editable" onMouseDown={ev => ev.stopPropagation()}>Blocked {format(props_.event.start, "hh:mm aa")} - {format(props_.event.end, "hh:mm aa")}</div>
        }
    }

    private TimeView = (props_: ITimeView) => {
        let now = this._getCurrentTime();
        let slotStartMinutes = props_.value.getHours() * 60 + props_.value.getMinutes();
        let slotEndMinutes = slotStartMinutes + this._slotSize;
        let timeOffsetMinutes = Math.floor((now.getTime() - props_.value.getTime()) / 1000) / 60;
        let reservationCount = 0;
        let parkerName: string = "";
        let parkerGuid: string = "";
        let license: string = "";
        let timeSlot: INumberRange = { start: slotStartMinutes, end: slotEndMinutes };
        let blockedState = this._getTimeBlockState(props_.value);
        let isFutureSpot: boolean = false;
        let currentReservation: IReservation;
        let reservationTypeId: number = 1;
        let reservationGuid: string = "";

        //console.log(this.state.reservations);
        for (let reservation of this.state.reservations) {
            if (reservation.spotAllocations) {
                for (let allocation of reservation.spotAllocations) {
                    if (props_.value >= allocation.start && props_.value < allocation.end) {
                        reservationCount++;
                        parkerName = reservation.parkerName;
                        parkerGuid = reservation.parkerGuid;

                        currentReservation = reservation;
                        reservationTypeId = reservation.reservationTypeId;
                        reservationGuid = reservation.reservationGuid;
                        if (reservation.licensePlateNumber) {
                            license = reservation.licensePlateNumber;
                        }
                        else {
                            license = "TBD";
                        }
                    }
                }
            }

            /*if (props_.value >= reservation.start && props_.value < reservation.end) {
                reservationCount++;
                parkerName = reservation.parkerName;
                parkerGuid = reservation.parkerGuid;

                currentReservation = reservation;
                reservationTypeId = reservation.reservationTypeId;
                if (reservation.licensePlateNumber) {
                    license = reservation.licensePlateNumber;
                }
                else {
                    license = "TBD";
                }
            }*/
        }

        if (this._lastSlotParkerGuid == parkerGuid && this._lastSlotReservationGuid == reservationGuid) {
            parkerName = "";
            license = "";
        }
        if (slotEndMinutes == 1440) {
            this._lastSlotParkerGuid = "";
            this._lastSlotReservationGuid = "";
        }
        else {
            this._lastSlotParkerGuid = parkerGuid;
            this._lastSlotReservationGuid = reservationGuid;
        }

        let classNames = ["time-time-slot"];
        if (timeOffsetMinutes >= 30) {
            classNames.push("expired");
        }
        else if (timeOffsetMinutes >= 0) {
            classNames.push("current");
        }
        else {
            classNames.push("future");
            isFutureSpot = true;
        }

        if ((reservationCount > 0)) {
            classNames.push("booked");
        }
        else if (blockedState.isBlocked) {
            classNames.push("blocked");
            if (blockedState.isOverrideBlocked) {
                classNames.push("extra-blocked");
            }
        }
        else {
            classNames.push("free");
        }

        if (this.props.selectedTimeRange) {
            if (NumberHelper.doNumberRangesConflict(timeSlot, this.props.selectedTimeRange)) {
                classNames.push("temp-selected");
            }
        }

        return (
            <div className={classNames.join(" ")} onClick={(ev_: any) => this._showBookingDetails(ev_, currentReservation, isFutureSpot)}>
                <span className="time-side">{format(props_.value, "h:mm aa")}</span>
                {
                    parkerName != "" &&
                    <span className="reservation-side">License: {license}
                        {
                            (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) > -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) > -1) &&
                            <span className="label-parker-name"> &bull; {parkerName}&nbsp;</span>
                        }
                    </span>
                }
                {
                    parkerName == "" &&
                    <span className="reservation-side">&nbsp;</span>
                }
            </div>
        );
    }

    private _showBookingDetails(ev_: React.MouseEvent<HTMLButtonElement>, reservation_: IReservation, isFutureSpot: boolean) {
        if (ev_) {
            if (this.props.anchorEl) {
                let anchorEl_: HTMLButtonElement | null = ev_.currentTarget;
                this.props.anchorEl(anchorEl_, reservation_, isFutureSpot);
            }
        }
    }

    private _getTimeBlockState(slotStartTime_: Date, slotEndTime_?: Date) {
        let isBlocked = false;
        let isOverrideBlocked = false;

        if (!slotEndTime_) {
            slotEndTime_ = new Date(slotStartTime_.getFullYear(), slotStartTime_.getMonth(), slotStartTime_.getDate(), slotStartTime_.getHours(), slotStartTime_.getMinutes() + 30, 0, 0);
        }

        let timeBlock: INumberRange = {
            start: DateTimeHelper.getDayMinutes(slotStartTime_),
            end: DateTimeHelper.getDayMinutes(slotEndTime_)
        };

        let blockedDuration: INumberRange[] = this.state.blocks.map(b => {
            return {
                start: DateTimeHelper.getDayMinutes(b.start),
                end: DateTimeHelper.getDayMinutes(b.end),
                optionalTag: "blocked"
            } as INumberRange;
        }).slice(0);

        blockedDuration = blockedDuration.concat(this._makeInverseRange(this.props.validTimeRanges, 0, 1439));

        for (let block of blockedDuration) {
            if (NumberHelper.doNumberRangesConflict(timeBlock, block)) {
                isBlocked = true;
                isOverrideBlocked = block.optionalTag == "blocked";
                break;
            }
        }

        return {
            isBlocked: isBlocked,
            isOverrideBlocked: isOverrideBlocked
        };
    }

    private _makeInverseRange(range_: INumberRange[], start_: number, end_: number): INumberRange[] {
        let inversed: INumberRange[] = [];
        let timePointer = start_;

        for (let s = 0; s <= range_.length; s++) {
            let currentStart_: number;
            let currentEnd_: number;
            // Final
            if (s == range_.length) {
                currentStart_ = end_;
                currentEnd_ = end_;
            }
            else {
                let entry = range_[s];
                currentStart_ = entry.start as number;
                currentEnd_ = entry.end as number;
            }

            if (currentStart_ > timePointer) {
                inversed.push({
                    start: timePointer,
                    end: currentStart_
                });
            }

            timePointer = currentEnd_;
        }

        return inversed;
    }

    private _getCurrentTime(): Date {
        return DateTimeHelper.convertToTimezone(new Date(), this.props.selectedProperty?.propertyTimeZone);
        // Just a place holder function to override current time
        // return DateTimeHelper.convertToTimezone(((window as any)["now"] ? (window as any)["now"] : new Date()) as Date, this.props.selectedProperty?.propertyTimeZone);
    }
}

interface ITimeView {
    value: Date
}

class HeaderView extends React.Component<IHeaderView> {
    render() {
        return "Bookings";
    }
}

interface IHeaderView {
    value: Date
}

interface ISpotScheduleListProps {
    isSelectionAllowed: boolean;
    reservations: IReservation[];
    blocks: ISpotBlockData[];
    selectedDate: Date;
    selectedTimeRange?: INumberRange;
    validTimeRanges: INumberRange[];
    onChange: (start_: Date, end_: Date) => void;
    onSelect: (start_: Date, end_: Date) => void;
    onSelecting: () => boolean;
    onUnblock: (block_: ISpotBlockData) => void;
    anchorEl: (anchorEl_: HTMLButtonElement | null, reservation: IReservation, isFuture: boolean) => void;
    selectedProperty: IPropertyBasics | null;
}

interface ISpotScheduleListState {
    reservations: IReservation[];
    blocks: ISpotBlockData[];
    selectedDate: Date;
    draggedEvent?: IReservation;
    currentTimeChanged?: boolean;
    currentTime?: Date;
}