export interface IShowModelAlert{
    showModelAlertType: number,
    headerTitle: string,
    messageContent: any,
    confirmation?: boolean,
    yesButton?: boolean,
    noButton?: boolean,
    yesButtonColor?: 'inherit' | 'primary' | 'secondary' | 'default',
    yesCallBack?: () => void,
    noButtonColor?: 'inherit' | 'primary' | 'secondary' | 'default',
    yesButtonText?: string,
    noButtonText?: string,
    noCallBack?: () => void,
    showHideAlert: boolean,
    closeCallBack?: () => void,
    iconClass?: string,
    buttonDivClass?: string,
    messageContentClass?: string
}

export enum ShowModelAlertType {
    None = 0,
    Error,
    Warning,
    Info,
    Success
}