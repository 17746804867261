import { Button, Grid, IconButton, TextField, Tooltip } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import { CardElement } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { cloneDeep } from "lodash";
import moment from 'moment';
import React from "react";
import { ShowCustomBlocker } from "../Components/LoaderComponent";
import { DateTimeHelper } from "../Helpers/DateTimeHelper";
import { BookingType, IBookingDetailInfo } from "../Pages/BookingsCalendar/interfaces";
import { EnumRecurringEventType } from "../Pages/Schedule/Interfaces";
import SpotService from "../Services/SpotService";
import "./BookingSummary.css";
import Notes from "./Notes";
import OverrideTF from "./OverrideTF";
import OverridePopup from "./Popup/OverridePopup";
import SelectCard from './SelectCard';
import { ShowAlert } from "./ShowAlert";
import ShowWarningMessage from "./ShowWarningMessage";
import TaxandWaivedoff from "./TaxandWaivedoff";
import { IShowModelAlert, ShowModelAlertType } from "./interface";

interface CountryType {
    image_url: string;
    card_name: string;
    card_number: string;
    suggested?: boolean;
}
export default class BookingModification extends React.Component<IBookingModificationProps, IBookingModificationState> {
    private spotService: SpotService;
    CheckoutOptionBookingAmount = 0
    CheckIntialRun = 0


    constructor(props_: IBookingModificationProps) {
        super(props_);

        this.state = {
            defaultSelectedCard: {
                image_url: "https://seeklogo.com/images/V/visa-logo-4E989827A8-seeklogo.com.png", card_name: "Visa", card_number: "0033"
            },
            showPromoCode: false,
            showOverrideTF: false,
            hourlyPrice: '',
            modifiedPrice: '',
            propertyGuid: this.props.propertyGuid,
            serviceFee: 0,
            tax: 0,
            price: 0,
            orderTotal: 0,
            intialPrice: 0,
            maxserviceFree: 0,
            bookingType: "",
            showNewCardScreen: false,
            showAddNewCard: false,
            marginOfShowCard: 10,
            showDropDown: false,
            cardArray: [],
            selectedCardDisplayName: '',
            selectedCardId: '',
            creditButtonText: 'Remove',
            couponButtonText: 'Remove',
            credit: 0,
            coupon: 0,
            isCreditBT: false,
            isCouponBT: false,
            showCredit: false,
            showCoupon: false,
            chooseCredit: true,
            chooseCoupon: true,
            bookingDetailInfo: this.props.bookingDetailInfo,
            manualSelectedCoupon: '',
            showOnPopUp: false,
            dropDownSelected: false,
            orderBookingAmount: 0,
            waivedOff: 0,
            couponIds: [],
            monthlyOverridOptions: 0,
            creditIds: [],
            discountObj: {},
            overridePrice: 0,
            originalBookingPrice: 0,
            newBookingPrice: 0,
            adjustmentPrice: 0,
            notes: "",
            prorate: 0,
            isOverrided: false,
            //showDrawerAlert:this.props.showDrawerAlert
        }
        this.spotService = new SpotService();
    }

    private getCheckoutOption = async (modifiedPrice?: string, forcePricetoZero?: boolean) => {
        //added because of did update undefined.
        if (!modifiedPrice && this.state.newBookingPrice === undefined) {
            return
        }

        let bookingAmount = modifiedPrice ? Number(modifiedPrice) : Number(this.state.newBookingPrice)
        const bookingDetailInfo = this.props.bookingDetailInfo;
        const propertyGuid = bookingDetailInfo.selectedProperty?.propertyGuid;
        const startTime = moment(bookingDetailInfo.date.startDate).format('YYYY-MM-DD');
        const endTime = moment(bookingDetailInfo.date.endDate).format('YYYY-MM-DD');
        //manualSelectedCoupon always be empty string..
        const Coupon = this.state.manualSelectedCoupon
        const parkerEncodeGuid = bookingDetailInfo.userDetail.parkerEncodeGuid
        const AccessToken = this.getAccessToken();
        //Price without tax from spot details
        let proratedAmount = this.state.prorate;
        const diff = DateTimeHelper.getMonthDifferenceFromCurrent(this.props.bookingDetailInfo.selectedModifyMonth);
        //Skip payment for future months. 
        if(bookingDetailInfo.bookingType === BookingType.Monthly && (!this.skipShowOverride() ||
         !(this.props.recurringEventType !== 1 && ((diff !== 0 && this.props.recurringEventType === 4) ||
        this.props.recurringEventType !== 4)))){
            proratedAmount = (this.props.recurringEventType === 4 || this.state.monthlyOverridOptions === 2) ? 
            (bookingDetailInfo.reservation?.paidForCurrentMonth ?? 0) 
            : proratedAmount;            
        }
         //proratedAmount : Price without tax from spot details - fullPrice
        let subtractNewAndOriginal = bookingDetailInfo.bookingType === BookingType.Monthly ? proratedAmount - this.state.originalBookingPrice : bookingAmount - this.state.originalBookingPrice
        if (forcePricetoZero) {
            subtractNewAndOriginal = 0
        }
        this.setState({
            adjustmentPrice: subtractNewAndOriginal < 0 ? subtractNewAndOriginal : 0
        }, () => {
            this.CheckoutOptionBookingAmount = subtractNewAndOriginal > 0 ? subtractNewAndOriginal : 0
            this.spotService.checkoutOption({
                "PropertyGuid": propertyGuid, "StartTime": startTime, "EndTime": endTime, 
                "BookingAmount": this.CheckoutOptionBookingAmount, "Coupon": Coupon, "IsNoCoupon": false,
                "ParkerEncodeGuid": parkerEncodeGuid
            }, AccessToken)
                .then(r => r.json())
                .then(r => {
                    let data = []
                    console.log("response", r);
                    console.log("areCouponsAvailable", r.areCouponsAvailable);
                    if(r.hasErrorLoadingVouchers && this.CheckIntialRun == 0){
                        this.CheckIntialRun = 1
                        ShowAlert("GrydPark", `Sorry! We are unable to access your credits and coupons. But, you can still complete your purchase. If you wish to apply credits or coupons, please try again later.`, "warning");

                    }
                    this.setState({
                        isCreditBT: r.areCreditsAvailable,
                        isCouponBT: r.areCouponsAvailable
                    }, () => {

                    })

                    console.log("booking type", this.state.bookingType);
                    if (this.state.chooseCredit && this.state.chooseCoupon) {
                        if (r.areCouponsAvailable && r.areCreditsAvailable) {
                            // creditCoupon
                            data = r['creditCoupon']
                            this.setState({
                                credit: data['credit'],
                                coupon: data['coupon'],
                                showCredit: data['credit'] > 0 ? true : false,
                                showCoupon: data['coupon'] > 0 ? true : false
                            })
                        } else if (r.areCouponsAvailable) {
                            data = r['coupon']
                            if (!r.areCreditsAvailable) {
                                this.setState({
                                    chooseCredit: false,
                                })
                            }
                            this.setState({
                                coupon: data['coupon'],
                                showCoupon: true,

                            })
                        }
                        else if (r.areCreditsAvailable) {
                            data = r['credit']
                            if (!r.areCouponsAvailable) {
                                this.setState({
                                    chooseCoupon: false,
                                })
                            }
                            this.setState({
                                credit: data['credit'],
                                showCredit: true,
                            })
                        }
                        else {
                            data = r['noDiscount']
                            // this.setState({
                            //     credit: 0,
                            //     coupon: 0
                            // })
                        }
                        this.setState({
                            serviceFee: data['serviceFee'],
                            tax: data['tax'],
                            orderTotal: data['orderTotal'],
                            orderBookingAmount: data['bookingAmount'],
                            price: data['bookingAmount'],
                            waivedOff: data['waivedOff'],
                            couponIds: data['couponIds'],
                            creditIds: data['creditIds'],
                            discountObj: data
                        })


                    }
                    else if (this.state.chooseCoupon && r.areCouponsAvailable) {
                        if (r.areCouponsAvailable) {
                            data = r['coupon']
                            //console.log('choose is,coupon', data)

                            this.setState({
                                coupon: data['coupon'],
                                credit: data['credit'],
                                showCoupon: true,
                                showCredit: r.areCreditsAvailable,
                                serviceFee: data['serviceFee'],
                                tax: data['tax'],
                                orderTotal: data['orderTotal'],
                                orderBookingAmount: data['bookingAmount'],
                                price: data['bookingAmount'],
                                waivedOff: data['waivedOff'],
                                couponIds: data['couponIds'],
                                creditIds: data['creditIds'],
                                discountObj: data
                            })

                        }

                    }
                    else if (this.state.chooseCredit && r.areCreditsAvailable) {
                        data = r['credit']
                        this.setState({
                            coupon: data['coupon'],
                            credit: data['credit'],
                            showCoupon: r.areCouponsAvailable,
                            showCredit: true,
                            serviceFee: data['serviceFee'],
                            tax: data['tax'],
                            orderTotal: data['orderTotal'],
                            orderBookingAmount: data['bookingAmount'],
                            price: data['bookingAmount'],
                            waivedOff: data['waivedOff'],
                            couponIds: data['couponIds'],
                            creditIds: data['creditIds'],
                            discountObj: data
                        })
                    }
                    else if (!this.state.chooseCoupon && !this.state.chooseCredit) {
                        data = r['noDiscount']
                        this.setState({
                            serviceFee: data['serviceFee'],
                            tax: data['tax'],
                            orderTotal: data['orderTotal'],
                            orderBookingAmount: data['bookingAmount'],
                            price: data['bookingAmount'],
                            coupon: data['coupon'],
                            credit: data['credit'],
                            waivedOff: data['waivedOff'],
                            couponIds: data['couponIds'],
                            creditIds: data['creditIds'],
                            discountObj: data
                        })
                    }

                   // hide credit coupon button when zero
                    if(this.CheckoutOptionBookingAmount <= 1){
                        this.setState({
                            showCoupon:false,
                            showCredit:false
                        })
                    }
                });
        });
    }

    private getCardList() {
        const bookingDetailInfo = this.props.bookingDetailInfo;
        const AccessToken = this.getAccessToken()
        this.spotService.getCardList(bookingDetailInfo.userDetail.parkerEncodeGuid, AccessToken)
            .then(r => r.json())
            .then(r => {
                console.log("getCardList", r);
                this.setState({
                    cardArray: r.cards
                }, () => {
                    console.log('card array', this.state.cardArray);
                })
            })
    }
    private getMaxServiceFee() {
        const AccessToken = this.getAccessToken();
        this.spotService.getMaxServerFee(AccessToken)
            .then(r => r.json())
            .then(r => {
                console.log("getMaxServerFee", r);
                this.setState({
                    maxserviceFree: r
                })
            })
    }

    componentDidMount() {
        const bookingDetailInfo = this.props.bookingDetailInfo;
        let amount = this.getAmount(bookingDetailInfo);
        this.setState({
            //originalBookingPrice: (this.props.bookingDetailInfo.reservation?.reservationAmount ?? 0),
            originalBookingPrice: amount,
            newBookingPrice: bookingDetailInfo.bookingType === BookingType['Monthly'] ? (this.props.bookingDetailInfo.selectedNewSpot?.fullPrice ?? 0) : (this.props.bookingDetailInfo.selectedNewSpot?.priceWithoutTax ?? 0),
            prorate: (this.props.bookingDetailInfo.selectedNewSpot?.priceWithoutTax ?? 0),

            //  monthly book call full_price instead pricewithouttax 
            // add prorate for with pricewitouttax
            // call checkoutoption after componentdidupdate


        }, () => {
            this.getMaxServiceFee()
            this.getCheckoutOption();
            this.getCardList();
        })
        console.log('called componentDidMount bookingDetailInfo', bookingDetailInfo);


        console.log('moment(bookingDetailInfo.date.startDate).diff(moment(bookingDetailInfo.date.endDate)', moment(bookingDetailInfo.date.endDate).diff(moment(bookingDetailInfo.date.startDate), 'days'));
    }

    private getAmount = (bookingDetailInfo: IBookingDetailInfo) => {        
        let amount = bookingDetailInfo.reservation?.reservationAmountWithoutTax ?? 0;
        if (this.props.bookingDetailInfo.bookingType === BookingType.Monthly) {
            switch (this.props.recurringEventType) {
                case EnumRecurringEventType.Today:
                    amount = bookingDetailInfo.reservation?.paidForToday ?? 0;
                    break;
                case EnumRecurringEventType["This month"]:
                    amount = bookingDetailInfo.reservation?.paidForCurrentMonth ?? 0;
                    break;
                default:
                    amount = bookingDetailInfo.reservation?.reservationAmountWithoutTax ?? 0;
                    break;
            }
        }
        return amount;
    }

    componentDidUpdate(oldProps_: IBookingModificationProps) {
        if (this.props.resetPrice) {
            this.setState({
                isOverrided: false,
                showOverrideTF: false,
                monthlyOverridOptions: 0,
                modifiedPrice: ""
            }, () => {
                this.props.handelAlert(false);
                this.props.isOverridePrice(this.state.showOverrideTF,
                    this.state.modifiedPrice, this.getCheckoutOption);
            });
        }
        if (!this.props.resetPrice && (this.props.bookingDetailInfo.selectedNewSpot?.priceWithoutTax != oldProps_.bookingDetailInfo.selectedNewSpot?.priceWithoutTax ||
            this.props.bookingDetailInfo.nearBySpots?.originalBookingPrice != oldProps_.bookingDetailInfo.nearBySpots?.originalBookingPrice)) {

            let amount = this.getAmount(this.props.bookingDetailInfo);
            this.setState({
                newBookingPrice: this.props.bookingDetailInfo.bookingType === BookingType['Monthly'] ? 
                (this.props.bookingDetailInfo.selectedNewSpot?.fullPrice ?? 0) : 
                (this.props.bookingDetailInfo.selectedNewSpot?.priceWithoutTax ?? 0),
                prorate: (this.props.bookingDetailInfo.selectedNewSpot?.priceWithoutTax ?? 0),
                originalBookingPrice: amount,

            }, () => {
                this.getMaxServiceFee();
                this.getCheckoutOption();
                this.getCardList();
            })
        }
    }

    showOverrideMonthlyOption() {
        let monthlyOverridOptions = 0;
        switch (this.props.recurringEventType) {
            case EnumRecurringEventType["This month"]:
                monthlyOverridOptions = 1;
                break;
            case EnumRecurringEventType["This and future months"]:
                monthlyOverridOptions = 3;
                break;
            case EnumRecurringEventType["Next month and future months"]:
                monthlyOverridOptions = 2
                break;
        }
        const showAlertModel: IShowModelAlert = {
            headerTitle: `Please choose override options`,
            messageContent:
                <OverridePopup
                    showWaringMessage={true}
                    warningMessage={<div style={{ fontSize: 12, fontWeight: 500, letterSpacing: 'normal' }}>The price override should be same as the global override.
                        If you would like to change the selection, please modify <b>Changes to recurring event</b> and override the price the again.</div>}
                    value={monthlyOverridOptions}
                    startDate={this.props.bookingDetailInfo.selectedModifyMonth}
                    disabledOptions={["1", "2", "3"].filter(x => x !== String(monthlyOverridOptions))}
                    handelCancel={() => {
                        this.props.handelAlert(false);
                        this.setState({
                            showOverrideTF: false,
                            modifiedPrice: "",
                            isOverrided: false,
                            monthlyOverridOptions: 0
                        }, () => {
                            this.props.isOverridePrice(this.state.showOverrideTF,
                                this.state.modifiedPrice, () => {
                                    this.getCheckoutOption();
                                    this.props.showModelAlert({
                                        headerTitle: "",
                                        messageContent: "",
                                        showHideAlert: false,
                                        showModelAlertType: ShowModelAlertType.Info
                                    });
                                });
                        });
                    }}
                    handelSubmit={(option: number) => {
                        this.props.handelAlert(false);
                        if (option !== 0) {
                            this.setState({
                                monthlyOverridOptions: option,
                                isOverrided: true
                            }, () => {
                                //MonthlyOverridePrice variable will not be send for forword months(with other options - Url - modify-booking/search-spot)                                 
                                this.props.isOverridePrice(this.skipShowOverride() ? this.state.showOverrideTF : false,
                                    this.state.modifiedPrice, () => {
                                        this.getCheckoutOption(this.state.modifiedPrice);
                                        this.props.showModelAlert({
                                            headerTitle: "",
                                            messageContent: "",
                                            showHideAlert: false,
                                            showModelAlertType: ShowModelAlertType.Info
                                        });
                                    }, this.state.showOverrideTF);
                            });
                        }
                    }
                    }
                />
            ,
            showHideAlert: true,
            showModelAlertType: ShowModelAlertType.None,
            buttonDivClass: "removeDevHeight"
        }
        this.props.showModelAlert(showAlertModel);
    }

    private skipShowOverride = () => {
        const diff = DateTimeHelper.getMonthDifferenceFromCurrent(this.props.bookingDetailInfo.selectedModifyMonth);
        return ((diff !== 0 && this.state.monthlyOverridOptions === 2) ||
            this.state.monthlyOverridOptions !== 2);
    }

    render() {
        const bookingDetailInfo = this.props.bookingDetailInfo;
        let proratedAmount = this.state.prorate;
        const diff = DateTimeHelper.getMonthDifferenceFromCurrent(this.props.bookingDetailInfo.selectedModifyMonth);
        if (bookingDetailInfo.bookingType === BookingType.Monthly && (!this.skipShowOverride() ||
            !(this.props.recurringEventType !== 1 && ((diff !== 0 && this.props.recurringEventType === 4) ||
                this.props.recurringEventType !== 4)))) {
            proratedAmount = (this.props.recurringEventType === 4 || this.state.monthlyOverridOptions === 2) ?
                (bookingDetailInfo.reservation?.paidForCurrentMonth ?? 0)
                : proratedAmount;
        }
        const isFuture = ((!this.skipShowOverride() && this.state.monthlyOverridOptions === 2) || 
        (!(this.props.recurringEventType !== 1 && ((diff !== 0 && this.props.recurringEventType === 4) ||
        this.props.recurringEventType !== 4)) && this.props.recurringEventType === 4));
        let futurePrice = this.state.intialPrice ? this.state.intialPrice.toFixed(2) : this.state.newBookingPrice.toFixed(2);
        futurePrice = this.state.isOverrided && this.state.modifiedPrice ? this.state.modifiedPrice : futurePrice;
        return (
            <>
                {
                    this.state.showNewCardScreen ? (
                        <>
                            <Grid style={{ marginTop: '-62px' }} container className="right-panel-header p-2">
                                <Grid item xs>
                                    <Grid item xs={2} className="MuiGrid-grid-custom">
                                        <Tooltip title="Back">
                                            <Grid container className="mt-4-px">
                                                <Grid item>
                                                    <IconButton aria-label="Back" size="small" onClick={() => {
                                                        this.setState({
                                                            showNewCardScreen: false
                                                        })
                                                    }}>
                                                        <ArrowBackIos />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>                            
                            <div className="root_view" style={{ display: 'flex', 'flexDirection': 'column', 'overflowY': 'scroll', height: '80vh', maxWidth: '370px' }}>
                                <h1 className="big_title">Payment information</h1>
                                <div className="flex_row" style={{ 'marginTop': '30px' }}>
                                    <div style={{ 'width': '100%' }}>
                                        <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                                            <form id="payment-form">
                                                <TextField fullWidth
                                                    placeholder="Cardholder Name"
                                                    variant="outlined"
                                                >
                                                </TextField>
                                                <div style={{ marginTop: '20px' }}>
                                                    <CardElement id="card-element" />
                                                </div>
                                                {
                                                    this.props.bookingDetailInfo.bookingType === BookingType.Monthly && (
                                                        <div style={{ marginTop: '20px' }}>
                                                            <ShowWarningMessage message={'The new card that is being added will not be linked to the monthly subscription'}></ShowWarningMessage>
                                                        </div>
                                                    )
                                                }
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="flex_colum mt-4" style={{ alignContent: 'center', flex: '10%', backgroundColor: 'white', width: '100%', height: '50px' }}>
                                <Button id="submit" className="title" style={{ 'backgroundColor': '#00AF85', alignSelf: 'center', width: '100%', 'color': 'white', }}
                                    onClick={() => {
                                        this.bookTheSpot(true);
                                    }}
                                >Update Booking</Button>
                            </div>
                        </>
                    ) : (
                        <>
                            {!this.props.isNoHeader && <Grid container className="right-panel-header p-2">
                                {!this.state.showNewCardScreen && <Grid item xs>
                                    <Grid item xs={2} className="MuiGrid-grid-custom">
                                        <Tooltip title="Back">
                                            <Grid container className="mt-4-px">
                                                <Grid item>

                                                </Grid>
                                            </Grid>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                }
                                <Grid item>
                                    <Button
                                        onClick={() => {
                                            this.props.onClose();
                                        }}
                                    >
                                        <CloseIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                            }
                            <div className="root_view" style={{ display: 'flex', 'flexDirection': 'column', 'overflowY': 'scroll', height: '80vh', maxWidth: '370px' }}>
                                <div style={{}}>
                                    <h1 className="big_title">{this.props.headerText ? this.props.headerText : "Booking Modification"}</h1>
                                    <>
                                        <div className="flex_row" style={{ 'marginTop': '10px' }}>
                                            <div style={{ 'width': '70%' }}>
                                                <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                                                    <label className="title_header" style={{ overflowWrap: 'break-word' }}>{'New Booking'}<br />{this.props.bookingDetailInfo.selectedNewSpot?.friendlySpotId},{this.props.bookingDetailInfo.selectedNewSpot?.zoneName}</label>
                                                </div>
                                                <label className="green_underline" style={{}} onMouseDown={() => {
                                                        this.setState({
                                                            intialPrice: this.state.newBookingPrice
                                                        }, () => {
                                                            if (this.state.showOverrideTF) {
                                                                this.setState({
                                                                    isOverrided: false,
                                                                    showOverrideTF: false,
                                                                    modifiedPrice: String(this.state.newBookingPrice.toFixed(2)),
                                                                    monthlyOverridOptions: 0
                                                                }, () => {
                                                                    this.props.isOverridePrice(this.state.showOverrideTF,
                                                                        this.state.modifiedPrice, this.getCheckoutOption);
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    showOverrideTF: true,
                                                                    modifiedPrice: String(this.state.newBookingPrice.toFixed(2)),
                                                                    isOverrided: false,
                                                                    monthlyOverridOptions: 0
                                                                })
                                                            }
                                                        });
                                                    }}>{this.state.showOverrideTF ? `Reset Price` : `Override Price`} </label>
                                            </div>
                                            <div style={{ 'width': '35%', marginTop: '10px' }}>
                                                {
                                                    this.state.showOverrideTF && !this.state.isOverrided && (
                                                        <OverrideTF
                                                            modifiedPrice={this.state.modifiedPrice}
                                                            handelOnFocus={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {}}
                                                            handelOnChange={(e_: React.ChangeEvent<HTMLInputElement>) => {
                                                                let modifiedPrice = this.state.modifiedPrice;
                                                                if (Number(e_.target.value) <= this.state.maxserviceFree) {
                                                                    modifiedPrice = e_.target.value;
                                                                }
                                                                this.setState({
                                                                    modifiedPrice
                                                                });
                                                            }}
                                                            handelOnBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                                                    console.log("end editing current modifity", Number(this.state.modifiedPrice).toFixed(2));
                                                                    this.setState({
                                                                        modifiedPrice: Number(this.state.modifiedPrice).toFixed(2)
                                                                    }, () => {
                                                                        if (this.state.modifiedPrice == '') {
                                                                            this.setState({
                                                                                showOverrideTF: false,
                                                                                modifiedPrice: String(this.state.price)
                                                                            })
                                                                        } else if (this.state.intialPrice.toFixed(2) == (Number(this.state.modifiedPrice).toFixed(2))) {
                                                                            this.setState({
                                                                                showOverrideTF: false,
                                                                                modifiedPrice: '',

                                                                            }, () => {
                                                                                console.log("modifiedPrice", this.state.modifiedPrice)
                                                                                this.setState({
                                                                                    chooseCredit: true,
                                                                                    chooseCoupon: true
                                                                                }, () => {
                                                                                    this.props.isOverridePrice(this.state.showOverrideTF,
                                                                                        this.state.modifiedPrice, this.getCheckoutOption);
                                                                                })
                                                                            })
                                                                        }
                                                                        else {

                                                                            if (this.state.intialPrice.toFixed(2) != (Number(this.state.modifiedPrice).toFixed(2))) {
                                                                                const showAlertModel: IShowModelAlert = {
                                                                                    headerTitle: "Override Price?",
                                                                                    messageContent: <div style={{ textAlign: 'center' }}>
                                                                                        <div className="mt-3 bulk-upload-sub-content">
                                                                                            Are you sure you want to override the booking <br /> price from <b> ${this.state.intialPrice.toFixed(2)} to ${Number(this.state.modifiedPrice).toFixed(2)}</b>?
                                                                                        </div>
                                                                                    </div>,
                                                                                    showHideAlert: true,
                                                                                    showModelAlertType: ShowModelAlertType.Error,
                                                                                    confirmation: true,
                                                                                    yesButtonText: "Yes",
                                                                                    noButtonText: "No",
                                                                                    yesCallBack: () => {
                                                                                        if (bookingDetailInfo.bookingType === BookingType['Monthly'] && this.props.recurringEventType !== 1) {
                                                                                            this.showOverrideMonthlyOption();
                                                                                        }
                                                                                        else {
                                                                                            this.setState({
                                                                                                isOverrided: true,
                                                                                                monthlyOverridOptions: 0
                                                                                            });
                                                                                            this.props.isOverridePrice(this.state.showOverrideTF,
                                                                                                this.state.modifiedPrice, () => {
                                                                                                    this.getCheckoutOption(this.state.modifiedPrice);
                                                                                                }, this.state.showOverrideTF);
                                                                                        }
                                                                                    },
                                                                                    noCallBack: () => {
                                                                                        this.setState({
                                                                                            showOverrideTF: false,
                                                                                            modifiedPrice: "",
                                                                                            isOverrided: false,
                                                                                            monthlyOverridOptions: 0
                                                                                        }, () => {
                                                                                            this.props.isOverridePrice(this.state.showOverrideTF,
                                                                                                this.state.modifiedPrice, () => {
                                                                                                    this.getCheckoutOption();
                                                                                                    this.props.showModelAlert({
                                                                                                        headerTitle: "",
                                                                                                        messageContent: "",
                                                                                                        showHideAlert: false,
                                                                                                        showModelAlertType: ShowModelAlertType.Info
                                                                                                    });
                                                                                                });
                                                                                        });
                                                                                    }
                                                                                }
                                                                                this.props.showModelAlert(showAlertModel);
                                                                                return
                                                                            }
                                                                        }
                                                                    })
                                                            }
                                                            }
                                                        ></OverrideTF>
                                                    )
                                                }
                                                {
                                                    !this.state.showOverrideTF && !this.state.isOverrided &&
                                                    <label className="title_header" style={{ textAlign: 'right', 'width': '100%' }}>{`$${(this.state.newBookingPrice).toFixed(2)}`}</label>
                                                }
                                                {
                                                    this.state.isOverrided && this.state.showOverrideTF &&
                                                    <label className="title_header" style={{ textAlign: 'right', 'width': '100%' }}>{`$${Number(this.state.modifiedPrice).toFixed(2)}`}</label>
                                                }
                                            </div>
                                        </div>
                                        <div className="underline"></div>
                                        {isFuture && <div style={{ maxWidth: '390px', marginBottom: '5px' }}>
                                            <ShowWarningMessage message={`The parker isn't charged the difference right away, in the next monthly renewal the parker will be charged $${futurePrice}`}></ShowWarningMessage>
                                        </div>}
                                        <div className={`${isFuture ? "opacity-display" : ""}`}><div>
                                            {
                                                bookingDetailInfo.bookingType === BookingType['Monthly'] && (
                                                    <div className="flex_row"  >
                                                        <label className="title" style={{ width: '60%' }}>Prorated for first month</label>
                                                        <label className="title" style={{ width: '40%', textAlign: 'right' }}>{`$${proratedAmount.toFixed(2)}`}</label>
                                                    </div>
                                                )
                                            }
                                            <div className="flex_row"  >
                                                <label className="title" style={{ width: '60%' }}>{'Original Booking'}<br />{this.props.bookingDetailInfo.selectedSpot?.friendlySpotId},{this.props.bookingDetailInfo.selectedZone?.zoneName}</label>
                                                <label className="title" style={{ width: '40%', textAlign: 'right' }}>{`-$${this.state.originalBookingPrice.toFixed(2)}`}</label>
                                            </div>
                                            {
                                                this.state.showCredit && (
                                                    <div className="flex_row"  >
                                                        <label className="title" style={{}}>Credit</label>
                                                        <label className="green_underline" style={{ 'color': '#00AF85', width: '60%', marginLeft: '5px', 'cursor': 'pointer', fontSize: '12px', marginTop: '3px' }} onClick={() => {
                                                            this.setState({
                                                                creditButtonText: this.state.creditButtonText == "Remove" ? 'Reapply' : 'Remove'
                                                            }, () => {
                                                                console.log("credit button is clicked");
                                                                if (this.state.creditButtonText == 'Reapply') {
                                                                    this.setState({
                                                                        chooseCredit: false
                                                                    }, () => {
                                                                        if (this.state.showOverrideTF) {
                                                                            this.getCheckoutOption(this.state.modifiedPrice);
                                                                        } else {
                                                                            this.getCheckoutOption();
                                                                        }
                                                                    });
                                                                } else {
                                                                    console.log("credit button is clicked 123");
                                                                    this.setState({
                                                                        chooseCredit: true
                                                                    }, () => {
                                                                        if (this.state.showOverrideTF) {
                                                                            this.getCheckoutOption(this.state.modifiedPrice);
                                                                        } else {
                                                                            this.getCheckoutOption();
                                                                        }
                                                                    });
                                                                }
                                                            });
                                                        }}> {this.state.creditButtonText}</label>
                                                        <label className="title" style={{ width: '40%', textAlign: 'right' }}>{` ${this.state.credit > 0 ? "-" : ""} $${this.state.credit.toFixed(2)}`}</label>
                                                    </div>
                                                )
                                            }
                                            {
                                                this.state.showCoupon && (
                                                    <div className="flex_row"  >
                                                        <label className="title" style={{}}>Coupon</label>
                                                        <label className="green_underline" style={{ 'color': '#00AF85', width: '60%', marginLeft: '5px', 'cursor': 'pointer', fontSize: '12px', marginTop: '3px' }} onClick={() => {
                                                            this.setState({
                                                                couponButtonText: this.state.couponButtonText == "Remove" ? 'Reapply' : 'Remove'
                                                            }, () => {
                                                                if (this.state.couponButtonText == 'Reapply') {
                                                                    this.setState({
                                                                        chooseCoupon: false
                                                                    }, () => {
                                                                        if (this.state.showOverrideTF) {
                                                                            this.getCheckoutOption(this.state.modifiedPrice);
                                                                        } else {
                                                                            this.getCheckoutOption();
                                                                        }
                                                                    });
                                                                } else {
                                                                    this.setState({
                                                                        chooseCoupon: true
                                                                    }, () => {
                                                                        if (this.state.showOverrideTF) {
                                                                            this.getCheckoutOption(this.state.modifiedPrice);
                                                                        } else {
                                                                            this.getCheckoutOption();
                                                                        }
                                                                    });
                                                                }
                                                            });
                                                        }}> {this.state.couponButtonText}</label>
                                                        <label className="title" style={{ width: '40%', textAlign: 'right' }}>{`${this.state.coupon > 0 ? "-" : ""} $${this.state.coupon.toFixed(2)}`}</label>
                                                    </div>
                                                )
                                            }
                                            <TaxandWaivedoff
                                                serviceFee={this.state.serviceFee}
                                                tax={this.state.tax}
                                                waivedOff={this.state.waivedOff}
                                            ></TaxandWaivedoff>
                                        </div>
                                            <div className="underline"></div>
                                            <div>
                                                <div className="flex_row"  >
                                                    <label className="title_header" style={{ width: '70%' }}>Adjustment </label>
                                                    <label className="title_header" style={{ width: '30%', textAlign: 'right' }}>{`${Number(this.state.adjustmentPrice) < 0 ? ` - $${Math.abs(this.state.adjustmentPrice).toFixed(2)}` : `$ ${this.state.orderTotal.toFixed(2)}`}`}</label>
                                                </div>
                                            </div>
                                            {
                                                (Number(this.state.adjustmentPrice) < 0) && 
                                                <div style={{ marginBottom: '20px' }}>
                                                    <ShowWarningMessage message={'The corresponding tax will be refunded in addition to the above mentioned amount. The service fee will not be refunded.'}></ShowWarningMessage>
                                                </div>
                                            }
                                            <div className="underline"></div>
                                            <SelectCard
                                                disable={Number(this.state.adjustmentPrice) < 0 || Number(this.state.orderTotal.toFixed(2)) <= 0 ? true : false}
                                                bookingDetailInfo={bookingDetailInfo}
                                                onCardSelection={
                                                    (cardId) => {
                                                        this.setState({
                                                            selectedCardId: cardId
                                                        }, () => {
                                                            console.log("selectedCardId", this.state.selectedCardId);
                                                        });
                                                    }
                                                }
                                                onOpenNewCardScreen={
                                                    () => {
                                                        this.setState({
                                                            showNewCardScreen: true
                                                        });
                                                    }
                                                }
                                            ></SelectCard>
                                        </div>
                                        </>
                                    <div className="flex_colum" style={{ width: '100%', alignContent: 'center', 'marginTop': '10px' }}>
                                        <Notes
                                            notes={this.state.notes}
                                            handelNotes={(e: any) => {
                                                this.setState({
                                                    notes: e.target.value
                                                });
                                            }}
                                        ></Notes>
                                    </div>

                                </div>
                            </div>
                            <div className="flex_colum mt-4" style={{ alignContent: 'center', backgroundColor: 'white', width: '100%', height: '50px',display:'flex',flexGrow: 1,flexBasis: 0 }}>
                                <Button className="title" style={{
                                    'backgroundColor': this.state.selectedCardId === '' && this.state.orderTotal > 0 ? '#F0F0F0' : '#00AF85', alignSelf: 'center',
                                    width: '100%', 'color': this.state.selectedCardId === '' && this.state.orderTotal > 0 ? '#323232' : 'white',
                                }}
                                    disabled={this.state.selectedCardId === '' && this.state.orderTotal > 0 ? true : false}

                                    onClick={async () => {
                                        this.bookTheSpot();
                                    }}

                                >Update Booking</Button>
                            </div>
                        </>
                    )
                }
            </>
        )
    }
    private async checkPaymentStatusRepeat(accesstoken: string, paymentIntent: string, blockGuid: string) {
        const paymentIntentId = paymentIntent
        for (let i = 0; i < 30; i++) {
            const no_payment_status: Response = await this.spotService.check_payment_no_final(accesstoken, paymentIntentId)
            if (no_payment_status.ok) {
                let check_payment_no_final: any = await no_payment_status.json()
                if (check_payment_no_final.reservationGuid) {
                    const payment_status: any = await this.spotService.check_payment_with_final(accesstoken, paymentIntentId, true)
                    let ConfirmPayment: any = await payment_status.json()
                    if (ConfirmPayment.friendlyReservationId && ConfirmPayment.friendlySpotId) {
                        setTimeout(() => {
                            this.setState({
                                showNewCardScreen: false
                            }, () => {
                                ShowCustomBlocker(false);
                                this.successfully(ConfirmPayment.friendlyReservationId);
                            });
                        }, 1000);
                    }
                    return;
                }
            } else {
                if (i == 29 && !no_payment_status.ok) {

                    const payment_status: any = await this.spotService.check_payment_with_final(accesstoken, paymentIntentId, true)
                    if (payment_status.status.ok) {
                        let ConfirmPayment: any = await payment_status.json()
                        if (ConfirmPayment.friendlyReservationId && ConfirmPayment.friendlySpotId) {
                            setTimeout(() => {
                                this.setState({
                                    showNewCardScreen: false
                                }, () => {
                                    ShowCustomBlocker(false);
                                    this.successfully(ConfirmPayment.friendlyReservationId);
                                })
                            }, 1000);
                        }
                    } else {
                        this.unHoldSpot(blockGuid);
                        ShowCustomBlocker(false);
                        if (no_payment_status.status == 404) {
                            ShowAlert("GrydPark", "Your payment isn't confirmed yet. Please check again later", "warning");
                        } else {
                            ShowAlert("GrydPark", "Sorry, we are facing issue in booking your spot. Please try again later", "warning");
                        }
                    }
                }
            }
            await new Promise<void>((resolve, reject) => {
                setTimeout(() => {
                    resolve()
                }, 1000);
            });
        }
    }
    private getAccessToken() {
        const storeUserData: any = localStorage.getItem('userdata') ? localStorage.getItem('userdata') : null;
        const userDatas: any = JSON.parse(storeUserData);
        const AccessToken = userDatas['accessToken'];
        if (AccessToken) {
            return AccessToken
        } else {
            return ""
        }
    }

    private async unHoldSpot(blockGuid: string) {
        if (blockGuid) {
            await this.spotService.unholdSpot(this.getAccessToken(), blockGuid);
        }
    }

    private successfully = (friendlyReservationId: string) => {
        const showAlertModel: IShowModelAlert = {
            headerTitle: "Booking Updated",
            messageContent: <div style={{ textAlign: 'center' }}>
                <div className="mt-3 bulk-upload-sub-content">
                    Booking information has been updated for
                </div>
                <div className="mt-1 bulk-upload-sub-content">
                    booking <b>{friendlyReservationId}</b>.
                </div>
            </div>,
            showHideAlert: true,
            showModelAlertType: ShowModelAlertType.Success,
            yesButton: true,
            yesButtonText: "Okay"
        }
        this.props.showModelAlert(showAlertModel, undefined, true);
    }

    private closeAndClearModifyBooking = () => {
        const showAlertModel: IShowModelAlert = {
            headerTitle: "",
            messageContent: "",
            showHideAlert: false,
            showModelAlertType: ShowModelAlertType.Info
        }
        this.props.showModelAlert(showAlertModel, undefined, true);
    }

    private async bookTheSpot(newCardBooking?: boolean, dntShowRefundAlert?: boolean) {
        const bookingDetailInfo = cloneDeep(this.props.bookingDetailInfo);
        if (!bookingDetailInfo.selectedNewZone && !bookingDetailInfo.selectedNewSpot) {
            ShowAlert("", "Please select zone and spot.", "error");
            return;
        }
        else if (!bookingDetailInfo.selectedNewZone) {
            ShowAlert("", "Please select zone.", "error");
            return;
        }
        else if (!bookingDetailInfo.selectedNewSpot) {
            ShowAlert("", "Please select spot.", "error");
            return;
        }
        if (bookingDetailInfo.reservation && (((this.props.checkModification && this.props.checkModification() &&
            bookingDetailInfo.vehicleDetail) ||
            (this.props.checkModification && this.props.checkModification(true) && this.state.notes))) && !this.state.showOverrideTF) {
            const postData = {
                "reservationGuid": bookingDetailInfo.reservation.reservationGuid,
                "vehicleGuid": bookingDetailInfo.vehicleDetail?.vehicleGuid,
                "notes": this.state.notes
            }
            const vehicleUpdate = await this.spotService.updateVehicle(postData);
            if (vehicleUpdate.ok) {
                this.successfully(bookingDetailInfo.reservation.friendlyReservationId);
            }
            else {
                vehicleUpdate.json().then(error => {
                    ShowAlert("", error, "error");
                });
            }
            return;
        }
        if (Number(this.state.adjustmentPrice.toFixed(2)) < 0 && !dntShowRefundAlert) {
            if (this.props.checkModification && this.props.checkModification(true) && !this.state.showOverrideTF) {
                ShowAlert("", "No changes to update", "info");
                return;
            }
            const showAlertModel: IShowModelAlert = {
                headerTitle: "Refund Required",
                messageContent: <div style={{ textAlign: 'center' }}>
                    <div className="mt-3 bulk-upload-sub-content">
                        The booking adjustment results in a refund of
                    </div>
                    <div className="mt-1 bulk-upload-sub-content">
                        <b>${Math.abs(this.state.adjustmentPrice).toFixed(2)}</b>. Would you like to process the refund?
                    </div>
                </div>,
                showHideAlert: true,
                showModelAlertType: ShowModelAlertType.Error,
                confirmation: true,
                yesButtonText: "Refund",
                noButtonText: "Cancel",
                yesCallBack: () => {
                    this.props.showModelAlert({
                        headerTitle: "",
                        messageContent: "",
                        showHideAlert: false,
                        showModelAlertType: ShowModelAlertType.Info
                    }, () => {
                        this.bookTheSpot(false, true);
                    });
                },
                noCallBack: () => {
                    this.props.showModelAlert({
                        headerTitle: "",
                        messageContent: "",
                        showHideAlert: false,
                        showModelAlertType: ShowModelAlertType.Info
                    });
                }
            }
            this.props.showModelAlert(showAlertModel);
            return
        }
        if (bookingDetailInfo.bookingType === BookingType['Monthly'] &&
            this.state.monthlyOverridOptions === 0 && this.state.modifiedPrice !== ''
            && this.state.showOverrideTF && this.props.recurringEventType !== 1) {
            this.showOverrideMonthlyOption();

        } else {
            if (this.props.checkModification && this.props.checkModification(true) && 
            !this.state.showOverrideTF) {
                ShowAlert("", "No changes to update", "info");
                return;
            }
            ShowCustomBlocker(true);
            const AccessToken = this.getAccessToken();
            let blockGuid: string = "";
            const StartDateTime = DateTimeHelper.ChangeTime(bookingDetailInfo.date.startDate ?? new Date(), bookingDetailInfo.startTime?.timeString ?? "");
            const EndDateTime = DateTimeHelper.ChangeTime(bookingDetailInfo.date.endDate ?? new Date(), bookingDetailInfo.endTime?.timeString ?? "")
            let holdSpotPostData: any;
            let discountData: any = this.state.discountObj;
            let isPassDiscount = false;
            if (this.state.credit >= 1 || this.state.coupon >= 1){
                isPassDiscount = true;
            }
            if (bookingDetailInfo.bookingType === BookingType["Hourly/Daily"]) {
                holdSpotPostData = {
                    PropertyGuid: bookingDetailInfo.selectedProperty?.propertyGuid,
                    BookingStartUTC: moment(StartDateTime).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                    BookingEndUTC: moment(EndDateTime).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                    BookingTotalPrice: this.state.orderBookingAmount,
                    FilterAmenities: "",
                    VehicleGuid: bookingDetailInfo.vehicleDetail?.vehicleGuid,
                    Discount: isPassDiscount ? discountData : null,
                    ZoneGuid: bookingDetailInfo.selectedNewSpot?.zoneGuid,
                    ParkerEncodeGuid: bookingDetailInfo.userDetail.parkerEncodeGuid,
                    isAllowOverlap: bookingDetailInfo.selectedSpot ? bookingDetailInfo.selectedSpot.isBooked : false,
                    existingBookingTotalPrice: this.state.intialPrice ? this.state.intialPrice : this.state.newBookingPrice
                }
                if (this.state.modifiedPrice) {
                    holdSpotPostData["newBookingTotalPrice"] = Number(this.state.modifiedPrice);
                }
            } else {
                holdSpotPostData = {
                    PropertyGuid: bookingDetailInfo.selectedProperty?.propertyGuid,
                    BookingStartUTC: moment(bookingDetailInfo.selectedMonth).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                    BookingTotalPrice: this.state.orderBookingAmount,
                    FilterAmenities: "",
                    VehicleGuid: bookingDetailInfo.vehicleDetail?.vehicleGuid,
                    Discount: isPassDiscount ? discountData : null,
                    MonthlyRateTypeId: bookingDetailInfo.monthlyBookingType,
                    ZoneGuid: bookingDetailInfo.selectedNewSpot?.zoneGuid,
                    ParkerEncodeGuid: bookingDetailInfo.userDetail.parkerEncodeGuid,
                    isAllowOverlap: bookingDetailInfo.selectedSpot ? bookingDetailInfo.selectedSpot.isBooked : false,
                    ModifiedBookingRate: this.state.modifiedPrice !== "" ? Number(this.state.modifiedPrice) : this.state.newBookingPrice,
                    spotChangeType: this.props.recurringEventType,
                    existingMonthlyPrice: bookingDetailInfo.reservation?.monthlyBookingAmount,
                    monthlyOverridePrice: this.state.modifiedPrice !== "" ? Number(this.state.modifiedPrice) : this.state.newBookingPrice
                }
            }

            holdSpotPostData["notes"] = this.state.notes;
            holdSpotPostData["SpotGuid"] = bookingDetailInfo.selectedNewSpot?.spotGuid;
            holdSpotPostData["rebookReservationGuid"] = bookingDetailInfo.reservation?.reservationGuid;
            holdSpotPostData["IsOverridePrice"] = true;
            
            if (this.state.monthlyOverridOptions !== 0 && bookingDetailInfo.bookingType === BookingType['Monthly'] && this.state.showOverrideTF && this.state.modifiedPrice != '' && this.props.recurringEventType !== 1) {
                holdSpotPostData["priceChangeType"] = this.state.monthlyOverridOptions;
            }
            if (this.state.adjustmentPrice < 0) {
                holdSpotPostData["BookingTotalPrice"] = 0
                holdSpotPostData["rebookRefundAmount"] = this.state.adjustmentPrice;

            }
            const holdSpot = await this.spotService.holdSpot(AccessToken, holdSpotPostData, bookingDetailInfo.bookingType === BookingType['Monthly'] ? 'Monthly' : '')
            if (holdSpot.status == 200) {
                const holdSpotResponse: any = await holdSpot.json();
                if (holdSpotResponse.hasAvailableSpots) {
                    blockGuid = holdSpotResponse.blockGuid;
                    let initiatePayment: any
                    if (newCardBooking && this.state.orderTotal > 0) {
                        const { stripe, elements } = this.props;
                        if (elements == null) {
                            ShowCustomBlocker(false);
                            return;
                        }
                        const payment = await stripe?.createPaymentMethod({
                            type: 'card',
                            card: elements.getElement(CardElement),
                        });
                        if (payment?.error) {
                            ShowCustomBlocker(false);
                            this.unHoldSpot(blockGuid);
                            ShowAlert('GrydPark', payment?.error.message ?? '', 'warning');
                            return;
                        } else if (payment?.paymentMethod) {
                            initiatePayment = await this.spotService.guestInitiate_payment(AccessToken, blockGuid, payment?.paymentMethod?.id, bookingDetailInfo.userDetail.parkerEncodeGuid);
                        }
                    } else {
                        initiatePayment = await this.spotService.initiate_payment(AccessToken, blockGuid, this.state.selectedCardId, bookingDetailInfo.userDetail.parkerEncodeGuid);
                    }
                    if (initiatePayment.status == 200) {
                        const clientSecret: string = await initiatePayment.json();
                        if (this.state.orderTotal == 0) {
                            // free booking
                            const checkPaymentStatusFinal: any = await this.spotService.check_payment_with_final(AccessToken, clientSecret, true)
                            const ConfirmPayment: any = await checkPaymentStatusFinal.json()
                            ShowCustomBlocker(false);
                            if (ConfirmPayment.friendlyReservationId && ConfirmPayment.friendlySpotId) {
                                ShowCustomBlocker(false);
                                this.successfully(ConfirmPayment.friendlyReservationId);
                            }
                        } else {
                            const { stripe } = this.props;
                            const payload = await stripe?.confirmCardPayment(clientSecret);
                            console.log("payload", payload?.paymentIntent?.id);
                            if (payload?.error) {
                                this.unHoldSpot(blockGuid);
                                ShowCustomBlocker(false);
                                ShowAlert('GrydPark', payload.error.message ?? '', 'warning')
                            } else {
                                let paymentIntent = payload?.paymentIntent?.id;
                                if (paymentIntent) {
                                    this.checkPaymentStatusRepeat(AccessToken, paymentIntent, blockGuid);
                                }
                            }
                        }
                    } else {
                        ShowCustomBlocker(false);
                        this.unHoldSpot(blockGuid);
                        if (initiatePayment.status == 400) {
                            const initiatePaymentRes: string = await initiatePayment.json();
                            if (typeof (initiatePaymentRes) === "string") {
                                ShowAlert("GrydPark", initiatePaymentRes == 'PAYMENT_ALREADY_INITIATED' ? "Your payment has already been initiated." : initiatePaymentRes, "warning");
                            }
                        }
                    }
                } else {
                    ShowCustomBlocker(false);
                    if (holdSpotResponse.booking && holdSpotResponse.booking.bestRate?.totalPrice !== 0) {
                        this.unHoldSpot(blockGuid);
                        ShowAlert("GrydPark", "Spot price are updated. Please verify", "warning").then(x =>
                            this.closeAndClearModifyBooking()
                        );

                    } else {
                        ShowAlert("GrydPark", "All spots are booked. Please check another one.", "warning");
                    }
                }
            } else {
                ShowCustomBlocker(false);
                ShowAlert("GrydPark", "Oops! We’re having trouble connecting with the server, could you try again later?", "warning").then(x =>
                    this.closeAndClearModifyBooking()
                );
            }
        }
    }
}


interface IBookingModificationProps {
    handelAlert: (b_: boolean) => void;
    propertyGuid: string;
    stripe: Stripe | null,
    elements: any,
    onClose: () => void,
    bookingDetailInfo: IBookingDetailInfo,
    appliedCoupon: string,
    isOnlyRefersh: boolean,
    isNoHeader?: boolean,
    headerText?: string,
    checkModification?: (checkAllChanges?: boolean, areDateAndSpotChanges?: boolean, checkOnlyDate?: boolean) => boolean,
    showModelAlert: (showModelAlert: IShowModelAlert, callback?: () => void, isSuccess?: boolean) => void,
    recurringEventType: number,
    isOverridePrice: (showOverrideTF: boolean, modifiedPrice: string, callback?: () => void, isResetPrice?: boolean) => void,
    resetPrice: boolean
}

interface IBookingModificationState {
    defaultSelectedCard: CountryType,
    showPromoCode: boolean,
    showOverrideTF: boolean,
    hourlyPrice: string,
    modifiedPrice: string,
    propertyGuid: string,
    serviceFee: number,
    tax: number,
    price: number,
    intialPrice: number,
    credit: number,
    coupon: number,
    maxserviceFree: number,
    orderTotal: number,
    orderBookingAmount: number,
    bookingType: string,
    showNewCardScreen: boolean,
    showAddNewCard: boolean,
    marginOfShowCard: number,
    showDropDown: boolean,
    cardArray: any[],
    selectedCardDisplayName: string,
    selectedCardId: string,
    creditButtonText: string,
    couponButtonText: string,
    isCreditBT: boolean,
    isCouponBT: boolean,
    showCredit: boolean,
    showCoupon: boolean,
    chooseCredit: boolean,
    chooseCoupon: boolean,
    bookingDetailInfo: IBookingDetailInfo,
    manualSelectedCoupon: string,
    showOnPopUp: boolean,
    dropDownSelected: boolean,
    waivedOff: number,
    couponIds: any[],
    monthlyOverridOptions: number,
    creditIds: any[],
    discountObj: any,
    overridePrice: 0,
    originalBookingPrice: number,
    newBookingPrice: number,
    adjustmentPrice: number,
    notes: string,
    prorate: number,
    isOverrided: boolean,
}