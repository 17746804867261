import moment from "moment";

export  function getMonthDifferenceFromCurrent(customDate: Date | null) {
    if(customDate){
        var startTime = moment().format('DD/MM/YYYY')
        var endTime = moment(customDate).format('DD/MM/YYYY')
        const getDate = (date: moment.MomentInput) => moment(date, 'DD/MM/YYYY').startOf('month')
        const diff = Math.abs(getDate(startTime.toString()).diff(getDate(endTime.toString()), 'months'));
        return diff
    }else{
        return -1
    }
   

}