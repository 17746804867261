import BaseService from "./BaseService";
import { ConfigService } from "./ConfigService";

export default class OnboardingService extends BaseService {
    public getOnboardingList() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "user/onboardinglist", {
                    method: "GET",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
            });
    }

    public bulkUploadVerify(formData_: FormData) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "user/bulk-load-verify", {
                    method: "POST",
                    body: formData_
                });
            });
    }

    public bulkUpload(formData_: FormData) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "user/bulk-load", {
                    method: "POST",
                    body: formData_
                });
            });
    }
}