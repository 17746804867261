import React from "react";
import { Doughnut } from 'react-chartjs-2';

export default class ChartUtilization extends React.Component<IChartUtilizationProps, IChartUtilizationState> {
    private chartReference: any = null;
    constructor(props_: IChartUtilizationProps) {
        super(props_);

        this.state = {
            doughnutData: {
                // labels: [],
                datasets: [{
                    label: 'Utilization',
                    data: [0, 100],
                    // data: [0, 0],
                    backgroundColor: [
                        'rgb(0, 175, 133)',
                        'rgb(204,239,231)',
                    ],
                    hoverOffset: 4
                }],
                innerText: '23%'
            },
            doughnutOption: {
                cutoutPercentage: 70,
                responsive: true,
                maintainAspectRatio: true,
                tooltips: {
                    enabled: false,
                },
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                }
            },
            doughnutPlugin: this.getPluginData(0)
        }
    }

    componentDidMount() {
        let doughnutData_: any = this.state.doughnutData;
        let percentage_ = 100;
        let balancePercentage = (percentage_ - this.props.percentage);
        doughnutData_.datasets[0].data = [this.props.percentage, balancePercentage];
        this.setState({ doughnutData: doughnutData_ }, () => {
            if (this.chartReference) { this.chartReference.chartInstance.update(); }
        });
    }

    componentDidUpdate(prevProps: IChartUtilizationProps) {
        if (this.props.percentage !== prevProps.percentage) {
            let doughnutData_: any = this.state.doughnutData;
            let percentage_ = 100;
            let balancePercentage = (percentage_ - this.props.percentage);
             doughnutData_.datasets[0].data = [this.props.percentage, balancePercentage];
            this.setState({ doughnutData: doughnutData_ }, () => {
                if (this.chartReference) { this.chartReference.chartInstance.update(); }
            });
        }
    }


    render() {
        return (this.renderDoughnutChart())
    }

    private renderDoughnutChart() {
        return (
            <Doughnut
                ref={(reference) => this.chartReference = reference}
                data={this.state.doughnutData}
                // height={100}
                // width={100}
                options={this.state.doughnutOption}
                plugins={[{
                    beforeDraw: (chart: any) => {
                        var width = chart.width,
                            height = chart.height,
                            ctx = chart.ctx;
                        ctx.restore();
                        var fontSize = (height / 120).toFixed(2);
                        ctx.font = "bold " + fontSize + "em sans-serif";
                        ctx.textBaseline = "top";
                        var text = this.props.percentage + "%",//"80 %",
                            textX = Math.round((width - ctx.measureText(text).width) / 2),
                            textY = height / 2;
                        ctx.fillText(text, textX, textY);
                        ctx.save();

                    }
                }]}
            />
        );
    }

    private getPluginData(roundedResp: number) {
        return [{
            beforeDraw: function (chart: any) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                ctx.restore();
                var fontSize = (height / 160).toFixed(2);
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                var text = roundedResp + " %",//"80 %",
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 2;
                ctx.fillText(text, textX, textY);
                ctx.save();
            }
        }]
    }
}

interface IChartUtilizationProps {
    percentage: number;
}

interface IChartUtilizationState {
    doughnutData: any;
    doughnutOption: any;
    doughnutPlugin: any[];
}