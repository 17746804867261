import { InputAdornment, TextField } from "@material-ui/core";
import React from "react";
const OverrideTF = (_props: any) => {
    return (
        <>
            <TextField
                placeholder=""
                variant="outlined"
                required={true}
                margin="normal"
                className="mt-0 customHeight"
                onPaste={() => { return false }}
                onFocus={(e) => {
                    _props.handelOnFocus(e)
                }}
                inputProps={
                    {
                        maxLength: 7,
                        min: 0,
                        inputMode: 'decimal',
                        style: { textAlign: 'right' }
                    }
                }
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <label style={{ 'margin': '5px', 'marginLeft': `${5}}px`, width: '100%' }}>$</label>
                        </InputAdornment>
                    ),
                }}
                value={_props.modifiedPrice}
                onChange={(e_: React.ChangeEvent<HTMLInputElement>) => {
                    _props.handelOnChange(e_)

                }}
                onBlur={(e) => {
                   _props.handelOnBlur(e)
                }
                }
                autoComplete="off"
            />
        </>
    )
}
export default OverrideTF