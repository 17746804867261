import React from "react";
import { Grid, Paper, Tooltip, IconButton } from "@material-ui/core";
import OnboardingService from "../Services/OnboardingService";
import FilePicker from "../Components/BasicComponents/FilePicker";
import AddIcon from '@material-ui/icons/Add';
import DataGrid from "../Components/DataGrid/DataGrid";
import { IFileInfo } from "../Components/BasicComponents/FileUploadModal";
import { IDataRow, IDataGridColumn } from "../Components/DataGrid/Interfaces";
import "./BulkUpload.css";

import DescriptionIcon from '@material-ui/icons/Description';
import PublishIcon from '@material-ui/icons/Publish';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Business from '@material-ui/icons/Business';
import PeopleOutlineRoundedIcon from '@material-ui/icons/PeopleOutlineRounded';
import SendIcon from '@material-ui/icons/Send';
import { ShowAlert } from "../Common/ShowAlert";
import { Link } from "react-router-dom";
import { FaParking } from 'react-icons/fa';
import StringHelper from "../Helpers/StringHelper";
import GeoHelper from "../Helpers/GeoHelper";
import { CheckScreenPermission } from "../Common/CheckScreenPermission";

export default class BulkUpload extends React.Component<{}, IBulkUploadState> {
    private _service = new OnboardingService();
    state: IBulkUploadState = {
        isFileModalOpen: true,
        files: [
        ],
        isScreenAccessible: false
    };

    private _fileListColumns: IDataGridColumn<IBulkUploadFileEntry>[] = [
        {
            key: "spacer",
            name: ""

        },
        {
            key: "fileName",
            name: "File Name"
        },
        {
            key: "fileType",
            name: "Data Type"
        },
        {
            key: "status",
            name: "Status",
            contentProvider: (row_: IBulkUploadFileEntry) => {
                let getStatusClass = (onGoingStatus_: FileBulkUploadStatus, failureStatus_?: FileBulkUploadStatus) => {
                    if (row_.status == failureStatus_) {
                        return " error";
                    }
                    else if (row_.status == onGoingStatus_) {
                        return " wip";
                    }
                    else if (row_.status < onGoingStatus_) {
                        return " new";
                    }
                    else if (row_.status > onGoingStatus_) {
                        return " done";
                    }
                }

                return (
                    <div className="status-bar">
                        <Tooltip title="Submit">
                            <span className={"status-indicator" + getStatusClass(FileBulkUploadStatus.Submitted)}>
                                <DescriptionIcon />
                            </span>
                        </Tooltip>
                        <span className={"status-connector" + getStatusClass(FileBulkUploadStatus.Submitted)}></span>
                        <Tooltip title="Upload">
                            <span className={"status-indicator" + getStatusClass(FileBulkUploadStatus.Uploading, FileBulkUploadStatus.UploadingFailed)}>
                                <PublishIcon />
                            </span>
                        </Tooltip>
                        <span className={"status-connector" + getStatusClass(FileBulkUploadStatus.Uploading, FileBulkUploadStatus.UploadingFailed)}></span>
                        <Tooltip title={"Validation" + ((row_.validationResult && row_.validationResult.length) ? (": Click the icon to show error details") : "")}>
                            <span className={"status-indicator" + getStatusClass(FileBulkUploadStatus.Verifying, FileBulkUploadStatus.VerificationFailed)}
                                onClick={() => {
                                    if (row_.validationResult && row_.validationResult.length) {
                                        ShowAlert("Validation failed because", `<div style='max-height: 200px; overflow-y: auto;'>${row_.validationResult.join("<br />")}</div>`, "error");
                                    }
                                }}
                            >
                                <VerifiedUserIcon />
                            </span>
                        </Tooltip>
                        <span className={"status-connector" + getStatusClass(FileBulkUploadStatus.Verifying, FileBulkUploadStatus.VerificationFailed)}></span>
                        <Tooltip title="Onboard">
                            <span className={"status-indicator" + getStatusClass(FileBulkUploadStatus.Onboarding, FileBulkUploadStatus.OnboardingFailed)}>
                                <CheckCircleIcon />
                            </span>
                        </Tooltip>
                        <span className={"status-connector new"}></span>
                        <Tooltip title="Go to list view">
                            {
                                row_.fileTypeKey == "property" ?
                                    <Link to={"/Properties"} className={row_.status == FileBulkUploadStatus.OnboardingSuccess ? "nav-button" : "nav-button disabled"}>
                                        <span className={"status-indicator new"}>
                                            <Business />
                                        </span>
                                    </Link>
                                    : (
                                        row_.fileTypeKey == "company" ?
                                            <Link to={"/Companies"} className={row_.status == FileBulkUploadStatus.OnboardingSuccess ? "nav-button" : "nav-button disabled"}>
                                                <span className={"status-indicator new"}>
                                                    <PeopleOutlineRoundedIcon />
                                                </span>
                                            </Link>
                                            : (
                                                row_.fileTypeKey == "spot" ?
                                                    <Link to={"/Spots"} className={row_.status == FileBulkUploadStatus.OnboardingSuccess ? "nav-button" : "nav-button disabled"}>
                                                        <span className={"status-indicator new"}>
                                                            <FaParking color={'#00000'} size={'1.4em'} />
                                                        </span>
                                                    </Link>
                                                    : <Link to={"/Users"} className={row_.status == FileBulkUploadStatus.OnboardingSuccess ? "nav-button" : "nav-button disabled"}>
                                                        <span className={"status-indicator new"}>
                                                            <SendIcon />
                                                        </span>
                                                    </Link>

                                            )
                                    )

                            }
                        </Tooltip>
                    </div>
                )
            }
        },
        // {
        //     key: "action",
        //     name: "Action"
        // },
    ];

    async componentDidMount() {
        var isAccessible = await CheckScreenPermission("user-interface-bulk-upload");

        this.setState({
            isScreenAccessible: isAccessible
        });
    }

    render() {
        if (!this.state.isScreenAccessible) {
            return null;
        }

        return (
            <div className="m-3 data-grid-container">
                {/* Header region of the page */}
                <Grid container className="mb-3">
                    <Grid item>
                        {/* Title of the page */}
                        <h1 className="list-header">Onboard</h1>
                    </Grid>
                    <Grid item xs className="text-right">
                        {/* Grid toolbar */}
                        <FilePicker
                            isOpen={this.state.isFileModalOpen}
                            label="Upload CSV Files"
                            descriptionSubset="csv files"
                            filter=".csv"
                            isButtonIcon={true}
                            customIcon={<AddIcon fontSize="small" />}
                            customIconClassName="add-lit-button"
                            onFilesSelectionComplete={files_ => {
                                let existingFiles = this.state.files;
                                let newFiles: IFileInfo[] = files_.filter(f => {
                                    for (let loadedFile of existingFiles) {
                                        if (loadedFile.fileInfo.internalUid == f.internalUid) {
                                            return false;
                                        }
                                    }

                                    return true;
                                });

                                let proceed = () => {
                                    let newUploadingFiles: IBulkUploadFileEntry[] = newFiles.map(f => {
                                        let fileType = "";
                                        let fileTypeKey = "";
                                        let fileTypeSequence = 0;
                                        let fileName = f.fileName.toLowerCase();

                                        if (fileName.indexOf("company") > -1 || fileName.indexOf("companies") > -1 || fileName.indexOf("manage") > -1) {
                                            fileType = "Company";
                                            fileTypeKey = "company";
                                            fileTypeSequence = 1;
                                        }
                                        else if (fileName.indexOf("property") > -1 || fileName.indexOf("properties") > -1) {
                                            fileType = "Property";
                                            fileTypeKey = "property";
                                            fileTypeSequence = 2;
                                        }
                                        else if (fileName.indexOf("spot") > -1) {
                                            fileType = "Spot";
                                            fileTypeKey = "spot";
                                            fileTypeSequence = 3;
                                        }

                                        return {
                                            fileInfo: f,
                                            fileName: f.fileName,
                                            fileType: fileType,
                                            fileTypeKey: fileTypeKey,
                                            fileTypeSequence: fileTypeSequence,
                                            status: FileBulkUploadStatus.Submitted
                                        }
                                    }).sort((a, b) => {
                                        if (a.fileTypeSequence > b.fileTypeSequence) {
                                            return 1;
                                        }
                                        else if (a.fileTypeSequence < b.fileTypeSequence) {
                                            return -1;
                                        }
                                        return 0;
                                    });

                                    this.setState({
                                        files: existingFiles.concat(newUploadingFiles)
                                    }, this._startUploadProcess);
                                }

                                let needsPreProcessing = 0;
                                let tryProceeding = () => {
                                    if (needsPreProcessing == 0) {
                                        proceed();
                                    }
                                }

                                let doGeoPart = (line_: string[], callback_: () => void) => {
                                    let addressText = "";
                                    for (let i = 1; i <= 5; i++) {
                                        let addressPart = StringHelper.trim(line_[i], "\"");
                                        if (addressPart) {
                                            addressText += addressPart + " ";
                                        }
                                    }

                                    if (addressText) {
                                        GeoHelper.getGeoLocationFromAddress(addressText)
                                            .then(resp_ => {
                                                if (resp_) {
                                                    line_[21] = resp_.lat.toString();
                                                    line_[22] = resp_.lng.toString();
                                                }
                                                callback_();
                                            })
                                            .catch(() => {
                                                callback_();
                                            });
                                    }
                                    else {
                                        callback_();
                                    }
                                }

                                let doFilePart = (fileBlob_: Blob, callback_: (updatedText_: string) => void) => {
                                    let fileReader = new FileReader();
                                    fileReader.onload = () => {
                                        let textContent = fileReader.result as string;
                                        let lines: string[][] = [];
                                        let line: string[] = [];
                                        let cellText: string = "";
                                        let dblQtsCount = 0;

                                        let fnCellComplete = () => {
                                            line.push(cellText);
                                            cellText = "";
                                        }

                                        let fnLineComplete = () => {
                                            if (line.length > 0 || cellText != "") {
                                                fnCellComplete();
                                                lines.push(line);
                                                line = [];
                                            }
                                        }

                                        for (let c of textContent) {
                                            if (c == "\"") {
                                                ++dblQtsCount;
                                            }
                                            else if (dblQtsCount % 2 == 0) {
                                                if (c == "\n" || c == "\r") {
                                                    fnLineComplete();
                                                    continue;
                                                }
                                                else if (c == ",") {
                                                    fnCellComplete();
                                                    continue;
                                                }
                                            }

                                            cellText += c;
                                        }

                                        fnLineComplete();

                                        let linesCount = lines.length - 1;
                                        let linesComplete = 0;
                                        let fnFileLineComplete = () => {
                                            if (++linesComplete == linesCount) {
                                                let text = "";
                                                for (let _l of lines) {
                                                    text += _l.join(",") + "\n";
                                                }
                                                callback_(text);
                                            }
                                        }


                                        for (let l = 1; l <= linesCount; l++) {
                                            doGeoPart(lines[l], fnFileLineComplete);
                                        }
                                    }
                                    fileReader.readAsText(fileBlob_);
                                }

                                for (let file of newFiles) {
                                    let fileName = file.fileName.toLowerCase();
                                    if (fileName.indexOf("property") > -1 || fileName.indexOf("properties") > -1) {
                                        needsPreProcessing++;

                                        doFilePart(file.fileContent as Blob, updatedText_ => {
                                            --needsPreProcessing;
                                            file.fileContent = new Blob([updatedText_])
                                            tryProceeding();
                                        });
                                    }
                                }
                                tryProceeding();
                            }}
                            onModalOpen={() => {
                                this.setState({
                                    isFileModalOpen: true
                                });
                            }}
                            onModalClose={() => {
                                this.setState({
                                    isFileModalOpen: false
                                });
                            }}
                        />
                    </Grid>
                </Grid>

                {/* Data Grid */}
                {
                    this.state.files.length > 0 ?
                        <DataGrid
                            title="Bulk Upload"
                            data={this.state.files}
                            columns={this._fileListColumns}
                            isRowsNonSelectable={true}
                            isNoMoreLink={true}
                        />
                        : <Paper className="bulk-load-instructions p-3">
                            To onboard bulk data, please click
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip title="Upload CSV Files">
                                <IconButton
                                    aria-label={"Upload CSV Files"}
                                    onClick={() => {
                                        this.setState({ isFileModalOpen: true })
                                    }}
                                    className="add-lit-button"
                                >
                                    <AddIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            &nbsp;&nbsp;
                            icon and upload CSV files based on <a href="/Properties.csv" download="Properties.csv">Properties Template</a>, <a href="/Companies.csv" download="Companies.csv">Property Management Companies Template</a> and <a href="/Spots.csv" download="Spots.csv">Spots Template</a>.
                            </Paper>
                }

            </div>
        );
    }

    private _startUploadProcess = (justFinished_?: boolean): void => {
        for (let file of this.state.files) {
            if (file.status == FileBulkUploadStatus.OnboardingSuccess) {
                continue;
            }

            if (file.status == FileBulkUploadStatus.Submitted) {
                file.status = FileBulkUploadStatus.Uploading;
                this.setState({
                    files: this.state.files
                }, () => {
                    if (file.fileInfo.fileContent) {
                        let form = new FormData();
                        form.append(file.fileTypeKey, file.fileInfo.fileContent, file.fileName);
                        this._sendForVerification(form, file);
                    }
                    else {
                        file.status = FileBulkUploadStatus.Verifying;
                        this.setState({
                            files: this.state.files
                        });
                    }
                });

                return;
            }
        }

        if (justFinished_) {
            ShowAlert("", "Successfully bulk onboarded!", "success");
        }
    }

    private _sendForVerification(form_: FormData, file_: IBulkUploadFileEntry): void {
        this._service.bulkUploadVerify(form_)
            .then(r => {
                if (r.ok) {
                    file_.status = FileBulkUploadStatus.VerificationSuccess;
                    this.setState({
                        files: this.state.files
                    }, () => {
                        file_.status = FileBulkUploadStatus.Onboarding;
                        this._submit(form_, file_);
                    });
                }
                else {
                    r.text().then(t => {
                        file_.validationResult = t.split("\n");
                        file_.status = FileBulkUploadStatus.VerificationFailed;
                        this.setState({
                            files: this.state.files
                        });
                    });
                }
            })
            .catch((ex_) => {
                file_.status = FileBulkUploadStatus.VerificationFailed;
                this.setState({
                    files: this.state.files
                });
            });
    }

    private _submit(form_: FormData, file_: IBulkUploadFileEntry): void {
        this._service.bulkUpload(form_)
            .then(r => {
                if (r.ok) {
                    file_.status = FileBulkUploadStatus.OnboardingSuccess;
                    this.setState({
                        files: this.state.files
                    }, () => {
                        this._startUploadProcess(true);
                    });
                }
                else {
                    file_.status = FileBulkUploadStatus.OnboardingFailed;
                    this.setState({
                        files: this.state.files
                    });
                }
            })
            .catch(() => {
                file_.status = FileBulkUploadStatus.OnboardingFailed;
                this.setState({
                    files: this.state.files
                });
            });
    }
}

interface IBulkUploadState {
    isFileModalOpen: boolean;
    files: IBulkUploadFileEntry[];
    isScreenAccessible: boolean;
}

interface IBulkUploadFileEntry extends IDataRow {
    fileName: string;
    fileType: string;
    fileTypeKey: string;
    fileTypeSequence: number;
    status: FileBulkUploadStatus;
    fileInfo: IFileInfo;
    validationResult?: string[];
    onboardResult?: string;
}

enum FileBulkUploadStatus {
    Submitted,
    Uploading,
    UploadingFailed,
    UploadingSuccess,
    Verifying,
    VerificationFailed,
    VerificationSuccess,
    Onboarding,
    OnboardingFailed,
    OnboardingSuccess
}