// export interface IReservationCount {
//     date: Date;
//     count: number;
// }

import { DateRange } from "materialui-daterange-picker";
import { INumberRange } from "../../Components/DataGrid/Interfaces";
import { ISingleScheduleData } from "../../Components/ScheduleComponent";
import { IBlockSpot } from "../BookingsCalendar/interfaces";

export interface IReservation {
    reservationGuid: string;
    friendlyReservationId: string;
    spotGuid: string;
    friendlySpotId: string;
    parkerGuid: string;
    parkerName: string;
    vehicleGuid: string;
    licensePlateNumber: string;
    propertyGuid: string;
    propertyName: string;
    start: Date;
    end: Date;
    startTimeUtc: Date;
    endTimeUtc: Date;
    reservationAmount: number;
    reservationStatus: number;
    paymentStatus: number;
    stripeTransactionsIds: string;
    description: string;
    reservationStatusText?: string;
    reservationTypeId: number;
    spotAllocations?: ISpotAllocation[];
    isMonthlyReservationActive: boolean;
    isUpcomingMonthlyReservationActive: boolean;
    reservationStatusClassName?: string;
    propertyCity: string;
    isCashPayment?: boolean;
    paidForToday?: number;
    reservationAmountWithoutTax?: number;
    paidForCurrentMonth?: number;
    monthlyBookingAmount?: number;
    spotDetails?: ISpotDetails[];
}

export interface ISpotDetails {
    friendlySpotId: string;
    spotGuid: string;
    spotRecurrenceType: number;
}

export interface ISpotAllocation {
    spotAllocationGuid: string;
    start: Date;
    end: Date;
}

export interface IReservationRequest {
    parkerGuid: string;
    vehicleGuid?: string;
    start: Date;
    end: Date;
}

export interface IVehicle {
    vehicleGuid: string;
    vehiclePlateNumber: string;
}

export function extractDayStartEndFromSpotSchedule(schedules_: ISingleScheduleData[], selectedDate_: Date): INumberRange[] {
    let selectedDateDay = selectedDate_.getDay() + 1;
    let ranges: INumberRange[] = [];

    if (schedules_) {
        for (let sc of schedules_) {
            if (sc.selectedDays && sc.selectedTimeRange) {
                if (sc.selectedDays.indexOf(selectedDateDay) > -1) {
                    ranges.push({
                        start: sc.selectedTimeRange.start,
                        end: sc.selectedTimeRange.end
                    });
                }
            }
        }
    }

    return ranges.sort((s1, s2) => (s1.start as number) - (s2.start as number));
}

export interface IActiveZone {
    zoneGuid: string;
    propertyGuid: string;
    zoneName: string;
}

export interface INewCashBookingRequest {
    zoneGuid: string,
    propertyGuid: string,
    bookingStartUTC: string,
    bookingEndUTC: string,
    spotGuid?: string,
    vehicleLicense: string,
    discount: string | null,
    paymentType?: string
}

export interface IAddCashBookingData {
    zones: IActiveZone[];
    propertyGuid: string;
    spots: IBlockSpot[];
    date_: DateRange;
    startTime: IBlockTime;
    endTime: IBlockTime;
    licensePlate: string;
    selectedZone: IActiveZone | null;
    selectedSpot: IBlockSpot | null;
    isNewItem: boolean;
    transactionType: number;
}

export interface IBlockTime {
    dateTime: Date;
    timeString: string;
}

export interface IPaymentType {
    label: string;
    value: number;
}

export interface IInitiateReservationPayment{
    temporaryBlockGuid?: string,
    paymentCardId?: string,
    paymentIntentType: string
}