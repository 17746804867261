import { Button, Drawer, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from "react";
import AddBlockSpotView from "../../Common/AddBlockSpotView";
import Bookinghistory from "../../Common/BookingHistory/Bookinghistory";
import BookingSummary from '../../Common/BookingSummary';
import { CheckScreenPermission } from "../../Common/CheckScreenPermission";
import RightPanelSearchListView from "../../Common/RightPanelSearchListView";
import { ShowAlertwithConfirm } from "../../Common/ShowAlert";
import ShowModelAlert from "../../Common/ShowModelAlert";
import { IShowModelAlert, ShowModelAlertType } from "../../Common/interface";
import { RightPanelState } from "../../Models/CommonInterfaces";
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";
import SpotService from "../../Services/SpotService";
import { IPropertyBasics } from "../Spots/Interfaces";
import AddEditBookings from "./AddEditBookings";
import "./BookingCalendar.css";
import BookingCalendarInfo from "./BookingCalendarInfo";
import BookingCalendarNav from "./BookingCalendarNav";
import BookingsDailyCalendar from "./BookingsDailyCalendar";
import CalendarProperty from "./CalendarProperty";
import MonthlyCalendar, { ISpotAggregation } from "./MonthlyCalendar";
import { BookingType, IBookingDetailInfo, IDailySpotAggregation, ISearch, IUserSearchModel, IUspGetBookingBlockingStatus, MonthlyBookingType, PropertyBookings } from "./interfaces";
import ScheduledSpots from "./scheduledSpots";

import DatePicker from "./DailyPickers";
import MonthPicker from "./MonthPicker";

export default class BookingsCalendar extends React.Component<
  IBookingsCalendarProps,
  IBookingsCalendarState
> {
  private interval: any;
  private _spotService: SpotService;

  private _durationFlag: string;
  private _default: IBookingDetailInfo = {
    userDetail: {
      parkerEncodeGuid: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      isExistingUser: false
    },
    bookingType: BookingType['Hourly/Daily'],
    selectedProperty: null,
    selectedZone: null,
    selectedSpot: null,
    startTime: {
      dateTime: new Date(),
      timeString: "12:00 PM"
    },
    endTime: {
      dateTime: new Date(),
      timeString: "05:00 PM"
    },
    date: {
      endDate: new Date(),
      startDate: new Date()
    },
    monthlyBookingType: MonthlyBookingType['24/7'],
    vehicleDetail: null,
    selectedMonth: new Date(),
    selectedModifyMonth: new Date(),
    preZoneSpot: []
  }
  private _defaultShowModelAlert: IShowModelAlert = {
    headerTitle: "",
    messageContent: "",
    showHideAlert: false,
    showModelAlertType: ShowModelAlertType.Info
  }
  constructor(props_: IBookingsCalendarProps) {
    super(props_);
    this.state = {
      properties: [],
      selectedProperty: null,
      viewDate: new Date(new Date().setHours(0, 0, 0, 0)),
      calendarType: EnumCalendarType.Monthly,
      spotAggregation: [],
      rightPanelState: RightPanelState.None,
      hasUnsavedChanges: false,
      isBlockSpotOpened: false,
      isShowHistory: false,
      selectedSpotAggregation: null,
      isScheduledSpotsOpened: false,
      bookingsForDay: {
        bookings: [],
        spots: [],
        allSpots: [],
      },
      refreshDailyView: false,
      propertyBlocker: true,
      uspGetBookingBlockingStatus: [],
      isScreenAccessible: false,
      selectedDate: new Date(),
      isAddEditOpen: false,
      isUserSearch: false,
      searchTexts: [],
      searchResult: [],
      userSearchModel: null,
      addEditProperties: [],
      bookingDetailInfo: { ...this._default },
      oldBookingDetailInfo: { ...this._default },
      showModelAlert: { ...this._defaultShowModelAlert },
      isBookingSummaryOpened: false,
      showDrawerAlert: false,
      isFromBookingSummary: false,
      appliedCoupon: "",
      isOnlyRefersh: false,
      bookingDetails: null,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this._spotService = new SpotService();
    this._durationFlag = Math.random().toString();
  }

  async componentDidMount() {
    const isAccessible = await CheckScreenPermission("user-interface-calendar");

    this.setState(
      {
        isScreenAccessible: isAccessible,
      },
      () => {
        if (this.state.isScreenAccessible) {
          this.interval = setInterval(() => this.getStatusOfBookings(), 5000);
          this.loadProperties();
          this.loadProperty();
        }
      }
    );

    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  private loadProperty() {
    this._spotService.getPreBookingProperty()
      .then(r => r.json())
      .then(r => {
        let properties_: IPropertyBasics[] = r;

        this.setState({
          addEditProperties: properties_
        });
      });
  }

  private loadProperties() {
    this._spotService
      .getPropertyAllActiveNames()
      .then((r) => r.json())
      .then((r) => {
        let properties_: IPropertyBasics[] = r;

        let selectedProperty_: IPropertyBasics | null = null;
        let calerdarType_: number = EnumCalendarType.Monthly;

        if (this.props.match.params.source == "spot") {
          const filteredProperty = properties_.filter(
            (x) => x.propertyGuid == this.props.match.params.propertyGuid
          );

          if (filteredProperty.length > 0) {
            selectedProperty_ = filteredProperty[0];
            calerdarType_ = EnumCalendarType.Daily;
            properties_ = properties_.filter(
              (x) =>
                x.isActive == true ||
                x.propertyGuid == this.props.match.params.propertyGuid
            );
          }
        } else {
          properties_ = properties_.filter((x) => x.isActive == true);
        }
        this.setState(
          {
            properties: properties_,
            selectedProperty: selectedProperty_,
            calendarType: calerdarType_,
            propertyBlocker: selectedProperty_ == null,
          },
          () => {
            this.getSpotStatus();
          }
        );
      });
  }

  handleDateChange(date: Date) {
    this.setState({ selectedDate: date, viewDate: date }, () => {
      this.getSpotStatus();
    });
  }

  handleBlockSpotClick = () => {
    this.setState({
      isScheduledSpotsOpened: false,
      isShowHistory: false,
      rightPanelState: RightPanelState.Add,
      isBlockSpotOpened: true,
      
    });
  };

  handleCustomNewBookings = () => {
    this.setState({
      isScheduledSpotsOpened: false,
      rightPanelState: RightPanelState.Add,
      isAddEditOpen: false,
      isUserSearch: true,
      isBlockSpotOpened: false,
      isBookingSummaryOpened: false
    });
  };
  handleShowHistory = (booking: any) => {
    if (!this.state.selectedSpotAggregation?.reservationGuid) {
      return;
    }

    this._spotService
      .getReservationActivity(
        this.state.selectedSpotAggregation.reservationGuid
      )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data) {
              // data.auditTrailDetails = activityData
              this.setState({
                bookingDetails: data,
                isScheduledSpotsOpened: false,
                isAddEditOpen: false,
                isShowHistory: true,
              });
            }
          });
        }
      });
  };
  render() {
    if (!this.state.isScreenAccessible) return null;
    if (this.state.rightPanelState === RightPanelState.Add) {
      this.openNav();
    } else if (this.state.rightPanelState === RightPanelState.Details) {
      this.openNav();
    } else {
      this.closeNav();
    }

    let isAdmin = (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) > -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) > -1);

    return (
      <div className="data-grid-container">
        <ShowModelAlert {...this.state.showModelAlert} />
        {/* Header region of the page */}
        <Grid container className="mb-6">
          <CalendarProperty
            properties={this.state.properties}
            onPropertyChange={(property_: IPropertyBasics | null) => {
              this.setState(
                {
                  selectedProperty: property_,
                  propertyBlocker: property_ == null,
                },
                () => {
                  if (this.state.calendarType == EnumCalendarType.Monthly) {
                    this.getSpotStatus();
                  }
                }
              );
            }}
            selectedProperty={this.state.selectedProperty}
            id="top-property"
          />
        </Grid>
        <div className="px-3">
          <div className="b-calendar-body ">
            <div className="calendar-parent-container">
              <div id="main">
                <div className="b-calendar-header">
                  {/* Calender parent header */}
                  <BookingCalendarNav
                    viewDate={this.state.viewDate}
                    onDateChange={(changedDate: Date) => {
                      this.setState(
                        {
                          viewDate: changedDate,
                        },
                        () => {
                          if (this.state.calendarType == 1) {
                            this.getSpotStatus();
                          }
                        }
                      );
                    }}
                    calendarType={this.state.calendarType}
                  />
                  {/* Calender parent header buttons */}
                  <div className="cal-par-content">
                    <Grid container>
                      <div className="cal-par-grp">
                        {
                          <React.Fragment>
                            <div className="grp-btn">
                              <Button
                                className={
                                  this.state.calendarType === 2
                                    ? "selector"
                                    : ""
                                }
                                onClick={() => this.changeCalendar(2)}
                              >
                                <div
                                  className={
                                    this.state.calendarType === 2
                                      ? "grp-btn-day-unselected"
                                      : "grp-btn-day-selected"
                                  }
                                >
                                  Daily
                                </div>
                              </Button>
                              <Button
                                className={
                                  this.state.calendarType === 1
                                    ? "selector"
                                    : ""
                                }
                                onClick={() => this.changeCalendar(1)}
                              >
                                <div
                                  className={
                                    this.state.calendarType === 1
                                      ? "grp-btn-mn-unselected"
                                      : "grp-btn-mn-selected"
                                  }
                                >
                                  Monthly
                                </div>
                              </Button>
                            </div>
                            <div className="divider"></div>
                            {isAdmin && <Button
                              className="new-bk-btn"
                              onClick={() => {
                                this.handleCustomNewBookings();
                              }}
                            >
                              <span className="new-bk-plus">+</span> New Booking
                            </Button>}

                            <Grid>
                              <Button
                                className="blk-btn"
                                variant="outlined"
                                color="primary"
                                onClick={this.handleBlockSpotClick}
                              >
                                Block Spot
                              </Button>
                            </Grid>
                          </React.Fragment>
                        }
                      </div>
                    </Grid>
                  </div>
                </div>
                {/* Calendar child header */}
                {this.state.calendarType == 2 && (!this.state.isBookingSummaryOpened &&
                  !this.state.isAddEditOpen && !this.state.isUserSearch) && (
                    <DatePicker
                      viewDate={this.state.viewDate}
                      selectedDate={this.state.viewDate}
                      onDateChange={this.handleDateChange}
                    />
                  )}
                {this.state.calendarType === 1 &&
                  this.state.spotAggregation.length !== 0 && (!this.state.isBookingSummaryOpened &&
                    !this.state.isAddEditOpen && !this.state.isUserSearch) && (
                    <MonthPicker
                      key="monthly-picker"
                      viewDate={this.state.viewDate}
                      selectedDate={this.state.viewDate}
                      onDateChange={this.handleDateChange}
                    />
                  )}
              </div>
              {/* Daily calender view*/}
              {(this.state.calendarType == 2 && (!this.state.isBookingSummaryOpened &&
                !this.state.isAddEditOpen && !this.state.isUserSearch)) && (
                  <div
                    className="booking-calendar-container daily-view"
                    style={{
                      width: this.getContainerWidthDaily() + "px",
                      height: this.getContainerHeightDaily() + "px",
                    }}
                    id="daily-view-calendar"
                  >
                    <div className="dummy-header-daily">Spots</div>
                    <BookingsDailyCalendar
                      viewDate={this.state.viewDate}
                      onSpotEventSelected={(
                        spotAggregation: IDailySpotAggregation | null,
                        bookingsForDay_: PropertyBookings
                      ) => {
                        this.setState({
                          selectedSpotAggregation: spotAggregation,
                          rightPanelState:
                            spotAggregation != null
                              ? RightPanelState.Details
                              : RightPanelState.None,
                          isScheduledSpotsOpened: this.state.isBookingSummaryOpened ? false : spotAggregation != null,
                          bookingsForDay: bookingsForDay_,
                          isBlockSpotOpened: false,
                          
                        });
                      }}
                      selectedSpotAggregation={this.state.selectedSpotAggregation}
                      rightPanelStatus={this.state.isScheduledSpotsOpened}
                      selectedProperty={this.state.selectedProperty}
                      refreshState={(rState: boolean) => {
                        this.setState({
                          refreshDailyView: rState,
                        });
                      }}
                      refresh={this.state.refreshDailyView}
                      rightPanelType={this.state.rightPanelState}
                      match={this.props.match}
                      scrollExtent={this.state.isScheduledSpotsOpened}
                    />
                  </div>
                )}
              {/* Monthly calender view*/}
              {this.state.calendarType === 1 &&
                this.state.spotAggregation.length !== 0 && (!this.state.isBookingSummaryOpened &&
                  !this.state.isAddEditOpen && !this.state.isUserSearch) && (
                  <div className="mn-cal-content">
                    <div
                      key="booking-calendar-container"
                      className="booking-calendar-container"
                      style={{
                        width: this.getContainerWidth() + "px",
                        height: this.getContainerHeight() + "px",
                      }}
                    >
                      <MonthlyCalendar
                        viewDate={this.state.viewDate}
                        selectedDates={[this.state.viewDate]}
                        aggregatedSpots={this.state.spotAggregation}
                        onDatesSelect={(date_: Date[]) => {
                          this.setState(
                            {
                              viewDate: date_[0],
                              calendarType: 2,
                            },
                            () => {
                              this.getSpotStatus();
                            }
                          );
                        }}
                      />
                    </div>
                    {/* Right side content, Legends and react calendar */}
                    <div className="right-content">
                      <BookingCalendarInfo
                        viewDate={this.state.viewDate}
                        selectedDate={(s_: Date) => {
                          this.setState(
                            {
                              viewDate: s_,
                              calendarType: 2,
                            },
                            () => {
                              this.getSpotStatus();
                            }
                          );
                        }}
                        onBlockSpotClick={(b_: boolean) => {
                          this.setState({
                            rightPanelState: RightPanelState.Add,
                            isBlockSpotOpened: true,
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        {
          //this.state.selectedProperty == null &&
          this.state.propertyBlocker && this.getPropertyDetais()
        }
        {/* Right side sidenav/drawer*/}
        <div className="sidenav">
          <Drawer
            id="rightSidenav"
            anchor="right"
            open={this.state.rightPanelState != RightPanelState.None}
            onClose={this._onCancelBlockPanel}
            variant={
              this.state.rightPanelState == RightPanelState.Add
                ? "temporary"
                : "persistent"
            }
            className="calendar-right-panal"
          >
            <div className={`mx-3
                ${this.state.isBookingSummaryOpened || this.state.isAddEditOpen || this.state.isUserSearch ? "user-grid-right-panel" : "data-grid-right-panel"}`}>
              {!this.state.isAddEditOpen && !this.state.isBookingSummaryOpened && !this.state.isShowHistory && 
              (<div className="sticky-01"><Grid container className="right-panel-header p-2">
                <Grid item xs>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      this._onCancelBlockPanel();
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </Grid>
              </Grid></div>)}
              {this._getRightPanelContent()}
            </div>
          </Drawer>
        </div>
      </div>
    );
  }

  _getRightPanelContent = () => {
    let drawOpen = <div></div>;
    if (this.state.isBlockSpotOpened) {
      drawOpen = <AddBlockSpotView
        rightPanelState={this.state.rightPanelState}
        selectedSpotAggregation={this.state.selectedSpotAggregation}
        propertyGuid={this.state.selectedProperty?.propertyGuid}
        selectedProperty={this.state.selectedProperty}
        onClose={(rState: boolean) => {
          this.setState({
            rightPanelState:
              this.state.rightPanelState == RightPanelState.Edit
                ? RightPanelState.Details
                : RightPanelState.None,
            isBlockSpotOpened: false,
            isScheduledSpotsOpened:
              this.state.rightPanelState == RightPanelState.Edit &&
              this.state.selectedSpotAggregation != null,
            refreshDailyView: rState,
          });
        }}
      />
    }
    else if (this.state.isScheduledSpotsOpened) {
      drawOpen = <ScheduledSpots
        viewDate={this.state.viewDate}
        bookingsForDay={this.state.bookingsForDay}
        selectedSpotAggregation={this.state.selectedSpotAggregation}
        onSpotAggregationChange={(
          spotAggregation: IDailySpotAggregation
        ) => {
          this.setState({
            selectedSpotAggregation: spotAggregation,
          });
        }}
        onEditCicked={(b_: boolean) => {
          this.setState({
            isScheduledSpotsOpened: false,
            isBlockSpotOpened: true,
            rightPanelState: RightPanelState.Edit,
          });
        }}
        onDelete={(rState: boolean) => {
          this.setState({
            refreshDailyView: rState,
            selectedSpotAggregation: null,
            isBlockSpotOpened: false,
            isScheduledSpotsOpened: false,
            rightPanelState: RightPanelState.None,
          });
        }}
        onShowHistory={this.handleShowHistory}
        onClose={() => {
          this._onCancelBlockPanel();
        }}
      />
    }
    else if (this.state.isAddEditOpen) {
      drawOpen = <AddEditBookings
        onClose={() => {
          this._onCancelBlockPanel();
        }}
        onNext={(bookingDetailInfo: IBookingDetailInfo, userSearchModel: IUserSearchModel | null) => {
          this.setState({
            rightPanelState: RightPanelState.Add,
            isAddEditOpen: false,
            isUserSearch: false,
            bookingDetailInfo,
            isBlockSpotOpened: false,
            isBookingSummaryOpened: true,
            userSearchModel
          }
            // , () => {
            //     this._onCancelBlockPanel();
            // }
          );
        }}
        isFromBookingSummary={this.state.isFromBookingSummary}
        handleChange={(bookingDetailInfo: IBookingDetailInfo) => {
          const { oldBookingDetailInfo } = this.state;
          if (JSON.stringify(bookingDetailInfo) !== JSON.stringify(oldBookingDetailInfo)) {
            this.setState({
              hasUnsavedChanges: true
            });
          }
          else {
            this.setState({
              hasUnsavedChanges: false
            });
          }
        }}
        selectedProperty={this.state.selectedProperty}
        properties={this.state.addEditProperties}
        userDetail={this.state.userSearchModel}
        backToSearch={() => {
          this.setState({
            rightPanelState: RightPanelState.Add,
            isAddEditOpen: false,
            isUserSearch: true,
            bookingDetailInfo: { ...this._default },
            hasUnsavedChanges: false,
            isBlockSpotOpened: false,
            isBookingSummaryOpened: false,
            userSearchModel: null,
            isFromBookingSummary: false
          });
        }}
        bookingDetailInfo={this.state.bookingDetailInfo}
        showModelAlert={(showModelAlert: IShowModelAlert, callback?: () => void) => {
          if (showModelAlert.confirmation || showModelAlert.yesButton) {
            showModelAlert.yesCallBack = () => {
              this.resetShowModelAlert(callback);
            }
          }
          if (showModelAlert.confirmation || showModelAlert.noButton) {
            showModelAlert.noCallBack = () => {
              this.resetShowModelAlert();
            }
          }
          this.setState({ showModelAlert });
        }}
      />
    }
    else if (this.state.isUserSearch) {
      drawOpen = <RightPanelSearchListView
        placeholder="Search by email address"
        showAddButton={true}
        addButtonText=" add new user"
        assignButtonText="Book"
        isOnFocus={true}
        isHeader={true}
        headerText={"User Search"}
        selectedSearchText={this.state.userSearchModel ? this.state.userSearchModel.email : ""}
        userSearch={async (searchText: string) => {
          this._durationFlag = Math.random().toString();
          await this.getUserSerchList(searchText, this._durationFlag);
        }}
        searchTexts={this.state.searchTexts}
        rightPanelCallBack={(id?: string) => {
          if (id) {
            let bookingDetailInfo = this.state.bookingDetailInfo;
            let userSearchModel = this.state.searchResult.find(x => x.parkerEncodeGuid === id) ?? null;

            if (userSearchModel !== null) {
              userSearchModel.isExistingUser = true;
              bookingDetailInfo.userDetail = userSearchModel;
            }
            else {
              bookingDetailInfo = { ...this._default };
            }

            this.setState({
              rightPanelState: RightPanelState.Add,
              isAddEditOpen: true,
              isUserSearch: false,
              userSearchModel,
              bookingDetailInfo,
              hasUnsavedChanges: userSearchModel !== null,
              isBlockSpotOpened: false,
              isBookingSummaryOpened: false
            });
          }
          else {
            this.setState({
              rightPanelState: RightPanelState.Add,
              isAddEditOpen: true,
              isUserSearch: false,
              userSearchModel: null,
              bookingDetailInfo: { ...this._default },
              hasUnsavedChanges: false,
              isBlockSpotOpened: false,
              isBookingSummaryOpened: false
            });
          }
        }}
        clearCallBack={() => {
          this.setState({
            searchResult: [],
            searchTexts: []
          });
        }}
      />
    }
    else if (this.state.isBookingSummaryOpened) {
      const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

      drawOpen =
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <BookingSummary
                handelAlert={(flag: boolean) => {
                  console.log("flag123", flag);
                  this.setState({
                    showDrawerAlert: flag
                  })

                }}
                appliedCoupon={this.state.appliedCoupon}
                isOnlyRefersh={this.state.isOnlyRefersh}
                onClose={(isSuccess?: boolean) => {
                  if (isSuccess) {
                    this.setState({
                      hasUnsavedChanges: false
                    }, this._onCancelBlockPanel);
                    return;
                  }
                  this._onCancelBlockPanel();
                }}
                bookingDetailInfo={this.state.bookingDetailInfo}
                updateBookingDetailInfo={(bookingDetailInfo: IBookingDetailInfo, callBack: () => void) => {
                  this.setState({
                    bookingDetailInfo
                  }, callBack);
                }}
                backToNewBookings={(bookingDetailInfo: IBookingDetailInfo) => {
                  this.setState({
                    rightPanelState: RightPanelState.Add,
                    isAddEditOpen: true,
                    isUserSearch: false,
                    bookingDetailInfo,
                    isBlockSpotOpened: false,
                    isBookingSummaryOpened: false,
                    isFromBookingSummary: true
                  });
                }}
                stripe={stripe} elements={elements}

                // bookingDetailInfo={this.state.bookingDetailInfo}
                propertyGuid={this.state.selectedProperty?.propertyGuid ? this.state.selectedProperty?.propertyGuid : ''}
                showModelAlert={(showModelAlert: IShowModelAlert, callBack?: () => void) => {
                  this.setState({
                    showModelAlert
                  }, callBack);

                }}
                resetHasChange={() => {
                  this.setState({
                    hasUnsavedChanges: false
                  });
                }}
              />
            )}
          </ElementsConsumer>
        </Elements>
    }
    else if (this.state.isShowHistory) {
      drawOpen = (
        <div className="history-top-margin">
          <Bookinghistory
            bookingDetails={this.state.bookingDetails}
            onClose={() => {
              this._onCancelBlockPanel();
            }}
            isButtonEnabled={false}
            backToSchedule={() => {
              this.setState({
                // rightPanelState: RightPanelState.Add,
                isShowHistory: false,
                isBlockSpotOpened: false,

                isScheduledSpotsOpened: true,
              });
            }}
          ></Bookinghistory>
        </div>
      );
    }
    return drawOpen;
  }

  private resetShowModelAlert = (callback?: () => void) => {
    this.setState({
      showModelAlert: { ...this._defaultShowModelAlert }
    }, callback);
  }

  private async getUserSerchList(searchText: string, flag: string) {
    if (searchText) {
      const searchResponse = await this._spotService.getUserSerchList(searchText);
      const searchResponseResult = await searchResponse.json();
      const searchResult: IUserSearchModel[] = searchResponseResult;
      console.log("flag ", flag);
      console.log("_durationFlag ", this._durationFlag);
      if (this._durationFlag !== flag) {
        console.log("skip searchText ", searchText);
        return;
      }
      this.setState({
        searchResult,
        searchTexts: searchResult.map((x: any) => {
          const search: ISearch = {
            label: x.email,
            value: x.parkerEncodeGuid
          };
          return search;
        })
      });
    }
    else {
      if (this._durationFlag !== flag) {
        console.log("skip searchText ", searchText);
        return;
      }
      this.setState({
        searchResult: [],
        searchTexts: []
      });
    }
  }

  private openNav(): void {
    const sidenav = document.getElementById("rightSidenav");
    const main = document.getElementById("main");

    if (sidenav && main) {
      sidenav.style.width = "250px";
      main.style.marginRight = "338px";
    }
  }

  private closeNav(): void {
    const sidenav = document.getElementById("rightSidenav");
    const main = document.getElementById("main");

    if (sidenav && main) {
      sidenav.style.width = "0px";
      main.style.marginRight = "0px";
    }
  }

  private handleResize = () => {
    this.setState({
      rightPanelState: this.state.rightPanelState,
    });
  };

  private getContainerWidth() {
    let rightSidePanalWidth: number = 370;
    let leftPanelWidth: number = 100;
    let paddingCalendar: number = 76;
    let calendarWidth: number =
      window.innerWidth -
      (rightSidePanalWidth + leftPanelWidth + paddingCalendar);
    return calendarWidth;
  }

  private getContainerHeight() {
    let calendarHeader: number = 70;
    let screenHeader: number = 127;

    let calendarHeight: number =
      window.innerHeight - (calendarHeader + screenHeader);

    return calendarHeight;
  }

  private getContainerWidthDaily() {
    let rightSidePanalWidth: number = 0;
    let leftPanelWidth: number = 100;
    let paddingCalendar: number = 58;
    let calendarWidth: number =
      window.innerWidth -
      (rightSidePanalWidth + leftPanelWidth + paddingCalendar);
    return calendarWidth;
  }

  private getContainerHeightDaily() {
    let calendarHeader: number = 70;
    let screenHeader: number = 118;

    let calendarHeight: number =
      window.innerHeight - (calendarHeader + screenHeader);

    return calendarHeight;
  }

  private _onCancelBlockPanel = () => {
    const { isAddEditOpen, hasUnsavedChanges, isBookingSummaryOpened } = this.state;
    if (isAddEditOpen || isBookingSummaryOpened) {
      if (hasUnsavedChanges) {
        const showModelAlert: IShowModelAlert = {
          headerTitle: "Close Without Booking?",
          messageContent: <div style={{ textAlign: 'center' }}>
            <div className="mt-3 bulk-upload-sub-content">
              Are you sure you would like to close before
            </div>
            <div className="mt-1 bulk-upload-sub-content">
              finishing the booking?
            </div>
          </div>,
          showHideAlert: true,
          showModelAlertType: ShowModelAlertType.Error,
          confirmation: true,
          yesButtonText: "Continue",
          noButtonText: "Close",
          yesCallBack: () => {
            this.setState({
              showModelAlert: { ...this._defaultShowModelAlert }
            });
          },
          noCallBack: this.resetState
        }
        this.setState({
          showModelAlert
        });
      }
      else {
        this.resetState();
      }
    }
    else {
      if (this.state.hasUnsavedChanges) {
        ShowAlertwithConfirm("You have unsaved changes!", "Are you sure you want to close this window?", "warning")
          .then(r_ => {
            if (r_) {
              this.resetState();
            }
          })
      }
      else {
        this.updatePanelStatus();
      }
    }
  }

  private updatePanelStatus() {
    this.resetState(() => {
      if (this.state.calendarType == EnumCalendarType.Monthly) {
        this.getSpotStatus();
      }
    });
  }

  private resetState = (callback?: () => void) => {
    this.setState({
      rightPanelState: RightPanelState.None,
      hasUnsavedChanges: false,
      isBlockSpotOpened: false,
      selectedSpotAggregation: null,
      isScheduledSpotsOpened: false,
      isAddEditOpen: false,
      isUserSearch: false,
      userSearchModel: null,
      searchResult: [],
      searchTexts: [],
      bookingDetailInfo: { ...this._default },
      oldBookingDetailInfo: { ...this._default },
      isBookingSummaryOpened: false,
      isFromBookingSummary: false,
      showModelAlert: { ...this._defaultShowModelAlert },
      isShowHistory:false,
    }, callback);
  }

  private getPropertyDetais() {
    return (
      <div
        className="calendar-property-blocker"
        onClick={(event: any) => {
          if (
            event.target &&
            event.target.classList
              .toString()
              .indexOf("calendar-property-blocker") != -1
          ) {
            this.setState({
              propertyBlocker: false,
            });
          }
        }}
      >
        <div className="calendar-property-popup">
          <CalendarProperty
            properties={this.state.properties}
            onPropertyChange={(property_: IPropertyBasics | null) => {
              this.setState(
                {
                  selectedProperty: property_,
                  propertyBlocker: property_ == null,
                },
                () => {
                  if (this.state.calendarType == EnumCalendarType.Monthly) {
                    this.getSpotStatus();
                  }
                }
              );
            }}
            selectedProperty={this.state.selectedProperty}
            id="blocker-property"
          />
        </div>
      </div>
    );
  }

  private changeCalendar(selectedType: EnumCalendarType) {
    let oldSelectedType = this.state.calendarType;
    this.setState(
      {
        calendarType: selectedType,
      },
      () => {
        if (oldSelectedType != selectedType) {
          this.updatePanelStatus();
        }
      }
    );
  }

  private getStatusOfBookings() {
    if (this.state.isBookingSummaryOpened || this.state.isAddEditOpen || this.state.isUserSearch) {
      return;
    }
    this._spotService
      .getBookingBlockingStatus()
      .then((r) => r.json())
      .then((r) => {
        let status: IUspGetBookingBlockingStatus[] = r;

        status = status.map((s) => {
          s.lastUserScan = null;
          s.lastUserSeek = null;
          return s;
        });

        status = status.filter((x) => x.tableName != "ReportParker");

        if (JSON.stringify(status) != JSON.stringify(this.state.uspGetBookingBlockingStatus)) {
          this.setState(
            {
              uspGetBookingBlockingStatus: status,
            },
            () => {
              if (this.state.calendarType == EnumCalendarType.Daily) {
                this.setState({
                  refreshDailyView: true,
                });
              } else {
                this.getSpotStatus(true);
              }
            }
          );
        }
      });
  }

  private getSpotStatus(hideLoader: boolean = false) {
    let selectedDate = new Date(this.state.viewDate);

    if (this.state.selectedProperty != null &&
      this.state.calendarType == EnumCalendarType.Monthly) {
      this._spotService
        .getPropertyBookingCount(
          this.state.selectedProperty.propertyGuid,
          selectedDate.getFullYear(),
          selectedDate.getMonth() + 1,
          hideLoader
        )
        .then((r) => r.json())
        .then((r) => {
          let spotAggregationList: ISpotAggregation[] = r;

          spotAggregationList.forEach((e: ISpotAggregation) => {
            e.start = new Date(e.startDate);
            e.end = new Date(e.endDate);
            e.startDate = new Date(e.startDate);
            e.endDate = new Date(e.endDate);
            e.selectedDate = new Date(
              selectedDate.getFullYear(),
              selectedDate.getMonth(),
              selectedDate.getDate()
            );
          });

          this.setState({
            spotAggregation: spotAggregationList,
          });
        });
    }
  }
}

enum EnumCalendarType {
  Monthly = 1,
  Daily,
}

interface IBookingsCalendarProps {
  match: {
    params: {
      source?: string;
      propertyGuid?: string;
      spotGuid?: string;
    };
  };
  selectedDate: Date;
  onDelete: (b_: boolean) => void;
  onClose?: () => void;
  handleModify?: () => void;
  handleCancel?: () => void;
  backToSchedule: () => void;
  isButtonEnabled: boolean;
  scrollExtent:boolean;
}

interface IBookingsCalendarState {
  properties: IPropertyBasics[];
  selectedProperty: IPropertyBasics | null;
  viewDate: Date;
  calendarType: EnumCalendarType;
  spotAggregation: ISpotAggregation[];
  rightPanelState: RightPanelState;
  hasUnsavedChanges: boolean;
  isBlockSpotOpened: boolean;
  selectedSpotAggregation: IDailySpotAggregation | null;
  isScheduledSpotsOpened: boolean;
  bookingsForDay: PropertyBookings;
  refreshDailyView: boolean;
  propertyBlocker: boolean;
  uspGetBookingBlockingStatus: IUspGetBookingBlockingStatus[];
  isScreenAccessible: boolean;
  selectedDate: Date;
  isShowHistory: boolean;
  isBookingSummaryOpened: boolean;
  isAddEditOpen: boolean;
  isUserSearch: boolean,
  searchResult: IUserSearchModel[],
  searchTexts: ISearch[],
  userSearchModel: IUserSearchModel | null,
  addEditProperties: IPropertyBasics[];
  bookingDetailInfo: IBookingDetailInfo,
  oldBookingDetailInfo: IBookingDetailInfo,
  showModelAlert: IShowModelAlert,
  showDrawerAlert: boolean,
  isFromBookingSummary: boolean,
  appliedCoupon: string,
  isOnlyRefersh: boolean,
  bookingDetails: any;
}
