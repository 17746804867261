import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    customAlert: {
        color: "#663C00",
        backgroundColor: "#FFF4E5"
    }
}));
const ShowWarningMessage = (_props: any) => {
    const classes = useStyles();
    return (
        <>
            <Alert severity="warning" className={classes.customAlert} style={{ 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '12px'}}>
                {_props.message}
            </Alert>
        </>
    )


}
export default ShowWarningMessage;