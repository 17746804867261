import * as React from 'react';
import ScheduleCalendar, { ISlotAggregation } from '../../Components/ScheduleCalendar';
import { Paper } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { DateTimeHelper } from '../../Helpers/DateTimeHelper';
import { IReservation } from './Interfaces';
import ObjectHelper from '../../Helpers/ObjectHelper';
import { IPropertyBasics } from '../Spots/Interfaces';

export default class ReservationCalendar extends React.Component<IReservationCalendarProps, IReservationCalendarState> {
    constructor(props_: IReservationCalendarProps) {
        super(props_);
        let today = this.props.selectedDay;

        this.state = {
            selectedDates: [today],
            viewDate: new Date(today.getFullYear(), today.getMonth(), 1)
        };
    }

    componentDidUpdate(oldProps_: IReservationCalendarProps) {
        if (oldProps_.selectedDay != this.props.selectedDay) {
            this.setState({
                selectedDates: [this.props.selectedDay],
                viewDate: new Date(this.props.selectedDay.getFullYear(), this.props.selectedDay.getMonth(), 1)
            });
        }
    }

    render() {
        return (
            <Paper className="monthly-calendar d-flex flex-column flex-grow-1">
                <div className="calendar-header text-center">
                    <div className="btn-prev-month"
                        onClick={() => {
                            this.setState({
                                viewDate: new Date(this.state.viewDate.getFullYear(), this.state.viewDate.getMonth() - 1, this.state.viewDate.getDate())
                            });
                        }}>
                        <ChevronLeftIcon />
                    </div>
                    <div className="month-name d-inline-block">{DateTimeHelper.monthLabels[this.state.viewDate.getMonth()]}</div>
                    <div className="btn-next-month"
                        onClick={() => {
                            this.setState({
                                viewDate: new Date(this.state.viewDate.getFullYear(), this.state.viewDate.getMonth() + 1, this.state.viewDate.getDate())
                            });
                        }}>
                        <ChevronRightIcon />
                    </div>
                </div>
                <div className="calendar-body flex-grow-1">
                    <ScheduleCalendar
                        aggregatedCount={this._getReservationCount(this.props.reservations, this.state.viewDate)}
                        selectedDates={this.state.selectedDates}
                        viewDate={this.state.viewDate}
                        onDatesSelect={(dates_: Date[]) => {
                            this.setState({
                                selectedDates: dates_
                            }, () => {
                                this.props.onDateSelected(dates_[0]);
                            });
                        }}
                        propertyDetails={this.props.propertyDetails}
                    />
                </div>
            </Paper>
        );
    }

    private _getReservationCount = (reservations_: IReservation[], viewDate_: Date): ISlotAggregation[] => {
        let aggregated: ISlotAggregation[] = [];
        let aggCounts: { [time_: string]: string[] } = {};

        for (let d = -8; d < 40; d++) {
            let date = new Date(viewDate_.getFullYear(), viewDate_.getMonth(), d);
            aggCounts[`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`] = [];
        }

        for (let reservation of reservations_) {
            let date = reservation.start;
            let dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            // var BookedDates: Date[] = this._getDays(ObjectHelper.deepClone(reservation.start), ObjectHelper.deepClone(reservation.end));
            var BookedDates: Date[] = this._getScheduledDays(reservation);

            if (reservation.reservationStatus == 1) {
                if (BookedDates.length > 0) {
                    BookedDates.forEach(currentDate => {
                        let currentDateString = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
                        if (!aggCounts[currentDateString]) {
                            aggCounts[currentDateString] = [reservation.reservationGuid];
                        }
                        else if (aggCounts[currentDateString].indexOf(reservation.reservationGuid) == -1) {
                            aggCounts[currentDateString].push(reservation.reservationGuid);
                        }
                    });
                } else {
                    if (!aggCounts[dateString]) {
                        aggCounts[dateString] = [reservation.reservationGuid];
                    }
                    else {
                        aggCounts[dateString].push(reservation.reservationGuid);
                    }
                }
            }
        }

        for (let a in aggCounts) {
            let date = new Date(a);
            aggregated.push({
                reservationCount: aggCounts[a].length,
                start: date,
                end: date
            });
        }
        console.log(aggregated);
        return aggregated;
    }

    private _getDays(startDate: Date, endDate: Date) {
        let dateArray: Date[] = [];
        let currentDate: Date = startDate;
        currentDate.setHours(0, 0, 0, 0);
        while (currentDate.getTime() <= endDate.getTime()) {
            dateArray.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dateArray;
    }

    private _getScheduledDays(reservation_: IReservation) {
        let dateArray: Date[] = [];

        if (reservation_.spotAllocations && reservation_.spotAllocations.length > 0) {
            reservation_.spotAllocations.forEach(spotAllocation => {
                dateArray.push(spotAllocation.start);
            });
        }

        return dateArray;
    }

    // private _blockTime = (start_: Date, end_: Date, isFullDay_?: boolean): void => {
    //     MockData.Schedules.push({
    //         id: new Date().getTime().toString(),
    //         start: start_,
    //         end: end_,
    //         spotGuid: this.state.selectedSpotGuid as string,
    //         slotDetail: {
    //             isBlocked: true,
    //             isBlockedFullDay: isFullDay_
    //         }
    //     });

    //     ReservationCalendar._mockSchedules = this.filterSchedules(this.state.selectedPropertyGuid as string, this.state.selectedSpotGuid as string);
    //     this.setState({
    //         schedules: ReservationCalendar._mockSchedules,
    //     });
    // }

    // private _doTime(date_: Date): string {
    //     let hours = date_.getHours();
    //     let minutes = date_.getMinutes();
    //     return `${(hours % 12) ? (hours % 12) : 12}:${minutes == 0 ? "00" : minutes} ${hours >= 12 ? "AM" : "PM"}`;
    // }
}

interface IReservationCalendarProps {
    reservations: IReservation[];
    selectedDay: Date;
    onDateSelected: (date_: Date) => void;
    propertyDetails: IPropertyBasics | null;
}

interface IReservationCalendarState {
    selectedDates: Date[];
    viewDate: Date;
}