import React, { Component } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

interface DatePickerProps {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
  viewDate: Date;
}

interface DatePickerState {
  currentDate: Date;
}

class MonthPicker extends Component<DatePickerProps, DatePickerState> {
  constructor(props: DatePickerProps) {
    super(props);

    this.state = {
      currentDate: props.selectedDate,
    };

    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
  }

  handlePrevClick() {
    const prevDate = new Date(this.state.currentDate);
    prevDate.setMonth(prevDate.getMonth() - 12); // Go back 12 month
    this.setState({ currentDate: prevDate });
    // this.props.onDateChange(prevDate);
  }

  handleNextClick() {
    const nextDate = new Date(this.state.currentDate);
    nextDate.setMonth(nextDate.getMonth() + 12); // Go forward 12 month
    this.setState({ currentDate: nextDate });
    // this.props.onDateChange(nextDate);
  }

  render() {
    const dates = [];
    const startDate = new Date(this.state.currentDate);
    startDate.setMonth(startDate.getMonth() - 2);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    for (let i = 0; i < 12; i++) {
      const date = new Date(startDate);
      date.setMonth(startDate.getMonth() + i);
      const month = months[date.getMonth()];
      dates.push({ date, month });
    }

    return (
      <div className="cal-par-head">
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "space-around",
            paddingBottom: "3px",
          }}
        >
          <button className="cal-par-icon" onClick={this.handlePrevClick}>
            <ChevronLeftIcon></ChevronLeftIcon>
          </button>
          {dates.map(({ date, month }) => (
            <div
              key={date.toDateString()}
              className={`date ${
                date.toDateString() === this.props.selectedDate.toDateString()
                  ? "selected-date"
                  : "unselected-date"
              } ${
                date.toDateString() !== this.props.selectedDate.toDateString()
                  ? "hover-effect"
                  : null
              }`}
              onClick={() => {
                if (this.state.currentDate != date) {
                  this.props.onDateChange(date);
                }
              }}
              style={{ flex: 1, textAlign: "center" }}
            >
              <div className="date-content">
                <div className="dayOfWeek">{month}</div>
                <div className="datePick">{date.getFullYear()}</div>
              </div>
            </div>
          ))}
          <button className="cal-par-icon" onClick={this.handleNextClick}>
            <ChevronRightIcon></ChevronRightIcon>
          </button>
        </div>
      </div>
    );
  }
}

export default MonthPicker;
