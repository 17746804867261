import React from "react";
import { Button, Modal, Fade, Backdrop, Grid, TextField, Dialog, Slide, AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { IReservation } from "./Interfaces";
import { format } from "date-fns";
import ClearIcon from '@material-ui/icons/Clear';
import CustomMaps from '../../Components/GoogleMapsComponent';
import AddressDropdown from "../../Components/AddressDropdown";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CancelIcon from '@material-ui/icons/Cancel';
import MapIcon from '@material-ui/icons/Map';
import { IDataGridColumn } from "../../Components/DataGrid/Interfaces";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DataGrid from "../../Components/DataGrid/DataGrid";
import { Autocomplete } from "@material-ui/lab";
import DataGridToolbar from "../../Components/DataGrid/DataGridToolbar";
import { RightPanelState } from "../../Models/CommonInterfaces";
import SpotService from "../../Services/SpotService";
import { ShowAlert, ShowAlertwithConfirm } from '../../Common/ShowAlert';
import { ISingleScheduleData } from "../../Components/ScheduleComponent";
import { getScheduleSummaryText } from "../Spots/Interfaces";
import { Cancel } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default class EditBookingsModal extends React.Component<IEditBookingsModalProps, IEditBookingsModalState> {
    private _spotService: SpotService;
    private _availableSpot: ISpotAvailable[] = [
        {
            spotGuid: "d944fe3d-f4cc-4bc9-9481-5698b0bd3eda",
            friendlySpotId: "001",
            spotSchedule: [],
            spotAmenities: [],
            schedule: [
                {
                    "selectedDays": [
                        1,
                        3,
                        5,
                        2,
                        7,
                        6,
                        4
                    ],
                    "selectedTimeRange": {
                        "start": 600,
                        "end": 840
                    },
                    "selectedPriceRange": {
                        "start": 5.5,
                        "end": 5.5
                    }
                },
                {
                    "selectedDays": [
                        4,
                        6,
                        1,
                        2,
                        3,
                        5,
                        7
                    ],
                    "selectedTimeRange": {
                        "start": 840,
                        "end": 1020
                    },
                    "selectedPriceRange": {
                        "start": 4.0,
                        "end": 4.0
                    }
                }
            ],
            price: 5.0,
            matchingAmenities: ["Covered", "Uncovered"],
            additionalAmenities: ["Electrical Outlets"],
            missingAmenities: ["Charging"]
        },
        {
            spotGuid: "faee5717-6c79-478d-8335-4a4e65fcbdf5",
            friendlySpotId: "002",
            spotSchedule: [],
            spotAmenities: [],
            schedule: [
                {
                    "selectedDays": [
                        1,
                        3,
                        5,
                        2,
                        7,
                        6,
                        4
                    ],
                    "selectedTimeRange": {
                        "start": 600,
                        "end": 840
                    },
                    "selectedPriceRange": {
                        "start": 5.5,
                        "end": 5.5
                    }
                },
                {
                    "selectedDays": [
                        4,
                        6,
                        1,
                        2,
                        3,
                        5,
                        7
                    ],
                    "selectedTimeRange": {
                        "start": 840,
                        "end": 1020
                    },
                    "selectedPriceRange": {
                        "start": 4.0,
                        "end": 4.0
                    }
                }
            ],
            price: 5.0,
            matchingAmenities: ["Covered", "Uncovered"],
            additionalAmenities: ["Electrical Outlets"],
            missingAmenities: ["Charging"]
        }
    ];

    private _spotListColumns: IDataGridColumn<ISpotAvailable>[] = [
        {
            key: "spacer1",
            name: ""
        },
        {
            key: "friendlySpotId",
            name: "SPOT NO"
        },
        {
            key: "spotAmenities",
            name: "Amenities",
            contentProvider: (row_: ISpotAvailable) => {
                return (<div>
                    {
                        row_.spotAmenities.map((s, i) => {
                            return (<span key={i}>
                                <span className={s.type}>{s.amenity}</span>{((row_.spotAmenities.length - 1) != i) ? ", " : ""}
                            </span>)
                        })
                    }
                </div>);
            }
        },
        {
            key: "spotSchedule",
            name: "Schedule(s)",
            contentProvider: (row_: ISpotAvailable) => {
                return (<div>
                    {
                        row_.spotSchedule?.map(s => {
                            return (<div>
                                {s}
                            </div>)
                        })
                    }
                </div>);
            }
        }
        /*,
        {
            key: "price",
            name: "Price",
            textAlignment: "right",
            contentProvider: (row_: ISpotAvailable) => {
                return (
                    <span>
                        ${row_.price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                );
            }
        }*/
    ]

    private _reservationListColumns: IDataGridColumn<IReservation>[] = [
        {
            key: "friendlyReservationId",
            name: "Booking Id"
        },
        {
            key: "parkerName",
            name: "Parker"
        },
        {
            key: "licensePlateNumber",
            name: "License"
        },
        {
            key: "reservationAmount",
            name: "Amount",
            textAlignment: "right",
            contentProvider: (row_: IReservation) => {
                return (
                    <span>
                        ${row_.reservationAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                );
            }
        }
    ]
    constructor(props_: IEditBookingsModalProps) {
        super(props_);

        //["Search Booking", "Spot Selection", "Payment"]
        this.state = {
            isForSingleDay: false,
            selectedNewSpot: null,
            bookingId: "",
            license: "",
            parkerName: "",
            screenNames: ["Search Booking", "Spot Confirmation"],
            isBasicDetailsVisible: false,
            //screenIndex: this.props.selectedReservation ? 1 : 0,
            screenIndex: 0,
            selectedProperty: 0,
            isViewSpotOpened: true,
            IsReservationVisible: false,
            selectedCard: "Master Card - xxxx xxxx 8888",
            reservations: [],
            nearBySpots: {
                availableSpots: [],
                refundPrice: 0,
                originalBookingPrice: 0,
                originalFriendlySpotId: ""
            }
        }
        this.clearUpdateBookingSearchField = this.clearUpdateBookingSearchField.bind(this)
        this._spotService = new SpotService();
    }

    componentDidMount() {
        console.log(this.props.selectedReservation);
        if (this.props.selectedReservation) {
            this.setState({
                bookingId: this.props.selectedReservation.friendlyReservationId,
                parkerName: this.props.selectedReservation.parkerName,
                license: this.props.selectedReservation.licensePlateNumber
            }, () => {
                this.getBookingDetails();
            });

            /*this.setState({
                selectedReservation: this.props.selectedReservation
            });*/
        }
    }

    render() {
        return (
            <Dialog fullScreen open={this.props.isOpen} onClose={() => this.props.onClose(false)} TransitionComponent={Transition}>
                <AppBar className='add-user-appbar'>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="default"
                            onClick={() => this.props.onClose(false)} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className='edit-booking-appbar-title'>Update Booking</Typography>
                        <div className="edit-booking-modal-header">
                            {this.renderHeaderPage()}
                        </div>
                    </Toolbar>
                </AppBar>
                <div className="edit-booking-modal-box">
                    {
                        (this.state.screenIndex == 0) && this.rederSearchBooking()
                    }
                    {
                        this.state.screenIndex == 1 && this.spotSelection()
                    }
                    {
                        this.state.screenIndex == 2 && this.paymentDetails()
                    }
                </div>
            </Dialog>
        )
        /*return (
            <Modal
                className="edit-booking-modal"
                open={this.props.isOpen}
                onClose={this.props.onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={this.props.isOpen}>
                    <div className="edit-booking-modal-box">
                        <div className="edit-booking-modal-header">
                            {this.renderHeaderPage()}
                        </div>
                        {
                            this.state.screenIndex == 0 && this.rederSearchBooking()
                        }
                        {
                            this.state.screenIndex == 1 && this.renderBasicDetails()
                        }
                        {
                            this.state.screenIndex == 2 && this.spotSelection()
                        }
                        {
                            this.state.screenIndex == 3 && this.paymentDetails()
                        }
                    </div>
                </Fade>
            </Modal>
        )*/
    }

    paymentDetails() {
        return (
            <div className="eb-modal-body">
                <div className="edit-booking-modal-body spot-selection">
                    <div className="spot-payment-diff">
                        <table>
                            <tr>
                                <td>New booking amount:</td>
                                <td>$48.00</td>
                            </tr>
                            <tr>
                                <td>Old booking amount:</td>
                                <td>$38.00</td>
                            </tr>
                            <tr>
                                <td>Difference:</td>
                                <td><hr /><hr /><span>$10.00</span></td>
                            </tr>
                        </table>
                    </div>
                    <div className="spot-selection-map">
                        <p>Payment options:</p>

                        <p><label htmlFor="type1"><input type="radio" name="amout" id="type1" /> Initiate payment/refund for the difference amount.</label></p>
                        <p><label htmlFor="type2"><input type="radio" name="amout" id="type2" /> Refund and initiate new payment for new amount on Parker’s card</label></p>
                        <p><label htmlFor="type3"><input type="radio" name="amout" id="type3" /> Initiate new payment for new amount on Gryd’s card<br /> (Amount paid by parker will neighter be refunded nor be distributed. Will stay in Gryd’s account)</label></p>
                        <Autocomplete
                            id="paymentOption"
                            className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                            options={["Master Card - xxxx xxxx 8888", "Visa - xxxx xxxx 4589"]}
                            value={this.state.selectedCard}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => <TextField required {...params} label="Card to pay from" variant="outlined" />}
                            disableClearable={true}
                            onChange={(event: any, value: any) => {
                                this.setState({
                                    selectedCard: value
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="edit-booking-modal-footer">
                    <div className="mt-2 file-upload-buttons text-right">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                ShowAlertwithConfirm("Are you sure?", "Do you really want to update the booking?", "warning")
                                    .then(r_ => {
                                        if (r_) {
                                            ShowAlert('', 'Booking updated successfully.', 'success').then(s_ => {
                                                if (this.props.onClose) {
                                                    this.props.onClose(true);
                                                }
                                            });
                                        }
                                    })
                            }}
                        >Finish Booking</Button>
                    </div>
                </div>
            </div>
        )
    }

    spotSelection() {
        return (
            <div className="eb-modal-body">
                <div className="edit-booking-modal-body spot-selection">
                    <div className="spot-list clickable-rows">
                        {
                            this.state.isViewSpotOpened && this.renderAvailableSpotsList()
                        }
                    </div>
                    <div className="spot-payment-diff">
                        {
                            this.state.selectedNewSpot == null && <div className="amount-msg">Please select new spot to see the amount difference.</div>
                        }

                        {
                            this.state.selectedNewSpot && <div className="total-payment-Diff">
                                <div className="total-payment-table">
                                    <table>
                                        <tr>
                                            <td>Original Booking:</td>
                                            <td><span>${this.getOriginalBookedAmount().toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></td>
                                        </tr>
                                        <tr>
                                            <td>Adjustment ({this.getOriginalBookedSpot()}):</td>
                                            <td><span>-${this.getRebookingAmount().toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></td>
                                        </tr>
                                        <tr>
                                            <td>Rebooking ({this.getSelectedSpot()}):</td>
                                            <td><span>${this.getRebookingAmount().toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></td>
                                        </tr>
                                        {/* <tr>
                                            <td>New booking amount:</td>
                                            <td><span>${this.getNewAmount().toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></td>
                                        </tr>
                                        <tr>
                                            <td>Old booking amount:</td>
                                            <td><span>${this.state.nearBySpots.refundPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></td>
                                        </tr>
                                        <tr>
                                            <td>Difference:</td>
                                            <td><hr /><hr /><span>${this.getTotalAmount()}</span></td>
                                        </tr> */}
                                    </table>
                                </div>
                                <div className="mt-2 file-upload-buttons text-right">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            ShowAlertwithConfirm("Are you sure?", "Do you really want to update the booking?", "warning")
                                                .then(r_ => {
                                                    if (r_) {

                                                        let postData: any = {
                                                            "IsForSingleDay": this.state.isForSingleDay
                                                        }

                                                        let reservationGuid = this.state.selectedReservation ? this.state.selectedReservation?.reservationGuid : "";
                                                        let spotGuid = this.state.selectedNewSpot ? this.state.selectedNewSpot.spotGuid : "";

                                                        this._spotService.updateSpotsRebooking(reservationGuid, spotGuid, postData)
                                                            .then(r => {
                                                                if (r.status == 200) {
                                                                    ShowAlert('', 'Booking updated successfully.', 'success').then(s_ => {
                                                                        if (this.props.onClose) {
                                                                            this.props.onClose(true);
                                                                        }
                                                                    });
                                                                } else {
                                                                    r.text().then(resp => {
                                                                        let errorMsg = resp != '' ? JSON.parse(resp) : "Your session has expired. You will be logged out.";
                                                                        ShowAlert("", errorMsg, "error")
                                                                            .then(() => {
                                                                                if (this.props.onClose) {
                                                                                    this.props.onClose(false);
                                                                                }
                                                                            });
                                                                    });
                                                                }
                                                            });
                                                    }
                                                })
                                        }}
                                    >Update Booking</Button>
                                </div>
                            </div>
                        }
                    </div>                   {/* <div className="spot-selection-list">
                        <div className="selection-list-container">
                            {
                                this.state.nearBySpots.map((spot, index) => {
                                    return (
                                        <div className={"spot-item " + ((index == this.state.selectedProperty) ? "active" : "")}
                                            onClick={() => {
                                                this.setState({
                                                    selectedProperty: index
                                                })
                                            }}
                                            key={index}>
                                            <div className="spot-name-price">
                                                <div className="spot-name">
                                                    {
                                                        <span className="spot-prop-name">{spot.propertyName}</span>
                                                    }
                                                </div>
                                                <div className="spot-price">
                                                    {
                                                        <span>${spot.spotPrice}</span>
                                                    }
                                                </div>
                                            </div>
                                            <p>{spot.propertyAddress}</p>
                                            <p>{spot.propertyAmenities.toString()}</p>
                                            <p>Spot available: {spot.spotAvailable}/{spot.totalSpot}</p>
                                            <div className="spot-name-price">
                                                <div className="spot-name">
                                                    {
                                                        <span className="spot-prop-name">Selected Spot: {spot.selectedSpot}</span>
                                                    }
                                                </div>
                                                <div className="spot-price">
                                                    {
                                                        <ArrowForwardIosIcon
                                                            className="icon-hand"
                                                            onClick={() => {
                                                                this.setState({
                                                                    isViewSpotOpened: true
                                                                })
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="spot-selection-map">
                        {
                            this.state.isViewSpotOpened && this.renderAvailableSpotsList()
                        }
                    </div> */}
                </div>
                {/* <div className="edit-booking-modal-footer">
                    <div className="mt-2 file-upload-buttons text-right">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                this.setState({
                                    screenIndex: 3
                                })
                            }}
                        >Pay</Button>
                    </div>
                </div> */}
            </div>
        )
    }

    getOriginalBookedAmount() {
        let amount = this.state.nearBySpots ? this.state.nearBySpots.originalBookingPrice : 0;

        return amount;
    }

    getOriginalBookedSpot() {
        let friendlySpotId = this.state.nearBySpots ? this.state.nearBySpots.originalFriendlySpotId : "";

        return friendlySpotId;
    }

    getRebookingAmount() {
        let amount = this.state.nearBySpots ? this.state.nearBySpots.refundPrice : 0;

        return amount;
    }

    getNewAmount() {
        let amount = this.state.selectedNewSpot ? this.state.selectedNewSpot.price : 0;

        return amount;
    }

    getSelectedSpot() {
        let friendlySpotId = this.state.selectedNewSpot ? this.state.selectedNewSpot.friendlySpotId : "";

        return friendlySpotId;
    }

    getTotalAmount() {
        let a = this.state.selectedNewSpot ? this.state.selectedNewSpot.price : 0;
        let b = this.state.nearBySpots.refundPrice;

        return (a - b).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    renderAvailableSpotsList() {
        return (
            <DataGrid
                title="Spot"
                data={this.state.nearBySpots.availableSpots}
                defaultSortColumnKey="price"
                columns={this._spotListColumns}
                isRowsNonSelectable={true}
                customPageSize={[10]}
                isNoRowsPerPage={true}
                isNoMoreLink={true}
                onRowClick={(item_: ISpotAvailable) => {
                    this.setState({
                        selectedNewSpot: item_
                    });
                }}
            />
        )
    }

    
    rederSearchBooking() {
        return (
            <div className="eb-modal-body"  onKeyDown={(e)=>{
                this.searchForUpdateBooking(e)
            }} >
                <div className="eb-search-flex">
                    <div className="eb-search-fields">
                        <Grid container spacing={1}>
                            {this.renderTextBox("Booking Id", "bookingId", this.state.bookingId, true)}
                            {this.renderTextBox("Parker", "parkerName", this.state.parkerName, true)}
                            {this.renderTextBox("License", "license", this.state.license, true)}
                        </Grid>
                    </div>
                    <div className="eb-search-button">
                        <Button
                            variant="contained"
                            color="primary"
                            
                            onClick={() => {
                                this.setState({
                                    IsReservationVisible: false,
                                    isBasicDetailsVisible: false,
                                    reservations: []
                                }, () => {
                                    this.getBookingDetails();
                                });
                            }}
                            size="small"
                        >Search</Button>
                    </div>
                </div>

                {
                    (this.state.IsReservationVisible && this.state.reservations.length > 0) && this.renderSearchedData()
                }

                {
                    (this.state.IsReservationVisible && this.state.reservations.length == 0) && this.renderFailedMessage()
                }
            </div>
        );
    }

    renderFailedMessage() {
        return (
            <div className="eb-search-result-container clickable-rows">
                <div className="amount-msg">Please select active booking.</div>
            </div>
        )
    }

    renderSearchedData() {
        return (
            <div className="eb-search-result-container clickable-rows">
                <DataGrid
                    title="Reservation"
                    data={this.state.reservations}
                    columns={this._reservationListColumns}
                    isRowsNonSelectable={true}
                    isNoMoreLink={true}
                    customPageSize={[5, 10, 15]}
                    isNoRowsPerPage={true}
                    onRowClick={(item_: IReservation) => {
                        this.setState({
                            isBasicDetailsVisible: true,
                            selectedReservation: item_
                        })
                    }}
                />
                {
                    this.state.isBasicDetailsVisible && this.renderBasicDetails()
                }
            </div>
        )
    }

    renderBasicDetails() {
        return (
            <div className="eb-modal-body">
                <div className="edit-booking-modal-body">
                    {
                        this.renderInputDetails()
                    }
                </div>
                <div className="edit-booking-modal-footer">
                    {
                        this.state.selectedReservation?.reservationTypeId == 1 &&
                        <div className="mt-2 file-upload-buttons text-right">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    this.getReBookingSpot(false);
                                }}
                                size="small"
                            >Change spot</Button>
                        </div>
                    }

                    {
                        this.state.selectedReservation?.reservationTypeId == 2 &&
                        <div className="mt-2 file-upload-buttons text-right">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    this.getReBookingSpot(false);
                                }}
                                className="mr-button"
                            >Change spot permanently</Button>
                            {
                                this.isCurrentBookingActive() && <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        this.getReBookingSpot(true);
                                    }}
                                    className="mr-button"
                                >Change spot for today</Button>
                            }

                            {/* <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                console.log("dfdd");
                            }}
                        >Pay without changing spot</Button> */}
                        </div>
                    }
                </div>
            </div>
        )
    }

    renderInputDetails() {
        return (
            <Grid container spacing={1}>
                {this.renderTextBox("Booking Id", "BookingId", this.state.selectedReservation?.friendlyReservationId)} 
                {this.renderTextBox("Parker", "Parker", this.state.selectedReservation?.parkerName)}
                {this.renderTextBox("License", "licensePlateNumber", this.state.selectedReservation?.licensePlateNumber)}
                {this.renderTextBox("Booking Type", "BookingType", this.state.selectedReservation?.reservationTypeId == 1 ? "Daily" : "Monthly")}
                {
                    this.state.selectedReservation?.reservationTypeId == 1 &&
                    this.renderTextBox("Start Time", "StartTime", format(this.state.selectedReservation?.start, "dd/MM/yyyy hh:mm aa"))
                }
                {
                    this.state.selectedReservation?.reservationTypeId == 1 &&
                    this.renderTextBox("End Time", "EndTime", format(this.state.selectedReservation?.end, "dd/MM/yyyy hh:mm aa"))
                }

                {
                    this.state.selectedReservation?.reservationTypeId == 2 &&
                    this.renderTextBox("Booking Rate", "BookingRate", this.state.selectedReservation?.description.split("monthly parking")[0])
                }
                {
                    this.state.selectedReservation?.reservationTypeId == 2 &&
                    this.renderTextBox("Start Date", "date", format(this.state.selectedReservation?.start, "dd/MM/yyyy"))
                }
                {this.renderTextBox("City", "City", this.state.selectedReservation?.propertyCity)}
                {this.renderTextBox("Property", "property", this.state.selectedReservation?.propertyName)}
                {this.renderTextBox("Spot", "spot", this.state.selectedReservation?.friendlySpotId)}
            </Grid>
        )
    }

    renderTextBox(lableText: string, lableName: string, inputValue?: string, isEditable?: boolean) {
        return (
            <Grid item xs={4}>
                <TextField
                    variant="outlined"
                    margin="none"
                    fullWidth
                    label={lableText}
                    name={lableName}
                    autoComplete="off"
                    disabled={isEditable ? false : true}
                    value={inputValue}
                    size="small"
                    InputProps={{
                        endAdornment: <IconButton
                        edge="start"
                        color="default"
                        className="edit-cls-btn"
                        onClick={(event) => { 
                            this.clearUpdateBookingSearchField(lableName)
                       }}><ClearIcon fontSize="small" /></IconButton>
                      }}
                    onChange={(e_: any) => {
                       
                        this.setState({
                            ...this.state,
                            [lableName]: e_.target.value
                        })
                    }}
                />
                {/* {
                    inputValue ?
                        <TextField
                            variant="outlined"
                            margin="none"
                            fullWidth
                            label={lableText}
                            name={lableName}
                            autoComplete="off"
                            disabled={isEditable ? false : true}
                            value={inputValue}
                            size="small"
                        />
                        : <TextField
                            variant="outlined"
                            margin="none"
                            fullWidth
                            label={lableText}
                            name={lableName}
                            autoComplete="off"
                            disabled={isEditable ? false : true}
                            size="small"
                        />
                } */}

            </Grid>
        )
    }

    searchForUpdateBooking(event:any){
        // let condition = fieldname=='bookingId' || fieldname=='parkerName' || fieldname=='license'
        if (event.key === 'Enter' ) {
            this.getBookingDetails();
          }
    }
  
    clearUpdateBookingSearchField(fieldname: string) {
        console.log(fieldname) 
        if(fieldname=='bookingId') {
            this.setState({bookingId:''})
        }
        else if(fieldname=='parkerName') {
            this.setState({parkerName:''})
        }
        else if(fieldname=='license') {
            this.setState({license:''})
        }
        

    }

    renderDateField(lableText: string, lableType: string, inputValue?: Date) {
        return (
            <Grid item xs={4}>
                <TextField
                    label={lableText}
                    type={lableType}
                    defaultValue="2017-05-24"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                />
            </Grid>
        )
    }

    renderHeaderPage() {
        return (
            <div className="boonav">
                {
                    !this.props.selectedReservation ?
                        this.state.screenNames.map((screen, index) => {
                            return (
                                <div className={"boonav-container "}
                                    onClick={() => {
                                        if (index == 0) {
                                            this.setState({
                                                screenIndex: index,
                                                selectedNewSpot: null
                                            })
                                        }
                                    }}
                                    key={index}>
                                    {
                                        index + 1 != this.state.screenNames.length && <div className="boonav-dash"></div>
                                    }
                                    <div className="boonav-circle-container">
                                        <div className={"boonav-circle " + ((index <= this.state.screenIndex) ? "active" : "")}></div>
                                        <div className="boonav-lable">{screen}</div>
                                    </div>
                                </div>
                            )
                        })
                        : this.state.screenNames.map((screen, index) => {
                            // index = index + 1;
                            return (
                                <div className={"boonav-container "}
                                    onClick={() => {
                                        if (index == 0) {
                                            this.setState({
                                                screenIndex: index,
                                                selectedNewSpot: null
                                            })
                                        }
                                    }}
                                    key={index}>
                                    {
                                        index + 1 != this.state.screenNames.length && <div className="boonav-dash"></div>
                                    }
                                    <div className="boonav-circle-container">
                                        <div className={"boonav-circle " + ((index <= this.state.screenIndex) ? "active" : "")}></div>
                                        <div className="boonav-lable">{screen}</div>
                                    </div>
                                </div>
                            )
                        })
                }
            </div>
        )
    }

    getBookingDetails() {
        if (!this.state.bookingId && !this.state.parkerName && !this.state.license) {
            ShowAlert("", 'Please enter booking Id/Parker Name/License', "error");
            return false;
        }
        let searchRequest: any = {
            friendlyReservationId: this.state.bookingId,
            parkerName: this.state.parkerName,
            licenseNo: this.state.license
        }

        this._spotService.getParkerBookings(searchRequest)
            .then(r => r.json())
            .then(r => {
                let updatedList = r.map((e: IReservation) => {
                    let reservationStatus = 'Reserved';
                    let className = "";

                    if (e.reservationTypeId == 1) {
                        if (e.reservationStatus == 1 && e.paymentStatus == 3) {
                            reservationStatus = 'Paid';
                            className = "paid";
                        }
                        else if (e.reservationStatus == 2) {
                            reservationStatus = 'Cancelled';
                            className = "cancelled";
                        }
                    }
                    else {
                        if ((e.reservationStatus == 1 && e.isMonthlyReservationActive) && e.paymentStatus == 3) {
                            reservationStatus = 'Paid';
                            className = "paid";
                        }
                        else if (e.reservationStatus == 2 || e.isMonthlyReservationActive) {
                            reservationStatus = "Cancelled"; // 'Unsubscribed';
                            className = "cancelled";
                        }
                    }

                    e.reservationStatusText = reservationStatus;
                    e.reservationStatusClassName = className;
                    e.start = new Date(e.start);
                    e.end = new Date(e.end);
                    return e;
                });

                this.setState({
                    IsReservationVisible: true,
                    reservations: updatedList
                });

                //reservations = updatedList;
            });
    }

    isCurrentBookingActive() {
        var activeBooking = false;
        if (this.state.selectedReservation) {
            var startDate = new Date(this.state.selectedReservation?.start);
            var currentDate = new Date();

            startDate.setHours(0, 0, 0, 0);
            currentDate.setHours(0, 0, 0, 0);

            activeBooking = startDate.getTime() <= currentDate.getTime();
        }


        return activeBooking;
    }

    getReBookingSpot(IsSingleDay: boolean) {
        let reservationGuid = this.state.selectedReservation ? this.state.selectedReservation?.reservationGuid : "";

        let postData: any = {
            "IsForSingleDay": IsSingleDay
        }

        this._spotService.getSpotsForRebooking(reservationGuid, postData)
            .then(resp => {
                if (resp.status == 200) {
                    resp.json().then(r => {
                        if (r.availableSpots.length > 0) {
                            let nearBySpots = this.state.nearBySpots;
                            let updated = r.availableSpots.map((a: ISpotAvailable, i: number) => {

                                // a.price = this.state.selectedReservation ? this.state.selectedReservation.reservationAmount : a.price;

                                let schedule: string[] = [];
                                if (a.schedule) {
                                    a.schedule.map((s, m) => {
                                        let scheduleSummary = getScheduleSummaryText(s);
                                        schedule.push(scheduleSummary[0] + "•" + scheduleSummary[1]);
                                    });
                                }
                                a.spotSchedule = schedule;

                                let spotAmenities: ISpotAmenities[] = [];

                                a.matchingAmenities.forEach(am => {
                                    let spotAmenity: ISpotAmenities = {
                                        type: "additionalAmenities",
                                        amenity: am
                                    };

                                    spotAmenities.push(spotAmenity);
                                });

                                a.additionalAmenities.forEach(am => {
                                    let spotAmenity: ISpotAmenities = {
                                        type: "matchingAmenities",
                                        amenity: am
                                    };

                                    spotAmenities.push(spotAmenity);
                                });

                                a.missingAmenities.forEach(am => {
                                    let spotAmenity: ISpotAmenities = {
                                        type: "missingAmenities",
                                        amenity: am
                                    };

                                    spotAmenities.push(spotAmenity);
                                });

                                a.spotAmenities = spotAmenities;

                                return a;
                            });
                            this.setState({
                                screenIndex: 1,
                                isForSingleDay: IsSingleDay,
                                nearBySpots: {
                                    availableSpots: updated,
                                    refundPrice: r.refundPrice,
                                    originalBookingPrice: r.originalBookingPrice,
                                    originalFriendlySpotId: r.originalFriendlySpotId
                                }
                            });
                        } else {
                            ShowAlert('', 'No spots are available to change.', 'error');
                        }
                    })
                } else {
                    resp.text().then(r => {
                        let errorMsg = r != '' ? JSON.parse(r) : "Your session has expired. You will be logged out.";
                        ShowAlert("", errorMsg, "error")
                            .then(() => {
                                if (this.props.onClose) {
                                    this.props.onClose(false);
                                }
                            });
                    });
                }
            });
    }
}

interface IEditBookingsModalProps {
    isOpen: boolean;
    onClose: (s_: boolean) => void;
    selectedReservation?: IReservation | null;
}

interface IEditBookingsModalState {
    screenNames: string[];
    screenIndex: number;
    nearBySpots: INearBySpots;
    selectedProperty: number;
    isViewSpotOpened: boolean;
    reservations: IReservation[];
    IsReservationVisible: boolean;
    selectedReservation?: IReservation;
    selectedCard: string;
    isBasicDetailsVisible: boolean;
    bookingId: string;
    parkerName: string;
    license: string;
    selectedNewSpot: ISpotAvailable | null;
    isForSingleDay: boolean;
}

interface INearBySpots {
    refundPrice: number;
    originalBookingPrice: number;
    originalFriendlySpotId: string;
    availableSpots: ISpotAvailable[];
}

interface ISpotAvailable {
    spotGuid: string;
    friendlySpotId: string;
    schedule: ISingleScheduleData[] | null;
    price: number;
    matchingAmenities: string[];
    additionalAmenities: string[];
    missingAmenities: string[];
    spotSchedule: string[];
    spotAmenities: ISpotAmenities[];
}

interface ISpotAmenities {
    type: string;
    amenity: string;
}