import React from 'react';
import './Dashboard.css'
import {Checkbox, Grid, TextField} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {
  IDashboardAddRequest,
  IDashboardOverviewModel,
  IManagerPropertyDashboardInformationModel,
  IPropertyItem,
  IUnAuthorized,
  WidgetModuleOption
} from './Interface';
import PropertyService from '../../Services/PropertyService';
import {ShowAlert} from '../../Common/ShowAlert';
import {Autocomplete} from '@material-ui/lab';
import {UserDetailsModel} from '../../Models/UserDetails';
import {GetUserDetailsService} from '../../Services/GetUserDetailsService';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WidgetModule from './WidgetModule';
import ChartUtilization from './ChartUtilization';
import GrydDateRangePicker from '../BookingsCalendar/GrydDateRangePicker';
import {DateRange} from "materialui-daterange-picker";
import {DateTimeHelper} from '../../Helpers/DateTimeHelper';


class ManagerDashboardChartsV2 extends React.Component<DashboardChartsProps, DashboardChartsState> {
  private _propertyService: PropertyService;

  constructor(props: DashboardChartsProps) {
    super(props);

    let widgetList: WidgetModuleOption[] = [
      // {
      //     title: 'Total Revenue<br/>(pre-tax)',
      //     value: '$20,567',
      //     isGrowth: true,
      //     growthValue: '25%'
      // },
      // {
      //     title: 'Total Revenue<br/>(pre-transaction fees)',
      //     value: '$18,975',
      //     isGrowth: true,
      //     growthValue: '35%'
      // },
      {
        title: 'Revenue',
        value: '$0',
        isGrowth: false,
        growthValue: '0%',
        info: "The amount of money expected to be paid out to you.",
        isBuildingContact: GetUserDetailsService.getUserDetails().userRoleID.indexOf(8) !== -1
      },
      {
        title: 'Average Spot<br/>Revenue',
        value: '$0',
        isGrowth: false,
        growthValue: '0%',
        isBuildingContact: GetUserDetailsService.getUserDetails().userRoleID.indexOf(8) !== -1
      },
      {
        title: 'Average<br/>Bookings Daily ',
        value: '0',
        isGrowth: false,
        growthValue: '0%'
      },
      {
        title: 'Total Bookings',
        value: '0',
        isGrowth: false,
        growthValue: '0%'
      },
      {
        title: 'Cancellation Rate',
        value: '0%',
        isGrowth: false,
        growthValue: '0'
      }
    ];

    let unAuthorizedList: IUnAuthorized[] = [
      {
        title: 'scans complete',
        value: '0',
        className: 'gryd-green'
      },
      {
        title: 'unauthorized parkers',
        value: '0',
        className: 'gryd-yellow'
      },
      {
        title: 'vehicles towed',
        value: '0',
        className: 'gryd-green2'
      },
      {
        title: 'unauthorized parkers per scan',
        value: '~0',
        className: 'gryd-green50'
      }
    ];


    this.state = {
      showChart: true,
      showBlocker: false,
      widgetList: widgetList,
      propertyOption: 'YTD',
      properties: [],
      selectedProperty: null,
      unAuthorized: unAuthorizedList,
      date_: {
        endDate: new Date(),
        startDate: new Date()
      },
      dashboardOverviewModel: null
    }

    this._propertyService = new PropertyService();

    window.addEventListener('resize', () => {
      this.updateChart();
    });
  }

  componentDidMount() {
    var _localData = localStorage.getItem('userdata');

    if (_localData != null && _localData !== '') {
      let _userDetails: UserDetailsModel = JSON.parse(_localData);

      this.setState({
        userDetails: _userDetails,
      }, () => {
        this._loadProperties();
      });
    }
  }

  public render() {
    const {dashboardOverviewModel} = this.state;
    return (
      <div>
        {/* <LoaderComponent loaderVisible={this.state.showBlocker} /> */}
        <div className="dashboard-widget-container">
          <div style={{margin: "10px", textAlign: "right"}}><Link to="/dashboard-old">Dashboard V1</Link></div>

          <Grid container>
            {/* <Grid item xs></Grid> */}
            <Grid item>
              <Autocomplete
                id="listPropertyCities"
                size="small"
                className="w-city-dropdown"
                options={this.state.properties}
                openOnFocus={true}
                selectOnFocus={true}
                // disableClearable={true}
                value={this.state.selectedProperty}
                onChange={(event: any, property_: IPropertyItem | null) => {
                  this.setState({
                    selectedProperty: property_
                  }, () => {
                    this._getDashboardOverview();
                  });
                }}
                getOptionLabel={(option) => option.propertyName}
                renderOption={(option, {selected}) => (
                  <React.Fragment>
                    <div className="cl-dropdown-list">
                      <Checkbox
                        icon={<div className="repeat-b-check"/>}
                        checkedIcon={<div className="repeat-b-check selected"/>}
                        style={{marginRight: 8}}
                        checked={selected}
                        color="primary"
                      />
                      {option.propertyName}
                    </div>
                  </React.Fragment>
                )}
                renderInput={(params) => <TextField {...params}
                                                    placeholder="Property Name"
                                                    variant="outlined"
                                                    required={true}
                                                    error={this.state.properties == null}
                />}
              />
            </Grid>
            <Grid item xs={4} className='c-d-date-pick flexbasis'>
              <GrydDateRangePicker
                placeHolder="Date"
                initialDateRange={this.state.date_}
                onDateChange={(dates_: any) => {
                  this.setState({
                    date_: dates_
                  }, () => {
                    this._getDashboardOverview();
                  });
                }}
                required={true}
                // minDate={this.getMinDateRange()}
                maxDate={this.getMaxDateRange()}
                moreOption={true}
                enableMoreOptionText={true}
                noAutoPosition={true}
              />

            </Grid>
          </Grid>
          <div className="w-module-flex-container">
            {
              this.state.widgetList.map((value_, index_) => (
                !value_.isBuildingContact &&
                <div className='dashboard-widget-v2' key={index_}>
                    <WidgetModule
                        module={value_}
                    />
                  {
                    (this.state.selectedProperty?.isPrivateLot === true &&
                      value_.value === '$0') && <div className='blur-box'>Not applicable</div>
                  }
                </div>
              ))
            }
          </div>

          <div className='w-m-table w-m-wrap'>
            <div className='w-m-cell w-m-xs w-m-box w-m-top'>
              <div className='w-m-table'>
                <div className='w-m-cell w-m-xs'><h3 className='w-d-header'>Utilization</h3></div>
                <div className='w-m-cell w-info'><InfoOutlinedIcon/>
                  <div className='w-info-popup'>Spots available is the number of spots active on GrydPark on the set end
                    date.
                    %&nbsp;Utilization is calculated based on an 8 hour day.
                  </div>
                </div>
              </div>
              <div className='w-m-table w-m-full'>
                <div className='w-m-cell w-m-table w-m-full'>
                  {
                    this.state.showChart && <ChartUtilization
                          percentage={Math.abs(Math.round(this._convertTo(dashboardOverviewModel?.utilization ?? 0)))}/>
                  }

                </div>
                <div className='w-m-cell w-d-min-width  w-m-full'>
                  <div className='w-m-table w-m-top'>
                    <div className='w-m-cell'>
                      <div className='w-m-round w-m-dim'></div>
                    </div>
                    <div className='w-m-cell'>
                      <div className='w-d-spot-status'>Spots available</div>
                      <div className='w-d-spot-status'>
                        <b>{this._convertTo(dashboardOverviewModel?.activeSpots ?? 0)}</b> spots
                      </div>
                    </div>
                  </div>
                  <div className='w-m-table w-m-top'>
                    <div className='w-m-cell'>
                      <div className='w-m-round'></div>
                    </div>
                    <div className='w-m-cell'>
                      <div className='w-d-spot-status'>Avg. spots rented out</div>
                      <div className='w-d-spot-status'>
                        <b>~{Math.round(this._convertTo(dashboardOverviewModel?.utilizationAvgSpotsRentOut ?? 0))}</b> spots
                      </div>
                    </div>
                  </div>
                  <div className='w-m-top'>
                    <div
                      className='w-d-spot-count'>{this._convertTo(dashboardOverviewModel?.activeSpots ?? 0)}/{this._convertTo(dashboardOverviewModel?.totalSpots ?? 0)}</div>
                    <div className='w-d-spot-status'>available on app</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-m-cell w-m-xs w-m-box w-m-top'>
              <div className='w-m-table'>
                <div className='w-m-cell w-m-xs'><h3 className='w-d-header'>Visitor Parking Utilization</h3></div>
                <div className='w-m-cell w-info'><InfoOutlinedIcon/>
                  <div className='w-info-popup'>% Utilization is calculated based on an 8 hour day.</div>
                </div>
              </div>
              <div className='w-m-table w-m-full'>
                <div className='w-m-cell w-m-table w-m-full'>
                  {
                    this.state.showChart && <ChartUtilization
                          percentage={Math.abs(Math.round(this._convertTo(dashboardOverviewModel?.visitorUtilization ?? 0)))}/>
                  }
                </div>
                <div className='w-m-cell w-m-full'>
                  <div className='w-m-table w-m-top'>
                    <div className='w-m-cell'>
                      <div className='w-m-round w-m-dim'></div>
                    </div>
                    <div className='w-m-cell'>
                      <div className='w-d-spot-status'>Active visitor spots</div>
                      <div className='w-d-spot-status'>
                        <b>{this._convertTo(dashboardOverviewModel?.activeVisitorSpots ?? 0)}</b></div>
                    </div>
                  </div>
                  <div className='w-m-table w-m-top'>
                    <div className='w-m-cell'>
                      <div className='w-m-round'></div>
                    </div>
                    <div className='w-m-cell'>
                      <div className='w-d-spot-status'>Avg. spots booked</div>
                      <div className='w-d-spot-status'>
                        <b>~{Math.round(this._convertTo(dashboardOverviewModel?.avgVisitorSpotsBooked ?? 0))}</b></div>
                    </div>
                  </div>
                  <div className='w-m-top'>
                    <div
                      className='w-d-spot-count'>{this._convertTo(dashboardOverviewModel?.visitorBooking ?? 0)} Bookings
                    </div>
                    <div className='w-d-spot-status'>during time period</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-module-p-container w-m-top w-m-padding'>
            <h3 className='w-d-header'>Unauthorized Parking </h3>
            <div className='w-m-table w-m-wrap'>
              {
                this.state.unAuthorized.map((value_, index) => (
                  <div className={'w-m-unauth ' + value_.className} key={index}>
                    <div className='w-unauth-value'>{value_.value}</div>
                    <div className='w-unauth-title'>{value_.title}</div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>


      </div>
    )
  }

  private _convertTo(value: number) {
    //TODO remove funciton and usages for good
    return value;
  }

  private _getDashboardOverview() {
    const {selectedProperty, date_, widgetList, unAuthorized} = this.state;
    if (!selectedProperty) {
      ShowAlert("", "Please select property", "error");
      return false;
    }
    if (!date_?.startDate || !date_?.endDate) {
      ShowAlert("", "Please select the date", "error");
      return false;
    }
    const dashboardRequest: IDashboardAddRequest = {
      propertyGuid: selectedProperty.propertyGuid,
      startDate: DateTimeHelper.LocalToUtcString(date_.startDate),
      endDate: DateTimeHelper.LocalToUtcString(date_.endDate)
    }
    this._propertyService.GetDashboardOverview(dashboardRequest)
      .then(dashboardResponse => {
        if (dashboardResponse.ok) {
          dashboardResponse.json().then(response => {
            const dashboardOverviewModel: IDashboardOverviewModel = response;

            //Revenue
            widgetList[0].growthValue = `${this._convertTo(Math.abs(Math.round(dashboardOverviewModel.revenuePreviousPercentage)))}%`;
            widgetList[0].isGrowth = dashboardOverviewModel.revenuePreviousPercentage > 0;
            widgetList[0].value = `$${Number.isInteger(dashboardOverviewModel.revenue) ?
              this._convertTo(Number(dashboardOverviewModel.revenue)).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
              this._convertTo(Number(dashboardOverviewModel.revenue)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

            //AverageSpot
            widgetList[1].growthValue = `${this._convertTo(Math.abs(Math.round(dashboardOverviewModel.averageSpotRevenuePreviousPercentage)))}%`;
            widgetList[1].isGrowth = dashboardOverviewModel.averageSpotRevenuePreviousPercentage > 0;
            widgetList[1].value = `$${Number.isInteger(dashboardOverviewModel.averageSpotRevenue) ?
              this._convertTo(Number(dashboardOverviewModel.averageSpotRevenue)).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
              this._convertTo(Number(dashboardOverviewModel.averageSpotRevenue)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

            //AverageBookingDaily
            widgetList[2].growthValue = `${this._convertTo(Math.abs(Math.round(dashboardOverviewModel.averageBookingsDailyPreviousPercentage)))}%`;
            widgetList[2].isGrowth = dashboardOverviewModel.averageBookingsDailyPreviousPercentage > 0;
            widgetList[2].value = `${Math.abs(Math.round(this._convertTo(dashboardOverviewModel.averageBookingsDaily)))}`;

            //TotalBooking
            widgetList[3].growthValue = `${this._convertTo(Math.abs(Math.round(dashboardOverviewModel.totalBookingsPreviousPercentage)))}%`;
            widgetList[3].isGrowth = dashboardOverviewModel.totalBookingsPreviousPercentage > 0;
            widgetList[3].value = `${this._convertTo(dashboardOverviewModel.totalBookings)}`;

            //CancellationRate
            widgetList[4].growthValue = `${this._convertTo(Math.abs(Math.round(dashboardOverviewModel.cancellationRatePreviousPercentage)))}%`;
            widgetList[4].isGrowth = dashboardOverviewModel.cancellationRatePreviousPercentage > 0;
            widgetList[4].value = `${this._convertTo(Math.abs(Math.round(dashboardOverviewModel.cancellationRate)))}`;

            //Scan Completed
            unAuthorized[0].value = `${this._convertTo(dashboardOverviewModel.scansComplete)}`;

            //UnAuthorized Parkers
            unAuthorized[1].value = `${this._convertTo(dashboardOverviewModel.unauthorizedParkers)}`;

            //UnAuthorized Parker per scan
            unAuthorized[3].value = `~${Math.abs(Math.round(this._convertTo(dashboardOverviewModel.unauthorizedParkersPerScan)))}`;

            this.setState({dashboardOverviewModel, widgetList, unAuthorized});
          });
        }
      });
  }

  private _loadProperties() {
    this._propertyService.getManagerDashboardProperties()
      .then(data => {
        if (data.ok) {
          data.json().then(d => {
            let _mappedProperties = d.map((r: any) => {
              return r;
            });

            let selectedProperty_: IPropertyItem | null = null;

            if (_mappedProperties.length > 0) {
              selectedProperty_ = _mappedProperties[0];
            }

            this.setState({
              properties: _mappedProperties,
              selectedProperty: selectedProperty_
            }, () => {
              console.log(this.state.selectedProperty)
              this._getDashboardOverview();
            });
          })
        }
      })
      .catch((error) => {
        console.error('Error:', JSON.stringify(error));
      });
  }

  private getMaxDateRange() {
    let currentDate = new Date();
    return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  }

  private updateChart() {
    this.setState({
      showChart: false
    }, () => {
      this.setState({
        showChart: true
      })
    })
  }
}

interface DashboardChartsProps {
}

interface DashboardChartsState {
  managerPropertyDashboardInformation?: IManagerPropertyDashboardInformationModel;
  showBlocker: boolean;
  widgetList: WidgetModuleOption[];
  propertyOption: string;
  properties: IPropertyItem[];
  selectedProperty: IPropertyItem | null;
  userDetails?: UserDetailsModel;
  unAuthorized: IUnAuthorized[];
  date_: DateRange;
  dashboardOverviewModel: IDashboardOverviewModel | null;
  showChart: boolean;
}

export default ManagerDashboardChartsV2;
