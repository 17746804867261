import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import SpotService from "../../Services/SpotService";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DeleteIcon from "@material-ui/icons/Delete";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CalendarTodayRoundedIcon from "@material-ui/icons/CalendarTodayRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import format from "date-fns/format";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import "./ScheduledSpots.css";
import { Autocomplete } from "@material-ui/lab";
import { ShowAlertwithConfirm, ShowAlert } from "../../Common/ShowAlert";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AutoCompleteBlockSpots from "./AutoCompleteBlockSpots";
import { Row, Col } from "reactstrap";
import {
  EnumCalendarType,
  EnumRepeatType,
  IDailySpotAggregation,
  PropertyBookings,
} from "./interfaces";
import ObjectHelper from "../../Helpers/ObjectHelper";
import Bookinghistory from "../../Common/BookingHistory/Bookinghistory";
import { BorderBottom } from "@material-ui/icons";

export default class ScheduledSpots extends React.Component<
  IScheduledSpotProps,
  IScheduledSpotState
> {
  private reasonList: string[] = [
    "Event",
    "Maintenance",
    "Visitor use",
    "Other",
  ];
  private repeatList: string[] = [
    "Don't repeat",
    "Repeat daily",
    "Repeat weekly",
    "Repeat monthly",
  ];
  private _spotService: SpotService;

  constructor(props: IScheduledSpotProps) {
    super(props);
    this.state = {
      showFilter: false,
      filteredSpots: [],
      selectedSpots: [],
      selectedStartTime: null,
      times: this.getTimes(),
      filteredBookings: {
        bookings: [],
        spots: [],
        allSpots: [],
      },
      bookingsForDay: {
        bookings: [],
        spots: [],
        allSpots: [],
      },
      isFiltered: false,
      filderDropdownButton: false,
      bookingDetails: null,
    };

    this._spotService = new SpotService();
  }
  componentDidMount() {
    window.addEventListener(
      "click",
      (event: any) => {
        this.getDropDownDomElementValue(event);
      },
      false
    );

    this.setState(
      {
        filteredBookings: this.props.bookingsForDay,
      },
      () => {
        this.filterArray();
      }
    );
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      JSON.stringify(this.props.bookingsForDay) !=
      JSON.stringify(this.state.bookingsForDay)
    ) {
      this.setState(
        {
          bookingsForDay: this.props.bookingsForDay,
          filteredBookings: this.props.bookingsForDay,
          selectedStartTime: null,
          selectedSpots: [],
        },
        () => {
          this.filterArray();
        }
      );
    } else if (
      JSON.stringify(this.state.selectedSpotAggregation) !=
      JSON.stringify(this.props.selectedSpotAggregation)
    ) {
      this.setState(
        {
          selectedSpotAggregation: this.props.selectedSpotAggregation,
          showFilter: false,
        },
        () => {
          this.applyFilter();
        }
      );
    } else if (prevState.showFilter !== this.state.showFilter) {
      // code for clearing filter when showFilter changes
      this.clearFilter();
    }
  }

  private handleFilter() {
    let showFilter = !this.state.showFilter;
    this.setState({ showFilter: showFilter });
  }

  private clearFilter() {
    this.setState(
      {
        selectedStartTime: null,
        selectedSpots: [],
        isFiltered: false,
      },
      () => {
        this.applyFilter();
      }
    );
  }

  public render() {
    return this.state.showFilter ? this.showFilter() : this.showSelectedSpots();
  }
  private showFilter() {
    return (
      <div className="scheduled-spot-container">
        <div className="cl-filter-btn-schedule">
          <Tooltip title="Filter list">
            <IconButton
              aria-label="Back"
              onClick={() => {
                this.handleFilter();
              }}
            >
              <ArrowBackIcon color="action" fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
        <div className="scheduled-spot-header filter-head">
          <h3 className="cl-details-header">Filter</h3>
        </div>

        <div className="scheduled-spot-filter-body">
          <div className="cl-block-sc">
            <div className="bl-sp-lable">Spot Number</div>
            <AutoCompleteBlockSpots
              onBlockSpotSelected={(values: IBlockSpot[]) => {
                this.setState({
                  selectedSpots: values,
                });
              }}
              spots={this.state.bookingsForDay.spots}
              selectedSpot={this.state.selectedSpots}
              enableSelectAll={true}
            />
          </div>
          <div className="cl-block-sc">
            <div className="bl-sp-lable">Start Time</div>
            <div className="cl-flex">
              <div className="cl-flex-cl cl-r-padding">
                <div className="cl-block-input-c">
                  <AccessTimeIcon className="cl-icon" />
                  <Autocomplete
                    id="cl-start-time"
                    className="cl-time-dropdown"
                    options={this.state.times}
                    value={this.state.selectedStartTime}
                    onChange={(event: any, value: IBlockTime | null) => {
                      this.setState({
                        selectedStartTime: value,
                      });
                    }}
                    openOnFocus={true}
                    selectOnFocus={true}
                    getOptionLabel={(option) => option.timeString}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>{option.timeString}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Start Time"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Row className="mt-3 mx-0">
            <Col>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  this.setState(
                    {
                      isFiltered: true,
                      showFilter: false,
                    },
                    () => {
                      this.applyFilter();
                    }
                  );
                }}
              >
                Save
              </Button>
            </Col>
            <Col>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => {
                  this.clearFilter();
                }}
              >
                Clear
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  private showSelectedSpots() {
    return (
      <div>
        <div className="sticky">
          <div className="scheduled-spot-container">
            <div className="scheduled-spot-header">
              <h3 className="cl-details-header">{`${format(
                this.props.viewDate,
                "EEE dd, MMM, yyyy"
              )}`}</h3>
              <div style={{ backgroundColor: "#F5F5F5", borderRadius: "5px" }}>
                <div
                  className="filter-daily-check-container"
                  style={{
                    width: "58px",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                  id="filter-dcc"
                >
                  {/* <Checkbox
                            checked={this.state.isBlockFiltered || this.state.isBookingFiltered}
                            color="primary"
                            className="filter-daily-check" /> */}
                  {this.state.selectedSpotAggregation != null &&
                    this.state.selectedSpotAggregation.bookedType ==
                      EnumCalendarType.Booked && (
                      <div className="c-bo-02 dummy-c-02">&#8195;</div>
                    )}

                  {/* {this.state.selectedSpotAggregation != null &&
                this.state.selectedSpotAggregation.bookedType ==
                  EnumCalendarType.Blocked && (
                  <div className="c-bl dummy-c">&#8195;</div>
                )} */}
                  {/* <Tooltip
                title="Filter Booking"
                placement="left"
                className={
                  "h-btn-spots " +
                  (this.state.filderDropdownButton ? "selected" : "")
                }
              >
                <IconButton
                  aria-label="filter list"
                  onClick={() => {
                    this.setState({
                      filderDropdownButton: !this.state.filderDropdownButton,
                    });
                  }}
                >
                  {!this.state.filderDropdownButton && (
                    <KeyboardArrowDownIcon />
                  )}

                  {this.state.filderDropdownButton && <KeyboardArrowUpIcon />}
                </IconButton>
              </Tooltip> */}
                  <div className="dropdown">
                    <ExpandMoreIcon className="dropbtn"></ExpandMoreIcon>
                    <div className="dropdown-content">
                      <div className="c-legends-01">
                        <div className="c-bo-1">Bookings</div>
                        <div className="c-bl-1">Blockings</div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {this.state.filderDropdownButton && (
                <div className="cl-filter-booking">
                  <div className="c-legends">
                    <div className="c-bo">Bookings</div>
                    <div className="c-bl">Blockings</div>
                  </div>
                   <FormGroup>
                                    <FormControlLabel control={<Checkbox
                                        onChange={() => {
                                            this.setState({
                                                isBookingFiltered: !this.state.isBookingFiltered
                                            }, () => {
                                                this.applyFilter();
                                            })
                                        }}
                                        checked={this.state.isBookingFiltered}
                                        color="primary"
                                        className="cl-booking-check" />} label="Bookings" />
                                    <FormControlLabel control={<Checkbox
                                        onChange={() => {
                                            this.setState({
                                                isBlockFiltered: !this.state.isBlockFiltered
                                            }, () => {
                                                this.applyFilter();
                                            })
                                        }}
                                        checked={this.state.isBlockFiltered}
                                        color="primary"
                                        className="cl-blocking-check" />} label="Blockings" />
                                </FormGroup> 
                </div>
              )} */}
              </div>
              <Tooltip
                title="Filter list"
                placement="left"
                className="h-btn-spots"
              >
                <IconButton
                  aria-label="filter list"
                  onClick={() => {
                    this.handleFilter();
                  }}
                >
                  <FilterListIcon
                    color={this.state.isFiltered ? "primary" : undefined}
                  />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
        <div>
          <div className="scheduled-spot-list">
            {this.state.filteredSpots.map((timeAsKey: number) => {
              return (
                <>
                  <h6 className="scheduled-time">
                    {this.formatAMPM(new Date(timeAsKey))}
                  </h6>

                  {this.state.filteredBookings.bookings.map(
                    (booking: IDailySpotAggregation) => {
                      return (
                        booking.spotStartTimeLocal.getTime() === timeAsKey && (
                          <>
                            <div
                              id={
                                booking.reservationGuid + booking.spotBlockGuid
                              }
                              onClick={() => {
                                this.selectBooking(booking);
                              }}
                              className={
                                "each-spot-container " +
                                (booking.bookedType == 1 ? "bo-s" : "bl-s") +
                                (this.isObjectSelected(booking)
                                  ? " selected"
                                  : "")
                              }
                              style={{ borderBottom: "none" }}
                            >
                              <div className="spot-details">
                                {booking.bookedType === 1 && (
                                  <div className="sd-p-n">
                                    {booking.parkerName}
                                  </div>
                                )}

                                {
                                  // Blocked - {this.reasonList[booking.reasonType != null ? booking.reasonType : 0]}
                                  booking.bookedType === 2 && (
                                    <div className="sd-p-n">
                                      {booking.friendlySpotId}
                                    </div>
                                  )
                                }
                                {/* <div className="sd-p-n">{booking.parkerName}</div> */}
                                <div className="booked-details">
                                  <AccessTimeIcon fontSize="small" />
                                  <div>
                                    {this.formatAMPM(
                                      booking.spotStartTimeLocal
                                    )}
                                    -{this.formatAMPM(booking.spotEndTimeLocal)}
                                  </div>
                                </div>
                              </div>
                              <div className="spot-details-rgt">
                                {booking.bookedType === 1
                                  ? this.bookedSpot(booking)
                                  : this.blockedSpot(booking)}
                                {booking.bookedType === 1 &&
                                this.isObjectSelected(booking) ? (
                                  <div className="icon-st-01">
                                    <Button
                                      className="btn-st-info"
                                      size="small"
                                      onClick={() =>
                                        this.props.onShowHistory(booking)
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle"
                                        aria-hidden="true"
                                        style={{ marginRight: "5px" }}
                                      ></i>
                                      <span style={{ marginLeft: "0px" }}>
                                        Info
                                      </span>
                                    </Button>
                                  </div>
                                ) : null}
                              </div>
                              {booking.bookedType === 1 &&
                              this.isObjectSelected(booking) ? (
                                <div>{this.note()}</div>
                              ) : null}
                            </div>
                            <div className="divider-rgt-pan"></div>
                          </>
                        )
                      );
                    }
                  )}
                </>
              );
            })}
          </div>
        </div>
        {this.state.filteredBookings.bookings.length == 0 &&
          this.state.isFiltered && (
            <div className="cl-no-record">No records found</div>
          )}
      </div>
    );
  }

  private applyFilter() {
    let filtetedBooking_: PropertyBookings = ObjectHelper.deepClone(
      this.state.bookingsForDay
    );

    if (this.state.selectedSpots.length > 0) {
      filtetedBooking_.bookings = this.state.bookingsForDay.bookings.filter(
        (booking) => {
          return (
            JSON.stringify(this.state.selectedSpots).indexOf(
              booking.spotGuid
            ) != -1
          );
        }
      );
    }

    if (this.state.selectedStartTime != null) {
      let d_ = this.state.selectedStartTime.dateTime;
      let selectedDateTime = new Date(
        new Date(this.props.viewDate).setHours(
          d_.getHours(),
          d_.getMinutes(),
          0,
          0
        )
      );
      filtetedBooking_.bookings = filtetedBooking_.bookings.filter(
        (booking) => {
          // if (this.state.selectedStartTime != null) {
          //     console.log(booking.spotStartTimeLocal, selectedDateTime)
          // }
          return (
            booking.spotStartTimeLocal.getTime() >= selectedDateTime.getTime()
          );
        }
      );
    }

    if (this.state.selectedSpotAggregation != null) {
      filtetedBooking_.bookings = filtetedBooking_.bookings.filter(
        (booking) => {
          return (
            booking.bookedType == this.state.selectedSpotAggregation?.bookedType
          );
        }
      );
    }

    filtetedBooking_.bookings = this.sortBookingBySpots(
      filtetedBooking_.bookings
    );

    /*if (this.state.isBookingFiltered && !this.state.isBlockFiltered) {
            filtetedBooking_.bookings = filtetedBooking_.bookings.filter(booking => {
                return (booking.bookedType == EnumCalendarType.Booked);
            });
        } else if (!this.state.isBookingFiltered && this.state.isBlockFiltered) {
            filtetedBooking_.bookings = filtetedBooking_.bookings.filter(booking => {
                return (booking.bookedType == EnumCalendarType.Blocked);
            });
        }*/

    this.setState(
      {
        filteredBookings: filtetedBooking_,
      },
      () => {
        this.filterArray();
      }
    );
  }

  private sortBookingBySpots(bookings: IDailySpotAggregation[]) {
    return bookings.sort(
      (a: IDailySpotAggregation, b: IDailySpotAggregation) => {
        let result: number;
        let reA = /[^a-zA-Z]/g;
        let reN = /[^0-9]/g;

        let aValue = String(a.friendlySpotId);
        let bValue = String(b.friendlySpotId);

        let aA = aValue.toLocaleLowerCase().replace(reA, "");
        let bA = bValue.toLocaleLowerCase().replace(reA, "");

        if (aA === bA) {
          var aN = parseInt(aValue.toLocaleLowerCase().replace(reN, ""), 10);
          var bN = parseInt(bValue.toLocaleLowerCase().replace(reN, ""), 10);

          result = aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
          result = aA > bA ? 1 : -1;
        }

        return result * 1;
      }
    );
  }
  // Do not change anything
  private isObjectSelected(booking: IDailySpotAggregation) {
    type ScrollOptions = ScrollIntoViewOptions & { offset?: number };
    if (this.props.selectedSpotAggregation) {
      var sb = this.props.selectedSpotAggregation;
      let slotElem = document.getElementById(
        sb.reservationGuid + sb.spotBlockGuid
      ) as HTMLElement;
      if (slotElem != null) {
        let rect = slotElem.getBoundingClientRect();
        if (
          rect.bottom > window.innerHeight ||
          rect.top < 62 ||
          rect.right > window.innerWidth ||
          rect.left < 0
        ) {
          let options: ScrollOptions = {
            behavior: "smooth",
            block: "center",
            inline: "nearest",
            offset: -100,
          };
          slotElem.scrollIntoView(options);
        }
      }

      if (booking.bookedType == EnumCalendarType.Booked) {
        return (
          this.props.selectedSpotAggregation.reservationGuid +
            this.props.selectedSpotAggregation.spotBlockGuid ==
          booking.reservationGuid + booking.spotBlockGuid
        );
      } else {
        return (
          this.props.selectedSpotAggregation.reservationGuid +
            this.props.selectedSpotAggregation.spotBlockGuid ==
          booking.reservationGuid + booking.spotBlockGuid
        );
      }
    }

    return false;
  }

  private selectBooking(booking: IDailySpotAggregation) {
    if (this.props.onSpotAggregationChange) {
      this.props.onSpotAggregationChange(booking);
    }
  }

  private bookedSpot(booking: IDailySpotAggregation) {
    return (
      <div>
        <span>Spot: {booking.friendlySpotId}</span>
        <span className="spot-details-bull-01">&bull;</span>
        <span>License plate: {booking.licenseNo}</span>
      </div>
    );
  }

  private note() {
    const { selectedSpotAggregation } = this.props;
    let truncatedNotes = "";

    if (
      selectedSpotAggregation &&
      selectedSpotAggregation.notes !== null &&
      selectedSpotAggregation.notes !== undefined
    ) {
      truncatedNotes = selectedSpotAggregation.notes.slice(0, 1000);

      return (
        <p className="spot-details-number">
          <strong>Note: </strong>
          {truncatedNotes}
        </p>
      );
    }
  }

  private blockedSpot(booking: IDailySpotAggregation) {
    return (
      <div className="block-container">
        <div>
          Repeat:
          {this.repeatList[booking.repeatType != null ? booking.repeatType : 0]}
        </div>
        <div className="block-container-icons">
          <div className="edit-calender">
            <Tooltip title="Edit Blocking">
              <IconButton
                aria-label="Edit Blocking"
                onClick={() => {
                  if (this.props.onEditCicked) {
                    this.props.onEditCicked(true);
                  }
                }}
              >
                <CalendarTodayRoundedIcon fontSize="small" />
                <EditRoundedIcon
                  fontSize="small"
                  className="edit-calender-pen"
                />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Delete Blocking">
              <IconButton
                aria-label="Delete Blocking"
                onClick={() => {
                  ShowAlertwithConfirm(
                    "Delete Entry",
                    "Are you sure you want to delete?",
                    "warning"
                  ).then((r_) => {
                    if (r_) {
                      if (booking.spotBlockGuid != null) {
                        this._spotService
                          .deleteBlockedSpot(booking.spotBlockGuid)
                          .then((data) => {
                            if (data.ok) {
                              ShowAlert(
                                "",
                                "Block deleted successfully",
                                "success"
                              ).then(() => {
                                if (this.props.onDelete) {
                                  this.props.onDelete(true);
                                }
                              });
                            }
                          });
                      }
                    }
                  });
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }

  private filterArray() {
    let timeAskey: number[] = [];
    for (let eachSpot of this.state.filteredBookings.bookings) {
      if (!timeAskey.includes(eachSpot.spotStartTimeLocal.getTime())) {
        timeAskey.push(eachSpot.spotStartTimeLocal.getTime());
      }
    }

    this.setState({
      filteredSpots: [...timeAskey].sort(),
    });
  }

  private formatAMPM(date: Date) {
    let hours = date.getHours();
    let minutes: number | string = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  private getTimes() {
    let currentDayDate: Date = new Date(new Date().setHours(0, 0, 0, 0));
    let totalHours: number = 48;
    let hours: IBlockTime[] = [];
    for (let i = 0; i < totalHours; i++) {
      let currentDate = new Date(currentDayDate);
      currentDate.setMinutes((i / 2) * 60);
      let outputDate = new Date(currentDate);
      let time: IBlockTime = {
        dateTime: outputDate,
        timeString: format(outputDate, "hh:mm aa"),
      };
      hours.push(time);
    }

    return hours;
  }

  private getDropDownDomElementValue(event: any) {
    // event.preventDefault();
    var isDropDownView = event.target.closest("#filter-dcc");
    if (isDropDownView == null && this.state.filderDropdownButton) {
      this.setState({
        filderDropdownButton: false,
      });
    }
  }
}

interface IScheduledSpotProps {
  viewDate: Date;
  bookingsForDay: PropertyBookings;
  selectedSpotAggregation: IDailySpotAggregation | null;
  onSpotAggregationChange: (b_: IDailySpotAggregation) => void;
  onEditCicked: (b_: boolean) => void;
  onDelete: (b_: boolean) => void;
  onClose?: () => void;
  handleModify?: () => void;
  handleCancel?: () => void;
  onShowHistory: (booking: any) => void;
}

interface IScheduledSpotState {
  showFilter: boolean;
  filteredSpots: number[];
  selectedSpots: IBlockSpot[];
  times: IBlockTime[];
  selectedStartTime: IBlockTime | null;
  filteredBookings: PropertyBookings;
  bookingsForDay: PropertyBookings;
  isFiltered: boolean;
  selectedSpotAggregation?: IDailySpotAggregation | null;
  filderDropdownButton: boolean;
  bookingDetails: any;
}

interface IBlockSpot {
  spotGuid: string;
  friendlySpotId: string;
}
interface IBlockTime {
  dateTime: Date;
  timeString: string;
}
