import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import { ISearch } from "../Pages/BookingsCalendar/interfaces";

export default class RightPanelSearchListView extends React.Component<
  IRightPanelSearchListViewProps,
  IRightPanelSearchListViewState
> {
  private _searchBy: HTMLElement | null;
  constructor(props: IRightPanelSearchListViewProps) {
    super(props);
    this.state = {
      showSearchList: false,
      searchText: "",
      filterSearch: [],
      searchTexts: [],
      onMouseLeave: true,
    };
    this._searchBy = null;
  }

  componentDidMount() {
    const { searchTexts, selectedSearchText } = this.props;
    this.setState({
      searchTexts,
      searchText: selectedSearchText,
    });
  }

  componentDidUpdate(prevProps: IRightPanelSearchListViewProps) {
    const { searchTexts } = this.props;
    if (JSON.stringify(searchTexts) !== JSON.stringify(prevProps.searchTexts)) {
      this.setState({
        searchTexts: searchTexts,
      });
    }
  }

  render() {
    return (
      <div>
        {this.props.isHeader && (
          <h4 className="booking-heading ml-10-px">{this.props.headerText}</h4>
        )}
        <div
          className="row-center"
          onMouseUp={() => {
            this.setState({ onMouseLeave: false });
          }}
          onMouseLeave={() => {
            if (this.state.onMouseLeave) {
              return;
            }
            this.setState({
              showSearchList: false,
              onMouseLeave: true,
            });
          }}
        >
          <div
            style={{ width: "90%", marginTop: "15px" }}
            className="display-flex-direction"
          >
            <TextField
              fullWidth
              id="outlined-basic"
              size="medium"
              label=""
              variant="outlined"
              color="primary"
              autoComplete="off"
              value={this.state.searchText}
              placeholder={this.props.placeholder}
              onChange={(e: any) => {
                this.setState(
                  {
                    searchText: e.target.value,
                    showSearchList: true,
                  },
                  () => {
                    this.props.userSearch(this.state.searchText);
                  }
                );
              }}
              onFocus={() => {
                if (this.props.isOnFocus) {
                  this.setState({
                    showSearchList: true,
                  });
                }
              }}
              onMouseEnter={(event: any) => {
                event.target.focus();
              }}
              inputRef={(refElm) => {
                this._searchBy = refElm;
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {this.state.searchText && (
                      <IconButton
                        onClick={() => {
                          this.setState(
                            {
                              searchText: "",
                              searchTexts: [],
                            },
                            () => {
                              this.props.clearCallBack();
                            }
                          );
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {this.state.showSearchList && (
              <>
                <div
                  style={{
                    maxHeight: "300px",
                    overflowY: "scroll",
                    margin: "1px",
                    borderRadius: "2px",
                    overflowX: "hidden",
                    paddingRight: "10px",
                  }}
                >
                  <div>
                    {this.state.searchTexts.length > 0 &&
                      this.state.searchTexts.map((val, index) => (
                        <div key={`searchTexts_${index.toString()}`}
                          style={{
                            borderBottom: "1px solid #F8F8F8",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          onClick={() => {
                            setTimeout(() => {
                              this.setState(
                                {
                                  showSearchList: false,
                                },
                                () => {
                                  this.props.rightPanelCallBack(val.value);
                                }
                              );
                            }, 500);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid container>
                              <Grid item xs={10} className="searchList">
                                <label
                                  style={{
                                    margin: "8px",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {val.label}
                                </label>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                className="display-flex-align-items-center"
                              >
                                <Button
                                  key={`assignButton_${index.toString()}`}
                                  style={{ color: "#00AF85" }}
                                  onClick={() => {
                                    this.props.rightPanelCallBack(val.value);
                                  }}
                                >
                                  <div>{this.props.assignButtonText}</div>
                                </Button>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                {this.props.showAddButton && (
                  <>
                    <Button
                      style={{ color: "#00AF85" }}
                      className="justify-content-left"
                      onClick={() => {
                        this.props.rightPanelCallBack();
                      }}
                    >
                      +{this.props.addButtonText ?? " add new tenant"}
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

interface IRightPanelSearchListViewProps {
  showAddButton?: boolean;
  addButtonText?: string;
  isOnFocus?: boolean;
  placeholder: string;
  assignButtonText: string;
  isHeader: boolean;
  headerText?: string;
  userSearch: (search: string) => void;
  searchTexts: ISearch[];
  rightPanelCallBack: (id?: string) => void;
  clearCallBack: () => void;
  selectedSearchText: string;
}

interface IRightPanelSearchListViewState {
  searchText: string;
  showSearchList: boolean;
  filterSearch: any[];
  searchTexts: ISearch[];
  onMouseLeave: boolean;
}
