import React from "react";
import './Popup.css';
const DrawPopup = (_props: any) => {
    return (
        <div className="Popup_Screen">
            <div className="Popup_View">
                {_props.child}
            </div>

        </div>
    )
}
export default DrawPopup;