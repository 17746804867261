import BaseService from "./BaseService";
import {ConfigService} from '../Services/ConfigService';

export default class StripeTerminalService extends BaseService {

  public getStripeTerminal(url: string) {
    return ConfigService.getBaseUrl()
      .then(baseUrl => {
        return BaseService.sendRequest(`${baseUrl}${url}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }, undefined, true, true)
      });
  }

  public postStripeTerminal(url: string, bodyTerminal: any) {
    return ConfigService.getBaseUrl()
      .then(baseUrl => {
        return BaseService.sendRequest(`${baseUrl}${url}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyTerminal)
        }, undefined, true, true)
      });
  }

  public fetchConnectionToken() {
    return this.postStripeTerminal("spot/stripe-terminal/connection_token", {});
  }

  public capture(bodyTerminal: any) {
    return this.postStripeTerminal("spot/stripe-terminal/capture_payment_intent", bodyTerminal);
  }

  public cancelpayment(bodyTerminal: any) {
    return this.postStripeTerminal("spot/stripe-terminal/cancel_payment_intent", bodyTerminal);
  }
}
