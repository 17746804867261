import { Button, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ShowWarningMessage from "../ShowWarningMessage";
import { getMonthDifferenceFromCurrent } from '../getMonthDifferenceFromCurrent';
import './Popup.css';



const OverridePopup = (_props: any) => {
    const [value, setValue] = React.useState(String(_props.value));
    const [bookingStartDate] = useState(_props.startDate);
    const [monthDifference, setMonthDifference] = useState(0)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };
    useEffect(() => {

    }, [value])
    useEffect(() => {
        if (bookingStartDate) {
            const diff = getMonthDifferenceFromCurrent(bookingStartDate);
            setMonthDifference(diff);
        }

    }, [bookingStartDate])
    return (
        <>
            <div style={{ width: '100%', padding: '10px', display: 'flex', flexDirection: 'row', alignContent: 'center', alignSelf: 'center', alignItems: 'center', }}>
                {
                    monthDifference === 0 ? (
                        <RadioGroup aria-label="" name="" value={value} onChange={handleChange}>
                            <FormControlLabel value="1" disabled={_props.disabledOptions?.indexOf('1') !== -1} control={<Radio />} label="Override for this month" />                            
                            <FormControlLabel value="3" disabled={_props.disabledOptions?.indexOf('3') !== -1} control={<Radio />} label="Override for this month & going forward months" />
                            <FormControlLabel value="2" disabled={_props.disabledOptions?.indexOf('2') !== -1} control={<Radio />} label="Override for going forward months" />
                        </RadioGroup>
                    ) :(
                        <RadioGroup aria-label="" name="" value={value} onChange={handleChange}>
                            <FormControlLabel value="2" control={<Radio />} label="Override for going forward months" />
                        </RadioGroup>
                    )
                }

            </div>
            {_props.showWaringMessage && <div style={{ maxWidth: '390px', marginTop: '-18px' }}>
                <ShowWarningMessage message={_props.warningMessage}></ShowWarningMessage>
            </div>
            }
            <div style={{ width: '100%', padding: '10px', display: 'flex', flexDirection: 'row', alignContent: 'center', alignSelf: 'center', alignItems: 'center', 'justifyContent': 'space-evenly', }}>
                <Button style={{ 'width': '140px', 'backgroundColor': value !== '0' ? '#00AF85' : '#8A959E', 'color': 'white', 'borderRadius': '8px' }} onClick={() => {
                    _props.handelSubmit(Number(value));

                }}>Apply</Button>
                <Button style={{ 'width': '140px', 'backgroundColor': '#F0F0F0', 'borderRadius': '8px' }} onClick={() => {

                    _props.handelCancel();

                }}>Cancel</Button>
            </div>
        </>
    )
}
export default OverridePopup;
