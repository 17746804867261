import React from "react";
import { Grid } from '@material-ui/core';
import { WidgetModuleOption } from "./Interface";
import TrendingUp from '@material-ui/icons/TrendingUp';
import TrendingDown from '@material-ui/icons/TrendingDown';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';


export default class WidgetModule extends React.Component<WidgetModuleProps, WidgetModuleState> {
    constructor(props: WidgetModuleProps) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="w-module-flex">
                    <div className="w-module-flex w-row">
                        <div className="w-module-cell w-m-title" dangerouslySetInnerHTML={{ __html: this.props.module.title }}></div>
                        {
                            this.props.module.info && <div className='w-m-cell w-info'><InfoOutlinedIcon />
                                <div className='w-info-popup'>{this.props.module.info}</div>
                            </div>
                        }

                    </div>
                    <div className="w-module-flex w-row w-mt">
                        <div className="w-module-cell xs w-m-value">{this.props.module.value}</div>
                        <div className={"w-module-cell w-m-gvalue " + (this.props.module.isGrowth ? "positive" : "negative")}>{(this.props.module.isGrowth ? <TrendingUp /> : <TrendingDown />)} {this.props.module.growthValue}</div>
                    </div>
                </div>
            </>
        )
    }
}

interface WidgetModuleProps {
    module: WidgetModuleOption;
}

interface WidgetModuleState {

}